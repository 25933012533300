import React, { Component } from 'react';
import Footer from '../layout/Footer';


class projectsCN extends Component {
    // state = {

    // }
    // handleChange = (e) => {
    //     console.log(e);
    // }
    // handleSubmit = (e) => {
    //     console.log(e);
    // }
    render() {


        return (
            <section>
<header>


<nav className="nav-wrapper black">
<div className="container">
    <a href="/CN" className="brand-logo ">
        <span>Y.X.</span>
    </a>
    
    <a href="" className="sidenav-trigger" data-target="mobile-menu">
    <i className="material-icons">menu</i>
    </a>
    <ul className="right hide-on-med-and-down">
    <li><a href="/travelCN" >旅游</a></li>
    <li><a href="/musicCN">音乐</a></li>
    <li><a href="/calligraphyCN">书法</a></li>
    
    <li><a href="/catCN">🐈</a></li>

    <li><a href="https://www.instagram.com/crys___7" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-instagram"></i>
    </a></li>
    <li><a href="https://www.youtube.com/channel/UClv5NmxBvWIjid8crocDvZQ" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-youtube"></i>
    </a></li>
    <li><a href="https://www.facebook.com/profile.php?id=100006981077508" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-facebook"></i>
    </a></li>
    <li><a href="https://twitter.com/YueqingXie" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-twitter"></i>
    </a></li>
    <li><a href="/projects" className="btn btn-floating transparent">ENG</a></li>
    
    </ul>
    
    <ul className="sidenav grey lighten-2" id="mobile-menu">
        <li><a href="/travelCN" className="sidenavtext">旅游</a></li>
        <li><a href="/musicCN" className="sidenavtext">音乐</a></li>
        <li><a href="/calligraphyCN" className="sidenavtext">书法</a></li>
        <li><a href="/catCN" className="sidenavtext">我的猫</a></li>
        <li><a href="/CN#aboutmenavCN" className="sidenavtext">关于我</a></li>
            <br/>
            <li><a href="/projects" className="sidenavtext">ENG</a></li>

    </ul>
</div>
</nav>
</header>

  
            <section className="container section">

        
                <h4 className="titleENG center">YX-我的项目</h4>
            
              <div className="YX-Ps">
                    <div className="row">
                        <div className="col s12 m6 l6">
                            <p>YX-P11 (D3网站): </p>
                            <p className="grey-text">[D3&Firebase] 2021/03/31 - 2021/03/31</p>
                        </div>
                        <div className="col s12 m6 l6">
                            <a href="https://yxp11-d3p1.netlify.app/" target="_blank" rel="noopener noreferrer">https://yxp11-d3p1.netlify.app/</a>
                                <br/>
                                <br/>
                            <a href="https://yxp11-d3p2.netlify.app/" target="_blank" rel="noopener noreferrer">https://yxp11-d3p2.netlify.app/</a>                        
                        </div>
                    </div>
                </div>
        
              <div className="YX-Ps">
                    <div className="row">
                        <div className="col s12 m6 l6">
                            <p>YX-P10 (共享笔记): </p>
                            <p className="grey-text">[Django] 2021/03/12 - 2021/03/12</p>
                        </div>
                        <div className="col s12 m6 l6">
                            <a href="https://yxp10.herokuapp.com/" target="_blank" rel="noopener noreferrer">https://yxp10.herokuapp.com/</a>                      
                        </div>
                    </div>
                </div>
        
                <div className="YX-Ps">
                    <div className="row">
                        <div className="col s12 m6 l6">
                            <p>YX-P9 (食谱App): </p>
                            <p className="grey-text">[PWA&Firebase] 2021/02/11 - 2021/02/11</p>
                        </div>
                        <div className="col s12 m6 l6">
                            <a href="https://yx-project9.web.app/" target="_blank" rel="noopener noreferrer">https://yx-project9.web.app/</a>
                                <br/>
                                <br/>
                            <a href="https://yx-project9.firebaseapp.com/" target="_blank" rel="noopener noreferrer">https://yx-project9.firebaseapp.com/</a>                        
                        </div>
                    </div>
                </div>
        
                 <div className="YX-Ps">
                    <div className="row">
                        <div className="col s12 m6 l6">
                            <p>YXP8 (博客平台): </p>                            
                            <p className="grey-text">[NodeJS+Express+JWT&MongoDB] 2021/02/06 - 2021/02/08</p>
                        </div>
                        <div className="col s12 m6 l6">
                            <a href="https://yxp8.herokuapp.com/" target="_blank" rel="noopener noreferrer">https://yxp8.herokuapp.com/</a>  
                        </div>
                    </div>
                </div>
        
            
               <div className="YX-Ps">
                    <div className="row">
                        <div className="col s12 m6 l6">
                            <p>YX-P7 (音乐播放清单): </p>                            
                            <p className="grey-text">[Vue3&Firebase] 2021/01/30 - 2021/01/30</p>
                        </div>
                        <div className="col s12 m6 l6">
                            <a href="https://yx-project7.web.app" target="_blank" rel="noopener noreferrer">https://yx-project7.web.app</a>  
                             <br/>
                             <br/>
                            <a href="https://yx-project7.firebaseapp.com/" target="_blank" rel="noopener noreferrer">https://yx-project7.firebaseapp.com/</a>    
                        </div>
                    </div>
                </div>
        
            <div className="YX-Ps">
                    <div className="row">
                        <div className="col s12 m6 l6">
                            <p>YX-P6 (博客平台): </p>                            
                            <p className="grey-text">[Vue2&Firebase] 2021/01/28 - 2021/01/28</p>
                        </div>
                        <div className="col s12 m6 l6">
                            <a href="https://yxp6.netlify.app/" target="_blank" rel="noopener noreferrer">https://yxp6.netlify.app/</a>   
                        </div>
                    </div>
                </div>
        
            <div className="YX-Ps">
                    <div className="row">
                        <div className="col s12 m6 l6">
                            <p>YX-P5 (YX 简历): </p>                            
                            <p className="grey-text">[Bootstrap] 2021/01/24 - 2021/01/25</p>
                        </div>
                        <div className="col s12 m6 l6">
                            <a href="https://yxp5.netlify.app/" target="_blank" rel="noopener noreferrer">https://yxp5.netlify.app/</a>   
                        </div>
                    </div>
                </div>
        
            <div className="YX-Ps">
                    <div className="row">
                        <div className="col s12 m6 l6">
                            <p>YX-P4 (日常视频平台): </p>                            
                            <p className="grey-text">[Angular] 2021/01/23 - 2021/01/24</p>
                        </div>
                        <div className="col s12 m6 l6">
                            <a href="https://yxp4.netlify.app/" target="_blank" rel="noopener noreferrer">https://yxp4.netlify.app/</a>   
                        </div>
                    </div>
                </div>
        
              <div className="YX-Ps">
                    <div className="row">
                        <div className="col s12 m6 l6">
                            <p>YX-P3 (小说 "LiYuan"): </p>                            
                            <p className="grey-text">[React&GraphQL+MongoDB] 2021/01/18 - 2021/01/18</p>
                        </div>
                        <div className="col s12 m6 l6">
                            <a href="https://yxp3.netlify.app/" target="_blank" rel="noopener noreferrer">https://yxp3.netlify.app/</a>     
                             <br/>
                             <br/>
                            <a href="https://yx-p3.netlify.app/" target="_blank" rel="noopener noreferrer">https://yx-p3.netlify.app/</a>   
                        </div>
                    </div>
                </div>
        
                <div className="YX-Ps">
                    <div className="row">
                        <div className="col s12 m6 l6">
                            <p>YX-P2 (虚拟中国餐厅): </p>                            
                            <p className="grey-text">[PHP&MySQL] 2020/12/30 - 2020/12/31</p>
                        </div>
                        <div className="col s12 m6 l6">
                            <a href="https://yxp2.herokuapp.com/" target="_blank" rel="noopener noreferrer">https://yxp2.herokuapp.com/</a>              
                        </div>
                    </div>
                </div>
        
                <div className="YX-Ps">
                    <div className="row">
                        <div className="col s12 m6 l6">
                            <p>YX-P1 (虚拟旅游公司): </p>
                            <p className="grey-text">[React&Firebase] 2020/12/05 - 2020/12/06</p>
                        </div>
                        <div className="col s12 m6 l6">
                            <a href="https://yx-p1-57ad3.web.app/" target="_blank" rel="noopener noreferrer">https://yx-p1-57ad3.web.app/</a>
                                <br/>
                                <br/>
                            <a href="https://yx-p1-57ad3.firebaseapp.com/" target="_blank" rel="noopener noreferrer">https://yx-p1-57ad3.firebaseapp.com/</a>                        
                        </div>
                    </div>
                </div>

        
  <h4 className="titleENG center">YX--我的项目</h4>
                   <div className="YX-Ps">
                    <div className="row">
                        <div className="col s12 m6 l6">
                            <p>YX-ENJINE (网上订单): </p>
                            <p className="grey-text">[Angular11] 2021/04/20 - 2021/04/26</p>
                        </div>
                        <div className="col s12 m6 l6">
                            <a href="https://yx-enjine.netlify.app/" target="_blank" rel="noopener noreferrer">https://yx-enjine.netlify.app/</a>                     
                        </div>
                    </div>
                </div>
        
                  <div className="YX-Ps">
                    <div className="row">
                        <div className="col s12 m6 l6">
                            <p>YX-Mensio Mental Health (待办事项清单): </p>
                            <p className="grey-text">[React&Firebase] 2021/04/14 - 2021/04/14</p>
                        </div>
                        <div className="col s12 m6 l6">
                            <a href="https://yx-mmh.netlify.app/" target="_blank" rel="noopener noreferrer">https://yx-mmh.netlify.app/</a>                      
                        </div>
                    </div>
                </div>
        
            </section>

<Footer/>
            </section>
        )
    }
}





export default projectsCN;
