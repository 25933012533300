import React, { Component } from 'react';



class travelPictures4 extends Component {

    render() {
        
        return (
            <section>
        <header>


<nav className="nav-wrapper black">
<div className="container">
    <a href="/" className="brand-logo ">
        <span>Y.X.</span>
    </a>
    
    <a href="" className="sidenav-trigger" data-target="mobile-menu">
    <i className="material-icons">menu</i>
    </a>
    <ul className="right hide-on-med-and-down">
    <li><a href="/travel">Travel</a></li>
    <li><a href="/music">Music</a></li>
    <li><a href="/calligraphy">Calligraphy</a></li>
    
    <li><a href="/cat">🐈</a></li>

    <li><a href="https://www.instagram.com/crys___7" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-instagram"></i>
    </a></li>
    <li><a href="https://www.youtube.com/channel/UClv5NmxBvWIjid8crocDvZQ" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-youtube"></i>
    </a></li>
    <li><a href="https://www.facebook.com/profile.php?id=100006981077508" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-facebook"></i>
    </a></li>
    <li><a href="https://twitter.com/YueqingXie" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-twitter"></i>
    </a></li>
    <li><a href="/travelPicturesUSACN" className="btn btn-floating transparent">CN</a></li>
    
    </ul>
    
    <ul className="sidenav grey lighten-2" id="mobile-menu">
        <li><a href="/travel" className="sidenavtext">Travel</a></li>
        <li><a href="/music" className="sidenavtext">Music</a></li>
        <li><a href="/calligraphy" className="sidenavtext">Calligraphy</a></li>
        <li><a href="/cat" className="sidenavtext">My Cat</a></li>
        <li><a href="/#aboutmenav" className="sidenavtext">About Me</a></li>
            <br/>
            <li><a href="/travelPicturesUSACN" className="sidenavtext">CN</a></li>

    </ul>
</div>
</nav>
</header>

           
            <section className="container section" id="tabs">
            <div className="scrollspy" id="top">
            <div className="row">
            <div className="col s12 m12 l12 center">
            <ul className="tabs">
                    <li className="tab">
                    <a href="#picsChina" className=" indigo-text text-darken-4">China</a>
                    </li>
                    <li className="tab">
                    <a href="#picsThailand" className="indigo-text text-darken-4">Thailand</a>
                    </li>
                    <li className="tab">
                    <a href="#picsCanada" className="indigo-text text-darken-4">Canada</a>
                    </li>
                    <li className="tab">
                    <a href="#picsUSA" className="active indigo-text text-darken-4">USA</a>
                    </li>
            </ul>
            </div>
            </div>
            </div>

            <section className="container section" id="picsChina">
            <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/1.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Performing Arts Centre</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/2.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Beihai Park</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/3.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Modernista</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/4.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Prince Gong Mansion</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>


        <div className="row">
        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/5.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Prince Gong Mansion</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/6.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">National Library</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/7.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Siji Minfu Roast Duck</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/8.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Siji Minfu Roast Duck</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>

        <div className="row">
        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/9.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Siji Minfu Roast Duck</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/10.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Siji Minfu Roast Duck</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/11.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Siji Minfu Roast Duck</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/12.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">National Museum</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>

        <div className="row">
        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/13.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Chong Qing</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/14.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Panda Center</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/15.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Yungang Grottoes</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/16.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Huang Long</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>

        <div className="row">
        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/17.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Jiuzhai Valley - Long Sea</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/18.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Multicolored pool</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/19.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Panda sea</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/20.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Pearl Beach Waterfall</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>

        <div className="row">
        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/21.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Terracotta Army</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/22.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Da-Yan Tower fountain</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/23.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Songzanlin Temple</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/24.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Lamuyangcuo Lake</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>

        <div className="row">
        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/25.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Lamuyangcuo Lake</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/26.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Napa Sea</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/27.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Dukezong Ancient City</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/28.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Tiger Leaping Gorge</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>

        <div className="row">
        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/29.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Lugu Lake</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/30.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Erhai Lake</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/31.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Botanical Garden</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/32.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Jinma Biji Fang</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>


        <div className="row">
        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/33.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Jiuxiang Scenic Area</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/34.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Jiuxiang Scenic Area</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/35.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Stone Forest</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/36.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Manting Park</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>

        <div className="row">
        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/37.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Jinci Temple</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/38.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Wang Family Courtyard</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/39.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Hanging Temple</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/40.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Huayan Temple</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>

        <div className="row">
        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/41.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Daming Lake</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/42.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Ji Nan</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/43.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Baotu Spring</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/44.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Jiuzhangya Park</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>

        <div className="row">
        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/45.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Shen Yang 918</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/46.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Changbai mountain pool</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/47.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Dalian Discoveryland</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/48.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">The Tientsin Eye</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>

        <div className="row">
        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/49.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Lion Forest Garden</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/50.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">West Lake</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/51.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Wu Zhen ancient town</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img3/52.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Shang Hai</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>

            </section>


            <section className="container section" id="picsThailand">
            <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img4/1.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Coral Island</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img4/2.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Racha Island</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img4/3.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Kata Beach</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img4/4.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Prom Thep Cape</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>


        <div className="row">
        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img4/5.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Prom Thep Cape</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img4/6.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Maya Bay</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img4/7.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Coral Island</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img4/8.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Karon Beach</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>

        <div className="row">
        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img4/9.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Maya Bay</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img4/10.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Pa Tong Beach</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img4/11.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Chiang Mai night market</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img4/12.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Zipline adventures</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>

        <div className="row">
        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img4/13.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Nimmanhaemin Road</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img4/14.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Chiang Mai Night Safari</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img4/15.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Chiang Mai Night Safari</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img4/16.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Bangkok SIAM</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>

            </section>


            <section className="container section" id="picsCanada">
            <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/1.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">CN Tower</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/2.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">High Park</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/3.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Hign Park</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/4.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Niagara Falls</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>


        <div className="row">
        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/5.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Wonderland</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/6.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Wonderland</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/7.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Wonderland</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/8.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Woodbine</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>

        <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/9.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Woodbine</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/10.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Art Gallery</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/11.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">City Hall</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/12.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Royal Museum</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>


        <div className="row">
        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/13.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Toronto Zoo</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/14.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Centre Island</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/15.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Eaton Centre</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/16.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Mont Royal</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>

        <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/17.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Notre-Dame</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/18.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Maple Tree Sq.</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/19.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">China Town</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/20.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Stanley Park</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>


        <div className="row">
        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/21.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">English Bay</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/22.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Canada Place</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/23.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">uOttawa</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/24.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Parliament Hill</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>

        <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/25.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Parliament Hill</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/26.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">ByWard</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/27.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Notre-Dame</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/28.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">NationalGallery</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>


        <div className="row">
        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/29.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Commissioners</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/30.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Parc Omega</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/31.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Thousand Islands</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img5/32.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Old Quebec</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>

            </section>


            <section className="container section" id="picsUSA">
            <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img6/1.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Universal Studios Florida</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img6/2.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Islands of Adventure</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img6/3.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Islands of Adventure</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img6/4.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Islands of Adventure</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>

        <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img6/5.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Magic Kingdom</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img6/6.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Magic Kingdom</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img6/7.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Magic Kingdom</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img6/8.png" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Epcot</span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>
            </section>

            <a href="#top" className="ToTheTopIcon">
                <i className="material-icons">arrow_upward</i>
            </a>

        </section>
        <footer className="page-footer black darken-3">
                <div className="container">
                <div className="row">

                    <div className="flex text-xs-center col l1 m2 s12">
                        {/* <img src="../img/me_small.png"></img> */}
                        <p className="center">Y.X.</p>
                    </div>

                    <div className="col l5 offset-l1 m5 s12 hide-on-small-only">
                    <h5>Who is Y.X.?</h5>
                    <p>Y.X. graduated from uOttawa in 2020. She studied computer science and mathematics. She enjoys programming, travelling & playing instruments.</p>
                    </div>
              

                    <div className="col l4 offset-l1 m5 s12">
                    {/* <h5 className="white-text">Connect</h5> */}
                    <div className="text-xs-center mx my social-icons">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/crys___7" className=" btn-floating btn-small transparent" >
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UClv5NmxBvWIjid8crocDvZQ" className=" btn-floating btn-small transparent">
                        <i className="fab fa-youtube"></i>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=100006981077508" className=" btn-floating btn-small transparent">
                        <i className="fab fa-facebook"></i>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/YueqingXie" className=" btn-floating btn-small transparent">
                        <i className="fab fa-twitter"></i>
                    </a>
                    </div>
                    </div>
                </div>
                </div>
                <div className="footer-copyright grey darken-4">
                <div className="container copyright center-align">Copyright &copy; 2020 Y.X.</div>
                </div>
            </footer>
  
        </section>

        )
    }
}




export default travelPictures4;