import React, { Component } from 'react';
import Footer from '../layout/Footer';


class travelNoteBeijingCN extends Component {
    
    render() {


        return ( 
        
            <section>
<header>


<nav className="nav-wrapper black">
<div className="container">
    <a href="/CN" className="brand-logo ">
        <span>Y.X.</span>
    </a>
    
    <a href="" className="sidenav-trigger" data-target="mobile-menu">
    <i className="material-icons">menu</i>
    </a>
    <ul className="right hide-on-med-and-down">
    <li><a href="/travelCN" >旅游</a></li>
    <li><a href="/musicCN">音乐</a></li>
    <li><a href="/calligraphyCN">书法</a></li>
    
    <li><a href="/catCN">🐈</a></li>

    <li><a href="https://www.instagram.com/crys___7" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-instagram"></i>
    </a></li>
    <li><a href="https://www.youtube.com/channel/UClv5NmxBvWIjid8crocDvZQ" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-youtube"></i>
    </a></li>
    <li><a href="https://www.facebook.com/profile.php?id=100006981077508" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-facebook"></i>
    </a></li>
    <li><a href="https://twitter.com/YueqingXie" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-twitter"></i>
    </a></li>
    <li><a href="/travelNoteBeijing" className="btn btn-floating transparent">ENG</a></li>
    
    </ul>
    
    <ul className="sidenav grey lighten-2" id="mobile-menu">
        <li><a href="/travelCN" className="sidenavtext">旅游</a></li>
        <li><a href="/musicCN" className="sidenavtext">音乐</a></li>
        <li><a href="/calligraphyCN" className="sidenavtext">书法</a></li>
        <li><a href="/catCN" className="sidenavtext">我的猫</a></li>
        <li><a href="/CN#aboutmenavCN" className="sidenavtext">关于我</a></li>
            <br/>
            <li><a href="/travelNoteBeijing" className="sidenavtext">ENG</a></li>

    </ul>
</div>
</nav>
</header>
          
                <div className="row hide-on-small-only" id="travelNoteBeijing">
                <div className="col s2 m2 l2">
                    <div>
                        {/* <nav className="nav">
                            <a href="#video4">title 4</a>
                        </nav> */}
                        
                        <ul className="nav">
                        <div className="bigTitle">北京</div>
                            <li className="titleDate">
                                <a href="#title1">2019/07/28</a>
                            </li>
                            <li className="smallTitle">
                                <a>1.1/ </a>
                                <a href="#title1">国家大剧院</a>
                            </li>

                            <li className="titleDate">
                                <a href="#title2">2019/07/29</a>
                            </li>
                            <li className="smallTitle">
                                <a>2.1/ </a>
                                <a href="#title2">景山公园</a>
                            </li>
                            <li className="smallTitle">
                                <a>2.2/ </a>
                                <a href="#title3">北海公园</a>
                            </li>
                            <li className="smallTitle">
                                <a>2.3/ </a>
                                <a href="#title4">Modernista</a>
                            </li>

                            <li className="titleDate">
                                <a href="#title5">2019/07/30</a>
                            </li>
                            <li className="smallTitle">
                                <a>3.1/ </a>
                                <a href="#title5">恭王府</a>
                            </li>
                            <li className="smallTitle">
                                <a>3.2/ </a>
                                <a href="#title6">中国地质博物馆</a>
                            </li>
                            <li className="smallTitle">
                                <a>3.3/ </a>
                                <a href="#title7">中国国家图书馆</a>
                            </li>
                            <li className="smallTitle">
                                <a>3.4/ </a>
                                <a href="#title8">海淀剧院</a>
                            </li>

                            <li className="titleDate">
                                <a href="#title9">2019/07/31</a>
                            </li>
                            <li className="smallTitle">
                                <a>4.1/ </a>
                                <a href="#title9">四季民福</a>
                            </li>
                            <li className="smallTitle">
                                <a>4.2/ </a>
                                <a href="#title10">中国国家博物馆</a>
                            </li>
                        
                        </ul>
                    </div>
                </div>

                <div className="col s9 m9 l9 offset-s1 offset-m1 offset-l1 noteBeijing">
                    {/* <div className="video" id="video1">
                        <video controls src="../video/1.mp4" width="560" height="315"></video>
                    </div>
                    <div className="video" id="video2">
                        <video controls src="../video/1.mp4" width="560" height="315"></video>
                    </div>
                    <div className="video" id="video3">
                        <video controls src="../video/1.mp4" width="560" height="315"></video>
                    </div>
                    <div className="video" id="video4">
                        <video controls src="../video/1.mp4" width="560" height="315"></video>
                    </div>
                    <div className="video" id="video5">
                        <video controls src="../video/1.mp4" width="560" height="315"></video>
                    </div>
                    <div className="video" id="video6">
                        <video controls src="../video/1.mp4" width="560" height="315"></video>
                    </div> */}

                        <div className="row">
                            <div className="col s10 m10 l10 offset-s1 offset-m1 offset-l1 ">
                            <div className="row travelDetail">
                                <div className="time col s12 m4 l4">
                                    <i className=" material-icons left">access_time</i>
                                    <span>出发时间</span>
                                    <span> / </span>
                                    <span>2019-07-28</span>
                                </div>
                                <div className="day col s12 m4 l4">
                                    <i className=" material-icons left">wb_sunny</i>
                                    <span>出行天数</span>
                                    <span> / </span>
                                    <span>4天</span>
                                </div>
                                <div className="people col s12 m4 l4">
                                    <i className=" material-icons left">account_circle</i>
                                    <span>人物</span>
                                    <span> / </span>
                                    <span>和朋友</span>
                                </div>
                            </div>
                            <div className="col s1 m1 l1"></div>
                            </div>
                        </div>

                    <div className="titles" id="title1">
                        <h4 className="tooltipped title" data-tooltip="National Centre for the Performing Arts of China">国家大剧院</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img7/1.jpg"  alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/National+Centre+for+the+Performing+Arts/@39.904799,116.3874825,17z/data=!3m1!4b1!4m5!3m4!1s0x35f0528e22e7a273:0x8b74ec5b2f1aaa87!8m2!3d39.904799!4d116.3896712">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">国家大剧院</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">

                        <a className="btn-flat modal-trigger right info " href="#modal4">门票价格</a>
                        <div id="modal4" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">国家大剧院 - 门票价格</h4>
                            <p>门票价格: 参观票价分为成人票40元/张和半价票20元/张</p>
                            <p className="grey-text">Price: 40 yuan/adult and 20 yuan/half-price ticket</p>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        <a className="btn-flat modal-trigger right info " href="#modal3">地址</a>
                        <div id="modal3" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">国家大剧院 - 地址</h4>
                            <p>地址: 中国国家大剧院位于北京市西城区西长安街2号</p>
                            <p className="grey-text">Address: 2 W Chang'an Ave, Xicheng District, China, 100031</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/National+Centre+for+the+Performing+Arts/@39.904799,116.3874825,17z/data=!3m1!4b1!4m5!3m4!1s0x35f0528e22e7a273:0x8b74ec5b2f1aaa87!8m2!3d39.904799!4d116.3896712">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Find it on map...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        <a className="btn-flat modal-trigger right info " href="#modal2">建筑</a>
                        <div id="modal2" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">国家大剧院 - 建筑</h4>
                            <p>国家大剧院占地11.893万平方米，总建筑面积149520平方米，主体建筑由外部围护结构和内部歌剧院、戏剧场、音乐厅和公共大厅及配套用房组成。国家大剧院共設有5366个座位，其中歌剧院席位2081个，戏剧院席位957个，音乐厅席位1859个，小剧场席位469个。
<br/>
<br/>
外部围护钢结构壳体呈半椭球形，其平面投影东西长212.20米，南北宽143.64米，建筑物高46.285米，基础埋深的最深部分达到32.5米。
椭球形屋面主要采用钛金属板饰面，中部为渐开式玻璃幕墙。椭球壳体外环绕人工湖，湖面面积达35500平方米，各种通道和入口都设在水面下。</p>
                            <p className="grey-text">The National Centre for the Performing Arts covers an area of 118,93 thousand square meters, with a total construction area of 149,520 square meters. The main building consists of an external envelope and an internal opera house, theater, concert hall, public hall and supporting rooms. The National Centre for the Performing Arts has a total of 5366 seats, including 2081 seats in the opera theater, 957 seats in the theater, 1859 seats in the concert hall, and 469 seats in the small theater.
<br/>
<br/>
The outer envelope steel structure shell is semi-ellipsoidal, and its plane projection is 212.20 meters long from east to west, 143.64 meters wide from north to south, and the building is 46.285 meters high. The deepest part of the foundation is 32.5 meters.
The ellipsoidal roof is mainly finished with titanium metal plate, and the middle part is a gradually opening glass curtain wall. The ellipsoid shell surrounds the artificial lake, with an area of 35,500 square meters, and various channels and entrances are located under the water surface.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E5%9B%BD%E5%AE%B6%E5%A4%A7%E5%89%A7%E9%99%A2">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        <a className="btn-flat modal-trigger right info " href="#modal1">历史</a>
                        <div id="modal1" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">国家大剧院 - 历史</h4>
                            <p>工程于2001年12月13日开工，2007年9月建成，2007年9月25日进行试演、投入使用，2007年12月22日正式投入运营。国家大剧院位于中国北京人民大会堂西侧，总投资26.8838亿元人民币，设计师为法国建筑师保罗·安德鲁。</p>

                            <p className="grey-text">The project started on December 13, 2001 and was completed in September 2007. It was audited and put into use on September 25, 2007. It was officially put into operation on December 22, 2007. The National Grand Theater is located on the west side of the Great Hall of the People in Beijing, China, with a total investment of 2.68838 billion yuan. The designer is French architect Paul Andreu.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E5%9B%BD%E5%AE%B6%E5%A4%A7%E5%89%A7%E9%99%A2">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2019/07/28 --- 6:30pm</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/1.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>我们买了7:30pm-9:30pm的菅野邦彦爵士钢琴音乐会的票。</p>
                                    <p>买了演出票的话，可以提前1h检票参观国家大剧院
                                        <br/>
                                    (不用再额外买参观票)。</p>
                                    <p>中国国家大剧院内，设有歌剧院、音乐厅、戏剧场以及艺术展厅、餐厅、音像商店等配套设施。手机没电了的话，可在剧院内的艺术展厅租借充电宝。</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/2.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>菅野邦彦爵士钢琴音乐会是在音乐厅内举办。</p>
                                    <p>音乐厅风格清新、高雅，适于演奏大型交响乐、民族乐，并可举办各种音乐会，有1859个席位（含站席）。音乐厅内拥有国内最大的管风琴，能满足各种不同流派作品演出的需要。此外数码墙、极具现代美感的抽象浮雕天花板、GRC墙面、龟背反声板等设计能令声音均匀、柔和地扩散反射，使音乐厅实现了建筑美学和声学美学的结合。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%B8%AD%E5%9B%BD%E5%9B%BD%E5%AE%B6%E5%A4%A7%E5%89%A7%E9%99%A2/1063477?fromtitle=%E5%9B%BD%E5%AE%B6%E5%A4%A7%E5%89%A7%E9%99%A2&fromid=69546&fr=aladdin#2_2">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>


                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/3.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>菅野邦彦爵士钢琴音乐会 - 刚刚开场时快速拍的一张照片</p>
                                    <p>菅野邦彦 - 他大学期间在吉屋润的乐队开始了演奏生涯。毕业后仅工作了一年后，又回到了音乐的世界。1960年，开始了与铃木勋、GEORGE大塚的三重奏。并且被当时来日的托尼·斯科特大加赞赏，从此一同开始了音乐演出。1963年至1964年期间，与松本英彦重新组建了四重奏。解散后，作为钢琴独奏家，发行了70张黑胶唱片。菅野的充满布鲁斯感的钢琴独奏不仅在日本受到乐迷的追捧，在全世界的爵士界都被认同。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="http://www.chncpaticket.org/pic_detail.asp?id=5782">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/4.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>夜晚的国家大剧院</p>
                                    <p>音乐会结束后，我们三个人沿着湖边慢走，湖面上还会有小鸭子，十分惬意。</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <div className="titles" id="title2">
                        <h4 className="tooltipped title" data-tooltip="Jingshan Park">景山公园</h4>
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img7/2.jpg"  alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Jingshan+Park/@39.9251029,116.3946546,17z/data=!3m1!4b1!4m5!3m4!1s0x35f052e0cf4da489:0x95549f0b9c7f635b!8m2!3d39.9250988!4d116.3968433">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">景山公园</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                            <div className="details">

<a className="btn-flat modal-trigger right info " href="#modal8">门票价格</a>
<div id="modal8" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">景山公园 - 门票价格</h4>
    <p>门票价格: 成人票2元/张; 半价票1元/张</p>
    <p className="grey-text">Price: 2 yuan/adult; 1 yuan/half-price ticket</p>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal7">地址</a>
<div id="modal7" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">景山公园 - 地址</h4>
    <p>地址: 北京市西城区景山西街44号</p>
    <p className="grey-text">Address: 44 Jingshan W St, Xicheng District, Beijing, China, 100009</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Jingshan+Park/@39.9251029,116.3946546,17z/data=!3m1!4b1!4m5!3m4!1s0x35f052e0cf4da489:0x95549f0b9c7f635b!8m2!3d39.9250988!4d116.3968433">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Find it on map...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal6">建筑</a>
<div id="modal6" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">景山公园 - 建筑</h4>
    <p>景山公园管理处隶属于北京市公园管理中心，位于中国北京市西城区景山前街。公园中有银杏园、海棠园、牡丹园、桃园、苹果园、葡萄园、柿子林。全园坐北朝南，红墙黄瓦围墙，占地23万平方米。山高43米，周长1015米。园内花卉草坪占地1100 平方米。</p>
    <p className="grey-text">Jingshan Park Management Office is affiliated to Beijing Park Management Center and is located in Jingshanqian Street, Xicheng District, Beijing, China. There are ginkgo garden, begonia garden, peony garden, peach garden, apple garden, vineyard, and persimmon forest in the park. The whole park faces south, with red walls and yellow tiles, covering an area of 230,000 square meters. The mountain is 43 meters high and has a circumference of 1015 meters. The flower lawn in the garden covers an area of 1,100 square meters.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%99%AF%E5%B1%B1%E5%85%AC%E5%9B%AD/63814?fr=aladdin#3">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>   
<br/>
<br/>
    <p>建筑包含围墙与河道，内墙，外墙，桥梁，城隍庙，景山五亭。</p>
    <p className="grey-text">The building includes surrounding walls and river channels, inner walls, outer walls, bridges, Chenghuang Temple, Jingshan Five Pavilions.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E6%99%AF%E5%B1%B1%E5%85%AC%E5%9B%AD#%E5%BB%BA%E7%AD%91">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>   

    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal5">历史</a>
<div id="modal5" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">景山公园 - 历史</h4>
    <p>明朝洪武初年，工部郎中萧洵参与拆毁元故宫，亲览后苑景色以及金殿、翠殿、花亭、毡阁等建筑。永乐年间，明成祖朱棣在北京大规模营建城池、宫殿和园林。依据“苍龙、白虎、朱雀、玄武，天之四灵，以正四方”之说，紫禁城之北乃是玄武之位，当有山。故将挖掘紫禁城筒子河和太液、南海的泥土堆积在“青山”，形成五座山峰，称“万岁山”。
<br/>
<br/>
明崇祯十七年（1644年）三月十九日，李自成攻入北京，是为甲申之变，明思宗自缢于万岁山东麓一株老槐树上。清军入关后，为笼络人心，将此槐树称为“罪槐”，用铁链锁住，并规定皇族、文武官员路过此地都要下马步行，以示对明思宗的尊敬。
<br/>
<br/>
清顺治十二年（1655年）将“万岁山”改称“景山”。康熙十九年（1680年）春，康熙帝登景山眺望京师，见晨雾缭绕，霞光流云，一派春色，即作诗一首，其中有“云霄千尺倚丹丘，辇下山河一望收”之句。丹丘乃神仙居所，此处以其比喻景山.
<br/>
<br/>
光绪二十六年（1900年）庚子事变时，八国联军占领北京，景山受到严重破坏。民国17年（1928年），景山被辟为公园，属故宫博物院管理，修葺后供游人观赏。 </p>
    <p className="grey-text">In the early years of Hongwu in the Ming Dynasty, Xiao Xun, a medic of the Ministry of Industry, participated in the demolition of the Yuan Palace, and saw the scenery of the back garden, the golden palace, the green palace, the flower pavilion, and the felt pavilion. During the Yongle period, Ming Chengzu Zhu Di built large-scale cities, palaces and gardens in Beijing. According to the saying "Canglong, White Tiger, Suzaku, Xuanwu, the four spirits of the sky, in order to be square", the north of the Forbidden City is the place of Xuanwu, and there should be mountains. Therefore, the soil from the Tongzi River, Taiye, and South China Sea in the Forbidden City was piled up on the "green hills" to form five peaks, called "Long Live Mountain".
<br/>
<br/>
On March 19 in the 17th year of Ming Chongzhen (1644), Li Zicheng invaded Beijing, which was a change in Jiashen. Ming Sizong hanged himself on an old locust tree at the foot of Long Live Mountain. After the Qing army entered the customs, in order to win people's hearts, the locust tree was called the "guilt locust", and it was locked with iron chains, and the royal family and civil and military officials were required to dismount and walk when passing by to show their respect for Ming Sizong.
<br/>
<br/>
In the twelfth year of Shunzhi in Qing Dynasty (1655), "Long Live Mountain" was renamed "Jingshan". In the spring of the nineteenth year of Kangxi (1680), Emperor Kangxi climbed Jingshan to look at the capital. Seeing the morning mist, the rays of light and the flowing clouds, he wrote a poem, including "The clouds are thousands of feet leaning against the Danqiu, and the mountains and rivers are overlooked. "Sentence. Danqiu is the abode of gods, and here is a metaphor for Jingshan.
<br/>
<br/>
During the Gengzi Incident in the twenty-sixth year of Guangxu (1900), the Eight-Nation Alliance occupied Beijing and Jingshan was severely damaged. In the 17th year of the Republic of China (1928), Jingshan was turned into a park, which was managed by the Palace Museum and was repaired for visitors to watch.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E6%99%AF%E5%B1%B1%E5%85%AC%E5%9B%AD">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
                            </div>
                        </div>

                        <div className="titleContent">
                            <h5>2019/07/29</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/5.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>明思宗殉国处</p>
                                    <p>在北京景山公园里，有一棵为人熟知的老槐树，据说明朝最后一位皇帝崇祯在此自缢。树的旁边，矗立着两座石碑。一座镌刻“明思宗殉国处”六个大字，为1930年故宫博物院延请著名书法家沈尹默书写勒成；另一座则是1944年由前清翰林傅增湘所作的《明思宗殉国三百年纪念碑》。</p>
                                    <p>作为明朝的第十六位皇帝，崇祯大概怎么也不会预料到自己将会成为明朝的亡国之君。在他之前，像嘉靖、万历两位皇帝，数十年不上朝，已经导致明朝内部政治腐败愈发严重，外部来自后金的侵扰也逐步加深。反观崇祯，即位之初便力图革新吏治，处置了以魏忠贤为首的阉党，“慨然有为”，给人带来了一丝希望。怎奈国家积弊已久，纵然崇祯再努力，也已无力挽救危局。内忧外患的夹击，最终促成了崇祯的自缢和明朝的灭亡。</p>
                                    <p>1944年，为农历甲申年，也是明朝灭亡整三百年，在崇祯自缢之处新立了一座纪念碑，这便是《明思宗殉国三百年纪念碑》。碑文由曾任北洋政府教育总长的著名藏书家傅增湘撰成。在近千字的碑文中，不仅可以看到傅先生对明朝灭亡和崇祯之死的追溯，更能感受到他文字背后的深层内涵和关怀。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="http://culture.people.com.cn/n/2015/0618/c22219-27174724.html">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>

                        </div>
                        

                    </div>

                    <div className="titles" id="title3">
                        <h4 className="tooltipped title" data-tooltip="Beihai Park">北海公园</h4>
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img7/3.jpg"  alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Beihai+Park/@39.9254515,116.3870752,17z/data=!3m1!4b1!4m5!3m4!1s0x35f052e6b32b6159:0xe22c80c89d0ec575!8m2!3d39.9254474!4d116.3892639">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">北海公园</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                            <div className="details">

<a className="btn-flat modal-trigger right info " href="#modal12">门票价格</a>
<div id="modal12" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">北海公园 - 门票价格</h4>
    <p>门票价格: 联票成人票20元/张; 半价票10元/张</p>
    <p className="grey-text">Price: 20 yuan/adult and 10 yuan/half-price ticket</p>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal11">地址</a>
<div id="modal11" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">北海公园 - 地址</h4>
    <p>地址: 北京市西城区文津街1号</p>
    <p className="grey-text">Address: 1 Wenjin St, Xicheng District, China, 100034</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Beihai+Park/@39.9254515,116.3870752,17z/data=!3m1!4b1!4m5!3m4!1s0x35f052e6b32b6159:0xe22c80c89d0ec575!8m2!3d39.9254474!4d116.3892639">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Find it on map...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal10">建筑</a>
<div id="modal10" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">北海公园 - 建筑</h4>
    <p>北海全园占地约70公顷，其中水域占据了一半以上的面积。太池中有琼华、团城和犀山台三岛，分别象征着蓬莱、瀛洲和方丈，体现了对蓬莱仙境的追求。是一座纯粹的人工园林，布局以水为主体，在太液池中布置岛屿，用桥和岸边相连。
<br/>
<br/>
它的建筑风格受到一些江南园林的影响，但总体上仍然保持了北方园林持重端庄的特点。园内宗教色彩十分浓厚，不仅琼华岛上有永安寺，在北岸和东岸还有阐福寺、西天梵境、小西天、龙王庙、先蚕坛等佛教、道教建筑，因此是一座集宫室、宅第、寺庙、园林于一体的宏大帝王宫苑。</p>
    <p className="grey-text">The entire Beihai Park occupies an area of about 70 hectares, of which the water area occupies more than half of the area. There are Qionghua, Tuancheng and Xishantai islands in Taichi, which symbolize Penglai, Yingzhou and Fangzhang, respectively, and embody the pursuit of Penglai's fairyland. It is a purely artificial garden with water as the main body. The islands are arranged in the Taiye Pool and connected to the shore by bridges. 
<br/>
<br/>
Its architectural style is influenced by some gardens in the south of the Yangtze River, but on the whole it still maintains the characteristics of dignity and dignity of northern gardens. The garden is very religious. There is not only Yong'an Temple on Qionghua Island, but also Buddhist and Taoist buildings such as Chanfu Temple, Xitian Fanjing, Xiaoxitian, Longwang Temple, and Xiancantan on the north and east banks. A grand imperial palace with palaces, mansions, temples and gardens.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E5%8C%97%E6%B5%B7%E5%85%AC%E5%9B%AD">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal9">历史</a>
<div id="modal9" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">北海公园 - 历史</h4>
    <p>北海先后历经辽、金、元、明、清五朝的兴建，历史悠久且重建时承袭较多。清代末光绪十一年（1885年），慈禧太后私自动用海军军费增修过北海。1900年八国联军攻占北京时，对北海有过较大的破坏，两年后进行了重建。中华人民共和国成立后，中南海成为了中央人民政府的驻地，北海作为公园开放。1961年，北海及团城被中华人民共和国国务院公布为第一批全国重点文物保护单位之一。1969年至1979年，北海公园以“保护中南海安全”为由关闭，禁止游人入内。1979年后北海公园重新对公众开放。</p>

    <p className="grey-text">Beihai has undergone the construction of the Liao, Jin, Yuan, Ming, and Qing dynasties successively, with a long history and many inheritances during reconstruction. In the eleventh year of Guangxu at the end of Qing Dynasty (1885), Empress Dowager Cixi used the navy and military expenses to increase the Beihai privately. When the Eight-Nation Alliance Forces captured Beijing in 1900, they caused considerable damage to the Beihai, and they were rebuilt two years later. After the founding of the People's Republic of China, Zhongnanhai became the residence of the Central People's Government and Beihai opened as a park. In 1961, Beihai and Tuancheng were announced by the State Council of the People's Republic of China as one of the first batch of national key cultural relics protection units. From 1969 to 1979, Beihai Park was closed on the grounds of "protecting Zhongnanhai", and visitors were prohibited from entering. After 1979, Beihai Park was reopened to the public.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E5%8C%97%E6%B5%B7%E5%85%AC%E5%9B%AD">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
                            </div>
                        </div>
                        <div className="titleContent">
                            <h5>2019/07/29</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/6.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>从景山公园出来后，步行几分钟就可以到达北海公园。</p>
                                    <p>全园以北海为中心，主要由琼华岛、东岸和北岸景区组成。琼华岛上树木苍郁，亭台楼阁幽静，白塔耸立山巅，成为公园的标志。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%8C%97%E6%B5%B7%E5%85%AC%E5%9B%AD/253388?fr=aladdin#3_3">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/7.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>北海白塔，位于北京北海公园琼华岛上。</p>
                                    <p>建于清初顺治八年(1651)，是一座藏式喇嘛塔，也是北海的标志性景点。北海白塔据建塔石碑记载，当时"有西域喇嘛者，欲以佛教阴赞皇猷，请立塔寺，寿国佑民"，得到皇帝的恩准，于是修建了永安寺和白塔。
<br/>
<br/>
塔身全部为砖木石混合结构，由塔基、塔身、相轮、华盖、塔刹五部分组成。塔身为覆钵式，因此从塔的表面只能看到砖和石料而见不到木构架。塔高35.9 米，上圆下方，富有变化，为须弥山座式，塔顶设有宝盖、宝顶，并装饰有日、月及火焰花纹，以表示“佛法”像日、月那样光芒四射，永照大地。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%8C%97%E6%B5%B7%E7%99%BD%E5%A1%94/748542?fr=aladdin">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>

                            </div>



                        </div>
                        
                    </div>

                    <div className="titles" id="title4">
                        <h4 className="title">Modernista</h4>
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img7/4.jpg"  alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/44+Baochao+Hutong,+Dongcheng+Qu,+Beijing+Shi,+China,+100190/@39.9445096,116.3996862,19.89z/data=!4m5!3m4!1s0x35f0533ee6163ddd:0x16b7703fe6644049!8m2!3d39.944584!4d116.399862">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">Modernista</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                            <div className="details">

<a className="btn-flat modal-trigger right info " href="#modal16">门票价格</a>
<div id="modal16" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">Modernista - 门票价格</h4>
    <p>门票价格: 90元/张</p>
    <p className="grey-text">Price: 90 yuan/ticket</p>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal15">地址</a>
<div id="modal15" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">Modernista - 地址</h4>
    <p>地址: 北京市东城区鼓楼东大街宝钞胡同44号</p>
    <p className="grey-text">Address: 44 Baochao Hutong, Dongcheng, Beijing, China</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/44+Baochao+Hutong,+Dongcheng+Qu,+Beijing+Shi,+China,+100190/@39.9445881,116.3976733,17z/data=!3m1!4b1!4m5!3m4!1s0x35f0533ee6163ddd:0x16b7703fe6644049!8m2!3d39.944584!4d116.399862">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Find it on map...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
                            </div>
                        </div>
                        <div className="titleContent">
                            <h5>2019/07/29</h5>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/8.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>晚饭过后我们来到Modernista，地方在宝钞胡同里面，有一点不好找。</p>
                                    <p>来这里的外国人挺多的，每晚有不同的乐队演出。</p>
                                </div>
                            </div>




                        </div>
                    </div>

                    <div className="titles" id="title5">
                        <h4 className="tooltipped title" data-tooltip="Prince Gong Mansion">恭王府</h4>
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img7/5.jpg"  alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Prince+Gong+Mansion/@39.935774,116.3776523,15z/data=!4m8!1m2!2m1!1sgongwangfu!3m4!1s0x35f05303c65abf2b:0x6ec74549d7c3a6f5!8m2!3d39.935774!4d116.386407">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">恭王府</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                            <div className="details">

<a className="btn-flat modal-trigger right info " href="#modal20">门票价格</a>
<div id="modal20" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">恭王府 - 门票价格</h4>
    <p>门票价格: 成人票40元/张; 半价票20元/张</p>
    <p className="grey-text">Price: 40 yuan/adult and 20 yuan/half-price ticket</p>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal19">地址</a>
<div id="modal19" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">恭王府 - 地址</h4>
    <p>地址: 北京市西城区什刹海前海西街17号</p>
    <p className="grey-text">Address: 17 Qianhai W St, Shi Cha Hai, Xicheng District, Beijing, China</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Prince+Gong+Mansion/@39.935774,116.3776523,15z/data=!4m8!1m2!2m1!1sgongwangfu!3m4!1s0x35f05303c65abf2b:0x6ec74549d7c3a6f5!8m2!3d39.935774!4d116.386407">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Find it on map...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal18">建筑</a>
<div id="modal18" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">恭王府 - 建筑</h4>
    <p>恭王府由府邸和花园两部分组成，南北长约330米，东西宽180余米，占地面积约61120平方米，其中府邸占地32260平方米，花园占地28860平方米。
<br/>
<br/>
恭王府王府在南，花园在北，由高高的后罩楼将王府与花园分开。
<br/>
<br/>
恭王府分中东西三路，分别由多过四合院组成，后为长160米的二层后罩楼。
<br/>
<br/>
恭王府花园也分为三路。中路是西洋门，独乐峰，蝠池，安善堂及左右配殿明道堂，棣华轩，福字碑，邀月台，蝠厅；东路是怡神所垂花门，大戏楼，芭蕉院；西路是湖心亭，澄怀撷秀。此外还有龙王庙、榆关、妙香亭、流杯亭、艺蔬圃。</p>
    <p className="grey-text">Prince Gong’s Mansion is composed of two parts: a mansion and a garden. It is about 330 meters long from north to south and 180 meters wide from east to west.
<br/>
<br/>
The palace of Prince Gong's residence is in the south, and the garden is in the north. The palace is separated from the garden by a tall back cover.
<br/>
<br/>
Prince Gong's Mansion is divided into three roads, the east and the west, which are composed of more than courtyards, followed by a two-story rear cover building with a length of 160 meters.
<br/>
<br/>
Prince Gong's Mansion Garden is also divided into three roads. The middle road is Xiyangmen, Dulefeng, Manta Pond, Anshan Hall and Mingdaotang, Dihuaxuan, Fuzi Tablet, Inviting Platform, Bat Hall; East Road is Yishen Suohuamen, Daxilou, Banjiaoyuan; west The road is the pavilion in the heart of the lake. In addition, there are Longwang Temple, Yuguan, Miaoxiang Pavilion, Liubei Pavilion, and Yishu Garden.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E6%81%AD%E7%8E%8B%E5%BA%9C#%E5%BB%BA%E7%AD%91">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal17">历史</a>
<div id="modal17" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">恭王府 - 历史</h4>
    <p>恭王府及花园原是固伦和孝公主在和珅作为二品户部侍郎邸的规制基础上，花六年增建的公主府，相当于郡王府规制，西路建筑是大臣和珅的府邸以一品大员建造，嘉庆四年（1799年）正月，和珅遭赐死，四月「和珅之宅，赏给庆郡王永璘居住；和珅之园，赏给成亲王永瑆居住。」，咸丰元年后由于庆亲王的孙子奕劻世袭降低为辅国将军，换至得定府大街原大学士琦善宅，原王府由内务府收回，后于咸丰年间赐予奕欣作为府邸，称为恭王府。 
<br/>
<br/>
1921年，恭亲王奕欣的孙子溥伟、 溥儒（溥心畲）将恭王府和花园抵押给天主教会，后由辅仁大学买去作为校舍，后先后为北京师范大学、中国音乐学院的校舍。恭王府建筑曾部分为北京空调机厂占用，1980年代腾退。 1996年10月起，恭王府花园作为旅游景点供公众参观。 2008年8月20日，修复后的恭王府全部对外开放。
<br/>
<br/>
2017年，文化部恭王府博物馆被中国博物馆协会评为第三批国家一级博物馆。</p>

    <p className="grey-text">Prince Gong’s Mansion and the Garden were originally built on the basis of the regulations of Heshen and Princess Gu Lun as the residence of Erpin Hubu. It was built in the first month of the fourth year of Jiaqing (1799), and Heshen was bestowed to death. In April, "Heshen’s House was rewarded to Qingjun Wang Yonglin for residence; Heshen’s Garden was rewarded to Cheng Prince Yongxuan’s residence.", After the first year of Xianfeng, the grandson of Prince Qing, Yiqiu, was heredically reduced to the general of the auxiliary country, and was replaced by the former University Shiqi Shan’s House on Dedingfu Street. The original palace was taken back by the House of Internal Affairs and later given to Yixin as a residence in the Xianfeng period. Prince Gong's Mansion.
<br/>
<br/>
In 1921, Prince Gong Yixin’s grandsons Pu Wei and Pu Ru (Pu Xinshe) pledged the Prince Gong’s mansion and garden to the Catholic Church, which was later bought by Fu Jen Catholic University as a school building, and later served as the Beijing Normal University and the Chinese Conservatory of Music. School building. The building of Prince Gong's Mansion was partly occupied by Beijing Air Conditioner Factory, and it was retired in the 1980s. Since October 1996, Prince Gong’s Mansion Garden has been used as a tourist attraction for the public to visit. On August 20, 2008, the restored Prince Gong's Mansion was all opened to the public.
<br/>
<br/>
In 2017, the Prince Gong's Mansion Museum of the Ministry of Culture was named the third batch of national first-class museums by the Chinese Museum Association.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E6%81%AD%E7%8E%8B%E5%BA%9C#%E5%8E%86%E5%8F%B2">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
                            </div>
                        </div>
                        <div className="titleContent">
                            <h5>2019/07/30</h5>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/9.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>恭王府花园</p>
                                    <p>恭王府王府在南，花园在北，由高高的后罩楼将王府与花园分开。</p>
                                    <p>恭王府花园也分为三路。中路是西洋门，独乐峰，蝠池，安善堂及左右配殿明道堂，棣华轩，福字碑，邀月台，蝠厅；东路是怡神所垂花门，大戏楼，芭蕉院；西路是湖心亭，澄怀撷秀。此外还有龙王庙、榆关、妙香亭、流杯亭、艺蔬圃。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E6%81%AD%E7%8E%8B%E5%BA%9C">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/10.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>恭王府大戏楼</p>
                                    <p>位于什刹海北岸的恭王府是世界最大的四合院，也是今日北京城里60余座清代王府中保存最完整的一个。大戏楼也叫怡神所是恭王府花园的第二绝。
                                    <br/>
                                    <br/>
                                    整个大戏楼是纯木结构，采用三卷勾连搭式屋顶，以前供亲王观戏，戏楼内厅堂很高大，但音响效果非常好，处在大堂最边远的角落，戏台上的唱词也听得清清楚楚，这在设计上确实到了绝妙的境地。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%81%AD%E7%8E%8B%E5%BA%9C%E5%A4%A7%E6%88%8F%E6%A5%BC">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>


                        </div>
                        
                    </div>

                    <div className="titles" id="title6">
                        <h4 className="tooltipped title" data-tooltip="The Geological Museum of China">中国地质博物馆</h4>
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img7/6.jpg"  alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/The+Geological+Museum+of+China,+Xicheng+Qu,+Beijing+Shi,+China/@39.9235363,116.3696596,17z/data=!3m1!4b1!4m5!3m4!1s0x35f052587d891865:0x9acf47983e4925f!8m2!3d39.9235322!4d116.3718483">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">中国地质博物馆</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                            <div className="details">

<a className="btn-flat modal-trigger right info " href="#modal24">门票价格</a>
<div id="modal24" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国地质博物馆 - 门票价格</h4>
    <p>门票价格: 成人票30元/张; 半价票15元/张</p>
    <p className="grey-text">Price: 30 yuan/adult and 15 yuan/half-price ticket</p>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal23">地址</a>
<div id="modal23" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国地质博物馆 - 地址</h4>
    <p>地址: 北京市西城区文津街1号</p>
    <p className="grey-text">Address: 1 Wenjin St, Xicheng District, China, 100034</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/The+Geological+Museum+of+China,+Xicheng+Qu,+Beijing+Shi,+China/@39.9235363,116.3696596,17z/data=!3m1!4b1!4m5!3m4!1s0x35f052587d891865:0x9acf47983e4925f!8m2!3d39.9235322!4d116.3718483">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Find it on map...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal22">展览</a>
<div id="modal22" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国地质博物馆 - 展览</h4>
    <p>中国地质博物馆收藏有20余万件地质标本，其中包括巨型山东龙、中华龙鸟等恐龙化石，北京人、元谋人、山顶洞人等古人类化石，鱼类、鸟类、昆虫等史前生物化石，此外还有有世界最大的“水晶王”、巨型萤石方解石晶簇标本、蓝铜矿、辰砂、雄黄、雌黄、白钨矿、辉锑矿等有中国特色的矿物标本，各种宝石、玉石等珍品。
<br/>
<br/>
中国地质博物馆长期开展矿物岩石学、地层古生物学、宝石学、博物馆学研究，特别是在早期脊椎动物学、昆虫学、辽西热河生物群等领域取得突出成果。该馆通过系统的宝石陈列及宝石研究成果，引导了中国当代宝石科学研究、普及及市场消费。
<br/>
<br/>
中国地质博物馆常年推出陈列及展览。基本陈列按照地球圈层结构进行布局，展示矿物、岩石、宝石、化石，关注人类生存环境，并且通过仿生、数字化、虚拟现实等等技术，使观众在地学空间中获得独特体验。
<br/>
<br/>
中国地质博物馆于1981年创刊《地球》，是中华人民共和国唯一的地学科普杂志。该馆还每年举办全国青少年地学夏令营。</p>
    <p className="grey-text">The China Geological Museum has more than 200,000 geological specimens, including giant Shandong dragon, Chinese dragon bird and other dinosaur fossils, Peking man, Yuanmou man, Shanding cave man and other ancient human fossils, fish, birds, insects and other prehistoric creature fossils. In addition, there are the world’s largest "Crystal King", giant fluorite calcite crystal cluster specimens, azurite, cinnabar, realgar, orpiment, scheelite, antimonite and other mineral specimens with Chinese characteristics, various gems and jade And other treasures.
<br/>
<br/>
The China Geological Museum has been carrying out researches on mineral petrology, stratigraphic paleontology, gemology, and museology for a long time, especially in the fields of early vertebrates, entomology, and Jehol Biota in western Liaoning. The museum has guided the scientific research, popularization and market consumption of contemporary gems in China through systematic gem display and gem research results.
<br/>
<br/>
The China Geological Museum launches displays and exhibitions throughout the year. The basic display is arranged according to the structure of the earth circle, showing minerals, rocks, gems, and fossils, paying attention to the human living environment, and using bionics, digitization, virtual reality and other technologies to enable the audience to have a unique experience in the geoscience space.
<br/>
<br/>
The China Geological Museum founded "Earth" in 1981, and it is the only popular geological magazine in the People's Republic of China. The museum also holds a national youth geoscience summer camp every year.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E4%B8%AD%E5%9B%BD%E5%9C%B0%E8%B4%A8%E5%8D%9A%E7%89%A9%E9%A6%86#%E5%B1%95%E8%A7%88%E5%8F%8A%E7%A0%94%E7%A9%B6">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal21">建筑</a>
<div id="modal21" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国地质博物馆 - 建筑</h4>
    <p>中国地质博物馆主馆共有大楼6层，总高约36米，东西长40米，南北长70米，建筑面积近11000平方米，建筑风格稳重大方。
<br/>
<br/>
中国地质博物馆展厅包括地球厅、矿物岩石厅、宝石玉厅、史前生物厅等六个部分，面积共2500平方米，另有临时展览面积1500平方米。</p>
    <p className="grey-text">The main building of the China Geological Museum has 6 floors with a total height of about 36 meters, 40 meters long from east to west, and 70 meters long from north to south. The building area is nearly 11,000 square meters and the architectural style is stable and generous.
<br/>
<br/>
The exhibition hall of the China Geological Museum includes six parts: the earth hall, the mineral rock hall, the gem and jade hall, and the prehistoric biology hall, covering a total area of 2500 square meters and a temporary exhibition area of 1500 square meters.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%B8%AD%E5%9B%BD%E5%9C%B0%E8%B4%A8%E5%8D%9A%E7%89%A9%E9%A6%86#2_1">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
                            </div>
                        </div>
                        <div className="titleContent">
                            <h5>2019/07/30</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/11.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>中国地质博物馆分为地球展厅，宝石展厅，矿物岩石展厅，史前生物展厅，关怀与鼓舞厅和虚拟展厅</p>
                                    <p>中国地质博物馆收藏地质标本20余万件，涵盖地学各领域。藏品有巨型山东龙、中华龙鸟等恐龙系列化石，北京人、元谋人、山顶洞人等古人类化石，以及大量集科学价值与观赏价值于一身的鱼类、鸟类、昆虫等珍贵史前生物化石；有世界最大的“水晶王”、巨型萤石方解石晶簇标本、精美的蓝铜矿、辰砂、雄黄、雌黄、白钨矿、辉锑矿等中国特色矿物标本，以及种类繁多的宝石、玉石等一批国宝级珍品。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%B8%AD%E5%9B%BD%E5%9C%B0%E8%B4%A8%E5%8D%9A%E7%89%A9%E9%A6%86">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>


                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/12.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>中国鸟龙</p>
                                    <p>中国鸟龙（属名：Sinornithosaurus，意为“中国的鸟蜥蜴”），是种驰龙科的有羽毛恐龙，化石发现于中国的义县组，年代为下白垩纪的中巴列姆阶。</p>
                                    <p>体长1.5米，体重8公斤，食性肉食。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%B8%AD%E5%9B%BD%E9%B8%9F%E9%BE%99">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>

                        
                        </div>
                        
                    </div>

                    <div className="titles" id="title7">
                        <h4 className="tooltipped title" data-tooltip="National Library of China">中国国家图书馆</h4>
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img7/7.jpg"  alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/National+Library+of+China/@39.943019,116.3143563,15z/data=!4m8!1m2!2m1!1z5Lit5Zu95Zu95a625Zu-5Lmm6aaG!3m4!1s0x35f05180c674fe5d:0x1a31b5d9e3498011!8m2!3d39.943019!4d116.323111">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">中国国家图书馆</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                            <div className="details">

<a className="btn-flat modal-trigger right info " href="#modal28">门票价格</a>
<div id="modal28" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国国家图书馆 - 门票价格</h4>
    <p>门票价格: 免费参观</p>
    <p className="grey-text">Price: free to visit</p>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal27">地址</a>
<div id="modal27" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国国家图书馆 - 地址</h4>
    <p>地址: 北京市海淀区紫竹桥中关村南大街33号</p>
    <p className="grey-text">Address: 33 Zhongguancun S St, Zi Zhu Qiao, Haidian District, China</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/National+Library+of+China/@39.943019,116.3143563,15z/data=!4m8!1m2!2m1!1z5Lit5Zu95Zu95a625Zu-5Lmm6aaG!3m4!1s0x35f05180c674fe5d:0x1a31b5d9e3498011!8m2!3d39.943019!4d116.323111">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Find it on map...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal26">建筑</a>
<div id="modal26" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国国家图书馆 - 建筑</h4>
    <p>中国国家图书馆总馆南区日均接待读者1.2万人次，馆藏远远超过2000万件的设计容量，原有馆舍已不敷使用。
<br/>
<br/>
2003年，经国家批准，立项扩建，二期新馆舍于2008年9月启用，成为中国国家图书馆总馆北区，扩建完成后中国国家图书馆的馆舍总面积达到25万平方米。</p>
    <p className="grey-text">The Southern District of the National Library of China receives an average of 12,000 readers per day, and the collection far exceeds the designed capacity of 20 million pieces. The original building is no longer sufficient.
<br/>
<br/>
In 2003, approved by the state, a project was established for expansion. The second phase of the new building was opened in September 2008 and became the north area of the National Library of China. After the expansion, the total area of the National Library of China reached 250,000 square meters.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E4%B8%AD%E5%9B%BD%E5%9B%BD%E5%AE%B6%E5%9B%BE%E4%B9%A6%E9%A6%86#%E5%BB%BA%E7%AD%91">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal25">历史</a>
<div id="modal25" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国国家图书馆 - 历史</h4>
    <p>中国国家图书馆的前身是于1909年9月9日始建的京师图书馆。中华民国成立后，北洋政府的教育部接管京师图书馆，后将其改名为国立京师图书馆，1912年（民国元年）8月27日正式开馆接待读者。 
<br/>
<br/>
1928年北伐成功后，国立京师图书馆改名国立北平图书馆，并迁址到中南海居仁堂。
<br/>
<br/>
中华人民共和国成立后，于1950年3月6日国立北平图书馆更名为国立北京图书馆，1951年6月12日更名为北京图书馆，成为中华人民共和国唯一的国家图书馆。</p>

    <p className="grey-text">The predecessor of the National Library of China was the Jingshi Library which was founded on September 9, 1909. After the establishment of the Republic of China, the Ministry of Education of the Beiyang Government took over the Jingshi Library and later renamed it the National Jingshi Library. It was officially opened on August 27 to receive readers.
<br/>
<br/>
After the successful Northern Expedition in 1928, the National Capital Library was renamed the National Beiping Library and moved to Jurentang, Zhongnanhai.
<br/>
<br/>
After the founding of the People’s Republic of China, the National Peking Library was renamed the National Beijing Library on March 6, 1950, and the Beijing Library on June 12, 1951, becoming the only national library of the People’s Republic of China.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E4%B8%AD%E5%9B%BD%E5%9B%BD%E5%AE%B6%E5%9B%BE%E4%B9%A6%E9%A6%86#%E5%8E%86%E5%8F%B2">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
                            </div>
                        </div>
                        <div className="titleContent">
                            <h5>2019/07/30</h5>


                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/13.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>不可以背大包进图书馆，进入前需要先存包。</p>
                                    <p>在一楼可以刷身份证进入图书馆借阅书籍</p>
                                    
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%B8%AD%E5%9B%BD%E5%9B%BD%E5%AE%B6%E5%A4%A7%E5%89%A7%E9%99%A2/1063477?fromtitle=%E5%9B%BD%E5%AE%B6%E5%A4%A7%E5%89%A7%E9%99%A2&fromid=69546&fr=aladdin#2_2">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>

                        </div>
                        
                    </div>

                    <div className="titles" id="title8">
                        <h4 className="tooltipped title" data-tooltip="Haidian Theatre">海淀剧院</h4>
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img7/8.jpg"  alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Haidian+Theater/@39.9650077,116.3034553,14z/data=!4m8!1m2!2m1!1z5rW35reA5Ymn6Zmi!3m4!1s0x0:0xf05dfe722ef4b08f!8m2!3d39.9766474!4d116.3182479">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">海淀剧院</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                            <div className="details">

<a className="btn-flat modal-trigger right info " href="#modal32">门票价格</a>
<div id="modal32" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">海淀剧院 - 门票价格</h4>
    <p>门票价格: 不同演出不同价格</p>
    <p className="grey-text">Price: Different performances, different prices</p>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal31">地址</a>
<div id="modal31" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">海淀剧院 - 地址</h4>
    <p>地址: 北京市海淀区中关村大街28号</p>
    <p className="grey-text">Address: 28 Zhongguancun St, Haidian District, Beijing, China</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Haidian+Theater/@39.9650077,116.3034553,14z/data=!4m8!1m2!2m1!1z5rW35reA5Ymn6Zmi!3m4!1s0x0:0xf05dfe722ef4b08f!8m2!3d39.9766474!4d116.3182479">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Find it on map...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal30">建筑</a>
<div id="modal30" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">海淀剧院 - 建筑</h4>
    <p>海淀剧院是北京西北地区惟一一座建筑一流、设备一流、功能品质一流的综合性大型文化设施。能够容纳980个座位的大厅和两个各容纳120名观众的豪华小厅，设施非常完备、高级。大厅舞台既有乐池、升降舞台，又有音罩、乐队平台，可以满足舞剧、歌剧、话剧、戏曲、歌舞、曲艺及交响音乐会的各种要求。</p>
    <p className="grey-text">Haidian Theater is the only comprehensive large-scale cultural facility with first-class architecture, first-class equipment, and first-class functional quality in northwestern Beijing. The hall with 980 seats and two luxurious small halls with 120 spectators each have very complete and advanced facilities. The hall stage has a music pool, a lifting stage, a sound cover, and a band platform, which can meet the various requirements of dance drama, opera, drama, opera, song and dance, folk art and symphony concerts.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%B5%B7%E6%B7%80%E5%89%A7%E9%99%A2#1">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal29">历史</a>
<div id="modal29" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">海淀剧院 - 历史</h4>
    <p>海淀区政府斥巨资兴建的海淀剧院重建工程于2001年3月1日正式开工，2003年11月30日竣工。在此期间，区委区政府对海淀剧院的建设工程给予了极大的关注和支持。经过各方积极的筹备，海淀剧院于2003年12月28日正式重张开业。</p>

    <p className="grey-text">The Haidian Theater reconstruction project, which the Haidian District Government invested heavily in construction, officially started on March 1, 2001 and was completed on November 30, 2003. During this period, the district committee and government paid great attention and support to the construction project of Haidian Theater. After active preparations from all parties, the Haidian Theater officially reopened on December 28, 2003.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%B5%B7%E6%B7%80%E5%89%A7%E9%99%A2#2">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
                            </div>
                        </div>
                        <div className="titleContent">
                            <h5>2019/07/30 --- 7:30pm</h5>


                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/14.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>音乐剧 - 拉赫玛尼诺夫</p>

                                </div>
                            </div>


                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/15.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>故事依托于著名俄罗斯音乐家拉赫玛尼诺夫（1873-1943）人生中一个很小的片段：《第一交响曲》首演失败后，面对种种质疑，24岁的年轻音乐家自此一蹶不振，陷入长达3年的创作空窗期。此后在心理医生达利的“催眠”和“谈话”等心理治疗下战胜心病，写出了里程碑式的《第二钢琴协奏曲》，并以此献给达利。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="https://www.sohu.com/a/277094198_169580">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>


                        </div>
                        
                    </div>

                    <div className="titles" id="title9">
                        <h4 className="tooltipped title" data-tooltip="Siji Minfu Roast Duck Shop">四季民福</h4>
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img7/9.jpg"  alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Siji+Minfu+Roast+Duck+Shop/@39.963138,116.4161941,17z/data=!4m8!1m2!2m1!1z5YyX5Lqs5biC5pyd6Ziz5Yy65a6J5a6a6Zeo5ZKM5bmz6YeM6KW_6KGXMjHlj7fljJfkuqzllYbmiqUxLDLlsYI!3m4!1s0x35f054aaa0489603:0x656edf40e4a77243!8m2!3d39.9623871!4d116.4180227">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">四季民福</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                            <div className="details">

<a className="btn-flat modal-trigger right info " href="#modal36">人均价格</a>
<div id="modal36" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">四季民福 - 人均价格</h4>
    <p>价格: 人均160RMB</p>
    <p className="grey-text">Price: RMB160 per person on average</p>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal35">地址</a>
<div id="modal35" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">四季民福 - 地址</h4>
    <p>地址: 北京市朝阳区安定门和平里西街21号北京商报1,2层</p>
    <p className="grey-text">Address: Hepingli W St, Andingmen, Chaoyang, Beijing, China</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Siji+Minfu+Roast+Duck+Shop/@39.963138,116.4161941,17z/data=!4m8!1m2!2m1!1z5YyX5Lqs5biC5pyd6Ziz5Yy65a6J5a6a6Zeo5ZKM5bmz6YeM6KW_6KGXMjHlj7fljJfkuqzllYbmiqUxLDLlsYI!3m4!1s0x35f054aaa0489603:0x656edf40e4a77243!8m2!3d39.9623871!4d116.4180227">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Find it on map...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

{/* <a className="btn-flat modal-trigger right info " href="#modal34">建筑</a>
<div id="modal34" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">四季民福 - 建筑</h4>
    <p></p>
    <p className="grey-text"></p>
    <a className="right" href="">
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div> */}

<a className="btn-flat modal-trigger right info " href="#modal33">餐馆简介</a>
<div id="modal33" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">四季民福 - 餐馆简介</h4>
    <p>四季民福主营北京菜和烤鸭，灯市口店装修古色古香中透着气派和华丽，很有老北京的味道。青砖砌成的墙面、灰色瓦房配上朱红色的招牌，仿佛是一张老北京的古老照片，承载着属于老北京特有的独特记忆。走进餐厅，清一色的长方桌，靠背椅，置身其中仿佛又嗅到了属于老北京的味道。菜品主要是老北京特色菜和烤鸭，菜品绝佳的味道，实在的份量以及公道的价格受到了很多食客的一致好评。“酥香嫩烤鸭”是店内当家菜品，精选北京填鸭，采用传统挂炉，用果木进行烘烤，肉质鲜嫩、酥香。此外，“贝勒烤肉”、“冲浪红极参”、“黄坛香”等京味儿特色菜也很值得品尝。</p>

    <p className="grey-text">Siji Minfu mainly specializes in Beijing cuisine and roast duck. Dengshikou store is decorated in antique style with style and gorgeousness, which is very old Beijing. The walls made of blue bricks, the gray tiled houses and the vermilion signboards are like an old photo of old Beijing, carrying unique memories that belong to old Beijing. Entering the restaurant, the rectangular tables and chairs are all in place, and you feel like you smell the old Beijing again. The dishes are mainly old Beijing specialties and roast duck. The excellent taste of the dishes, the actual portion and the reasonable price have been praised by many diners. "Crispy and tender roast duck" is the home-cooked dish in the store. Selected Beijing stuffed duck is roasted in a traditional hanging oven with fruit trees. The meat is tender and crispy. In addition, "Baylor barbecue", "Surfing Red Ginseng", "Huang Tan Xiang" and other Beijing specialties are also worth tasting.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%9B%9B%E5%AD%A3%E6%B0%91%E7%A6%8F%EF%BC%88%E7%8E%8B%E5%BA%9C%E4%BA%95%E5%BA%97%EF%BC%89">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
                            </div>
                        </div>
                        <div className="titleContent">
                            <h5>2019/07/31</h5>


                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/16.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>四季民福 - 和平里店</p>

                                </div>
                            </div>


                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/17.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>小吃拼盘 老北京炸酱面 龙虾汤泡饭</p>
                                    <p>蜜汁酥皮虾 赠品 宫廷杏仁豆腐</p>
                                    <p>巧拌豆苗 半只烤鸭 京城酥肉锅</p>

                                </div>
                            </div>


                        </div>
                        
                    </div>
                    
                    <div className="titles" id="title10">
                        <h4 className="tooltipped title" data-tooltip="National Museum of China">中国国家博物馆</h4>
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img7/10.jpg"  alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/National+Museum+of+China/@39.9050986,116.3993942,17z/data=!3m1!4b1!4m5!3m4!1s0x35f052b94ab111e7:0x2476241f0e8c609!8m2!3d39.9050945!4d116.4015829">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">中国国家博物馆</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                            <div className="details">

<a className="btn-flat modal-trigger right info " href="#modal40">门票价格</a>
<div id="modal40" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国国家博物馆 - 门票价格</h4>
    <p>门票价格: 免费参观</p>
    <p className="grey-text">Price: free to visit</p>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal39">地址</a>
<div id="modal39" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国国家博物馆 - 地址</h4>
    <p>地址: 北京市西城区文津街1号</p>
    <p className="grey-text">Address: 1 Wenjin St, Xicheng District, China, 100034</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/National+Museum+of+China/@39.9050986,116.3993942,17z/data=!3m1!4b1!4m5!3m4!1s0x35f052b94ab111e7:0x2476241f0e8c609!8m2!3d39.9050945!4d116.4015829">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Find it on map...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal38">建筑</a>
<div id="modal38" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国国家博物馆 - 建筑</h4>
    <p>中国国家博物馆，位于中国北京市东城区东长安街16号、天安门广场东侧。总建筑面积近20万平方米，藏品数量140余万件，展厅数量48个，是世界上单体建筑面积最大的博物馆，是中华文物收藏量最丰富的博物馆之一。</p>
    <p className="grey-text">The National Museum of China is located on the east side of Tiananmen Square, 16 East Chang'an Street, Dongcheng District, Beijing, China. With a total construction area of nearly 200,000 square meters, a collection of more than 1.4 million pieces, and 48 exhibition halls, it is the largest museum in the world with a single building area and one of the museums with the richest collection of cultural relics in China.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E4%B8%AD%E5%9B%BD%E5%9B%BD%E5%AE%B6%E5%8D%9A%E7%89%A9%E9%A6%86">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal37">历史</a>
<div id="modal37" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国国家博物馆 - 历史</h4>
    <p>1912年7月9日中华民国教育部于国子监设立的国立历史博物馆筹备处。 1917年，教育部决议将紫禁城端门至午门一带划为国立历史博物馆馆址。 1918年7月迁入，并将午门正楼、雁翅楼及四角亭辟为10个陈列室，东西庑各三间为办公室，东西朝房为储藏室，总计馆舍150余间，文物五万余件。 1924年8月1日在正式对外公开展览。 1926年定名为国立历史博物馆。 1949年10月1日中华人民共和国建国后，改为国立北京历史博物馆，隶属中央人民政府文化部。
<br/>
<br/>
中国革命博物馆的前身为1950年3月成立的中央革命博物馆筹备处。
<br/>
<br/>
1960年8月，北京历史博物馆正式更名为中国历史博物馆，中央革命博物馆正式更名为中国革命博物馆。
<br/>
<br/>
1969年9月，中国革命博物馆和中国历史博物馆合并，称中国革命历史博物馆。
<br/>
<br/>
2003年2月28日两馆再次合并，成立中国国家博物馆。国博当时总面积65,000多平方米，中央部分和两翼的中部是十二根巨型方柱式的门廊。穿过门廊，走进中央大厅，整个建筑分成互相对称的南北两部分。
<br/>
<br/>
中国国家博物馆从2007年1月31日起闭馆进行扩建，设计方案于2007年春节公布。工程于2007年4月开始至2010年3月1日完工，历时3年，投资25亿人民币。建筑面积由6.5万平方公​​尺增加到19.19万平方公尺,使得该馆成为世界上单体建筑面积最大的博物馆，展厅数量为48个，设有“古代中国”、“复兴之路”两个主要的基本陈列，设有十余个各艺术门类的专题展览及国际交流展览。</p>

    <p className="grey-text">The Preparatory Office of the National Museum of History established by the Ministry of Education of the Republic of China in the Imperial College on July 9, 1912. In 1917, the Ministry of Education decided to designate the area from Duanmen to Wumen of the Forbidden City as the site of the National Museum of History. Moved in in July 1918, and opened the Meridian Main Building, Yanchi Building and Sijiao Pavilion into 10 showrooms, with three offices in each of the east and west halls, and storage rooms in the east and west halls. There are more than 150 buildings and cultural relics. More than 50,000 pieces. It was officially publicly exhibited on August 1, 1924. Named the National Museum of History in 1926. After the founding of the People’s Republic of China on October 1, 1949, it was renamed the National Beijing History Museum under the Ministry of Culture of the Central People’s Government.
<br/>
<br/>
The predecessor of the Chinese Revolution Museum was the Preparatory Office of the Central Revolution Museum established in March 1950.
<br/>
<br/>
In August 1960, the Beijing History Museum officially changed its name to the Chinese History Museum, and the Central Revolution Museum officially changed its name to the Chinese Revolution Museum.
<br/>
<br/>
In September 1969, the Chinese Revolution Museum and the Chinese History Museum were merged to form the Chinese Revolution History Museum.
<br/>
<br/>
On February 28, 2003, the two museums merged again to form the National Museum of China. At that time, the National Museum had a total area of ​​more than 65,000 square meters. The central part and the middle of the two wings were twelve giant square pillar porches. Passing through the porch and into the central hall, the whole building is divided into two symmetrical north and south parts.
<br/>
<br/>
The National Museum of China will be closed for expansion on January 31, 2007, and the design plan was announced in the 2007 Spring Festival. The project started in April 2007 and was completed on March 1, 2010. It lasted 3 years and invested 2.5 billion yuan. The building area has increased from 65,000 square meters to 191,900 square meters, making the museum the largest museum in the world with a single building area. The number of exhibition halls is 48, with "Ancient China" and "Rejuvenation Road". There are two main basic exhibitions, with more than ten thematic exhibitions of various art categories and international exchange exhibitions.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E4%B8%AD%E5%9B%BD%E5%9B%BD%E5%AE%B6%E5%8D%9A%E7%89%A9%E9%A6%86#%E6%B2%BF%E9%9D%A9">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
                            </div>
                        </div>
                        <div className="titleContent">
                            <h5>2019/07/31</h5>


                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/18.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>需要在公众号上提前1-5天预约</p>
                                    <p>公众号：国家博物馆</p>
                                    <p>存包的价格是5RMB/包</p>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/19.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>玉龙 - 红山文化</p>
                                    <p>新石器时代红山文化玉龙是新石器时代的玉器（祭器礼器），于1971年出土于内蒙古自治区翁牛特旗三星他拉遗址。 
<br/>
<br/>
新石器时代红山文化玉龙墨绿色，高26厘米，完整无缺，体蜷曲，呈“C”字形。吻部前伸，略向上弯曲，嘴紧闭，有对称的双鼻孔，双眼突起呈棱形，有鬣。龙背有对称的单孔。龙身大部光素无纹，只在额及鄂底刻以细密的方格网状纹，网格突起作规整的小菱形。 
<br/>
<br/>
新石器时代红山文化玉龙由墨绿色的岫岩玉雕琢而成，周身光洁，头部长吻修目，鬣鬃飞扬，躯体卷曲若钩。造型生动，雕琢精美，被誉为“三星他拉红山文化玉龙”，也称为“中华第一龙”。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%96%B0%E7%9F%B3%E5%99%A8%E6%97%B6%E4%BB%A3%E7%BA%A2%E5%B1%B1%E6%96%87%E5%8C%96%E7%8E%89%E9%BE%99/23706890?fromtitle=%E7%8E%89%E9%BE%99&fromid=1050781&fr=aladdin">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/20.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                <p>四羊青铜方尊 - 商后期</p>
                                    <p>四羊方尊是商代晚期青铜礼器，祭祀用品。1938年出土于湖南宁乡县黄材镇月山铺转耳仑的山腰上，现属炭河里遗址。收藏于中国国家博物馆。
<br/>
<br/>
四羊方尊是中国仍存商代青铜方尊中最大的一件，其每边边长为52.4 厘米，高58.3厘米，重量34.5公斤，长颈，高圈足，颈部高耸，四边上装饰有蕉叶纹、三角夔纹和兽面纹，尊的中部是器的重心所在，尊四角各塑一羊，肩部四角是4个卷角羊头，羊头与羊颈伸出于器外，羊身与羊腿附着于尊腹部及圈足上。同时，方尊肩饰高浮雕蛇身而有爪的龙纹，尊四面正中即两羊比邻处，各一双角龙首探出器表，从方尊每边右肩蜿蜒于前居的中间。
<br/>
<br/>
四羊方尊可能是用两次分铸技术铸造的，即先将羊角与龙头单个铸好，然后将其分别配置在外范内，再进行整体浇铸。整个器物用块范法浇铸，一气呵成，巧夺天工，显示了高超的铸造水平，被史学界称为“臻于极致的青铜典范”，位列十大传世国宝之一。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%9B%9B%E7%BE%8A%E6%96%B9%E5%B0%8A/617449?fr=aladdin">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>


                        </div>
                        
                    </div>


                    <h4 className="title"> </h4>


                    {/* <div className="Comment">
                    
                        <div className="row">   
                        <div className="col s1 m1 l1">
                            <a className="btn btn-floating black lighten-1 ">YX</a>
                        </div>
                        <div className="col s11 m11 l11 commentContent">
                            <form action="">
                                <div className="input-field">
                                    <textarea id="message" className="materialize-textarea"></textarea>
                                    <label htmlFor="message">发表评论</label>
                                </div>
                            </form>
                        </div>
                        </div>

                    </div> */}
           



                </div>
            </div>

                <div className="row hide-on-med-and-up" id="travelNoteBeijing">

                <div className="col s12 m12 l12 noteBeijing">
                   {/* <Testnav/> */}
                    <a href="" className="sidenav-trigger menu-bars" data-target="mobile-menu999" >
                        <i className="material-icons smallnotenav" >menu</i>
                    </a>
                    <ul className="sidenav grey lighten-2 sidenav999" id="mobile-menu999">
                            <div className="bigTitle">北京</div>
                            <li className="titleDate">
                                <a href="#title11">2019/07/28</a>
                            </li>
                            <li className="smallTitle">

                                <a href="#title11">国家大剧院</a>
                            </li>

                            <li className="titleDate">
                                <a href="#title12">2019/07/29</a>
                            </li>
                            <li className="smallTitle">
                           
                                <a href="#title12">景山公园</a>
                            </li>
                            <li className="smallTitle">
                             
                                <a href="#title13">北海公园</a>
                            </li>
                            <li className="smallTitle">

                                <a href="#title14">Modernista</a>
                            </li>

                            <li className="titleDate">
                                <a href="#title15">2019/07/30</a>
                            </li>
                            <li className="smallTitle">
                               
                                <a href="#title15">恭王府</a>
                            </li>
                            <li className="smallTitle">
                               
                                <a href="#title16">中国地质博物馆</a>
                            </li>
                            <li className="smallTitle">
                                
                                <a href="#title17">中国国家图书馆</a>
                            </li>
                            <li className="smallTitle">
                                
                                <a href="#title18">海淀剧院</a>
                            </li>

                            <li className="titleDate">
                                <a href="#title19">2019/07/31</a>
                            </li>
                            <li className="smallTitle">
                             
                                <a href="#title19">四季民福</a>
                            </li>
                            <li className="smallTitle">
                                
                                <a href="#title20">中国国家博物馆</a>
                            </li>
                        
                    </ul>
                

                        <div className="row">
                            <div className="col s10 m10 l10 offset-s1 offset-m1 offset-l1 ">
                            <div className="row travelDetail">
                                <div className="time col s12 m4 l4">
                                    <i className=" material-icons left">access_time</i>
                                    <span>出发时间</span>
                                    <span> / </span>
                                    <span>2019-07-28</span>
                                </div>
                                <div className="day col s12 m4 l4">
                                    <i className=" material-icons left">wb_sunny</i>
                                    <span>出行天数</span>
                                    <span> / </span>
                                    <span>4天</span>
                                </div>
                                <div className="people col s12 m4 l4">
                                    <i className=" material-icons left">account_circle</i>
                                    <span>人物</span>
                                    <span> / </span>
                                    <span>和朋友</span>
                                </div>
                            </div>
                            <div className="col s1 m1 l1"></div>
                            </div>
                        </div>

                    <div className="titles" id="title11">
                        <h4 className="tooltipped title" data-tooltip="National Centre for the Performing Arts of China">国家大剧院</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img7/1.jpg"  alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/National+Centre+for+the+Performing+Arts/@39.904799,116.3874825,17z/data=!3m1!4b1!4m5!3m4!1s0x35f0528e22e7a273:0x8b74ec5b2f1aaa87!8m2!3d39.904799!4d116.3896712">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">国家大剧院</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">

                        <a className="btn-flat modal-trigger right info " href="#modal41">门票价格</a>
                        <div id="modal41" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">国家大剧院 - 门票价格</h4>
                            <p>门票价格: 参观票价分为成人票40元/张和半价票20元/张</p>
                            <p className="grey-text">Price: 40 yuan/adult and 20 yuan/half-price ticket</p>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        <a className="btn-flat modal-trigger right info " href="#modal42">地址</a>
                        <div id="modal42" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">国家大剧院 - 地址</h4>
                            <p>地址: 中国国家大剧院位于北京市西城区西长安街2号</p>
                            <p className="grey-text">Address: 2 W Chang'an Ave, Xicheng District, China, 100031</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/National+Centre+for+the+Performing+Arts/@39.904799,116.3874825,17z/data=!3m1!4b1!4m5!3m4!1s0x35f0528e22e7a273:0x8b74ec5b2f1aaa87!8m2!3d39.904799!4d116.3896712">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Find it on map...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        <a className="btn-flat modal-trigger right info " href="#modal43">建筑</a>
                        <div id="modal43" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">国家大剧院 - 建筑</h4>
                            <p>国家大剧院占地11.893万平方米，总建筑面积149520平方米，主体建筑由外部围护结构和内部歌剧院、戏剧场、音乐厅和公共大厅及配套用房组成。国家大剧院共設有5366个座位，其中歌剧院席位2081个，戏剧院席位957个，音乐厅席位1859个，小剧场席位469个。
<br/>
<br/>
外部围护钢结构壳体呈半椭球形，其平面投影东西长212.20米，南北宽143.64米，建筑物高46.285米，基础埋深的最深部分达到32.5米。
椭球形屋面主要采用钛金属板饰面，中部为渐开式玻璃幕墙。椭球壳体外环绕人工湖，湖面面积达35500平方米，各种通道和入口都设在水面下。</p>
                            <p className="grey-text">The National Centre for the Performing Arts covers an area of 118,93 thousand square meters, with a total construction area of 149,520 square meters. The main building consists of an external envelope and an internal opera house, theater, concert hall, public hall and supporting rooms. The National Centre for the Performing Arts has a total of 5366 seats, including 2081 seats in the opera theater, 957 seats in the theater, 1859 seats in the concert hall, and 469 seats in the small theater.
<br/>
<br/>
The outer envelope steel structure shell is semi-ellipsoidal, and its plane projection is 212.20 meters long from east to west, 143.64 meters wide from north to south, and the building is 46.285 meters high. The deepest part of the foundation is 32.5 meters.
The ellipsoidal roof is mainly finished with titanium metal plate, and the middle part is a gradually opening glass curtain wall. The ellipsoid shell surrounds the artificial lake, with an area of 35,500 square meters, and various channels and entrances are located under the water surface.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E5%9B%BD%E5%AE%B6%E5%A4%A7%E5%89%A7%E9%99%A2">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        <a className="btn-flat modal-trigger right info " href="#modal44">历史</a>
                        <div id="modal44" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">国家大剧院 - 历史</h4>
                            <p>工程于2001年12月13日开工，2007年9月建成，2007年9月25日进行试演、投入使用，2007年12月22日正式投入运营。国家大剧院位于中国北京人民大会堂西侧，总投资26.8838亿元人民币，设计师为法国建筑师保罗·安德鲁。</p>

                            <p className="grey-text">The project started on December 13, 2001 and was completed in September 2007. It was audited and put into use on September 25, 2007. It was officially put into operation on December 22, 2007. The National Grand Theater is located on the west side of the Great Hall of the People in Beijing, China, with a total investment of 2.68838 billion yuan. The designer is French architect Paul Andreu.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E5%9B%BD%E5%AE%B6%E5%A4%A7%E5%89%A7%E9%99%A2">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2019/07/28 --- 6:30pm</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/1.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>我们买了7:30pm-9:30pm的菅野邦彦爵士钢琴音乐会的票。</p>
                                    <p>买了演出票的话，可以提前1h检票参观国家大剧院
                                        <br/>
                                    (不用再额外买参观票)。</p>
                                    <p>中国国家大剧院内，设有歌剧院、音乐厅、戏剧场以及艺术展厅、餐厅、音像商店等配套设施。手机没电了的话，可在剧院内的艺术展厅租借充电宝。</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/2.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>菅野邦彦爵士钢琴音乐会是在音乐厅内举办。</p>
                                    <p>音乐厅风格清新、高雅，适于演奏大型交响乐、民族乐，并可举办各种音乐会，有1859个席位（含站席）。音乐厅内拥有国内最大的管风琴，能满足各种不同流派作品演出的需要。此外数码墙、极具现代美感的抽象浮雕天花板、GRC墙面、龟背反声板等设计能令声音均匀、柔和地扩散反射，使音乐厅实现了建筑美学和声学美学的结合。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%B8%AD%E5%9B%BD%E5%9B%BD%E5%AE%B6%E5%A4%A7%E5%89%A7%E9%99%A2/1063477?fromtitle=%E5%9B%BD%E5%AE%B6%E5%A4%A7%E5%89%A7%E9%99%A2&fromid=69546&fr=aladdin#2_2">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>


                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/3.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>菅野邦彦爵士钢琴音乐会 - 刚刚开场时快速拍的一张照片</p>
                                    <p>菅野邦彦 - 他大学期间在吉屋润的乐队开始了演奏生涯。毕业后仅工作了一年后，又回到了音乐的世界。1960年，开始了与铃木勋、GEORGE大塚的三重奏。并且被当时来日的托尼·斯科特大加赞赏，从此一同开始了音乐演出。1963年至1964年期间，与松本英彦重新组建了四重奏。解散后，作为钢琴独奏家，发行了70张黑胶唱片。菅野的充满布鲁斯感的钢琴独奏不仅在日本受到乐迷的追捧，在全世界的爵士界都被认同。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="http://www.chncpaticket.org/pic_detail.asp?id=5782">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/4.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>夜晚的国家大剧院</p>
                                    <p>音乐会结束后，我们三个人沿着湖边慢走，湖面上还会有小鸭子，十分惬意。</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <div className="titles" id="title12">
                        <h4 className="tooltipped title" data-tooltip="Jingshan Park">景山公园</h4>
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img7/2.jpg"  alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Jingshan+Park/@39.9251029,116.3946546,17z/data=!3m1!4b1!4m5!3m4!1s0x35f052e0cf4da489:0x95549f0b9c7f635b!8m2!3d39.9250988!4d116.3968433">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">景山公园</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                            <div className="details">

<a className="btn-flat modal-trigger right info " href="#modal45">门票价格</a>
<div id="modal45" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">景山公园 - 门票价格</h4>
    <p>门票价格: 成人票2元/张; 半价票1元/张</p>
    <p className="grey-text">Price: 2 yuan/adult; 1 yuan/half-price ticket</p>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal46">地址</a>
<div id="modal46" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">景山公园 - 地址</h4>
    <p>地址: 北京市西城区景山西街44号</p>
    <p className="grey-text">Address: 44 Jingshan W St, Xicheng District, Beijing, China, 100009</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Jingshan+Park/@39.9251029,116.3946546,17z/data=!3m1!4b1!4m5!3m4!1s0x35f052e0cf4da489:0x95549f0b9c7f635b!8m2!3d39.9250988!4d116.3968433">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Find it on map...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal47">建筑</a>
<div id="modal47" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">景山公园 - 建筑</h4>
    <p>景山公园管理处隶属于北京市公园管理中心，位于中国北京市西城区景山前街。公园中有银杏园、海棠园、牡丹园、桃园、苹果园、葡萄园、柿子林。全园坐北朝南，红墙黄瓦围墙，占地23万平方米。山高43米，周长1015米。园内花卉草坪占地1100 平方米。</p>
    <p className="grey-text">Jingshan Park Management Office is affiliated to Beijing Park Management Center and is located in Jingshanqian Street, Xicheng District, Beijing, China. There are ginkgo garden, begonia garden, peony garden, peach garden, apple garden, vineyard, and persimmon forest in the park. The whole park faces south, with red walls and yellow tiles, covering an area of 230,000 square meters. The mountain is 43 meters high and has a circumference of 1015 meters. The flower lawn in the garden covers an area of 1,100 square meters.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%99%AF%E5%B1%B1%E5%85%AC%E5%9B%AD/63814?fr=aladdin#3">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>   
<br/>
<br/>
    <p>建筑包含围墙与河道，内墙，外墙，桥梁，城隍庙，景山五亭。</p>
    <p className="grey-text">The building includes surrounding walls and river channels, inner walls, outer walls, bridges, Chenghuang Temple, Jingshan Five Pavilions.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E6%99%AF%E5%B1%B1%E5%85%AC%E5%9B%AD#%E5%BB%BA%E7%AD%91">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>   

    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal48">历史</a>
<div id="modal48" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">景山公园 - 历史</h4>
    <p>明朝洪武初年，工部郎中萧洵参与拆毁元故宫，亲览后苑景色以及金殿、翠殿、花亭、毡阁等建筑。永乐年间，明成祖朱棣在北京大规模营建城池、宫殿和园林。依据“苍龙、白虎、朱雀、玄武，天之四灵，以正四方”之说，紫禁城之北乃是玄武之位，当有山。故将挖掘紫禁城筒子河和太液、南海的泥土堆积在“青山”，形成五座山峰，称“万岁山”。
<br/>
<br/>
明崇祯十七年（1644年）三月十九日，李自成攻入北京，是为甲申之变，明思宗自缢于万岁山东麓一株老槐树上。清军入关后，为笼络人心，将此槐树称为“罪槐”，用铁链锁住，并规定皇族、文武官员路过此地都要下马步行，以示对明思宗的尊敬。
<br/>
<br/>
清顺治十二年（1655年）将“万岁山”改称“景山”。康熙十九年（1680年）春，康熙帝登景山眺望京师，见晨雾缭绕，霞光流云，一派春色，即作诗一首，其中有“云霄千尺倚丹丘，辇下山河一望收”之句。丹丘乃神仙居所，此处以其比喻景山.
<br/>
<br/>
光绪二十六年（1900年）庚子事变时，八国联军占领北京，景山受到严重破坏。民国17年（1928年），景山被辟为公园，属故宫博物院管理，修葺后供游人观赏。 </p>
    <p className="grey-text">In the early years of Hongwu in the Ming Dynasty, Xiao Xun, a medic of the Ministry of Industry, participated in the demolition of the Yuan Palace, and saw the scenery of the back garden, the golden palace, the green palace, the flower pavilion, and the felt pavilion. During the Yongle period, Ming Chengzu Zhu Di built large-scale cities, palaces and gardens in Beijing. According to the saying "Canglong, White Tiger, Suzaku, Xuanwu, the four spirits of the sky, in order to be square", the north of the Forbidden City is the place of Xuanwu, and there should be mountains. Therefore, the soil from the Tongzi River, Taiye, and South China Sea in the Forbidden City was piled up on the "green hills" to form five peaks, called "Long Live Mountain".
<br/>
<br/>
On March 19 in the 17th year of Ming Chongzhen (1644), Li Zicheng invaded Beijing, which was a change in Jiashen. Ming Sizong hanged himself on an old locust tree at the foot of Long Live Mountain. After the Qing army entered the customs, in order to win people's hearts, the locust tree was called the "guilt locust", and it was locked with iron chains, and the royal family and civil and military officials were required to dismount and walk when passing by to show their respect for Ming Sizong.
<br/>
<br/>
In the twelfth year of Shunzhi in Qing Dynasty (1655), "Long Live Mountain" was renamed "Jingshan". In the spring of the nineteenth year of Kangxi (1680), Emperor Kangxi climbed Jingshan to look at the capital. Seeing the morning mist, the rays of light and the flowing clouds, he wrote a poem, including "The clouds are thousands of feet leaning against the Danqiu, and the mountains and rivers are overlooked. "Sentence. Danqiu is the abode of gods, and here is a metaphor for Jingshan.
<br/>
<br/>
During the Gengzi Incident in the twenty-sixth year of Guangxu (1900), the Eight-Nation Alliance occupied Beijing and Jingshan was severely damaged. In the 17th year of the Republic of China (1928), Jingshan was turned into a park, which was managed by the Palace Museum and was repaired for visitors to watch.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E6%99%AF%E5%B1%B1%E5%85%AC%E5%9B%AD">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
                            </div>
                        </div>

                        <div className="titleContent">
                            <h5>2019/07/29</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/5.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>明思宗殉国处</p>
                                    <p>在北京景山公园里，有一棵为人熟知的老槐树，据说明朝最后一位皇帝崇祯在此自缢。树的旁边，矗立着两座石碑。一座镌刻“明思宗殉国处”六个大字，为1930年故宫博物院延请著名书法家沈尹默书写勒成；另一座则是1944年由前清翰林傅增湘所作的《明思宗殉国三百年纪念碑》。</p>
                                    <p>作为明朝的第十六位皇帝，崇祯大概怎么也不会预料到自己将会成为明朝的亡国之君。在他之前，像嘉靖、万历两位皇帝，数十年不上朝，已经导致明朝内部政治腐败愈发严重，外部来自后金的侵扰也逐步加深。反观崇祯，即位之初便力图革新吏治，处置了以魏忠贤为首的阉党，“慨然有为”，给人带来了一丝希望。怎奈国家积弊已久，纵然崇祯再努力，也已无力挽救危局。内忧外患的夹击，最终促成了崇祯的自缢和明朝的灭亡。</p>
                                    <p>1944年，为农历甲申年，也是明朝灭亡整三百年，在崇祯自缢之处新立了一座纪念碑，这便是《明思宗殉国三百年纪念碑》。碑文由曾任北洋政府教育总长的著名藏书家傅增湘撰成。在近千字的碑文中，不仅可以看到傅先生对明朝灭亡和崇祯之死的追溯，更能感受到他文字背后的深层内涵和关怀。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="http://culture.people.com.cn/n/2015/0618/c22219-27174724.html">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>

                        </div>
                        

                    </div>

                    <div className="titles" id="title13">
                        <h4 className="tooltipped title" data-tooltip="Beihai Park">北海公园</h4>
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img7/3.jpg"  alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Beihai+Park/@39.9254515,116.3870752,17z/data=!3m1!4b1!4m5!3m4!1s0x35f052e6b32b6159:0xe22c80c89d0ec575!8m2!3d39.9254474!4d116.3892639">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">北海公园</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                            <div className="details">

<a className="btn-flat modal-trigger right info " href="#modal49">门票价格</a>
<div id="modal49" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">北海公园 - 门票价格</h4>
    <p>门票价格: 联票成人票20元/张; 半价票10元/张</p>
    <p className="grey-text">Price: 20 yuan/adult and 10 yuan/half-price ticket</p>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal50">地址</a>
<div id="modal50" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">北海公园 - 地址</h4>
    <p>地址: 北京市西城区文津街1号</p>
    <p className="grey-text">Address: 1 Wenjin St, Xicheng District, China, 100034</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Beihai+Park/@39.9254515,116.3870752,17z/data=!3m1!4b1!4m5!3m4!1s0x35f052e6b32b6159:0xe22c80c89d0ec575!8m2!3d39.9254474!4d116.3892639">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Find it on map...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal51">建筑</a>
<div id="modal51" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">北海公园 - 建筑</h4>
    <p>北海全园占地约70公顷，其中水域占据了一半以上的面积。太池中有琼华、团城和犀山台三岛，分别象征着蓬莱、瀛洲和方丈，体现了对蓬莱仙境的追求。是一座纯粹的人工园林，布局以水为主体，在太液池中布置岛屿，用桥和岸边相连。
<br/>
<br/>
它的建筑风格受到一些江南园林的影响，但总体上仍然保持了北方园林持重端庄的特点。园内宗教色彩十分浓厚，不仅琼华岛上有永安寺，在北岸和东岸还有阐福寺、西天梵境、小西天、龙王庙、先蚕坛等佛教、道教建筑，因此是一座集宫室、宅第、寺庙、园林于一体的宏大帝王宫苑。</p>
    <p className="grey-text">The entire Beihai Park occupies an area of about 70 hectares, of which the water area occupies more than half of the area. There are Qionghua, Tuancheng and Xishantai islands in Taichi, which symbolize Penglai, Yingzhou and Fangzhang, respectively, and embody the pursuit of Penglai's fairyland. It is a purely artificial garden with water as the main body. The islands are arranged in the Taiye Pool and connected to the shore by bridges. 
<br/>
<br/>
Its architectural style is influenced by some gardens in the south of the Yangtze River, but on the whole it still maintains the characteristics of dignity and dignity of northern gardens. The garden is very religious. There is not only Yong'an Temple on Qionghua Island, but also Buddhist and Taoist buildings such as Chanfu Temple, Xitian Fanjing, Xiaoxitian, Longwang Temple, and Xiancantan on the north and east banks. A grand imperial palace with palaces, mansions, temples and gardens.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E5%8C%97%E6%B5%B7%E5%85%AC%E5%9B%AD">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal52">历史</a>
<div id="modal52" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">北海公园 - 历史</h4>
    <p>北海先后历经辽、金、元、明、清五朝的兴建，历史悠久且重建时承袭较多。清代末光绪十一年（1885年），慈禧太后私自动用海军军费增修过北海。1900年八国联军攻占北京时，对北海有过较大的破坏，两年后进行了重建。中华人民共和国成立后，中南海成为了中央人民政府的驻地，北海作为公园开放。1961年，北海及团城被中华人民共和国国务院公布为第一批全国重点文物保护单位之一。1969年至1979年，北海公园以“保护中南海安全”为由关闭，禁止游人入内。1979年后北海公园重新对公众开放。</p>

    <p className="grey-text">Beihai has undergone the construction of the Liao, Jin, Yuan, Ming, and Qing dynasties successively, with a long history and many inheritances during reconstruction. In the eleventh year of Guangxu at the end of Qing Dynasty (1885), Empress Dowager Cixi used the navy and military expenses to increase the Beihai privately. When the Eight-Nation Alliance Forces captured Beijing in 1900, they caused considerable damage to the Beihai, and they were rebuilt two years later. After the founding of the People's Republic of China, Zhongnanhai became the residence of the Central People's Government and Beihai opened as a park. In 1961, Beihai and Tuancheng were announced by the State Council of the People's Republic of China as one of the first batch of national key cultural relics protection units. From 1969 to 1979, Beihai Park was closed on the grounds of "protecting Zhongnanhai", and visitors were prohibited from entering. After 1979, Beihai Park was reopened to the public.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E5%8C%97%E6%B5%B7%E5%85%AC%E5%9B%AD">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
                            </div>
                        </div>
                        <div className="titleContent">
                            <h5>2019/07/29</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/6.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>从景山公园出来后，步行几分钟就可以到达北海公园。</p>
                                    <p>全园以北海为中心，主要由琼华岛、东岸和北岸景区组成。琼华岛上树木苍郁，亭台楼阁幽静，白塔耸立山巅，成为公园的标志。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%8C%97%E6%B5%B7%E5%85%AC%E5%9B%AD/253388?fr=aladdin#3_3">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/7.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>北海白塔，位于北京北海公园琼华岛上。</p>
                                    <p>建于清初顺治八年(1651)，是一座藏式喇嘛塔，也是北海的标志性景点。北海白塔据建塔石碑记载，当时"有西域喇嘛者，欲以佛教阴赞皇猷，请立塔寺，寿国佑民"，得到皇帝的恩准，于是修建了永安寺和白塔。
<br/>
<br/>
塔身全部为砖木石混合结构，由塔基、塔身、相轮、华盖、塔刹五部分组成。塔身为覆钵式，因此从塔的表面只能看到砖和石料而见不到木构架。塔高35.9 米，上圆下方，富有变化，为须弥山座式，塔顶设有宝盖、宝顶，并装饰有日、月及火焰花纹，以表示“佛法”像日、月那样光芒四射，永照大地。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%8C%97%E6%B5%B7%E7%99%BD%E5%A1%94/748542?fr=aladdin">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>

                            </div>



                        </div>
                        
                    </div>

                    <div className="titles" id="title14">
                        <h4 className="title">Modernista</h4>
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img7/4.jpg"  alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/44+Baochao+Hutong,+Dongcheng+Qu,+Beijing+Shi,+China,+100190/@39.9445096,116.3996862,19.89z/data=!4m5!3m4!1s0x35f0533ee6163ddd:0x16b7703fe6644049!8m2!3d39.944584!4d116.399862">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">Modernista</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                            <div className="details">

<a className="btn-flat modal-trigger right info " href="#modal53">门票价格</a>
<div id="modal53" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">Modernista - 门票价格</h4>
    <p>门票价格: 90元/张</p>
    <p className="grey-text">Price: 90 yuan/ticket</p>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal54">地址</a>
<div id="modal54" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">Modernista - 地址</h4>
    <p>地址: 北京市东城区鼓楼东大街宝钞胡同44号</p>
    <p className="grey-text">Address: 44 Baochao Hutong, Dongcheng, Beijing, China</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/44+Baochao+Hutong,+Dongcheng+Qu,+Beijing+Shi,+China,+100190/@39.9445881,116.3976733,17z/data=!3m1!4b1!4m5!3m4!1s0x35f0533ee6163ddd:0x16b7703fe6644049!8m2!3d39.944584!4d116.399862">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Find it on map...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
                            </div>
                        </div>
                        <div className="titleContent">
                            <h5>2019/07/29</h5>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/8.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>晚饭过后我们来到Modernista，地方在宝钞胡同里面，有一点不好找。</p>
                                    <p>来这里的外国人挺多的，每晚有不同的乐队演出。</p>
                                </div>
                            </div>




                        </div>
                    </div>

                    <div className="titles" id="title15">
                        <h4 className="tooltipped title" data-tooltip="Prince Gong Mansion">恭王府</h4>
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img7/5.jpg"  alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Prince+Gong+Mansion/@39.935774,116.3776523,15z/data=!4m8!1m2!2m1!1sgongwangfu!3m4!1s0x35f05303c65abf2b:0x6ec74549d7c3a6f5!8m2!3d39.935774!4d116.386407">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">恭王府</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                            <div className="details">

<a className="btn-flat modal-trigger right info " href="#modal55">门票价格</a>
<div id="modal55" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">恭王府 - 门票价格</h4>
    <p>门票价格: 成人票40元/张; 半价票20元/张</p>
    <p className="grey-text">Price: 40 yuan/adult and 20 yuan/half-price ticket</p>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal56">地址</a>
<div id="modal56" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">恭王府 - 地址</h4>
    <p>地址: 北京市西城区什刹海前海西街17号</p>
    <p className="grey-text">Address: 17 Qianhai W St, Shi Cha Hai, Xicheng District, Beijing, China</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Prince+Gong+Mansion/@39.935774,116.3776523,15z/data=!4m8!1m2!2m1!1sgongwangfu!3m4!1s0x35f05303c65abf2b:0x6ec74549d7c3a6f5!8m2!3d39.935774!4d116.386407">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Find it on map...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal57">建筑</a>
<div id="modal57" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">恭王府 - 建筑</h4>
    <p>恭王府由府邸和花园两部分组成，南北长约330米，东西宽180余米，占地面积约61120平方米，其中府邸占地32260平方米，花园占地28860平方米。
<br/>
<br/>
恭王府王府在南，花园在北，由高高的后罩楼将王府与花园分开。
<br/>
<br/>
恭王府分中东西三路，分别由多过四合院组成，后为长160米的二层后罩楼。
<br/>
<br/>
恭王府花园也分为三路。中路是西洋门，独乐峰，蝠池，安善堂及左右配殿明道堂，棣华轩，福字碑，邀月台，蝠厅；东路是怡神所垂花门，大戏楼，芭蕉院；西路是湖心亭，澄怀撷秀。此外还有龙王庙、榆关、妙香亭、流杯亭、艺蔬圃。</p>
    <p className="grey-text">Prince Gong’s Mansion is composed of two parts: a mansion and a garden. It is about 330 meters long from north to south and 180 meters wide from east to west.
<br/>
<br/>
The palace of Prince Gong's residence is in the south, and the garden is in the north. The palace is separated from the garden by a tall back cover.
<br/>
<br/>
Prince Gong's Mansion is divided into three roads, the east and the west, which are composed of more than courtyards, followed by a two-story rear cover building with a length of 160 meters.
<br/>
<br/>
Prince Gong's Mansion Garden is also divided into three roads. The middle road is Xiyangmen, Dulefeng, Manta Pond, Anshan Hall and Mingdaotang, Dihuaxuan, Fuzi Tablet, Inviting Platform, Bat Hall; East Road is Yishen Suohuamen, Daxilou, Banjiaoyuan; west The road is the pavilion in the heart of the lake. In addition, there are Longwang Temple, Yuguan, Miaoxiang Pavilion, Liubei Pavilion, and Yishu Garden.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E6%81%AD%E7%8E%8B%E5%BA%9C#%E5%BB%BA%E7%AD%91">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal58">历史</a>
<div id="modal58" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">恭王府 - 历史</h4>
    <p>恭王府及花园原是固伦和孝公主在和珅作为二品户部侍郎邸的规制基础上，花六年增建的公主府，相当于郡王府规制，西路建筑是大臣和珅的府邸以一品大员建造，嘉庆四年（1799年）正月，和珅遭赐死，四月「和珅之宅，赏给庆郡王永璘居住；和珅之园，赏给成亲王永瑆居住。」，咸丰元年后由于庆亲王的孙子奕劻世袭降低为辅国将军，换至得定府大街原大学士琦善宅，原王府由内务府收回，后于咸丰年间赐予奕欣作为府邸，称为恭王府。 
<br/>
<br/>
1921年，恭亲王奕欣的孙子溥伟、 溥儒（溥心畲）将恭王府和花园抵押给天主教会，后由辅仁大学买去作为校舍，后先后为北京师范大学、中国音乐学院的校舍。恭王府建筑曾部分为北京空调机厂占用，1980年代腾退。 1996年10月起，恭王府花园作为旅游景点供公众参观。 2008年8月20日，修复后的恭王府全部对外开放。
<br/>
<br/>
2017年，文化部恭王府博物馆被中国博物馆协会评为第三批国家一级博物馆。</p>

    <p className="grey-text">Prince Gong’s Mansion and the Garden were originally built on the basis of the regulations of Heshen and Princess Gu Lun as the residence of Erpin Hubu. It was built in the first month of the fourth year of Jiaqing (1799), and Heshen was bestowed to death. In April, "Heshen’s House was rewarded to Qingjun Wang Yonglin for residence; Heshen’s Garden was rewarded to Cheng Prince Yongxuan’s residence.", After the first year of Xianfeng, the grandson of Prince Qing, Yiqiu, was heredically reduced to the general of the auxiliary country, and was replaced by the former University Shiqi Shan’s House on Dedingfu Street. The original palace was taken back by the House of Internal Affairs and later given to Yixin as a residence in the Xianfeng period. Prince Gong's Mansion.
<br/>
<br/>
In 1921, Prince Gong Yixin’s grandsons Pu Wei and Pu Ru (Pu Xinshe) pledged the Prince Gong’s mansion and garden to the Catholic Church, which was later bought by Fu Jen Catholic University as a school building, and later served as the Beijing Normal University and the Chinese Conservatory of Music. School building. The building of Prince Gong's Mansion was partly occupied by Beijing Air Conditioner Factory, and it was retired in the 1980s. Since October 1996, Prince Gong’s Mansion Garden has been used as a tourist attraction for the public to visit. On August 20, 2008, the restored Prince Gong's Mansion was all opened to the public.
<br/>
<br/>
In 2017, the Prince Gong's Mansion Museum of the Ministry of Culture was named the third batch of national first-class museums by the Chinese Museum Association.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E6%81%AD%E7%8E%8B%E5%BA%9C#%E5%8E%86%E5%8F%B2">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
                            </div>
                        </div>
                        <div className="titleContent">
                            <h5>2019/07/30</h5>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/9.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>恭王府花园</p>
                                    <p>恭王府王府在南，花园在北，由高高的后罩楼将王府与花园分开。</p>
                                    <p>恭王府花园也分为三路。中路是西洋门，独乐峰，蝠池，安善堂及左右配殿明道堂，棣华轩，福字碑，邀月台，蝠厅；东路是怡神所垂花门，大戏楼，芭蕉院；西路是湖心亭，澄怀撷秀。此外还有龙王庙、榆关、妙香亭、流杯亭、艺蔬圃。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E6%81%AD%E7%8E%8B%E5%BA%9C">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/10.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>恭王府大戏楼</p>
                                    <p>位于什刹海北岸的恭王府是世界最大的四合院，也是今日北京城里60余座清代王府中保存最完整的一个。大戏楼也叫怡神所是恭王府花园的第二绝。
                                    <br/>
                                    <br/>
                                    整个大戏楼是纯木结构，采用三卷勾连搭式屋顶，以前供亲王观戏，戏楼内厅堂很高大，但音响效果非常好，处在大堂最边远的角落，戏台上的唱词也听得清清楚楚，这在设计上确实到了绝妙的境地。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%81%AD%E7%8E%8B%E5%BA%9C%E5%A4%A7%E6%88%8F%E6%A5%BC">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>


                        </div>
                        
                    </div>

                    <div className="titles" id="title16">
                        <h4 className="tooltipped title" data-tooltip="The Geological Museum of China">中国地质博物馆</h4>
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img7/6.jpg"  alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/The+Geological+Museum+of+China,+Xicheng+Qu,+Beijing+Shi,+China/@39.9235363,116.3696596,17z/data=!3m1!4b1!4m5!3m4!1s0x35f052587d891865:0x9acf47983e4925f!8m2!3d39.9235322!4d116.3718483">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">中国地质博物馆</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                            <div className="details">

<a className="btn-flat modal-trigger right info " href="#modal59">门票价格</a>
<div id="modal59" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国地质博物馆 - 门票价格</h4>
    <p>门票价格: 成人票30元/张; 半价票15元/张</p>
    <p className="grey-text">Price: 30 yuan/adult and 15 yuan/half-price ticket</p>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal60">地址</a>
<div id="modal60" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国地质博物馆 - 地址</h4>
    <p>地址: 北京市西城区文津街1号</p>
    <p className="grey-text">Address: 1 Wenjin St, Xicheng District, China, 100034</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/The+Geological+Museum+of+China,+Xicheng+Qu,+Beijing+Shi,+China/@39.9235363,116.3696596,17z/data=!3m1!4b1!4m5!3m4!1s0x35f052587d891865:0x9acf47983e4925f!8m2!3d39.9235322!4d116.3718483">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Find it on map...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal61">展览</a>
<div id="modal61" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国地质博物馆 - 展览</h4>
    <p>中国地质博物馆收藏有20余万件地质标本，其中包括巨型山东龙、中华龙鸟等恐龙化石，北京人、元谋人、山顶洞人等古人类化石，鱼类、鸟类、昆虫等史前生物化石，此外还有有世界最大的“水晶王”、巨型萤石方解石晶簇标本、蓝铜矿、辰砂、雄黄、雌黄、白钨矿、辉锑矿等有中国特色的矿物标本，各种宝石、玉石等珍品。
<br/>
<br/>
中国地质博物馆长期开展矿物岩石学、地层古生物学、宝石学、博物馆学研究，特别是在早期脊椎动物学、昆虫学、辽西热河生物群等领域取得突出成果。该馆通过系统的宝石陈列及宝石研究成果，引导了中国当代宝石科学研究、普及及市场消费。
<br/>
<br/>
中国地质博物馆常年推出陈列及展览。基本陈列按照地球圈层结构进行布局，展示矿物、岩石、宝石、化石，关注人类生存环境，并且通过仿生、数字化、虚拟现实等等技术，使观众在地学空间中获得独特体验。
<br/>
<br/>
中国地质博物馆于1981年创刊《地球》，是中华人民共和国唯一的地学科普杂志。该馆还每年举办全国青少年地学夏令营。</p>
    <p className="grey-text">The China Geological Museum has more than 200,000 geological specimens, including giant Shandong dragon, Chinese dragon bird and other dinosaur fossils, Peking man, Yuanmou man, Shanding cave man and other ancient human fossils, fish, birds, insects and other prehistoric creature fossils. In addition, there are the world’s largest "Crystal King", giant fluorite calcite crystal cluster specimens, azurite, cinnabar, realgar, orpiment, scheelite, antimonite and other mineral specimens with Chinese characteristics, various gems and jade And other treasures.
<br/>
<br/>
The China Geological Museum has been carrying out researches on mineral petrology, stratigraphic paleontology, gemology, and museology for a long time, especially in the fields of early vertebrates, entomology, and Jehol Biota in western Liaoning. The museum has guided the scientific research, popularization and market consumption of contemporary gems in China through systematic gem display and gem research results.
<br/>
<br/>
The China Geological Museum launches displays and exhibitions throughout the year. The basic display is arranged according to the structure of the earth circle, showing minerals, rocks, gems, and fossils, paying attention to the human living environment, and using bionics, digitization, virtual reality and other technologies to enable the audience to have a unique experience in the geoscience space.
<br/>
<br/>
The China Geological Museum founded "Earth" in 1981, and it is the only popular geological magazine in the People's Republic of China. The museum also holds a national youth geoscience summer camp every year.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E4%B8%AD%E5%9B%BD%E5%9C%B0%E8%B4%A8%E5%8D%9A%E7%89%A9%E9%A6%86#%E5%B1%95%E8%A7%88%E5%8F%8A%E7%A0%94%E7%A9%B6">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal62">建筑</a>
<div id="modal62" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国地质博物馆 - 建筑</h4>
    <p>中国地质博物馆主馆共有大楼6层，总高约36米，东西长40米，南北长70米，建筑面积近11000平方米，建筑风格稳重大方。
<br/>
<br/>
中国地质博物馆展厅包括地球厅、矿物岩石厅、宝石玉厅、史前生物厅等六个部分，面积共2500平方米，另有临时展览面积1500平方米。</p>
    <p className="grey-text">The main building of the China Geological Museum has 6 floors with a total height of about 36 meters, 40 meters long from east to west, and 70 meters long from north to south. The building area is nearly 11,000 square meters and the architectural style is stable and generous.
<br/>
<br/>
The exhibition hall of the China Geological Museum includes six parts: the earth hall, the mineral rock hall, the gem and jade hall, and the prehistoric biology hall, covering a total area of 2500 square meters and a temporary exhibition area of 1500 square meters.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%B8%AD%E5%9B%BD%E5%9C%B0%E8%B4%A8%E5%8D%9A%E7%89%A9%E9%A6%86#2_1">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
                            </div>
                        </div>
                        <div className="titleContent">
                            <h5>2019/07/30</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/11.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>中国地质博物馆分为地球展厅，宝石展厅，矿物岩石展厅，史前生物展厅，关怀与鼓舞厅和虚拟展厅</p>
                                    <p>中国地质博物馆收藏地质标本20余万件，涵盖地学各领域。藏品有巨型山东龙、中华龙鸟等恐龙系列化石，北京人、元谋人、山顶洞人等古人类化石，以及大量集科学价值与观赏价值于一身的鱼类、鸟类、昆虫等珍贵史前生物化石；有世界最大的“水晶王”、巨型萤石方解石晶簇标本、精美的蓝铜矿、辰砂、雄黄、雌黄、白钨矿、辉锑矿等中国特色矿物标本，以及种类繁多的宝石、玉石等一批国宝级珍品。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%B8%AD%E5%9B%BD%E5%9C%B0%E8%B4%A8%E5%8D%9A%E7%89%A9%E9%A6%86">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>


                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/12.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>中国鸟龙</p>
                                    <p>中国鸟龙（属名：Sinornithosaurus，意为“中国的鸟蜥蜴”），是种驰龙科的有羽毛恐龙，化石发现于中国的义县组，年代为下白垩纪的中巴列姆阶。</p>
                                    <p>体长1.5米，体重8公斤，食性肉食。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%B8%AD%E5%9B%BD%E9%B8%9F%E9%BE%99">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>

                        
                        </div>
                        
                    </div>

                    <div className="titles" id="title17">
                        <h4 className="tooltipped title" data-tooltip="National Library of China">中国国家图书馆</h4>
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img7/7.jpg"  alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/National+Library+of+China/@39.943019,116.3143563,15z/data=!4m8!1m2!2m1!1z5Lit5Zu95Zu95a625Zu-5Lmm6aaG!3m4!1s0x35f05180c674fe5d:0x1a31b5d9e3498011!8m2!3d39.943019!4d116.323111">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">中国国家图书馆</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                            <div className="details">

<a className="btn-flat modal-trigger right info " href="#modal63">门票价格</a>
<div id="modal63" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国国家图书馆 - 门票价格</h4>
    <p>门票价格: 免费参观</p>
    <p className="grey-text">Price: free to visit</p>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal64">地址</a>
<div id="modal64" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国国家图书馆 - 地址</h4>
    <p>地址: 北京市海淀区紫竹桥中关村南大街33号</p>
    <p className="grey-text">Address: 33 Zhongguancun S St, Zi Zhu Qiao, Haidian District, China</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/National+Library+of+China/@39.943019,116.3143563,15z/data=!4m8!1m2!2m1!1z5Lit5Zu95Zu95a625Zu-5Lmm6aaG!3m4!1s0x35f05180c674fe5d:0x1a31b5d9e3498011!8m2!3d39.943019!4d116.323111">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Find it on map...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal65">建筑</a>
<div id="modal65" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国国家图书馆 - 建筑</h4>
    <p>中国国家图书馆总馆南区日均接待读者1.2万人次，馆藏远远超过2000万件的设计容量，原有馆舍已不敷使用。
<br/>
<br/>
2003年，经国家批准，立项扩建，二期新馆舍于2008年9月启用，成为中国国家图书馆总馆北区，扩建完成后中国国家图书馆的馆舍总面积达到25万平方米。</p>
    <p className="grey-text">The Southern District of the National Library of China receives an average of 12,000 readers per day, and the collection far exceeds the designed capacity of 20 million pieces. The original building is no longer sufficient.
<br/>
<br/>
In 2003, approved by the state, a project was established for expansion. The second phase of the new building was opened in September 2008 and became the north area of the National Library of China. After the expansion, the total area of the National Library of China reached 250,000 square meters.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E4%B8%AD%E5%9B%BD%E5%9B%BD%E5%AE%B6%E5%9B%BE%E4%B9%A6%E9%A6%86#%E5%BB%BA%E7%AD%91">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal66">历史</a>
<div id="modal66" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国国家图书馆 - 历史</h4>
    <p>中国国家图书馆的前身是于1909年9月9日始建的京师图书馆。中华民国成立后，北洋政府的教育部接管京师图书馆，后将其改名为国立京师图书馆，1912年（民国元年）8月27日正式开馆接待读者。 
<br/>
<br/>
1928年北伐成功后，国立京师图书馆改名国立北平图书馆，并迁址到中南海居仁堂。
<br/>
<br/>
中华人民共和国成立后，于1950年3月6日国立北平图书馆更名为国立北京图书馆，1951年6月12日更名为北京图书馆，成为中华人民共和国唯一的国家图书馆。</p>

    <p className="grey-text">The predecessor of the National Library of China was the Jingshi Library which was founded on September 9, 1909. After the establishment of the Republic of China, the Ministry of Education of the Beiyang Government took over the Jingshi Library and later renamed it the National Jingshi Library. It was officially opened on August 27 to receive readers.
<br/>
<br/>
After the successful Northern Expedition in 1928, the National Capital Library was renamed the National Beiping Library and moved to Jurentang, Zhongnanhai.
<br/>
<br/>
After the founding of the People’s Republic of China, the National Peking Library was renamed the National Beijing Library on March 6, 1950, and the Beijing Library on June 12, 1951, becoming the only national library of the People’s Republic of China.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E4%B8%AD%E5%9B%BD%E5%9B%BD%E5%AE%B6%E5%9B%BE%E4%B9%A6%E9%A6%86#%E5%8E%86%E5%8F%B2">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
                            </div>
                        </div>
                        <div className="titleContent">
                            <h5>2019/07/30</h5>


                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/13.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>不可以背大包进图书馆，进入前需要先存包。</p>
                                    <p>在一楼可以刷身份证进入图书馆借阅书籍</p>
                                    
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%B8%AD%E5%9B%BD%E5%9B%BD%E5%AE%B6%E5%A4%A7%E5%89%A7%E9%99%A2/1063477?fromtitle=%E5%9B%BD%E5%AE%B6%E5%A4%A7%E5%89%A7%E9%99%A2&fromid=69546&fr=aladdin#2_2">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>

                        </div>
                        
                    </div>

                    <div className="titles" id="title18">
                        <h4 className="tooltipped title" data-tooltip="Haidian Theatre">海淀剧院</h4>
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img7/8.jpg"  alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Haidian+Theater/@39.9650077,116.3034553,14z/data=!4m8!1m2!2m1!1z5rW35reA5Ymn6Zmi!3m4!1s0x0:0xf05dfe722ef4b08f!8m2!3d39.9766474!4d116.3182479">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">海淀剧院</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                            <div className="details">

<a className="btn-flat modal-trigger right info " href="#modal67">门票价格</a>
<div id="modal67" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">海淀剧院 - 门票价格</h4>
    <p>门票价格: 不同演出不同价格</p>
    <p className="grey-text">Price: Different performances, different prices</p>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal68">地址</a>
<div id="modal68" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">海淀剧院 - 地址</h4>
    <p>地址: 北京市海淀区中关村大街28号</p>
    <p className="grey-text">Address: 28 Zhongguancun St, Haidian District, Beijing, China</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Haidian+Theater/@39.9650077,116.3034553,14z/data=!4m8!1m2!2m1!1z5rW35reA5Ymn6Zmi!3m4!1s0x0:0xf05dfe722ef4b08f!8m2!3d39.9766474!4d116.3182479">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Find it on map...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal69">建筑</a>
<div id="modal69" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">海淀剧院 - 建筑</h4>
    <p>海淀剧院是北京西北地区惟一一座建筑一流、设备一流、功能品质一流的综合性大型文化设施。能够容纳980个座位的大厅和两个各容纳120名观众的豪华小厅，设施非常完备、高级。大厅舞台既有乐池、升降舞台，又有音罩、乐队平台，可以满足舞剧、歌剧、话剧、戏曲、歌舞、曲艺及交响音乐会的各种要求。</p>
    <p className="grey-text">Haidian Theater is the only comprehensive large-scale cultural facility with first-class architecture, first-class equipment, and first-class functional quality in northwestern Beijing. The hall with 980 seats and two luxurious small halls with 120 spectators each have very complete and advanced facilities. The hall stage has a music pool, a lifting stage, a sound cover, and a band platform, which can meet the various requirements of dance drama, opera, drama, opera, song and dance, folk art and symphony concerts.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%B5%B7%E6%B7%80%E5%89%A7%E9%99%A2#1">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal70">历史</a>
<div id="modal70" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">海淀剧院 - 历史</h4>
    <p>海淀区政府斥巨资兴建的海淀剧院重建工程于2001年3月1日正式开工，2003年11月30日竣工。在此期间，区委区政府对海淀剧院的建设工程给予了极大的关注和支持。经过各方积极的筹备，海淀剧院于2003年12月28日正式重张开业。</p>

    <p className="grey-text">The Haidian Theater reconstruction project, which the Haidian District Government invested heavily in construction, officially started on March 1, 2001 and was completed on November 30, 2003. During this period, the district committee and government paid great attention and support to the construction project of Haidian Theater. After active preparations from all parties, the Haidian Theater officially reopened on December 28, 2003.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%B5%B7%E6%B7%80%E5%89%A7%E9%99%A2#2">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
                            </div>
                        </div>
                        <div className="titleContent">
                            <h5>2019/07/30 --- 7:30pm</h5>


                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/14.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>音乐剧 - 拉赫玛尼诺夫</p>

                                </div>
                            </div>


                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/15.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>故事依托于著名俄罗斯音乐家拉赫玛尼诺夫（1873-1943）人生中一个很小的片段：《第一交响曲》首演失败后，面对种种质疑，24岁的年轻音乐家自此一蹶不振，陷入长达3年的创作空窗期。此后在心理医生达利的“催眠”和“谈话”等心理治疗下战胜心病，写出了里程碑式的《第二钢琴协奏曲》，并以此献给达利。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="https://www.sohu.com/a/277094198_169580">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>


                        </div>
                        
                    </div>

                    <div className="titles" id="title19">
                        <h4 className="tooltipped title" data-tooltip="Siji Minfu Roast Duck Shop">四季民福</h4>
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img7/9.jpg"  alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Siji+Minfu+Roast+Duck+Shop/@39.963138,116.4161941,17z/data=!4m8!1m2!2m1!1z5YyX5Lqs5biC5pyd6Ziz5Yy65a6J5a6a6Zeo5ZKM5bmz6YeM6KW_6KGXMjHlj7fljJfkuqzllYbmiqUxLDLlsYI!3m4!1s0x35f054aaa0489603:0x656edf40e4a77243!8m2!3d39.9623871!4d116.4180227">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">四季民福</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                            <div className="details">

<a className="btn-flat modal-trigger right info " href="#modal71">人均价格</a>
<div id="modal71" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">四季民福 - 人均价格</h4>
    <p>价格: 人均160RMB</p>
    <p className="grey-text">Price: RMB160 per person on average</p>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal72">地址</a>
<div id="modal72" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">四季民福 - 地址</h4>
    <p>地址: 北京市朝阳区安定门和平里西街21号北京商报1,2层</p>
    <p className="grey-text">Address: Hepingli W St, Andingmen, Chaoyang, Beijing, China</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Siji+Minfu+Roast+Duck+Shop/@39.963138,116.4161941,17z/data=!4m8!1m2!2m1!1z5YyX5Lqs5biC5pyd6Ziz5Yy65a6J5a6a6Zeo5ZKM5bmz6YeM6KW_6KGXMjHlj7fljJfkuqzllYbmiqUxLDLlsYI!3m4!1s0x35f054aaa0489603:0x656edf40e4a77243!8m2!3d39.9623871!4d116.4180227">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Find it on map...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

{/* <a className="btn-flat modal-trigger right info " href="#modal34">建筑</a>
<div id="modal34" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">四季民福 - 建筑</h4>
    <p></p>
    <p className="grey-text"></p>
    <a className="right" href="">
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div> */}

<a className="btn-flat modal-trigger right info " href="#modal73">餐馆简介</a>
<div id="modal73" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">四季民福 - 餐馆简介</h4>
    <p>四季民福主营北京菜和烤鸭，灯市口店装修古色古香中透着气派和华丽，很有老北京的味道。青砖砌成的墙面、灰色瓦房配上朱红色的招牌，仿佛是一张老北京的古老照片，承载着属于老北京特有的独特记忆。走进餐厅，清一色的长方桌，靠背椅，置身其中仿佛又嗅到了属于老北京的味道。菜品主要是老北京特色菜和烤鸭，菜品绝佳的味道，实在的份量以及公道的价格受到了很多食客的一致好评。“酥香嫩烤鸭”是店内当家菜品，精选北京填鸭，采用传统挂炉，用果木进行烘烤，肉质鲜嫩、酥香。此外，“贝勒烤肉”、“冲浪红极参”、“黄坛香”等京味儿特色菜也很值得品尝。</p>

    <p className="grey-text">Siji Minfu mainly specializes in Beijing cuisine and roast duck. Dengshikou store is decorated in antique style with style and gorgeousness, which is very old Beijing. The walls made of blue bricks, the gray tiled houses and the vermilion signboards are like an old photo of old Beijing, carrying unique memories that belong to old Beijing. Entering the restaurant, the rectangular tables and chairs are all in place, and you feel like you smell the old Beijing again. The dishes are mainly old Beijing specialties and roast duck. The excellent taste of the dishes, the actual portion and the reasonable price have been praised by many diners. "Crispy and tender roast duck" is the home-cooked dish in the store. Selected Beijing stuffed duck is roasted in a traditional hanging oven with fruit trees. The meat is tender and crispy. In addition, "Baylor barbecue", "Surfing Red Ginseng", "Huang Tan Xiang" and other Beijing specialties are also worth tasting.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%9B%9B%E5%AD%A3%E6%B0%91%E7%A6%8F%EF%BC%88%E7%8E%8B%E5%BA%9C%E4%BA%95%E5%BA%97%EF%BC%89">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
                            </div>
                        </div>
                        <div className="titleContent">
                            <h5>2019/07/31</h5>


                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/16.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>四季民福 - 和平里店</p>

                                </div>
                            </div>


                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/17.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>小吃拼盘 老北京炸酱面 龙虾汤泡饭</p>
                                    <p>蜜汁酥皮虾 赠品 宫廷杏仁豆腐</p>
                                    <p>巧拌豆苗 半只烤鸭 京城酥肉锅</p>

                                </div>
                            </div>


                        </div>
                        
                    </div>
                    
                    <div className="titles" id="title20">
                        <h4 className="tooltipped title" data-tooltip="National Museum of China">中国国家博物馆</h4>
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img7/10.jpg"  alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/National+Museum+of+China/@39.9050986,116.3993942,17z/data=!3m1!4b1!4m5!3m4!1s0x35f052b94ab111e7:0x2476241f0e8c609!8m2!3d39.9050945!4d116.4015829">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">中国国家博物馆</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                            <div className="details">

<a className="btn-flat modal-trigger right info " href="#modal74">门票价格</a>
<div id="modal74" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国国家博物馆 - 门票价格</h4>
    <p>门票价格: 免费参观</p>
    <p className="grey-text">Price: free to visit</p>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal75">地址</a>
<div id="modal75" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国国家博物馆 - 地址</h4>
    <p>地址: 北京市西城区文津街1号</p>
    <p className="grey-text">Address: 1 Wenjin St, Xicheng District, China, 100034</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/National+Museum+of+China/@39.9050986,116.3993942,17z/data=!3m1!4b1!4m5!3m4!1s0x35f052b94ab111e7:0x2476241f0e8c609!8m2!3d39.9050945!4d116.4015829">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Find it on map...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal76">建筑</a>
<div id="modal76" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国国家博物馆 - 建筑</h4>
    <p>中国国家博物馆，位于中国北京市东城区东长安街16号、天安门广场东侧。总建筑面积近20万平方米，藏品数量140余万件，展厅数量48个，是世界上单体建筑面积最大的博物馆，是中华文物收藏量最丰富的博物馆之一。</p>
    <p className="grey-text">The National Museum of China is located on the east side of Tiananmen Square, 16 East Chang'an Street, Dongcheng District, Beijing, China. With a total construction area of nearly 200,000 square meters, a collection of more than 1.4 million pieces, and 48 exhibition halls, it is the largest museum in the world with a single building area and one of the museums with the richest collection of cultural relics in China.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E4%B8%AD%E5%9B%BD%E5%9B%BD%E5%AE%B6%E5%8D%9A%E7%89%A9%E9%A6%86">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

<a className="btn-flat modal-trigger right info " href="#modal77">历史</a>
<div id="modal77" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">中国国家博物馆 - 历史</h4>
    <p>1912年7月9日中华民国教育部于国子监设立的国立历史博物馆筹备处。 1917年，教育部决议将紫禁城端门至午门一带划为国立历史博物馆馆址。 1918年7月迁入，并将午门正楼、雁翅楼及四角亭辟为10个陈列室，东西庑各三间为办公室，东西朝房为储藏室，总计馆舍150余间，文物五万余件。 1924年8月1日在正式对外公开展览。 1926年定名为国立历史博物馆。 1949年10月1日中华人民共和国建国后，改为国立北京历史博物馆，隶属中央人民政府文化部。
<br/>
<br/>
中国革命博物馆的前身为1950年3月成立的中央革命博物馆筹备处。
<br/>
<br/>
1960年8月，北京历史博物馆正式更名为中国历史博物馆，中央革命博物馆正式更名为中国革命博物馆。
<br/>
<br/>
1969年9月，中国革命博物馆和中国历史博物馆合并，称中国革命历史博物馆。
<br/>
<br/>
2003年2月28日两馆再次合并，成立中国国家博物馆。国博当时总面积65,000多平方米，中央部分和两翼的中部是十二根巨型方柱式的门廊。穿过门廊，走进中央大厅，整个建筑分成互相对称的南北两部分。
<br/>
<br/>
中国国家博物馆从2007年1月31日起闭馆进行扩建，设计方案于2007年春节公布。工程于2007年4月开始至2010年3月1日完工，历时3年，投资25亿人民币。建筑面积由6.5万平方公​​尺增加到19.19万平方公尺,使得该馆成为世界上单体建筑面积最大的博物馆，展厅数量为48个，设有“古代中国”、“复兴之路”两个主要的基本陈列，设有十余个各艺术门类的专题展览及国际交流展览。</p>

    <p className="grey-text">The Preparatory Office of the National Museum of History established by the Ministry of Education of the Republic of China in the Imperial College on July 9, 1912. In 1917, the Ministry of Education decided to designate the area from Duanmen to Wumen of the Forbidden City as the site of the National Museum of History. Moved in in July 1918, and opened the Meridian Main Building, Yanchi Building and Sijiao Pavilion into 10 showrooms, with three offices in each of the east and west halls, and storage rooms in the east and west halls. There are more than 150 buildings and cultural relics. More than 50,000 pieces. It was officially publicly exhibited on August 1, 1924. Named the National Museum of History in 1926. After the founding of the People’s Republic of China on October 1, 1949, it was renamed the National Beijing History Museum under the Ministry of Culture of the Central People’s Government.
<br/>
<br/>
The predecessor of the Chinese Revolution Museum was the Preparatory Office of the Central Revolution Museum established in March 1950.
<br/>
<br/>
In August 1960, the Beijing History Museum officially changed its name to the Chinese History Museum, and the Central Revolution Museum officially changed its name to the Chinese Revolution Museum.
<br/>
<br/>
In September 1969, the Chinese Revolution Museum and the Chinese History Museum were merged to form the Chinese Revolution History Museum.
<br/>
<br/>
On February 28, 2003, the two museums merged again to form the National Museum of China. At that time, the National Museum had a total area of ​​more than 65,000 square meters. The central part and the middle of the two wings were twelve giant square pillar porches. Passing through the porch and into the central hall, the whole building is divided into two symmetrical north and south parts.
<br/>
<br/>
The National Museum of China will be closed for expansion on January 31, 2007, and the design plan was announced in the 2007 Spring Festival. The project started in April 2007 and was completed on March 1, 2010. It lasted 3 years and invested 2.5 billion yuan. The building area has increased from 65,000 square meters to 191,900 square meters, making the museum the largest museum in the world with a single building area. The number of exhibition halls is 48, with "Ancient China" and "Rejuvenation Road". There are two main basic exhibitions, with more than ten thematic exhibitions of various art categories and international exchange exhibitions.</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://zh.wikipedia.org/wiki/%E4%B8%AD%E5%9B%BD%E5%9B%BD%E5%AE%B6%E5%8D%9A%E7%89%A9%E9%A6%86#%E6%B2%BF%E9%9D%A9">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
                            </div>
                        </div>
                        <div className="titleContent">
                            <h5>2019/07/31</h5>


                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/18.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>需要在公众号上提前1-5天预约</p>
                                    <p>公众号：国家博物馆</p>
                                    <p>存包的价格是5RMB/包</p>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/19.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>玉龙 - 红山文化</p>
                                    <p>新石器时代红山文化玉龙是新石器时代的玉器（祭器礼器），于1971年出土于内蒙古自治区翁牛特旗三星他拉遗址。 
<br/>
<br/>
新石器时代红山文化玉龙墨绿色，高26厘米，完整无缺，体蜷曲，呈“C”字形。吻部前伸，略向上弯曲，嘴紧闭，有对称的双鼻孔，双眼突起呈棱形，有鬣。龙背有对称的单孔。龙身大部光素无纹，只在额及鄂底刻以细密的方格网状纹，网格突起作规整的小菱形。 
<br/>
<br/>
新石器时代红山文化玉龙由墨绿色的岫岩玉雕琢而成，周身光洁，头部长吻修目，鬣鬃飞扬，躯体卷曲若钩。造型生动，雕琢精美，被誉为“三星他拉红山文化玉龙”，也称为“中华第一龙”。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%96%B0%E7%9F%B3%E5%99%A8%E6%97%B6%E4%BB%A3%E7%BA%A2%E5%B1%B1%E6%96%87%E5%8C%96%E7%8E%89%E9%BE%99/23706890?fromtitle=%E7%8E%89%E9%BE%99&fromid=1050781&fr=aladdin">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img8/20.jpg"  alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                <p>四羊青铜方尊 - 商后期</p>
                                    <p>四羊方尊是商代晚期青铜礼器，祭祀用品。1938年出土于湖南宁乡县黄材镇月山铺转耳仑的山腰上，现属炭河里遗址。收藏于中国国家博物馆。
<br/>
<br/>
四羊方尊是中国仍存商代青铜方尊中最大的一件，其每边边长为52.4 厘米，高58.3厘米，重量34.5公斤，长颈，高圈足，颈部高耸，四边上装饰有蕉叶纹、三角夔纹和兽面纹，尊的中部是器的重心所在，尊四角各塑一羊，肩部四角是4个卷角羊头，羊头与羊颈伸出于器外，羊身与羊腿附着于尊腹部及圈足上。同时，方尊肩饰高浮雕蛇身而有爪的龙纹，尊四面正中即两羊比邻处，各一双角龙首探出器表，从方尊每边右肩蜿蜒于前居的中间。
<br/>
<br/>
四羊方尊可能是用两次分铸技术铸造的，即先将羊角与龙头单个铸好，然后将其分别配置在外范内，再进行整体浇铸。整个器物用块范法浇铸，一气呵成，巧夺天工，显示了高超的铸造水平，被史学界称为“臻于极致的青铜典范”，位列十大传世国宝之一。</p>
                                    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%9B%9B%E7%BE%8A%E6%96%B9%E5%B0%8A/617449?fr=aladdin">
                                        <span className="grey-text">[Source From...]</span>
                                    </a>  
                                </div>
                            </div>


                        </div>
                        
                    </div>


                    <h4 className="title"> </h4>


                    {/* <div className="Comment">
                    
                        <div className="row">   
                        <div className="col s1 m1 l1">
                            <a className="btn btn-floating black lighten-1 ">YX</a>
                        </div>
                        <div className="col s11 m11 l11 commentContent">
                            <form action="">
                                <div className="input-field">
                                    <textarea id="message" className="materialize-textarea"></textarea>
                                    <label htmlFor="message">发表评论</label>
                                </div>
                            </form>
                        </div>
                        </div>

                    </div> */}
                



                </div>
            </div>
            
<Footer/>
            </section>
         
        )
    }
}







export default travelNoteBeijingCN;