import React, { Component } from 'react';




class calligraphy extends Component {
    // state = {

    // }
    // handleChange = (e) => {
    //     console.log(e);
    // }
    // handleSubmit = (e) => {
    //     console.log(e);
    // }
    render() {

   
        return (
            <body>
        <header>


<nav className="nav-wrapper black">
<div className="container">
    <a href="/" className="brand-logo ">
        <span>Y.X.</span>
    </a>
    
    <a href="" className="sidenav-trigger" data-target="mobile-menu">
    <i className="material-icons">menu</i>
    </a>
    <ul className="right hide-on-med-and-down">
    <li><a href="/travel">Travel</a></li>
    <li><a href="/music">Music</a></li>
    <li><a href="/calligraphy">Calligraphy</a></li>
    
    <li><a href="/cat">🐈</a></li>

    <li><a href="https://www.instagram.com/crys___7" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-instagram"></i>
    </a></li>
    <li><a href="https://www.youtube.com/channel/UClv5NmxBvWIjid8crocDvZQ" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-youtube"></i>
    </a></li>
    <li><a href="https://www.facebook.com/profile.php?id=100006981077508" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-facebook"></i>
    </a></li>
    <li><a href="https://twitter.com/YueqingXie" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-twitter"></i>
    </a></li>
    <li><a href="/calligraphyCN" className="btn btn-floating transparent">CN</a></li>
    
    </ul>
    
    <ul className="sidenav grey lighten-2" id="mobile-menu">
        <li><a href="/travel" className="sidenavtext">Travel</a></li>
        <li><a href="/music" className="sidenavtext">Music</a></li>
        <li><a href="/calligraphy" className="sidenavtext">Calligraphy</a></li>
        <li><a href="/cat" className="sidenavtext">My Cat</a></li>
        <li><a href="/#aboutmenav" className="sidenavtext">About Me</a></li>
            <br/>
            <li><a href="/calligraphyCN" className="sidenavtext">CN</a></li>

    </ul>
</div>
</nav>
</header>

            <section className="container section" id="tabs">
            <div className="scrollspy" id="top">
            <div className="row">
                {/* <div className="col s12 l4">
                <h2 className="indigo-text text-darken-4">Travel</h2>
                </div> */}
                <div className="col s12 l8 offset-l2">
                <ul className="tabs">
                    <li className="tab col s2">
                    <a href="#kaishu" className=" black-text">KAI</a>
                    </li>
                    <li className="tab col s2">
                    <a href="#lishu" className="black-text">LI</a>
                    </li>

                    <li className="tab col s2">
                    <a href="#xingshu" className="black-text">XING</a>
                    </li>
                    <li className="tab col s2">
                    <a href="#zhuanshu" className="black-text">ZHUAN</a>
                    </li>
                    <li className="tab col s2">
                    <a href="#caoshu" className="active black-text">CAO</a>
                    </li>

                    <li className="tab col s2">
                    <a href="#qitaziti" className="black-text">Others</a>
                    </li>

                </ul>
                </div>
            </div>
            </div>
            </section>


            <section className="container section" id="kaishu">
            <h4 className="title modal-trigger" href="#modal1">KAI SHU</h4>
            <div id="modal1" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">KAI SHU</h4>
                            <p>Kai Shu, font name, also called Regular script, block script, true script, regular script, lower case. Gradually evolved from the official script, more simplified, horizontal and vertical. The book "Cihai" explains that it "has a square shape and straight strokes, which can be used as a model." This kind of Chinese character font is correct, it is the modern handwritten Chinese character.</p>
                            <a className="right" href="https://baike.baidu.com/item/%E6%A5%B7%E4%B9%A6/482941?fr=aladdin">
                                <span className="blue-text">[Source From...]</span>
                            </a> 
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>  

                        <div id="kaishuContent">
                            <div className="kaishuContent1">
                                <img src="../imgcalligraphy/39.jpg" alt=""   className="responsive-img materialboxed"></img>
                            </div>
                            <div className="kaishuContent2">
                                <img src="../imgcalligraphy/40.jpg" alt=""   className="responsive-img materialboxed"></img>
                            </div>
                            <div className="kaishuContent3">
                                <img src="../imgcalligraphy/41.jpg" alt=""   className="responsive-img materialboxed"></img>
                            </div>
                        </div>

            </section>   

            <section className="container section" id="lishu">
            <h4 className="title modal-trigger" href="#modal2">LI SHU</h4>
            <div id="modal2" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">LI SHU</h4>
                            <p>
                            
                            Li Shu (official scripts) include Qin and Han scripts. They are generally considered to be developed from seal scripts. The characters are mostly wide and flat, with long horizontal paintings and short vertical paintings.
<br/><br/>
According to the unearthed bamboo slips, the official script was founded in the Qin Dynasty. It is said that Cheng Miao was the official official. The Han officialdom reached its peak in the Eastern Han Dynasty. It inherited the tradition of seal script and opened the Wei, Jin and Southern and Northern Dynasties. Known as "Han Li Tang Kai".</p>
                            <a className="right" href="https://baike.baidu.com/item/%E9%9A%B6%E4%B9%A6/835864?fr=aladdin">
                                <span className="blue-text">[Source From...]</span>
                            </a> 
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../imgcalligraphy/42.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
            <img src="../imgcalligraphy/43.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
            <img src="../imgcalligraphy/44.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
            <img src="../imgcalligraphy/45.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>
        </div>

                        
            </section>   

            <section className="container section" id="xingshu">
            <h4 className="title modal-trigger" href="#modal4">XING SHU</h4>
            <div id="modal4" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">XING SHU</h4>
                            <p>
                            
                            Xing Shu(Running script) is a collective term for calligraphy, divided into two types: Xing Kai and Xing Cao. It developed and originated on the basis of regular script. It is a font between regular script and cursive script. It was created to compensate for the slow writing speed of regular script and the difficulty of recognizing cursive script.
<br/><br/>
"Xing" means "walking", so it is not scribbled like cursive script, nor is it as straightforward as regular script. In essence, it is the cursive or cursive regularization of regular script. Those with more regular script than cursive are called "Xingkai", and those with more cursive than regular scripts are called "Xing Cao". Running script has high practicability and artistry, while regular script is a text symbol with high practicability and skill; in comparison, cursive script is highly artistic, but its practicability is relatively insufficient.</p>
                            <a className="right" href="https://baike.baidu.com/item/%E8%A1%8C%E4%B9%A6/472950?fr=aladdin">
                                <span className="blue-text">[Source From...]</span>
                            </a> 
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

        <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../imgcalligraphy/1.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
            <img src="../imgcalligraphy/2.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
            <img src="../imgcalligraphy/3.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
            <img src="../imgcalligraphy/4.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>
        </div>

        <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../imgcalligraphy/5.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
            <img src="../imgcalligraphy/6.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
            <img src="../imgcalligraphy/7.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
            <img src="../imgcalligraphy/8.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>
        </div>

        <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../imgcalligraphy/9.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
            <img src="../imgcalligraphy/10.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
            <img src="../imgcalligraphy/11.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
            <img src="../imgcalligraphy/12.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>
        </div>

        <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../imgcalligraphy/13.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
            <img src="../imgcalligraphy/14.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
            <img src="../imgcalligraphy/15.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
            <img src="../imgcalligraphy/16.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>
        </div>

        <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../imgcalligraphy/17.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
            <img src="../imgcalligraphy/18.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
            <img src="../imgcalligraphy/19.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
            <img src="../imgcalligraphy/20.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>
        </div>

        <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../imgcalligraphy/21.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
            <img src="../imgcalligraphy/22.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
            <img src="../imgcalligraphy/23.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
            <img src="../imgcalligraphy/24.jpg" alt="" className="responsive-img materialboxed"/>
                {/* <span className="card-title">国家大剧院</span> */}
            </div>
            </div>
        </div>
        </div>


            </section>   

            <section className="container section" id="zhuanshu">
            <h4 className="title modal-trigger" href="#modal5">ZHUAN SHU</h4>
            <div id="modal5" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">ZHUAN SHU</h4>
                            <p>Zhuan Shu(Seal script) is the collective name of the big seal and the small seal. The big seal refers to the inscriptions in bronze, the inscriptions of the 籀, and the characters of the six countries, which preserve the obvious characteristics of ancient hieroglyphs. Xiaozhuan, also known as "Qin Zhuan", is the universal character of the Qin State and a simplified font of the large seal. It is characterized by its uniform shape and the easier writing of the font than the Qinwen. Its brushwork is thin and straight, with more curves and fewer straight lines. It has square pens, round pens, and pointed pens.</p>
                            <a className="right" href="https://baike.baidu.com/item/%E7%AF%86%E4%B9%A6/890743?fr=aladdin">
                                <span className="blue-text">[Source From...]</span>
                            </a> 
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        <div id="zhuanshuContent">
                            <div className="zhuanshuContent1">
                                <img src="../imgcalligraphy/25.jpg" alt=""   className="responsive-img materialboxed"></img>
                            </div>
                            <div className="zhuanshuContent2">
                                <img src="../imgcalligraphy/26.jpg" alt=""   className="responsive-img materialboxed"></img>
                            </div>
                            <div className="zhuanshuContent3">
                                <img src="../imgcalligraphy/27.jpg" alt=""   className="responsive-img materialboxed"></img>
                            </div>
                            <div className="zhuanshuContent4">
                                <img src="../imgcalligraphy/28.jpg" alt=""   className="responsive-img materialboxed"></img>
                            </div>
                            <div className="zhuanshuContent5">
                                <img src="../imgcalligraphy/29.jpg" alt=""   className="responsive-img materialboxed"></img>
                            </div>
                            <div className="zhuanshuContent6">
                                <img src="../imgcalligraphy/30.jpg" alt=""   className="responsive-img materialboxed"></img>
                            </div>
                            <div className="zhuanshuContent7">
                                <img src="../imgcalligraphy/31.jpg" alt=""   className="responsive-img materialboxed"></img>
                            </div>
                            <div className="zhuanshuContent8">
                                <img src="../imgcalligraphy/32.jpg" alt=""   className="responsive-img materialboxed"></img>
                            </div>
                        </div>


            </section>   

            <section className="container section" id="caoshu">
            <h4 className="title modal-trigger" href="#modal6">CAO SHU</h4>
            <div id="modal6" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">CAO SHU</h4>
                            <p>
                            
                            The development of Cao Shu(cursive script) can be divided into three stages: early Cao Shu, Zhang Cao and Jin Cao.
                            <br/><br/>
Early cursive script was a script parallel to the official script, generally called the official script, but actually mixed with some seal-grass forms.
<br/><br/>
The initial cursive script, breaking the strict rules of the official script, was a hasty way of writing. Called "Zhang Cao". Zhang Cao is an elegant cursive that blended early limited cursive script and Han Li, with sharp waves, the strokes and hooks are in a "wave" shape, the characters are independent, the characters are all over, and the strokes are horizontal. Zhang Cao was most popular during the Han Dynasty and the Wei Dynasty, and later revived in the Yuan Dynasty and transformed into the Ming Dynasty.
<br/><br/>
At the end of the Han Dynasty, Zhang Cao further "cursively", stripped off the strokes of the official script, the gestures between the upper and lower characters are connected, and the radicals are simplified and borrowed, called "Jincao". The modern cursive style evolved from Zhang Cao's exhausting efforts. The modern cursive style has been popular since the Wei and Jin Dynasties. In the Tang Dynasty, the current cursive writing was more indulgent, with continuous strokes, strange and varied characters, called "crazy grass", also known as big grass.
<br/><br/>
Today, the aesthetic value of cursive script far exceeds its practical value. In cursive script, the dots and ligatures of the characters are ligated according to a certain rule, the structure is simplified, and the radicals are used as partials. One of the main characteristics of cursive symbols is the strokes with hooks, including upper and lower hooks and left and right hooks. The horizontal tendency of Lihua brushwork provides a basis for the cursiveness of left and right hooks. The Zhangcao brushwork uses the "one" shape, and the Jincao brushwork uses the "s" shape. This is the fundamental difference between the two. Indulgence and messy stippling are also called big grass or crazy grass.
</p>
                            <a className="right" href="https://www.google.ca/maps/place/National+Centre+for+the+Performing+Arts/@39.904799,116.3874825,17z/data=!3m1!4b1!4m5!3m4!1s0x35f0528e22e7a273:0x8b74ec5b2f1aaa87!8m2!3d39.904799!4d116.3896712">
                                <span className="blue-text">[Source From...]</span>
                            </a> 
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../imgcalligraphy/36.jpg" width="420" height="420" alt="" className="responsive-img materialboxed"></img>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                                <div className="gushi">
                                    <p className="center">《题都城南庄》 - 崔护[唐]</p>
                                    
                                    <p className="center">去年今日此门中，人面桃花相映红。
                                    <br/>人面不知何处去，桃花依旧笑春风。</p>
                                </div>

                                {/* <br/>
                                <p>译文
                                <br/>
去年春天，在这户人家里，我看见那美丽的脸庞和桃花互相衬托，显得分外红润。今日再来此地，姑娘不知去向何处，只有桃花依旧，含笑怒放春风之中。</p> */}
<br/>
                                <p>Translation
                                <br/>
                                
Last spring, in this family's house, I saw that beautiful face and peach blossoms set off each other, and they looked very rosy. Coming here again today, the girl doesn't know where to go, only the peach blossom is still there, smiling in the spring breeze.</p>


                            </div>
                        </div>

                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../imgcalligraphy/34.jpg" width="420" height="420" alt="" className="responsive-img materialboxed"></img>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                                <div className="gushi">
                                    <p className="center">《再下第》 - 孟郊[唐]</p>
                                    
                                    <p className="center">一夕九起嗟，梦短不到家。
                                    <br/>
                                    两度长安陌，空将泪见花。</p>
                                </div>

                                {/* <br/>
                                <p>译文
                                <br/>
                                一夜睡不好，多次醒来长叹，每次做梦都太短，来不及梦到回家就醒了，内心既幽愤又思乡，还有愧对父老的深深自责.两度赴京考试都落第，只好泪流满面地面对长安花景，辜负这帝都春色与大好年华。</p> */}
                                <br/>
                                <p>Translation
                                <br/>
                                I didn’t sleep well all night, I woke up many times and sighed. Every time I dreamed too short, I woke up before I went home. I felt angry and homesick in my heart, as well as deep self-blame for my elders. I went to Beijing for exams twice. After all fell into the ranks, they had to face the Chang'an Flower Scenery with tears streaming down their faces, letting down the spring scenery and good years of the imperial capital.</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../imgcalligraphy/35.jpg" width="420" height="420" alt="" className="responsive-img materialboxed"></img>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                                <div className="gushi">
                                    <p className="center">《逢雪宿芙蓉山主人》 - 刘长卿[唐]</p>
                                    
                                    <p className="center">日暮苍山远，天寒白屋贫。
                                    <br/>
柴门闻犬吠，风雪夜归人。</p>
                                </div>

                                {/* <br/>
                                <p>译文
                                <br/>
                                当暮色降临山苍茫的时候就越来越觉得路途遥远，当天气越寒冷茅草屋显得更加孤零零。
柴门外忽传来犬吠声声，原来是有人冒着风雪归家门。</p> */}
<br/>
                                <p>Translation
                                <br/>
                                When the twilight falls on the vast mountains, I feel that the road is farther and farther, and when the weather gets colder, the thatched cottages appear more lonely. Suddenly there was a dog barking outside the Chai Gate. It turned out that someone came home braving the wind and snow.</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../imgcalligraphy/37.jpg" width="420" height="420" alt="" className="responsive-img materialboxed"></img>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                                <div className="gushi">
                                    <p className="center">《寻隐者不遇 / 孙革访羊尊师诗》 - 贾岛[唐]</p>
                                    
                                    <p className="center">松下问童子，言师采药去。
                                    <br/>
只在此山中，云深不知处。</p>
                                </div>

                                {/* <br/>
                                <p>译文
                                <br/>
                                苍松下询问年少的学童，他说他的师傅已经去山中采药了。
只知道就在这座大山里，可山中云雾缭绕不知道他的行踪。</p> */}
<br/>
                                <p>Translation
                                <br/>
                                Cang Matsushita asked the young schoolchildren, and he said that his master had gone to the mountains to collect medicine. I only know that he is in this big mountain, but the clouds and mist in the mountain do not know his whereabouts.</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../imgcalligraphy/38.jpg" width="420" height="420" alt="" className="responsive-img materialboxed"></img>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                                <div className="gushi">
                                    <p className="center">《送别》 - 王维[唐]</p>
                                    
                                    <p className="center">下马饮君酒，问君何所之。君言不得意，归卧南山陲。</p>
                                </div>

                                {/* <br/>
                                <p>译文
                                <br/>
                                请你下马来喝一杯酒，敢问朋友你要去何方？
你说因为生活不得意，回乡隐居在终南山旁。
只管去吧我何须再问，看那白云正无边飘荡。</p> */}
<br/>
                                <p>Translation
                                <br/>
                                Would you please get out of Malaysia for a drink, dare to ask friends where are you going? You said that because of unpleasant life, you returned to your hometown and lived in seclusion beside Zhongnan Mountain. Just go, why don't I ask any more, see that white clouds are floating endlessly.</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../imgcalligraphy/33.jpg" width="420"  alt="" className="responsive-img materialboxed"></img>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                                <div className="gushi">
                                    <p className="center">《雪谤后逢李叔度》- 卢纶[唐]</p>
                                    
                                    <p className="center">相逢空握手，往事不堪思。
                                    <br/>
                                    见少情难尽，愁深语自迟。
                                    <br/>
                                    草生分路处，雨散出山时。
                                    <br/>
                                    强得宽离恨，唯当说后期。</p>
                                </div>

                                <br/>
                                
                            </div>
                        </div>

                    
                        

            </section>   

            <section className="container section" id="qitaziti">
            <h4 className="title modal-trigger" href="#modal3">XING KAI</h4>
            <div id="modal3" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">XING KAI</h4>
                            <p>

Xingkai, also known as "Chinese character Xingkai handwritten font", is a font that emphasizes the writing style (rules) of regular script, and is more free than regular script, and more straightforward than cursive script. Or said: Chinese characters are simple written strokes based on regular script and enhanced writing reflection.
<br/><br/>
Practical handwritten fonts that express the interest of writing in order to appropriately speed up the writing speed, change the order of the writing strokes a little, and have the characteristics of easy recognition and easy learning.</p>
                            <a className="right" href="https://baike.baidu.com/item/%E8%A1%8C%E6%A5%B7/9415199?fr=aladdin">
                                <span className="blue-text">[Source From...]</span>
                            </a> 
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../imgcalligraphy/46.jpg" width="420" alt="" className="responsive-img materialboxed"></img>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                                <div className="gushi">
                                    <p className="center">《鹿柴》 - 王维[唐]</p>
                                    
                                    <p className="center">空山不见人，但闻人语响。
                                    <br/>
返景入深林，复照青苔上。</p>
                                </div>

                                {/* <br/>
                                <p>译文
                                <br/>
                                诗中描写了鹿柴附近空山深林傍晚时幽静的景色。采用反衬手法，来突现诗的意境。空山万籁俱寂，偶尔传来人语声; 闻人声，却不见人迹，极言林深树茂。暂时的 “人声”更加突出了山林长久的空寂。此时一抹余辉射入林中，斑驳的树影落在树下的青苔上，小片的光影与大片的天边的幽暗形成强烈对比，令人更觉深林的幽暗。</p> */}
                                <br/>
                                <p>Translation
                                <br/>
                                
The poem described the quiet scenery of the empty mountain and deep forest near Luchai in the evening. Use contrast techniques to highlight the artistic conception of the poem. The empty mountains are all silent, and occasionally there are human voices; when they hear human voices, but no one is seen, they are extremely rich in the forest. The temporary "human voice" accentuated the long emptiness of the forest. At this moment, a touch of afterglow shot into the forest, and the mottled shadow of the tree fell on the moss under the tree. The small patches of light and shadow formed a strong contrast with the darkness of the large sky, which made people feel the darkness of the deep forest.</p>
                            </div>
                        </div>



                        <h4 className="title modal-trigger" href="#modal7">WEI BEI</h4>
            <div id="modal7" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">WEI BEI</h4>
                            <p>
                            
                            Wei Bei(Wei stele) is the general term for the Northern and Southern Dynasties (420-588 AD) in my country. The Northern Wei Dynasty is the most refined. It can be roughly divided into four types: stele, epitaph, statue inscription and cliff carved stone. At this time, calligraphy is a transitional calligraphy system that inherits the past and the future. Zhong Zhishuai said that the Wei stele "can glimpse the old style of the Han and Qin dynasties, and the customs of the Sui and Tang Dynasties." Had a huge impact. The calligraphers of the past dynasties also drew beneficial essence from it in the innovation and reform.
<br/><br/>
Wei Bei has various styles, simple and dangerous, comfortable and beautiful. The most famous ones such as "Stele of Zheng Wengong", "Stele of Zhang Ronglong", "Stele of Gaozhen", "Yuanhuai Epitaph" and "Zhang Xuan Epitaph" have been the first calligraphy in the Sui and Tang dynasties. The mid-Qing Dynasty advocated "respecting tablets and suppressing posts", breaking through the shackles of "guange style", and the rise of tablet studies changed the style of writing. The Wei stele inherited the Han Li tradition and inspired the new style of Tang Kai, laying a solid foundation for the composition and writing of modern Chinese characters. However, there are many stone carvings on the cliffs, which are not preceded by the book pill, but directly carved on the stone surface. When writing, be careful not to exaggerate the knife marks to avoid habit.</p>
                            <a className="right" href="https://baike.baidu.com/item/%E9%AD%8F%E7%A2%91/7187676?fr=aladdin">
                                <span className="blue-text">[Source From...]</span>
                            </a> 
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../imgcalligraphy/47.jpg" width="420" alt="" className="responsive-img materialboxed"></img>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                                <div className="gushi">
                                    <p className="center">《渡汉江》 - 宋之问[唐]</p>
                                    
                                    <p className="center">岭外音书断，经冬复历春。
                                    <br/>
近乡情更怯，不敢问来人。</p>
                                </div>

                                {/* <br/>
                                <p>译文
                                <br/>
                                客居岭外与家里音信断绝，

经过了冬天又到了春天。

离故乡越近心中越胆怯，

不敢询问遇到的家乡来人。</p> */}
<br/>
                                <p>Translation
                                <br/>
                                
There was no news between the guest and the family, and after winter, spring came again. The closer I get to my hometown, the more timid I am, and I dare not ask people from my hometown I meet.</p>
                            </div>
                        </div>


                        <h4 className="title modal-trigger" href="#modal8">XIAO KAI</h4>
            <div id="modal8" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">XIAO KAI</h4>
                            <p>Xiao Kai is the little one of regular script. It was founded by Zhong Yao, who was the most prominent authority in official script during the Wei Dynasty of the Three Kingdoms. The result is that the body is wide and flat, the horizontal drawing is long and the vertical drawing is short, and there is still the legacy of the division, but the script is fully prepared, and it is the ancestor of the original book. In the Eastern Jin Dynasty, Wang Xizhi meticulously studied Xiao Kai to achieve perfection and set the standard for the appreciation of the beautiful Chinese Xiao Kai calligraphy.</p>
                            <a className="right" href="https://baike.baidu.com/item/%E5%B0%8F%E6%A5%B7/8566047?fr=aladdin">
                                <span className="blue-text">[Source From...]</span>
                            </a> 
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../imgcalligraphy/48.jpg" width="420" alt="" className="responsive-img materialboxed"></img>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">
                                <div className="gushi">
                                    <p className="center">《游包山集》 - 王宠</p>
                                    
                                    <p className="center"></p>
                                </div>

                                <br/>
                            </div>
                        </div>


            </section>   
            <footer className="page-footer black darken-3">
                <div className="container">
                <div className="row">

                    <div className="flex text-xs-center col l1 m2 s12">
                        {/* <img src="../img/me_small.png"></img> */}
                        <p className="center">Y.X.</p>
                    </div>

                    <div className="col l5 offset-l1 m5 s12 hide-on-small-only">
                    <h5>Who is Y.X.?</h5>
                    <p>Y.X. graduated from uOttawa in 2020. She studied computer science and mathematics. She enjoys programming, travelling & playing instruments.</p>
                    </div>
              

                    <div className="col l4 offset-l1 m5 s12">
                    {/* <h5 className="white-text">Connect</h5> */}
                    <div className="text-xs-center mx my social-icons">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/crys___7" className=" btn-floating btn-small transparent" >
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UClv5NmxBvWIjid8crocDvZQ" className=" btn-floating btn-small transparent">
                        <i className="fab fa-youtube"></i>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=100006981077508" className=" btn-floating btn-small transparent">
                        <i className="fab fa-facebook"></i>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/YueqingXie" className=" btn-floating btn-small transparent">
                        <i className="fab fa-twitter"></i>
                    </a>
                    </div>
                    </div>
                </div>
                </div>
                <div className="footer-copyright grey darken-4">
                <div className="container copyright center-align">Copyright &copy; 2020 Y.X.</div>
                </div>
            </footer>
  
            </body>
        )
    }
}




export default calligraphy;
