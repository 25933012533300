import React, { Component } from 'react';



class travelNoteChongqingChengdu extends Component {

    render() {

        return ( 

            <section>
        <header>


<nav className="nav-wrapper black">
<div className="container">
    <a href="/" className="brand-logo ">
        <span>Y.X.</span>
    </a>
    
    <a href="" className="sidenav-trigger" data-target="mobile-menu">
    <i className="material-icons">menu</i>
    </a>
    <ul className="right hide-on-med-and-down">
    <li><a href="/travel">Travel</a></li>
    <li><a href="/music">Music</a></li>
    <li><a href="/calligraphy">Calligraphy</a></li>
    
    <li><a href="/cat">🐈</a></li>

    <li><a href="https://www.instagram.com/crys___7" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-instagram"></i>
    </a></li>
    <li><a href="https://www.youtube.com/channel/UClv5NmxBvWIjid8crocDvZQ" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-youtube"></i>
    </a></li>
    <li><a href="https://www.facebook.com/profile.php?id=100006981077508" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-facebook"></i>
    </a></li>
    <li><a href="https://twitter.com/YueqingXie" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-twitter"></i>
    </a></li>
    <li><a href="/travelNoteChongqingChengduCN" className="btn btn-floating transparent">CN</a></li>
    
    </ul>
    
    <ul className="sidenav grey lighten-2" id="mobile-menu">
        <li><a href="/travel" className="sidenavtext">Travel</a></li>
        <li><a href="/music" className="sidenavtext">Music</a></li>
        <li><a href="/calligraphy" className="sidenavtext">Calligraphy</a></li>
        <li><a href="/cat" className="sidenavtext">My Cat</a></li>
        <li><a href="/#aboutmenav" className="sidenavtext">About Me</a></li>
            <br/>
            <li><a href="/travelNoteChongqingChengduCN" className="sidenavtext">CN</a></li>

    </ul>
</div>
</nav>
</header>

            
            <div className="row hide-on-small-only" id="travelNoteChongqingChengdu">
                <div className="col s2 m2 l2">
                    <div>
                        {/* <nav className="nav">
                            <a href="#video4">title 4</a>
                        </nav> */}
                        
                        <ul className="nav">
                        <div className="bigTitle">Chongqing&Chengdu</div>
                            <li className="titleDate">
                                <a href="#title1">2017/05/28-06/01 & 06/15-06/20</a>
                            </li>
                            <li className="smallTitle">
                                <a>1.1/ </a>
                                <a href="#title1">Chongqing Dishes</a>
                            </li>

                            <li className="titleDate">
                                <a href="#title2">2017/06/02</a>
                            </li>
                            <li className="smallTitle">
                                <a>2.1/ </a>
                                <a href="#title2">Chengdu-Jin Li</a>
                            </li>

                            <li className="titleDate">
                                <a href="#title3">2017/06/03</a>
                            </li>
                            <li className="smallTitle">
                                <a>3.1/ </a>
                                <a href="#title3">Chengdu-Jinsha Site Museum</a>
                            </li>
                            <li className="smallTitle">
                                <a>3.2/ </a>
                                <a href="#title4">Chengdu-Panda Research Center</a>
                            </li>
                            <li className="smallTitle">
                                <a>3.3/ </a>
                                <a href="#title5">Chengdu-Dujiangyan City</a>
                            </li>

                            <li className="titleDate">
                                <a href="#title6">2017/06/04</a>
                            </li>
                            <li className="smallTitle">
                                <a>4.1/ </a>
                                <a href="#title6">Chengdu-Huang Long</a>
                            </li>


                            <li className="titleDate">
                                <a href="#title7">2017/06/05</a>
                            </li>
                            <li className="smallTitle">
                                <a>5.1/ </a>
                                <a href="#title7">Chengdu-Jiuzhai Valley</a>
                            </li>
                            <li className="smallTitle">
                                <a>5.2/ </a>
                                <a href="#title8">Chengdu-Tibetan Mystery Theater</a>
                            </li>

                            <li className="titleDate">
                                <a href="#title9">2017/06/06</a>
                            </li>
                            <li className="smallTitle">
                                <a>5.1/ </a>
                                <a href="#title9">Chengdu-Mount Heng</a>
                            </li>
                            <li className="smallTitle">
                                <a>5.2/ </a>
                                <a href="#title10">Chengdu-Yungang Grottoes</a>
                            </li>                            


                            

                            
                        </ul>
                    </div>
                </div>

                <div className="col s9 m9 l9 offset-s1 offset-m1 offset-l1 noteChongqingChengdu">

                        <div className="row">
                            <div className="col s10 m10 l10 offset-s1 offset-m1 offset-l1 ">
                            <div className="row travelDetail">
                                <div className="time col s12 m4 l4">
                                    <i className=" material-icons left">access_time</i>
                                    <span>Departure</span>
                                    <span> / </span>
                                    <span>2017-05-28</span>
                                </div>
                                <div className="day col s12 m4 l4">
                                    <i className=" material-icons left">wb_sunny</i>
                                    <span>Travel Days</span>
                                    <span> / </span>
                                    <span>16 days</span>
                                </div>
                                <div className="people col s12 m4 l4">
                                    <i className=" material-icons left">account_circle</i>
                                    <span>People</span>
                                    <span> / </span>
                                    <span>With Family</span>
                                </div>
                            </div>
                            <div className="col s1 m1 l1"></div>
                            </div>
                        </div>

                    <div className="titles" id="title1">
                        <h4 className="tooltipped title" data-tooltip="重庆菜">Chongqing Dishes</h4>
                        <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img25/1.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title"></span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img25/2.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title"></span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img25/3.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title"></span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img25/4.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title"></span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>

        <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img25/5.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title"></span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img25/6.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title"></span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img25/7.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title"></span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img25/8.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title"></span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>

        <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img25/9.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title"></span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img25/10.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title"></span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img25/11.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title"></span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img25/12.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title"></span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>

        <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img25/13.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title"></span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img25/14.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title"></span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img25/15.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title"></span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img25/16.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title"></span>
                {/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
                {/* <i className="material-icons">favorite</i> */}
                {/* </a> */}
            </div>
            {/* <div className="card-content">
                <span className="card-title">Canada</span>
                <p>Toronto</p>
                <br/>
                <p className="right grey-text">Upload Date: 2016-12-05</p>
            </div>
            <div className="card-action">
            <a href="/travelNoteToronto">View travel notes</a>
            </div> */}
            </div>
        </div>
        </div>



                    </div>

                    <div className="titles" id="title2">
                        <h4 className="tooltipped title" data-tooltip="锦里">Jin Li</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img25'/1.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/%E9%94%A6%E9%87%8C/@30.6449526,104.0477028,17z/data=!4m8!1m2!2m1!1z6ZSm6YeMIOaIkOmDvQ!3m4!1s0x36efc4e308193d21:0x1697f99de46c46df!8m2!3d30.644859!4d104.049974">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">Jin Li</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal2">Introduction</a>
                        <div id="modal2" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">Jin Li - Introduction</h4>
                            <p>Jinli is a part of Chengdu Wuhou Temple Museum (Three Kingdoms Historical Relics Area, Jinli Folk Custom Area, West District). It covers an area of more than 30,000 square meters, with a construction area of more than 14,000 square meters, and a street length of 550 meters. The folk house is used as the coat, and the culture of the Three Kingdoms and Chengdu folk customs are the connotation, integrating tourism, shopping, leisure and entertainment.
                            <br/>
<br/>
According to legend, Jinli was once one of the oldest and most commercial streets in the history of Xishu. It was famous throughout the country as early as the Qin and Han Dynasties and the Three Kingdoms period. At present, Jinli covers an area of more than 30,000 square meters, with a construction area of more than 14,000 square meters, and the street is 550 meters in length. It uses the folk houses in Western Sichuan in the late Ming and early Qing Dynasty as the outer clothing, and the Three Kingdoms culture and Chengdu folk customs as the connotation. It integrates tourism, shopping, leisure and entertainment. . On November 18, 2020, Chengdu Wuhou Temple·Jinli was elected as one of the “Top Ten New Cultural Tourism Landmarks in Chengdu”.</p>

                            <p className="grey-text">锦里是成都武侯祠博物馆（三国历史遗迹区、锦里民俗区、西区）的一部分，占地30000余平方米，建筑面积14000余平方米，街道全长550米，以明末清初川西民居作外衣，三国文化与成都民俗作内涵，集旅游购物、休闲娱乐为一体。
                            <br/>
<br/>
传说中锦里曾是西蜀历史上最古老、最具有商业气息的街道之一，早在秦汉、三国时期便闻名全国。现在，锦里占地30000余平方米，建筑面积14000余平方米，街道全长550米，以明末清初川西民居作外衣，三国文化与成都民俗作内涵，集旅游购物、休闲娱乐为一体。2020年11月18日，成都武侯祠·锦里当选“成渝十大文旅新地标”。
                            
                            
                            </p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E9%94%A6%E9%87%8C/2277338?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2017/06/02</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img26/1.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>In the afternoon, take a train to Chengdu in Chongqing.</p>
                                    <p>There are ’chuan ju bian lian‘ performances in Jinli, and there are many snacks. Come at night.</p>
                                </div>
                            </div>



                            




                        </div>
                        
                    </div>

                    <div className="titles" id="title3">
                        <h4 className="tooltipped title" data-tooltip="金沙遗址博物馆">Jinsha Site Museum</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img25'/2.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Jinsha+Site+Museum+Tourist+Center/@30.6793636,104.0094753,17z/data=!3m1!4b1!4m5!3m4!1s0x36efc4ab3e6ce6f7:0x88afb9ab6549be2!8m2!3d30.679359!4d104.011664">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">Jinsha Site Museum</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal3">Introduction</a>
                        <div id="modal3" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">Jinsha Site Museum - Introduction</h4>
                            <p>Chengdu Jinsha Site Museum, a national AAAA-level tourist attraction, is located at No. 2 Jinsha Site Road, Qingyang District, Chengdu City, Sichuan Province. Chengdu Jinsha Site Museum covers an area of 456 acres and has a building area of 38,000 square meters. In June 2006, the Cultural Relics Exhibition Hall of Chengdu Jinsha Site Museum opened. On April 16, 2007, Chengdu Jinsha Site Museum officially opened. In January 2009, Chengdu Jinsha Site Museum Cultural Relics Protection Center was basically completed. Chengdu Jinsha Site Museum is a site museum built for the protection, research and display of Jinsha culture and ancient Shu civilization.
                            <br/><br/>
The Chengdu Jinsha Site Museum has a total of 2,235 pieces (sets) of cultural relics in the collections of gold, bronze, jade, stone, lacquered wood, pottery, and ivory. Among them, there are 366 first-class cultural relics (sets), 374 second-class cultural relics (sets), and 1,429 third-class cultural relics (sets).
                            </p>

                            <p className="grey-text">成都金沙遗址博物馆，国家AAAA级旅游景区，位于四川省成都市青羊区金沙遗址路2号，成都金沙遗址博物馆占地面积456亩，建筑面积38000平方米。2006年6月，成都金沙遗址博物馆文物陈列馆开馆。2007年4月16日，成都金沙遗址博物馆正式开馆。2009年1月，成都金沙遗址博物馆文物保护中心基本建成。成都金沙遗址博物馆是一座为保护、研究、展示金沙文化和古蜀文明而兴建的遗址类博物馆。
<br/><br/>
成都金沙遗址博物馆有金器、铜器、玉器、石器、漆木器、陶器、象牙等馆藏文物共2235件（套）。其中，一级文物366件（套）、二级文物374件（套）、三级文物1429件（套）。</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%88%90%E9%83%BD%E9%87%91%E6%B2%99%E9%81%97%E5%9D%80%E5%8D%9A%E7%89%A9%E9%A6%86/7432729?fromtitle=%E9%87%91%E6%B2%99%E9%81%97%E5%9D%80%E5%8D%9A%E7%89%A9%E9%A6%86&fromid=5600166&fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2017/06/03</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img26/2.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>Shang and Zhou Sun God Bird</p>
                                    <p>

The gold ornaments of the Sun God Bird of Shang and Zhou Dynasties are round slices with an outer diameter of 12.5 cm, an inner diameter of 5.29 cm, a thickness of 0.02 cm, and a weight of 20 grams. The pattern is divided into inner and outer layers. The inner layer is equidistantly distributed with twelve rotating tooth-like rays; the outer layer is composed of four identical birds flying counterclockwise.</p>
                                </div>
                            </div>



                        </div>
                        
                    </div>

                    <div className="titles" id="title4">
                        <h4 className="tooltipped title" data-tooltip="大熊猫保护研究中心">Panda Breeding Research Center</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img25'/3.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Chengdu+Panda+Breeding+Research+Center+Dujiangyan+Breeding+Yefang+Research+Center/@30.9623756,103.5883583,17z/data=!3m1!4b1!4m5!3m4!1s0x36f00102ae790e17:0xb607507f02456f58!8m2!3d30.962371!4d103.590547">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">Panda Breeding Research Center</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal4">Introduction</a>
                        <div id="modal4" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">Panda Breeding Research Center - Introduction</h4>
                            <p>The China Conservation Research Center for Giant Pandas was established in 1983 by the cooperation between Wolong Nature Reserve and the World Wildlife Fund. Giant panda research expert Hu Jinchu served as the first director of the center. In 2008, the original Wolong Base of the China Research Center for the Conservation of Giant Pandas was severely damaged in the Wenchuan earthquake and was rebuilt with Hong Kong aid.
                            <br/>
<br/>
The main task of the center's scientific research is to focus on the breeding of giant pandas, increase the number of giant pandas, conduct applied basic research, and comprehensively carry out the behavior, ecology, breeding, breeding, breeding, nurturing, physiology and biochemistry, endocrinology, genetics, Basic and applied research in the fields of disease control, artificial rehabilitation, and population monitoring. The three bases of Wolong, Dujiangyan and Ya'an Bifengxia will become the country's largest panda scientific research and nature protection education base, which will enhance the level of China's panda protection and scientific research.
                            
                            </p>

                            <p className="grey-text">中国保护大熊猫研究中心成立于1983年，由卧龙自然保护区与世界野生生物基金会合作建立，大熊猫研究专家胡锦矗任中心第一任主任。2008年，中国保护大熊猫研究中心原卧龙基地在汶川特大地震中遭受重创，后由香港援助重建。
                            <br/>
<br/>
中心科研的主要任务是围绕大熊猫的繁育，增加大熊猫的数量进行应用基础研究，综合开展大熊猫、珍贵经济动植物的行为、生态、饲养、繁殖、育幼、生理生化、内分泌、遗传、疾病防治、人工复壮、种群监测等领域的基础和应用研究。卧龙、都江堰、雅安碧峰峡三个基地组成，将成为全国规模最大的大熊猫科研与自然保护教育基地，提升中国大熊猫保护与科研水平。</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%B8%AD%E5%9B%BD%E5%A4%A7%E7%86%8A%E7%8C%AB%E4%BF%9D%E6%8A%A4%E7%A0%94%E7%A9%B6%E4%B8%AD%E5%BF%83/13472513?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2017/06/03</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img26/3.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p></p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img26/4.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p></p>
                                </div>
                            </div>






                        </div>
                        
                    </div>

                    <div className="titles" id="title5">
                        <h4 className="tooltipped title" data-tooltip="都江堰市">Dujiangyan City</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img25'/4.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Dujiangyan+City,+Chengdu,+Sichuan,+China/@31.0579796,103.3280435,10z/data=!3m1!4b1!4m5!3m4!1s0x36f00687821ead4b:0xe39237074e70244d!8m2!3d30.988434!4d103.646912">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">Dujiangyan City</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal5">Introduction</a>
                        <div id="modal5" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">Dujiangyan City - Introduction</h4>
                            <p>Dujiangyan is directly under the jurisdiction of Sichuan Province and managed by Chengdu City. It is located at the exit of the Minjiang River on the northwestern edge of the Chengdu Plain. It is named after the water conservancy project Dujiangyan; the city borders Pengzhou, Pidu and Wenjiang districts in the east, and Wenchuan County in the west and north. It is connected with Chongzhou City to the south; the city is high in the northwest and low in the southeast. It belongs to the mid-subtropical humid monsoon climate zone in the Sichuan Basin. The city has a total area of 1,208 square kilometers, and governs 6 streets and 5 towns; in 2019, the permanent population was 702,800.
                            <br/>
<br/>
Dujiangyan is a city with a history of more than 2,000 years of building a city, rising from the weir and prospering from water; it was an area where ancient Shu ancestors lived in the Neolithic Age, and it was one of the birthplaces of the ancient Shu kingdom. Dujiangyan City is integrated with mountains, water, forests, weirs and bridges, reflecting the layout characteristics of water in the city, water in the city, and "water-colored half-city-mountain". For this reason, it has the reputation of "Worshiping Dujiangyan by water and asking for Qingcheng Mountain". Dujiangyan-Qingcheng Mountain was listed as a scenic spot in Western Sichuan during the Republic of China
                            </p>

                            <p className="grey-text">都江堰由四川省直辖，成都市代管，位于成都平原西北边缘岷江出山口处，因水利工程都江堰而得名；市境东与彭州市、郫都区、温江区交界，西、北与汶川县相连，南邻崇州市；市境内地势西北高，东南低，属四川盆地中亚热带湿润季风气候区。全市总面积1208平方公里，辖6街道、5镇；2019年常住人口70.28万人。 
                            <br/>
<br/>
都江堰是一座具有2000多年建城史，因堰而起、因水而兴的城市；在新石器时代就是古蜀先民聚居的地区，是古蜀国的发祥地之一。都江堰市以山、水、林、堰、桥浑然一体，体现城中有水、水在城中、“灌城水色半城山”的布局特色，为此有着“拜水都江堰、问道青城山”之美誉。都江堰—青城山在民国时期就列为川西风景名胜区</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E9%83%BD%E6%B1%9F%E5%A0%B0/12634169?fromtitle=%E9%83%BD%E6%B1%9F%E5%A0%B0%E5%B8%82&fromid=7261645&fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2017/06/03</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img26/5.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p></p>
                                </div>
                            </div>


                        </div>
                        
                    </div>

                    <div className="titles" id="title6">
                        <h4 className="tooltipped title" data-tooltip="黄龙">Huang Long</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img25'/5.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Huanglong/@32.7487455,103.8210013,17z/data=!3m1!4b1!4m5!3m4!1s0x36f76bfd0ae5f8a5:0x8afc08fba6b1efc!8m2!3d32.748741!4d103.82319">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">Huang Long</span>
                                </a>

                                
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal6">Introduction</a>
                        <div id="modal6" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">Huang Long - Introduction</h4>
                            <p>Huanglong Scenic Area is located in Songpan County, Aba Tibetan and Qiang Autonomous Prefecture, Sichuan Province. It covers an area of ​​700 square kilometers. It is the only well-protected plateau wetland in China. It is 100 kilometers away from Jiuzhaigou and has an altitude of 1700-5588 meters.
                            <br/><br/>
Huanglong is famous for the "four uniques" of colored pools, snow-capped mountains, canyons, and forests. Together with beach streams, ancient temples, and folk customs, they are called "seven uniques". The scenic spot consists of Huanglonggou, Danyunxia, ​​Munigou, Xuebaoding, Xueshanliang, Hongxingyan, Xigou and other scenic spots. The main landscape is concentrated in Huanglong Gully, which is about 3.6 kilometers long, with travertine deposits all over it. They are arranged in a terraced field and are well-known in the world for their rich flora and fauna resources, and enjoy the reputations of "world wonders" and "Jade Lake on earth". It was included in the "World Natural Heritage List" in 1992. In addition to the alpine landscape, various forest ecosystems can be found here, as well as limestone structures, waterfalls and hot springs. Many endangered animals live in this area, including giant pandas and Sichuan mute golden monkeys.
<br/><br/>
Huanglong is dominated by the large-scale, ingenious structure, and colorful surface travertine landscape. It is famous at home and abroad for its rare karst landforms, which can be called a fairyland on earth. On October 9, 2016, the National Tourism Administration released the "Red and Black List" for the November holiday tourism, and Huanglong Scenic Spot was listed as the best scenic spot in comprehensive order. It is a World Natural Heritage, World Man and Biosphere Reserve, "Green Global 21" certificate, a national AAAAA-level tourist attraction, and a national key scenic spot.
                            
                            
                            </p>

                            <p className="grey-text">黄龙风景名胜区位于四川省阿坝藏族羌族自治州松潘县。面积700平方公里。是中国唯一保护完好的高原湿地，与九寨沟相距100千米，海拔1700-5588米。
                            <br/><br/>
黄龙以彩池、雪山、峡谷、森林“四绝”著称于世，再加上滩流、古寺、民俗称为“七绝”。景区由黄龙沟、丹云峡、牟尼沟、雪宝鼎、雪山梁、红星岩，西沟等景区组成。主要景观集中于长约3.6公里的黄龙沟，沟内遍布碳酸钙华沉积。并呈梯田状排列，以丰富的动植物资源享誉人间，享有“世界奇观”、“人间瑶池”等美誉。1992年列入《世界自然遗产名录》。除了高山景观，还可以在这里发现各种不同的森林生态系统，以及石灰岩构造、瀑布和温泉。这一地区还生存着许多濒临灭绝的动物，包括大熊猫和四川疣鼻金丝猴。 
<br/><br/>
黄龙以规模宏大、结构奇巧、色彩丰艳的地表钙华景观为主景，以罕见的岩溶地貌蜚声中外，堪称人间仙境。2016年10月9日，国家旅游局发布十一假日旅游“红黑榜”，黄龙景区上榜综合秩序最佳景区。是世界自然遗产，世界人与生物圈保护区，“绿色环球21”证书，国家AAAAA级旅游景区，国家重点风景名胜区。</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E9%BB%84%E9%BE%99%E9%A3%8E%E6%99%AF%E5%90%8D%E8%83%9C%E5%8C%BA/17722?fromtitle=%E9%BB%84%E9%BE%99&fromid=22850&fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2017/06/04</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img26/6.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>Multicolored pool</p>
                                    <p>

Multicolored pool is a group of calcified colored ponds located at the top of Huanglong. There are 693 calcium ponds, 4166 meters away from the entrance. There are green hills and green hills, and the 5588-meter-high Xuebao Ding, the main peak of Minshan Mountain, stands majestically in front of you. Walking along the side of the pool, there are countless colored pools of various sizes and shapes like watercolor plates filled with colorful paints, blue, green, and sea. Orchid, light blue, etc., gorgeous and unique.</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img26/7.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p></p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img26/8.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>Since water is rich in calcium ions, the water flow is slow, so after years of deposition, different shapes of deposits are gradually formed in the water. The color of the lake is mainly due to the scattering, reflection and absorption of sunlight by the lake. Sunlight or natural light is a polychromatic light composed of monochromatic lights of different wavelengths. In the spectrum, from red light to purple light, the wavelength gradually decreases.</p>
                                </div>
                            </div>



                        </div>
                        
                    </div>

                    <div className="titles" id="title7">
                        <h4 className="tooltipped title" data-tooltip="九寨沟">Jiuzhai Valley National Park</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img25'/6.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Jiuzhai+Valley+National+Park/@33.2600466,103.9164107,17z/data=!3m1!4b1!4m5!3m4!1s0x36f7bdf69e1c6729:0xc9fde8c8b5f60cc6!8m2!3d33.2600421!4d103.9185994">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">Jiuzhai Valley National Park</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal7">Introduction</a>
                        <div id="modal7" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">Jiuzhai Valley National Park - Introduction</h4>
                            <p>Jiuzhaigou: World Natural Heritage, National Key Scenic Spots, National AAAAA-level Tourist Attractions, National Nature Reserves, National Geoparks, World Biosphere Reserves Network, is China's first nature reserve with the main purpose of protecting natural scenery.
                            <br/><br/>
Jiuzhaigou is located in Jiuzhaigou County, Aba Tibetan and Qiang Autonomous Prefecture, Sichuan Province. Located in the Qinghai-Tibet Plateau, Western Sichuan Plateau, and the transition zone from mountain to Sichuan Basin, it is more than 300 kilometers south of Chengdu. It is a valley with a depth of more than 50 kilometers, with a total area of ​​64297 hectares and a forest coverage rate of over 80%. Nine Tibetan villages, including Shuzheng Village, Heye Village, and Zechawa Village, are located in this mountain lake group.
<br/><br/>
The main protection objects of the Jiuzhaigou National Nature Reserve are rare animals such as giant pandas and golden monkeys and their natural ecological environment. There are 74 nationally protected rare plants, 18 nationally protected animals, and rich paleontological fossils and ancient glacier landforms.
<br/><br/>
"I don't look at the water after returning from Jiuzhaigou" is a true interpretation of the scenery of Jiuzhaigou. There are 108 seas in springs, waterfalls, rivers and beaches, forming a colorful jade basin. The six landscapes of Changhai, Jianyan, Nuorilang, Shuzheng, Zharu, and Black Sea are distributed in a "Y" shape. Cuihai, stacked waterfalls, colorful forests, snow peaks, Tibetan love, and blue ice are called "Six Uniques". The magical Jiuzhai is known as the "Fairy Tale World" and the "King of Water Scenery".
                            
                            </p>

                            <p className="grey-text">九寨沟：世界自然遗产、国家重点风景名胜区、国家AAAAA级旅游景区、国家级自然保护区、国家地质公园、世界生物圈保护区网络，是中国第一个以保护自然风景为主要目的的自然保护区。
<br/><br/>
九寨沟位于四川省阿坝藏族羌族自治州九寨沟县境内。地处青藏高原、川西高原、山地向四川盆地过渡地带，南距成都市300多公里，是一条纵深50余千米的山沟谷地，总面积64297公顷，森林覆盖率超过80%。因沟内有树正寨、荷叶寨、则查洼寨等九个藏族村寨坐落在这片高山湖泊群中而得名。
<br/><br/>
九寨沟国家级自然保护区主要保护对象是以大熊猫、金丝猴等珍稀动物及其自然生态环境。有74种国家保护珍稀植物，有18种国家保护动物，还有丰富的古生物化石、古冰川地貌。
<br/><br/>
“九寨归来不看水”，是对九寨沟景色真实的诠释。泉、瀑、河、滩108个海子，构成一个个五彩斑斓的瑶池玉盆。长海、剑岩、诺日朗、树正、扎如、黑海六大景观，呈“Y”字形分布。翠海、叠瀑、彩林、雪峰、藏情、蓝冰，被称为“六绝”。神奇的九寨，被世人誉为“童话世界”，号称“水景之王”。</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%B9%9D%E5%AF%A8%E6%B2%9F%E5%9B%BD%E5%AE%B6%E7%BA%A7%E8%87%AA%E7%84%B6%E4%BF%9D%E6%8A%A4%E5%8C%BA/8762684?fromtitle=%E4%B9%9D%E5%AF%A8%E6%B2%9F&fromid=122560&fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                        <div className="titleContent">
                            <h5>2017/06/05</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img26/9.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>Long sea</p>
                                    <p>

                                    Long sea is the largest sea in Jiuzhaigou. The long sea bends along the mountains, hiding its head deep in the valley of mountains. The mountain peaks at the end of the Changhai Sea are covered with snow all year round, covered with silver makeup, and are above 4000-5000 meters above sea level.</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img26/10.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>Multicolored pool</p>
                                    <p>

Multicolored pool is the essence of Jiuzhaigou. It is the smallest and most colorful pond in Jiuzhaigou. The upper half is azure blue, the lower half is orange-red, the left is sky blue, and the right is olive green. In the lake, there are aquatic plant communities such as Spirogyra, Chara, and small ferns, as well as various herbaceous plants such as reeds, knotweeds, and water wicks.


<br/><br/>
These aquatic communities contain different depths of chlorophyll and can show different colors in lake water rich in calcium carbonate. In winter, there is ice and snow all around, but this colorful pond is not frozen, which is amazing.</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img26/11.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>Panda Sea</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img26/12.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>Panda Sea</p>
                                    <p>The Panda Sea is 2587 meters above sea level, 14 meters deep, and covers an area of 90,000 square meters. There are often panda activities here. The rock wall by the lake has a peculiar texture, reflecting in the lake, and there are a few circles of black patterned round, like panda-like white stones, reflecting in the lake.</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img26/13.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p></p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img26/14.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>Five-flower Lake</p>
                                    <p>The five-flower lake is known as the "One Unique in Jiuzhaigou" and the "Essence of Jiuzhaigou", and is the pride of Jiuzhaigou.


<br/><br/>
In the same water area, the five-flower lake can show goose yellow, dark green, dark blue, navy blue and other colors, mottled and blurred, and colorful. It is one of the most exciting attractions in Jiuzhaigou, attracting countless tourists and photographers.</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img26/15.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>Pearl Beach Waterfall</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img26/16.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>Pearl Beach Waterfall</p>
                                    <p>

At an altitude of 2445 meters, the waterfall is 21 meters high and 162 meters wide. It is a typical combined landscape in Jiuzhaigou. It is the place where Tang monk and his apprentice wade horses in the end of the TV series "Journey to the West".</p>
                                </div>
                            </div>



                        </div>
                  
                        
                    </div>

                    <div className="titles" id="title8">
                        <h4 className="tooltipped title" data-tooltip="九寨沟藏谜大剧院">Jiuzhaigou Tibetan Mystery Theater</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img25'/7.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Jiuzhaigou+Tibetan+Mystery+Theater/@33.2877283,103.8766571,17z/data=!3m1!4b1!4m5!3m4!1s0x36f795d0bd3f1751:0x537fe1d76e0514f1!8m2!3d33.2877238!4d103.8788458">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">Jiuzhaigou Tibetan Mystery Theater</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal8">Introduction</a>
                        <div id="modal8" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">Jiuzhaigou Tibetan Mystery Theater - Introduction</h4>
                            <p>The Jiuzhaigou Tibetan Mystery Theater is a long-term performance location of the large-scale original ecological song and dance "The Tibetan Mystery". The theater was invested and constructed by Jiuzhaigou County Rongzhong Erjia Culture Communication Co., Ltd. The project is located in Zhangzha Town, Jiuzhaigou County, on the south side of Chuanjiu Highway. A 7-meter-wide river meanders along the north side of the planned land. The theater covers an area of about 1,350.34 square meters, with 350 internal sub-buildings and 860 pools.</p>

                            <p className="grey-text">九寨沟藏谜大剧院是大型原生态歌舞乐《藏谜》的长期演出地点。该剧院由九寨沟县容中尔甲文化传播有限公司投资建设。项目位于九寨沟县漳扎镇，川九公路南侧。一条7米宽的河流沿规划用地北侧蜿蜒流淌。该剧院面积1350.34平方米左右，内分楼座350座，池座860座。</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%B9%9D%E5%AF%A8%E6%B2%9F%E8%97%8F%E8%B0%9C%E5%A4%A7%E5%89%A7%E9%99%A2%EF%BC%9F/17405004?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2017/06/05</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img26/17.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>Old grandma pilgrimage</p>
                                </div>
                            </div>


                        </div>
                        
                    </div>

                    <div className="titles" id="title9">
                        <h4 className="tooltipped title" data-tooltip="藏寨">Mount Heng</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img25'/8.png" alt="" className="responsive-img"></img>
                                {/* <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Mount+Heng/@39.6728066,113.7240542,15z/data=!3m1!4b1!4m5!3m4!1s0x35e314eca54c24bd:0x728a6ddd443e0192!8m2!3d39.672791!4d113.732809">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">藏寨</span>
                                </a> */}
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        {/* <a className="btn-flat modal-trigger right info " href="#modal9">介绍</a>
                        <div id="modal9" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">藏寨 - 介绍</h4>
                            <p></p>

                            <p className="grey-text"></p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%81%92%E5%B1%B1/395558?fr=aladdin">
                   
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div> */}

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2017/06/06</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img26/18.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p></p>
                                </div>
                            </div>



                        </div>
                        
                    </div>

                    <div className="titles" id="title10">
                        <h4 className="tooltipped title" data-tooltip="钟楼">Yungang Grottoes</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img25'/9.png" alt="" className="responsive-img"></img>
                                {/* <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Yungang+Grottoes/@40.1115138,113.1302848,17z/data=!3m1!4b1!4m5!3m4!1s0x35e2cc48520de2e3:0xff93e49966b42707!8m2!3d40.1115097!4d113.1324735">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">钟楼</span>
                                </a> */}
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        {/* <a className="btn-flat modal-trigger right info " href="#modal10">介绍</a>
                        <div id="modal10" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">钟楼 - 介绍</h4>
                            <p></p>
                            <p className="grey-text"></p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%BA%91%E5%86%88%E7%9F%B3%E7%AA%9F/1252?fr=aladdin">
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div> */}

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2017/06/06</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img26/19.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>Wenchuan Earthquake Site
                                    <br/><br/>
                                    

The hour hand always stays at 14:28:04 on May 12, 2008.</p>
                                </div>
                            </div>




                        </div>
                        
                    </div>

                    <h4 className="title"> </h4>

                    {/* <div className="Comment">
                    
                        <div className="row">   
                        <div className="col s1 m1 l1">
                            <a className="btn btn-floating black lighten-1 ">YX</a>
                        </div>
                        <div className="col s11 m11 l11 commentContent">
                            <form action="">
                                <div className="input-field">
                                    <textarea id="message" className="materialize-textarea"></textarea>
                                    <label htmlFor="message">发表评论</label>
                                </div>
                            </form>
                        </div>
                        </div>

                      

                    </div> */}

              



                </div>
            </div>

            <div className="row hide-on-med-and-up" id="travelNoteChongqingChengdu">


<div className="col s12 m12 l12 noteChongqingChengdu">

<a href="" className="sidenav-trigger" data-target="mobile-menu999">
        <i className="material-icons smallnotenav" >menu</i>
    </a>
    <ul className="sidenav grey lighten-2 sidenav999" id="mobile-menu999">
    <div className="bigTitle">Chongqing&Chengdu</div>
            <li className="titleDate">
                <a href="#title11">2017/05/28-06/01 & 06/15-06/20</a>
            </li>
            <li className="smallTitle">
             
                <a href="#title11">Chongqing Dishes</a>
            </li>

            <li className="titleDate">
                <a href="#title12">2017/06/02</a>
            </li>
            <li className="smallTitle">
             
                <a href="#title12">Chengdu-Jin Li</a>
            </li>

            <li className="titleDate">
                <a href="#title13">2017/06/03</a>
            </li>
            <li className="smallTitle">
            
                <a href="#title13">Chengdu-Jinsha Site Museum</a>
            </li>
            <li className="smallTitle">
            
                <a href="#title14">Chengdu-Panda Research Center</a>
            </li>
            <li className="smallTitle">
            
                <a href="#title15">Chengdu-Dujiangyan City</a>
            </li>

            <li className="titleDate">
                <a href="#title16">2017/06/04</a>
            </li>
            <li className="smallTitle">
            
                <a href="#title16">Chengdu-Huang Long</a>
            </li>


            <li className="titleDate">
                <a href="#title17">2017/06/05</a>
            </li>
            <li className="smallTitle">
                
                <a href="#title17">Chengdu-Jiuzhai Valley</a>
            </li>
            <li className="smallTitle">
               
                <a href="#title18">Chengdu-Tibetan Mystery</a>
            </li>

            <li className="titleDate">
                <a href="#title19">2017/06/06</a>
            </li>
            <li className="smallTitle">
             
                <a href="#title19">Chengdu-Mount Heng</a>
            </li>
            <li className="smallTitle">
              
                <a href="#title20">Chengdu-Yungang Grottoes</a>
            </li>                            

        
    </ul>



        <div className="row">
            <div className="col s10 m10 l10 offset-s1 offset-m1 offset-l1 ">
            <div className="row travelDetail">
                <div className="time col s12 m4 l4">
                    <i className=" material-icons left">access_time</i>
                    <span>Departure</span>
                    <span> / </span>
                    <span>2017-05-28</span>
                </div>
                <div className="day col s12 m4 l4">
                    <i className=" material-icons left">wb_sunny</i>
                    <span>Travel Days</span>
                    <span> / </span>
                    <span>16 days</span>
                </div>
                <div className="people col s12 m4 l4">
                    <i className=" material-icons left">account_circle</i>
                    <span>People</span>
                    <span> / </span>
                    <span>With Family</span>
                </div>
            </div>
            <div className="col s1 m1 l1"></div>
            </div>
        </div>

        <div className="titles" id="title11">
<h4 className="tooltipped title" data-tooltip="重庆菜">Chongqing Dishes</h4>
<div className="row">
<div className="col s12 m6 l3">
<div className="card">
<div className="card-image">
<img src="../img25/1.jpg" alt="" className="responsive-img materialboxed"/>
<span className="card-title"></span>
{/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
{/* <i className="material-icons">favorite</i> */}
{/* </a> */}
</div>
{/* <div className="card-content">
<span className="card-title">Canada</span>
<p>Toronto</p>
<br/>
<p className="right grey-text">Upload Date: 2016-12-05</p>
</div>
<div className="card-action">
<a href="/travelNoteToronto">View travel notes</a>
</div> */}
</div>
</div>

<div className="col s12 m6 l3">
<div className="card">
<div className="card-image">
<img src="../img25/2.jpg" alt="" className="responsive-img materialboxed"/>
<span className="card-title"></span>
{/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
{/* <i className="material-icons">favorite</i> */}
{/* </a> */}
</div>
{/* <div className="card-content">
<span className="card-title">Canada</span>
<p>Toronto</p>
<br/>
<p className="right grey-text">Upload Date: 2016-12-05</p>
</div>
<div className="card-action">
<a href="/travelNoteToronto">View travel notes</a>
</div> */}
</div>
</div>

<div className="col s12 m6 l3">
<div className="card">
<div className="card-image">
<img src="../img25/3.jpg" alt="" className="responsive-img materialboxed"/>
<span className="card-title"></span>
{/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
{/* <i className="material-icons">favorite</i> */}
{/* </a> */}
</div>
{/* <div className="card-content">
<span className="card-title">Canada</span>
<p>Toronto</p>
<br/>
<p className="right grey-text">Upload Date: 2016-12-05</p>
</div>
<div className="card-action">
<a href="/travelNoteToronto">View travel notes</a>
</div> */}
</div>
</div>

<div className="col s12 m6 l3">
<div className="card">
<div className="card-image">
<img src="../img25/4.jpg" alt="" className="responsive-img materialboxed"/>
<span className="card-title"></span>
{/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
{/* <i className="material-icons">favorite</i> */}
{/* </a> */}
</div>
{/* <div className="card-content">
<span className="card-title">Canada</span>
<p>Toronto</p>
<br/>
<p className="right grey-text">Upload Date: 2016-12-05</p>
</div>
<div className="card-action">
<a href="/travelNoteToronto">View travel notes</a>
</div> */}
</div>
</div>
</div>

<div className="row">
<div className="col s12 m6 l3">
<div className="card">
<div className="card-image">
<img src="../img25/5.jpg" alt="" className="responsive-img materialboxed"/>
<span className="card-title"></span>
{/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
{/* <i className="material-icons">favorite</i> */}
{/* </a> */}
</div>
{/* <div className="card-content">
<span className="card-title">Canada</span>
<p>Toronto</p>
<br/>
<p className="right grey-text">Upload Date: 2016-12-05</p>
</div>
<div className="card-action">
<a href="/travelNoteToronto">View travel notes</a>
</div> */}
</div>
</div>

<div className="col s12 m6 l3">
<div className="card">
<div className="card-image">
<img src="../img25/6.jpg" alt="" className="responsive-img materialboxed"/>
<span className="card-title"></span>
{/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
{/* <i className="material-icons">favorite</i> */}
{/* </a> */}
</div>
{/* <div className="card-content">
<span className="card-title">Canada</span>
<p>Toronto</p>
<br/>
<p className="right grey-text">Upload Date: 2016-12-05</p>
</div>
<div className="card-action">
<a href="/travelNoteToronto">View travel notes</a>
</div> */}
</div>
</div>

<div className="col s12 m6 l3">
<div className="card">
<div className="card-image">
<img src="../img25/7.jpg" alt="" className="responsive-img materialboxed"/>
<span className="card-title"></span>
{/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
{/* <i className="material-icons">favorite</i> */}
{/* </a> */}
</div>
{/* <div className="card-content">
<span className="card-title">Canada</span>
<p>Toronto</p>
<br/>
<p className="right grey-text">Upload Date: 2016-12-05</p>
</div>
<div className="card-action">
<a href="/travelNoteToronto">View travel notes</a>
</div> */}
</div>
</div>

<div className="col s12 m6 l3">
<div className="card">
<div className="card-image">
<img src="../img25/8.jpg" alt="" className="responsive-img materialboxed"/>
<span className="card-title"></span>
{/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
{/* <i className="material-icons">favorite</i> */}
{/* </a> */}
</div>
{/* <div className="card-content">
<span className="card-title">Canada</span>
<p>Toronto</p>
<br/>
<p className="right grey-text">Upload Date: 2016-12-05</p>
</div>
<div className="card-action">
<a href="/travelNoteToronto">View travel notes</a>
</div> */}
</div>
</div>
</div>

<div className="row">
<div className="col s12 m6 l3">
<div className="card">
<div className="card-image">
<img src="../img25/9.jpg" alt="" className="responsive-img materialboxed"/>
<span className="card-title"></span>
{/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
{/* <i className="material-icons">favorite</i> */}
{/* </a> */}
</div>
{/* <div className="card-content">
<span className="card-title">Canada</span>
<p>Toronto</p>
<br/>
<p className="right grey-text">Upload Date: 2016-12-05</p>
</div>
<div className="card-action">
<a href="/travelNoteToronto">View travel notes</a>
</div> */}
</div>
</div>

<div className="col s12 m6 l3">
<div className="card">
<div className="card-image">
<img src="../img25/10.jpg" alt="" className="responsive-img materialboxed"/>
<span className="card-title"></span>
{/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
{/* <i className="material-icons">favorite</i> */}
{/* </a> */}
</div>
{/* <div className="card-content">
<span className="card-title">Canada</span>
<p>Toronto</p>
<br/>
<p className="right grey-text">Upload Date: 2016-12-05</p>
</div>
<div className="card-action">
<a href="/travelNoteToronto">View travel notes</a>
</div> */}
</div>
</div>

<div className="col s12 m6 l3">
<div className="card">
<div className="card-image">
<img src="../img25/11.jpg" alt="" className="responsive-img materialboxed"/>
<span className="card-title"></span>
{/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
{/* <i className="material-icons">favorite</i> */}
{/* </a> */}
</div>
{/* <div className="card-content">
<span className="card-title">Canada</span>
<p>Toronto</p>
<br/>
<p className="right grey-text">Upload Date: 2016-12-05</p>
</div>
<div className="card-action">
<a href="/travelNoteToronto">View travel notes</a>
</div> */}
</div>
</div>

<div className="col s12 m6 l3">
<div className="card">
<div className="card-image">
<img src="../img25/12.jpg" alt="" className="responsive-img materialboxed"/>
<span className="card-title"></span>
{/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
{/* <i className="material-icons">favorite</i> */}
{/* </a> */}
</div>
{/* <div className="card-content">
<span className="card-title">Canada</span>
<p>Toronto</p>
<br/>
<p className="right grey-text">Upload Date: 2016-12-05</p>
</div>
<div className="card-action">
<a href="/travelNoteToronto">View travel notes</a>
</div> */}
</div>
</div>
</div>

<div className="row">
<div className="col s12 m6 l3">
<div className="card">
<div className="card-image">
<img src="../img25/13.jpg" alt="" className="responsive-img materialboxed"/>
<span className="card-title"></span>
{/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
{/* <i className="material-icons">favorite</i> */}
{/* </a> */}
</div>
{/* <div className="card-content">
<span className="card-title">Canada</span>
<p>Toronto</p>
<br/>
<p className="right grey-text">Upload Date: 2016-12-05</p>
</div>
<div className="card-action">
<a href="/travelNoteToronto">View travel notes</a>
</div> */}
</div>
</div>

<div className="col s12 m6 l3">
<div className="card">
<div className="card-image">
<img src="../img25/14.jpg" alt="" className="responsive-img materialboxed"/>
<span className="card-title"></span>
{/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
{/* <i className="material-icons">favorite</i> */}
{/* </a> */}
</div>
{/* <div className="card-content">
<span className="card-title">Canada</span>
<p>Toronto</p>
<br/>
<p className="right grey-text">Upload Date: 2016-12-05</p>
</div>
<div className="card-action">
<a href="/travelNoteToronto">View travel notes</a>
</div> */}
</div>
</div>

<div className="col s12 m6 l3">
<div className="card">
<div className="card-image">
<img src="../img25/15.jpg" alt="" className="responsive-img materialboxed"/>
<span className="card-title"></span>
{/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
{/* <i className="material-icons">favorite</i> */}
{/* </a> */}
</div>
{/* <div className="card-content">
<span className="card-title">Canada</span>
<p>Toronto</p>
<br/>
<p className="right grey-text">Upload Date: 2016-12-05</p>
</div>
<div className="card-action">
<a href="/travelNoteToronto">View travel notes</a>
</div> */}
</div>
</div>

<div className="col s12 m6 l3">
<div className="card">
<div className="card-image">
<img src="../img25/16.jpg" alt="" className="responsive-img materialboxed"/>
<span className="card-title"></span>
{/* <a href="" className="halfway-fab btn-floating pink pulse"> */}
{/* <i className="material-icons">favorite</i> */}
{/* </a> */}
</div>
{/* <div className="card-content">
<span className="card-title">Canada</span>
<p>Toronto</p>
<br/>
<p className="right grey-text">Upload Date: 2016-12-05</p>
</div>
<div className="card-action">
<a href="/travelNoteToronto">View travel notes</a>
</div> */}
</div>
</div>
</div>



</div>

<div className="titles" id="title12">
<h4 className="tooltipped title" data-tooltip="锦里">Jin Li</h4>

<div className="row">
    <div className="col s12 m12 l5">
        <img src="../img25'/1.png" alt="" className="responsive-img"></img>
        <br></br>
        <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/%E9%94%A6%E9%87%8C/@30.6449526,104.0477028,17z/data=!4m8!1m2!2m1!1z6ZSm6YeMIOaIkOmDvQ!3m4!1s0x36efc4e308193d21:0x1697f99de46c46df!8m2!3d30.644859!4d104.049974">
            <i className="tiny material-icons">location_on</i>
            <span className="titleMap">Jin Li</span>
        </a>
    </div>
    <div className="col s12 m12 l6 offset-l1">

    <div className="details">


<a className="btn-flat modal-trigger right info " href="#modal9992">Introduction</a>
<div id="modal9992" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">Jin Li - Introduction</h4>
    <p>Jinli is a part of Chengdu Wuhou Temple Museum (Three Kingdoms Historical Relics Area, Jinli Folk Custom Area, West District). It covers an area of more than 30,000 square meters, with a construction area of more than 14,000 square meters, and a street length of 550 meters. The folk house is used as the coat, and the culture of the Three Kingdoms and Chengdu folk customs are the connotation, integrating tourism, shopping, leisure and entertainment.
    <br/>
<br/>
According to legend, Jinli was once one of the oldest and most commercial streets in the history of Xishu. It was famous throughout the country as early as the Qin and Han Dynasties and the Three Kingdoms period. At present, Jinli covers an area of more than 30,000 square meters, with a construction area of more than 14,000 square meters, and the street is 550 meters in length. It uses the folk houses in Western Sichuan in the late Ming and early Qing Dynasty as the outer clothing, and the Three Kingdoms culture and Chengdu folk customs as the connotation. It integrates tourism, shopping, leisure and entertainment. . On November 18, 2020, Chengdu Wuhou Temple·Jinli was elected as one of the “Top Ten New Cultural Tourism Landmarks in Chengdu”.</p>

    <p className="grey-text">锦里是成都武侯祠博物馆（三国历史遗迹区、锦里民俗区、西区）的一部分，占地30000余平方米，建筑面积14000余平方米，街道全长550米，以明末清初川西民居作外衣，三国文化与成都民俗作内涵，集旅游购物、休闲娱乐为一体。
    <br/>
<br/>
传说中锦里曾是西蜀历史上最古老、最具有商业气息的街道之一，早在秦汉、三国时期便闻名全国。现在，锦里占地30000余平方米，建筑面积14000余平方米，街道全长550米，以明末清初川西民居作外衣，三国文化与成都民俗作内涵，集旅游购物、休闲娱乐为一体。2020年11月18日，成都武侯祠·锦里当选“成渝十大文旅新地标”。
    
    
    </p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E9%94%A6%E9%87%8C/2277338?fr=aladdin">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
    </div>
</div>


<div className="titleContent">
    <h5>2017/06/02</h5>
    <div className="row">
        <div className="col s12 m12 l5">
            <img src="../img26/1.png" alt="" className="responsive-img"></img>
        </div>
        <div className="col s12 m12 l6 offset-l1">
            <p>In the afternoon, take a train to Chengdu in Chongqing.</p>
            <p>There are ’chuan ju bian lian‘ performances in Jinli, and there are many snacks. Come at night.</p>
        </div>
    </div>



    




</div>

</div>

<div className="titles" id="title13">
<h4 className="tooltipped title" data-tooltip="金沙遗址博物馆">Jinsha Site Museum</h4>

<div className="row">
    <div className="col s12 m12 l5">
        <img src="../img25'/2.png" alt="" className="responsive-img"></img>
        <br></br>
        <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Jinsha+Site+Museum+Tourist+Center/@30.6793636,104.0094753,17z/data=!3m1!4b1!4m5!3m4!1s0x36efc4ab3e6ce6f7:0x88afb9ab6549be2!8m2!3d30.679359!4d104.011664">
            <i className="tiny material-icons">location_on</i>
            <span className="titleMap">Jinsha Site Museum</span>
        </a>
    </div>
    <div className="col s12 m12 l6 offset-l1">

    <div className="details">


<a className="btn-flat modal-trigger right info " href="#modal9993">Introduction</a>
<div id="modal9993" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">Jinsha Site Museum - Introduction</h4>
    <p>Chengdu Jinsha Site Museum, a national AAAA-level tourist attraction, is located at No. 2 Jinsha Site Road, Qingyang District, Chengdu City, Sichuan Province. Chengdu Jinsha Site Museum covers an area of 456 acres and has a building area of 38,000 square meters. In June 2006, the Cultural Relics Exhibition Hall of Chengdu Jinsha Site Museum opened. On April 16, 2007, Chengdu Jinsha Site Museum officially opened. In January 2009, Chengdu Jinsha Site Museum Cultural Relics Protection Center was basically completed. Chengdu Jinsha Site Museum is a site museum built for the protection, research and display of Jinsha culture and ancient Shu civilization.
    <br/><br/>
The Chengdu Jinsha Site Museum has a total of 2,235 pieces (sets) of cultural relics in the collections of gold, bronze, jade, stone, lacquered wood, pottery, and ivory. Among them, there are 366 first-class cultural relics (sets), 374 second-class cultural relics (sets), and 1,429 third-class cultural relics (sets).
    </p>

    <p className="grey-text">成都金沙遗址博物馆，国家AAAA级旅游景区，位于四川省成都市青羊区金沙遗址路2号，成都金沙遗址博物馆占地面积456亩，建筑面积38000平方米。2006年6月，成都金沙遗址博物馆文物陈列馆开馆。2007年4月16日，成都金沙遗址博物馆正式开馆。2009年1月，成都金沙遗址博物馆文物保护中心基本建成。成都金沙遗址博物馆是一座为保护、研究、展示金沙文化和古蜀文明而兴建的遗址类博物馆。
<br/><br/>
成都金沙遗址博物馆有金器、铜器、玉器、石器、漆木器、陶器、象牙等馆藏文物共2235件（套）。其中，一级文物366件（套）、二级文物374件（套）、三级文物1429件（套）。</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%88%90%E9%83%BD%E9%87%91%E6%B2%99%E9%81%97%E5%9D%80%E5%8D%9A%E7%89%A9%E9%A6%86/7432729?fromtitle=%E9%87%91%E6%B2%99%E9%81%97%E5%9D%80%E5%8D%9A%E7%89%A9%E9%A6%86&fromid=5600166&fr=aladdin">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
    </div>
</div>


<div className="titleContent">
    <h5>2017/06/03</h5>
    <div className="row">
        <div className="col s12 m12 l5">
            <img src="../img26/2.png" alt="" className="responsive-img"></img>
        </div>
        <div className="col s12 m12 l6 offset-l1">
            <p>Shang and Zhou Sun God Bird</p>
            <p>

The gold ornaments of the Sun God Bird of Shang and Zhou Dynasties are round slices with an outer diameter of 12.5 cm, an inner diameter of 5.29 cm, a thickness of 0.02 cm, and a weight of 20 grams. The pattern is divided into inner and outer layers. The inner layer is equidistantly distributed with twelve rotating tooth-like rays; the outer layer is composed of four identical birds flying counterclockwise.</p>
        </div>
    </div>



</div>

</div>

<div className="titles" id="title14">
<h4 className="tooltipped title" data-tooltip="大熊猫保护研究中心">Panda Breeding Research Center</h4>

<div className="row">
    <div className="col s12 m12 l5">
        <img src="../img25'/3.png" alt="" className="responsive-img"></img>
        <br></br>
        <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Chengdu+Panda+Breeding+Research+Center+Dujiangyan+Breeding+Yefang+Research+Center/@30.9623756,103.5883583,17z/data=!3m1!4b1!4m5!3m4!1s0x36f00102ae790e17:0xb607507f02456f58!8m2!3d30.962371!4d103.590547">
            <i className="tiny material-icons">location_on</i>
            <span className="titleMap">Panda Breeding Research Center</span>
        </a>
    </div>
    <div className="col s12 m12 l6 offset-l1">

    <div className="details">


<a className="btn-flat modal-trigger right info " href="#modal9994">Introduction</a>
<div id="modal9994" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">Panda Breeding Research Center - Introduction</h4>
    <p>The China Conservation Research Center for Giant Pandas was established in 1983 by the cooperation between Wolong Nature Reserve and the World Wildlife Fund. Giant panda research expert Hu Jinchu served as the first director of the center. In 2008, the original Wolong Base of the China Research Center for the Conservation of Giant Pandas was severely damaged in the Wenchuan earthquake and was rebuilt with Hong Kong aid.
    <br/>
<br/>
The main task of the center's scientific research is to focus on the breeding of giant pandas, increase the number of giant pandas, conduct applied basic research, and comprehensively carry out the behavior, ecology, breeding, breeding, breeding, nurturing, physiology and biochemistry, endocrinology, genetics, Basic and applied research in the fields of disease control, artificial rehabilitation, and population monitoring. The three bases of Wolong, Dujiangyan and Ya'an Bifengxia will become the country's largest panda scientific research and nature protection education base, which will enhance the level of China's panda protection and scientific research.
    
    </p>

    <p className="grey-text">中国保护大熊猫研究中心成立于1983年，由卧龙自然保护区与世界野生生物基金会合作建立，大熊猫研究专家胡锦矗任中心第一任主任。2008年，中国保护大熊猫研究中心原卧龙基地在汶川特大地震中遭受重创，后由香港援助重建。
    <br/>
<br/>
中心科研的主要任务是围绕大熊猫的繁育，增加大熊猫的数量进行应用基础研究，综合开展大熊猫、珍贵经济动植物的行为、生态、饲养、繁殖、育幼、生理生化、内分泌、遗传、疾病防治、人工复壮、种群监测等领域的基础和应用研究。卧龙、都江堰、雅安碧峰峡三个基地组成，将成为全国规模最大的大熊猫科研与自然保护教育基地，提升中国大熊猫保护与科研水平。</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%B8%AD%E5%9B%BD%E5%A4%A7%E7%86%8A%E7%8C%AB%E4%BF%9D%E6%8A%A4%E7%A0%94%E7%A9%B6%E4%B8%AD%E5%BF%83/13472513?fr=aladdin">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
    </div>
</div>


<div className="titleContent">
    <h5>2017/06/03</h5>
    <div className="row">
        <div className="col s12 m12 l5">
            <img src="../img26/3.png" alt="" className="responsive-img"></img>
        </div>
        <div className="col s12 m12 l6 offset-l1">
            <p></p>
        </div>
    </div>

    <div className="row">
        <div className="col s12 m12 l5">
            <img src="../img26/4.png" alt="" className="responsive-img"></img>
        </div>
        <div className="col s12 m12 l6 offset-l1">
            <p></p>
        </div>
    </div>






</div>

</div>

<div className="titles" id="title15">
<h4 className="tooltipped title" data-tooltip="都江堰市">Dujiangyan City</h4>

<div className="row">
    <div className="col s12 m12 l5">
        <img src="../img25'/4.png" alt="" className="responsive-img"></img>
        <br></br>
        <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Dujiangyan+City,+Chengdu,+Sichuan,+China/@31.0579796,103.3280435,10z/data=!3m1!4b1!4m5!3m4!1s0x36f00687821ead4b:0xe39237074e70244d!8m2!3d30.988434!4d103.646912">
            <i className="tiny material-icons">location_on</i>
            <span className="titleMap">Dujiangyan City</span>
        </a>
    </div>
    <div className="col s12 m12 l6 offset-l1">

    <div className="details">


<a className="btn-flat modal-trigger right info " href="#modal9995">Introduction</a>
<div id="modal9995" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">Dujiangyan City - Introduction</h4>
    <p>Dujiangyan is directly under the jurisdiction of Sichuan Province and managed by Chengdu City. It is located at the exit of the Minjiang River on the northwestern edge of the Chengdu Plain. It is named after the water conservancy project Dujiangyan; the city borders Pengzhou, Pidu and Wenjiang districts in the east, and Wenchuan County in the west and north. It is connected with Chongzhou City to the south; the city is high in the northwest and low in the southeast. It belongs to the mid-subtropical humid monsoon climate zone in the Sichuan Basin. The city has a total area of 1,208 square kilometers, and governs 6 streets and 5 towns; in 2019, the permanent population was 702,800.
    <br/>
<br/>
Dujiangyan is a city with a history of more than 2,000 years of building a city, rising from the weir and prospering from water; it was an area where ancient Shu ancestors lived in the Neolithic Age, and it was one of the birthplaces of the ancient Shu kingdom. Dujiangyan City is integrated with mountains, water, forests, weirs and bridges, reflecting the layout characteristics of water in the city, water in the city, and "water-colored half-city-mountain". For this reason, it has the reputation of "Worshiping Dujiangyan by water and asking for Qingcheng Mountain". Dujiangyan-Qingcheng Mountain was listed as a scenic spot in Western Sichuan during the Republic of China
    </p>

    <p className="grey-text">都江堰由四川省直辖，成都市代管，位于成都平原西北边缘岷江出山口处，因水利工程都江堰而得名；市境东与彭州市、郫都区、温江区交界，西、北与汶川县相连，南邻崇州市；市境内地势西北高，东南低，属四川盆地中亚热带湿润季风气候区。全市总面积1208平方公里，辖6街道、5镇；2019年常住人口70.28万人。 
    <br/>
<br/>
都江堰是一座具有2000多年建城史，因堰而起、因水而兴的城市；在新石器时代就是古蜀先民聚居的地区，是古蜀国的发祥地之一。都江堰市以山、水、林、堰、桥浑然一体，体现城中有水、水在城中、“灌城水色半城山”的布局特色，为此有着“拜水都江堰、问道青城山”之美誉。都江堰—青城山在民国时期就列为川西风景名胜区</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E9%83%BD%E6%B1%9F%E5%A0%B0/12634169?fromtitle=%E9%83%BD%E6%B1%9F%E5%A0%B0%E5%B8%82&fromid=7261645&fr=aladdin">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
    </div>
</div>


<div className="titleContent">
    <h5>2017/06/03</h5>
    <div className="row">
        <div className="col s12 m12 l5">
            <img src="../img26/5.png" alt="" className="responsive-img"></img>
        </div>
        <div className="col s12 m12 l6 offset-l1">
            <p></p>
        </div>
    </div>


</div>

</div>

<div className="titles" id="title16">
<h4 className="tooltipped title" data-tooltip="黄龙">Huang Long</h4>

<div className="row">
    <div className="col s12 m12 l5">
        <img src="../img25'/5.png" alt="" className="responsive-img"></img>
        <br></br>
        <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Huanglong/@32.7487455,103.8210013,17z/data=!3m1!4b1!4m5!3m4!1s0x36f76bfd0ae5f8a5:0x8afc08fba6b1efc!8m2!3d32.748741!4d103.82319">
            <i className="tiny material-icons">location_on</i>
            <span className="titleMap">Huang Long</span>
        </a>

        
    </div>
    <div className="col s12 m12 l6 offset-l1">

    <div className="details">


<a className="btn-flat modal-trigger right info " href="#modal9996">Introduction</a>
<div id="modal9996" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">Huang Long - Introduction</h4>
    <p>Huanglong Scenic Area is located in Songpan County, Aba Tibetan and Qiang Autonomous Prefecture, Sichuan Province. It covers an area of ​​700 square kilometers. It is the only well-protected plateau wetland in China. It is 100 kilometers away from Jiuzhaigou and has an altitude of 1700-5588 meters.
    <br/><br/>
Huanglong is famous for the "four uniques" of colored pools, snow-capped mountains, canyons, and forests. Together with beach streams, ancient temples, and folk customs, they are called "seven uniques". The scenic spot consists of Huanglonggou, Danyunxia, ​​Munigou, Xuebaoding, Xueshanliang, Hongxingyan, Xigou and other scenic spots. The main landscape is concentrated in Huanglong Gully, which is about 3.6 kilometers long, with travertine deposits all over it. They are arranged in a terraced field and are well-known in the world for their rich flora and fauna resources, and enjoy the reputations of "world wonders" and "Jade Lake on earth". It was included in the "World Natural Heritage List" in 1992. In addition to the alpine landscape, various forest ecosystems can be found here, as well as limestone structures, waterfalls and hot springs. Many endangered animals live in this area, including giant pandas and Sichuan mute golden monkeys.
<br/><br/>
Huanglong is dominated by the large-scale, ingenious structure, and colorful surface travertine landscape. It is famous at home and abroad for its rare karst landforms, which can be called a fairyland on earth. On October 9, 2016, the National Tourism Administration released the "Red and Black List" for the November holiday tourism, and Huanglong Scenic Spot was listed as the best scenic spot in comprehensive order. It is a World Natural Heritage, World Man and Biosphere Reserve, "Green Global 21" certificate, a national AAAAA-level tourist attraction, and a national key scenic spot.
    
    
    </p>

    <p className="grey-text">黄龙风景名胜区位于四川省阿坝藏族羌族自治州松潘县。面积700平方公里。是中国唯一保护完好的高原湿地，与九寨沟相距100千米，海拔1700-5588米。
    <br/><br/>
黄龙以彩池、雪山、峡谷、森林“四绝”著称于世，再加上滩流、古寺、民俗称为“七绝”。景区由黄龙沟、丹云峡、牟尼沟、雪宝鼎、雪山梁、红星岩，西沟等景区组成。主要景观集中于长约3.6公里的黄龙沟，沟内遍布碳酸钙华沉积。并呈梯田状排列，以丰富的动植物资源享誉人间，享有“世界奇观”、“人间瑶池”等美誉。1992年列入《世界自然遗产名录》。除了高山景观，还可以在这里发现各种不同的森林生态系统，以及石灰岩构造、瀑布和温泉。这一地区还生存着许多濒临灭绝的动物，包括大熊猫和四川疣鼻金丝猴。 
<br/><br/>
黄龙以规模宏大、结构奇巧、色彩丰艳的地表钙华景观为主景，以罕见的岩溶地貌蜚声中外，堪称人间仙境。2016年10月9日，国家旅游局发布十一假日旅游“红黑榜”，黄龙景区上榜综合秩序最佳景区。是世界自然遗产，世界人与生物圈保护区，“绿色环球21”证书，国家AAAAA级旅游景区，国家重点风景名胜区。</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E9%BB%84%E9%BE%99%E9%A3%8E%E6%99%AF%E5%90%8D%E8%83%9C%E5%8C%BA/17722?fromtitle=%E9%BB%84%E9%BE%99&fromid=22850&fr=aladdin">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
    </div>
</div>


<div className="titleContent">
    <h5>2017/06/04</h5>
    <div className="row">
        <div className="col s12 m12 l5">
            <img src="../img26/6.png" alt="" className="responsive-img"></img>
        </div>
        <div className="col s12 m12 l6 offset-l1">
            <p>Multicolored pool</p>
            <p>

Multicolored pool is a group of calcified colored ponds located at the top of Huanglong. There are 693 calcium ponds, 4166 meters away from the entrance. There are green hills and green hills, and the 5588-meter-high Xuebao Ding, the main peak of Minshan Mountain, stands majestically in front of you. Walking along the side of the pool, there are countless colored pools of various sizes and shapes like watercolor plates filled with colorful paints, blue, green, and sea. Orchid, light blue, etc., gorgeous and unique.</p>
        </div>
    </div>

    <div className="row">
        <div className="col s12 m12 l5">
            <img src="../img26/7.png" alt="" className="responsive-img"></img>
        </div>
        <div className="col s12 m12 l6 offset-l1">
            <p></p>
        </div>
    </div>

    <div className="row">
        <div className="col s12 m12 l5">
            <img src="../img26/8.png" alt="" className="responsive-img"></img>
        </div>
        <div className="col s12 m12 l6 offset-l1">
            <p>Since water is rich in calcium ions, the water flow is slow, so after years of deposition, different shapes of deposits are gradually formed in the water. The color of the lake is mainly due to the scattering, reflection and absorption of sunlight by the lake. Sunlight or natural light is a polychromatic light composed of monochromatic lights of different wavelengths. In the spectrum, from red light to purple light, the wavelength gradually decreases.</p>
        </div>
    </div>



</div>

</div>

<div className="titles" id="title17">
<h4 className="tooltipped title" data-tooltip="九寨沟">Jiuzhai Valley National Park</h4>

<div className="row">
    <div className="col s12 m12 l5">
        <img src="../img25'/6.png" alt="" className="responsive-img"></img>
        <br></br>
        <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Jiuzhai+Valley+National+Park/@33.2600466,103.9164107,17z/data=!3m1!4b1!4m5!3m4!1s0x36f7bdf69e1c6729:0xc9fde8c8b5f60cc6!8m2!3d33.2600421!4d103.9185994">
            <i className="tiny material-icons">location_on</i>
            <span className="titleMap">Jiuzhai Valley National Park</span>
        </a>
    </div>
    <div className="col s12 m12 l6 offset-l1">

    <div className="details">


<a className="btn-flat modal-trigger right info " href="#modal9997">Introduction</a>
<div id="modal9997" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">Jiuzhai Valley National Park - Introduction</h4>
    <p>Jiuzhaigou: World Natural Heritage, National Key Scenic Spots, National AAAAA-level Tourist Attractions, National Nature Reserves, National Geoparks, World Biosphere Reserves Network, is China's first nature reserve with the main purpose of protecting natural scenery.
    <br/><br/>
Jiuzhaigou is located in Jiuzhaigou County, Aba Tibetan and Qiang Autonomous Prefecture, Sichuan Province. Located in the Qinghai-Tibet Plateau, Western Sichuan Plateau, and the transition zone from mountain to Sichuan Basin, it is more than 300 kilometers south of Chengdu. It is a valley with a depth of more than 50 kilometers, with a total area of ​​64297 hectares and a forest coverage rate of over 80%. Nine Tibetan villages, including Shuzheng Village, Heye Village, and Zechawa Village, are located in this mountain lake group.
<br/><br/>
The main protection objects of the Jiuzhaigou National Nature Reserve are rare animals such as giant pandas and golden monkeys and their natural ecological environment. There are 74 nationally protected rare plants, 18 nationally protected animals, and rich paleontological fossils and ancient glacier landforms.
<br/><br/>
"I don't look at the water after returning from Jiuzhaigou" is a true interpretation of the scenery of Jiuzhaigou. There are 108 seas in springs, waterfalls, rivers and beaches, forming a colorful jade basin. The six landscapes of Changhai, Jianyan, Nuorilang, Shuzheng, Zharu, and Black Sea are distributed in a "Y" shape. Cuihai, stacked waterfalls, colorful forests, snow peaks, Tibetan love, and blue ice are called "Six Uniques". The magical Jiuzhai is known as the "Fairy Tale World" and the "King of Water Scenery".
    
    </p>

    <p className="grey-text">九寨沟：世界自然遗产、国家重点风景名胜区、国家AAAAA级旅游景区、国家级自然保护区、国家地质公园、世界生物圈保护区网络，是中国第一个以保护自然风景为主要目的的自然保护区。
<br/><br/>
九寨沟位于四川省阿坝藏族羌族自治州九寨沟县境内。地处青藏高原、川西高原、山地向四川盆地过渡地带，南距成都市300多公里，是一条纵深50余千米的山沟谷地，总面积64297公顷，森林覆盖率超过80%。因沟内有树正寨、荷叶寨、则查洼寨等九个藏族村寨坐落在这片高山湖泊群中而得名。
<br/><br/>
九寨沟国家级自然保护区主要保护对象是以大熊猫、金丝猴等珍稀动物及其自然生态环境。有74种国家保护珍稀植物，有18种国家保护动物，还有丰富的古生物化石、古冰川地貌。
<br/><br/>
“九寨归来不看水”，是对九寨沟景色真实的诠释。泉、瀑、河、滩108个海子，构成一个个五彩斑斓的瑶池玉盆。长海、剑岩、诺日朗、树正、扎如、黑海六大景观，呈“Y”字形分布。翠海、叠瀑、彩林、雪峰、藏情、蓝冰，被称为“六绝”。神奇的九寨，被世人誉为“童话世界”，号称“水景之王”。</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%B9%9D%E5%AF%A8%E6%B2%9F%E5%9B%BD%E5%AE%B6%E7%BA%A7%E8%87%AA%E7%84%B6%E4%BF%9D%E6%8A%A4%E5%8C%BA/8762684?fromtitle=%E4%B9%9D%E5%AF%A8%E6%B2%9F&fromid=122560&fr=aladdin">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
    </div>
</div>

<div className="titleContent">
    <h5>2017/06/05</h5>
    <div className="row">
        <div className="col s12 m12 l5">
            <img src="../img26/9.png" alt="" className="responsive-img"></img>
        </div>
        <div className="col s12 m12 l6 offset-l1">
            <p>Long sea</p>
            <p>

            Long sea is the largest sea in Jiuzhaigou. The long sea bends along the mountains, hiding its head deep in the valley of mountains. The mountain peaks at the end of the Changhai Sea are covered with snow all year round, covered with silver makeup, and are above 4000-5000 meters above sea level.</p>
        </div>
    </div>

    <div className="row">
        <div className="col s12 m12 l5">
            <img src="../img26/10.png" alt="" className="responsive-img"></img>
        </div>
        <div className="col s12 m12 l6 offset-l1">
            <p>Multicolored pool</p>
            <p>

Multicolored pool is the essence of Jiuzhaigou. It is the smallest and most colorful pond in Jiuzhaigou. The upper half is azure blue, the lower half is orange-red, the left is sky blue, and the right is olive green. In the lake, there are aquatic plant communities such as Spirogyra, Chara, and small ferns, as well as various herbaceous plants such as reeds, knotweeds, and water wicks.


<br/><br/>
These aquatic communities contain different depths of chlorophyll and can show different colors in lake water rich in calcium carbonate. In winter, there is ice and snow all around, but this colorful pond is not frozen, which is amazing.</p>
        </div>
    </div>

    <div className="row">
        <div className="col s12 m12 l5">
            <img src="../img26/11.png" alt="" className="responsive-img"></img>
        </div>
        <div className="col s12 m12 l6 offset-l1">
            <p>Panda Sea</p>
        </div>
    </div>

    <div className="row">
        <div className="col s12 m12 l5">
            <img src="../img26/12.png" alt="" className="responsive-img"></img>
        </div>
        <div className="col s12 m12 l6 offset-l1">
            <p>Panda Sea</p>
            <p>The Panda Sea is 2587 meters above sea level, 14 meters deep, and covers an area of 90,000 square meters. There are often panda activities here. The rock wall by the lake has a peculiar texture, reflecting in the lake, and there are a few circles of black patterned round, like panda-like white stones, reflecting in the lake.</p>
        </div>
    </div>

    <div className="row">
        <div className="col s12 m12 l5">
            <img src="../img26/13.png" alt="" className="responsive-img"></img>
        </div>
        <div className="col s12 m12 l6 offset-l1">
            <p></p>
        </div>
    </div>

    <div className="row">
        <div className="col s12 m12 l5">
            <img src="../img26/14.png" alt="" className="responsive-img"></img>
        </div>
        <div className="col s12 m12 l6 offset-l1">
            <p>Five-flower Lake</p>
            <p>The five-flower lake is known as the "One Unique in Jiuzhaigou" and the "Essence of Jiuzhaigou", and is the pride of Jiuzhaigou.


<br/><br/>
In the same water area, the five-flower lake can show goose yellow, dark green, dark blue, navy blue and other colors, mottled and blurred, and colorful. It is one of the most exciting attractions in Jiuzhaigou, attracting countless tourists and photographers.</p>
        </div>
    </div>

    <div className="row">
        <div className="col s12 m12 l5">
            <img src="../img26/15.png" alt="" className="responsive-img"></img>
        </div>
        <div className="col s12 m12 l6 offset-l1">
            <p>Pearl Beach Waterfall</p>
        </div>
    </div>

    <div className="row">
        <div className="col s12 m12 l5">
            <img src="../img26/16.png" alt="" className="responsive-img"></img>
        </div>
        <div className="col s12 m12 l6 offset-l1">
            <p>Pearl Beach Waterfall</p>
            <p>

At an altitude of 2445 meters, the waterfall is 21 meters high and 162 meters wide. It is a typical combined landscape in Jiuzhaigou. It is the place where Tang monk and his apprentice wade horses in the end of the TV series "Journey to the West".</p>
        </div>
    </div>



</div>


</div>

<div className="titles" id="title18">
<h4 className="tooltipped title" data-tooltip="九寨沟藏谜大剧院">Jiuzhaigou Tibetan Mystery Theater</h4>

<div className="row">
    <div className="col s12 m12 l5">
        <img src="../img25'/7.png" alt="" className="responsive-img"></img>
        <br></br>
        <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Jiuzhaigou+Tibetan+Mystery+Theater/@33.2877283,103.8766571,17z/data=!3m1!4b1!4m5!3m4!1s0x36f795d0bd3f1751:0x537fe1d76e0514f1!8m2!3d33.2877238!4d103.8788458">
            <i className="tiny material-icons">location_on</i>
            <span className="titleMap">Jiuzhaigou Tibetan Mystery Theater</span>
        </a>
    </div>
    <div className="col s12 m12 l6 offset-l1">

    <div className="details">


<a className="btn-flat modal-trigger right info " href="#modal9998">Introduction</a>
<div id="modal9998" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">Jiuzhaigou Tibetan Mystery Theater - Introduction</h4>
    <p>The Jiuzhaigou Tibetan Mystery Theater is a long-term performance location of the large-scale original ecological song and dance "The Tibetan Mystery". The theater was invested and constructed by Jiuzhaigou County Rongzhong Erjia Culture Communication Co., Ltd. The project is located in Zhangzha Town, Jiuzhaigou County, on the south side of Chuanjiu Highway. A 7-meter-wide river meanders along the north side of the planned land. The theater covers an area of about 1,350.34 square meters, with 350 internal sub-buildings and 860 pools.</p>

    <p className="grey-text">九寨沟藏谜大剧院是大型原生态歌舞乐《藏谜》的长期演出地点。该剧院由九寨沟县容中尔甲文化传播有限公司投资建设。项目位于九寨沟县漳扎镇，川九公路南侧。一条7米宽的河流沿规划用地北侧蜿蜒流淌。该剧院面积1350.34平方米左右，内分楼座350座，池座860座。</p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%B9%9D%E5%AF%A8%E6%B2%9F%E8%97%8F%E8%B0%9C%E5%A4%A7%E5%89%A7%E9%99%A2%EF%BC%9F/17405004?fr=aladdin">
        {/* <i className="material-icons left">details</i>   */}
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div>

</div>
    </div>
</div>


<div className="titleContent">
    <h5>2017/06/05</h5>
    <div className="row">
        <div className="col s12 m12 l5">
            <img src="../img26/17.png" alt="" className="responsive-img"></img>
        </div>
        <div className="col s12 m12 l6 offset-l1">
            <p>Old grandma pilgrimage</p>
        </div>
    </div>


</div>

</div>

<div className="titles" id="title19">
<h4 className="tooltipped title" data-tooltip="藏寨">Mount Heng</h4>

<div className="row">
    <div className="col s12 m12 l5">
        <img src="../img25'/8.png" alt="" className="responsive-img"></img>
        {/* <br></br>
        <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Mount+Heng/@39.6728066,113.7240542,15z/data=!3m1!4b1!4m5!3m4!1s0x35e314eca54c24bd:0x728a6ddd443e0192!8m2!3d39.672791!4d113.732809">
            <i className="tiny material-icons">location_on</i>
            <span className="titleMap">藏寨</span>
        </a> */}
    </div>
    <div className="col s12 m12 l6 offset-l1">

    <div className="details">


{/* <a className="btn-flat modal-trigger right info " href="#modal9999">介绍</a>
<div id="modal9999" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">藏寨 - 介绍</h4>
    <p></p>

    <p className="grey-text"></p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%81%92%E5%B1%B1/395558?fr=aladdin">

        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div> */}

</div>
    </div>
</div>


<div className="titleContent">
    <h5>2017/06/06</h5>
    <div className="row">
        <div className="col s12 m12 l5">
            <img src="../img26/18.png" alt="" className="responsive-img"></img>
        </div>
        <div className="col s12 m12 l6 offset-l1">
            <p></p>
        </div>
    </div>



</div>

</div>

<div className="titles" id="title20">
<h4 className="tooltipped title" data-tooltip="钟楼">Yungang Grottoes</h4>

<div className="row">
    <div className="col s12 m12 l5">
        <img src="../img25'/9.png" alt="" className="responsive-img"></img>
        {/* <br></br>
        <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Yungang+Grottoes/@40.1115138,113.1302848,17z/data=!3m1!4b1!4m5!3m4!1s0x35e2cc48520de2e3:0xff93e49966b42707!8m2!3d40.1115097!4d113.1324735">
            <i className="tiny material-icons">location_on</i>
            <span className="titleMap">钟楼</span>
        </a> */}
    </div>
    <div className="col s12 m12 l6 offset-l1">

    <div className="details">


{/* <a className="btn-flat modal-trigger right info " href="#modal99910">介绍</a>
<div id="modal99910" className="modal modal-fixed-footer">
    <div className="modal-content">
    <h4 className="title">钟楼 - 介绍</h4>
    <p></p>
    <p className="grey-text"></p>
    <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%BA%91%E5%86%88%E7%9F%B3%E7%AA%9F/1252?fr=aladdin">
        <span className="blue-text">[Source From...]</span>
    </a>    
    </div>
    <div className="modal-footer">
    <a href="#!" className="modal-close btn-flat">Close</a>
    </div>
</div> */}

</div>
    </div>
</div>


<div className="titleContent">
    <h5>2017/06/06</h5>
    <div className="row">
        <div className="col s12 m12 l5">
            <img src="../img26/19.png" alt="" className="responsive-img"></img>
        </div>
        <div className="col s12 m12 l6 offset-l1">
            <p>Wenchuan Earthquake Site
            <br/><br/>
            

The hour hand always stays at 14:28:04 on May 12, 2008.</p>
        </div>
    </div>




</div>

</div>

    <h4 className="title"> </h4>

    {/* <div className="Comment">
    
        <div className="row">   
        <div className="col s1 m1 l1">
            <a className="btn btn-floating black lighten-1 ">YX</a>
        </div>
        <div className="col s11 m11 l11 commentContent">
            <form action="">
                <div className="input-field">
                    <textarea id="message" className="materialize-textarea"></textarea>
                    <label htmlFor="message">发表评论</label>
                </div>
            </form>
        </div>
        </div>

      

    </div> */}





</div>
</div>
    
            
            
            <footer className="page-footer black darken-3">
                <div className="container">
                <div className="row">

                    <div className="flex text-xs-center col l1 m2 s12">
                        {/* <img  alt="" className="responsive-img"></img> */}
                        <p className="center">Y.X.</p>
                    </div>

                    <div className="col l5 offset-l1 m5 s12 hide-on-small-only">
                    <h5>Who is Y.X.?</h5>
                    <p>Y.X. graduated from uOttawa in 2020. She studied computer science and mathematics. She enjoys programming, travelling & playing instruments.</p>
                    </div>
              

                    <div className="col l4 offset-l1 m5 s12">
                    {/* <h5 className="white-text">Connect</h5> */}
                    <div className="text-xs-center mx my social-icons">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/crys___7" className=" btn-floating btn-small transparent" >
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UClv5NmxBvWIjid8crocDvZQ" className=" btn-floating btn-small transparent">
                        <i className="fab fa-youtube"></i>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=100006981077508" className=" btn-floating btn-small transparent">
                        <i className="fab fa-facebook"></i>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/YueqingXie" className=" btn-floating btn-small transparent">
                        <i className="fab fa-twitter"></i>
                    </a>
                    </div>
                    </div>
                </div>
                </div>
                <div className="footer-copyright grey darken-4">
                <div className="container copyright center-align">Copyright &copy; 2020 Y.X.</div>
                </div>
            </footer>
  
            </section>
        )
    }
}

   



export default travelNoteChongqingChengdu;