import React, { Component } from 'react';



class cai extends Component {
    // state = {

    // }
    // handleChange = (e) => {
    //     console.log(e);
    // }
    // handleSubmit = (e) => {
    //     console.log(e);
    // }
    render() {


        return (
            <section>

<header>


<nav className="nav-wrapper black">
<div className="container">
    <a href="/" className="brand-logo ">
        <span>Y.X.</span>
    </a>
    
    <a href="" className="sidenav-trigger" data-target="mobile-menu">
    <i className="material-icons">menu</i>
    </a>
    <ul className="right hide-on-med-and-down">
    <li><a href="/travel">Travel</a></li>
    <li><a href="/music">Music</a></li>
    <li><a href="/calligraphy">Calligraphy</a></li>
    
    <li><a href="/cat">🐈</a></li>

    <li><a href="https://www.instagram.com/crys___7" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-instagram"></i>
    </a></li>
    <li><a href="https://www.youtube.com/channel/UClv5NmxBvWIjid8crocDvZQ" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-youtube"></i>
    </a></li>
    <li><a href="https://www.facebook.com/profile.php?id=100006981077508" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-facebook"></i>
    </a></li>
    <li><a href="https://twitter.com/YueqingXie" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-twitter"></i>
    </a></li>
    <li><a href="/caiCN" className="btn btn-floating transparent">CN</a></li>
    
    </ul>
    
    <ul className="sidenav grey lighten-2" id="mobile-menu">
        <li><a href="/travel" className="sidenavtext">Travel</a></li>
        <li><a href="/music" className="sidenavtext">Music</a></li>
        <li><a href="/calligraphy" className="sidenavtext">Calligraphy</a></li>
        <li><a href="/cat" className="sidenavtext">My Cat</a></li>
        <li><a href="/#aboutmenav" className="sidenavtext">About Me</a></li>
            <br/>
            <li><a href="/caiCN" className="sidenavtext">CN</a></li>

    </ul>
</div>
</nav>
</header>

            
            <section className="container section">

            <h4 className="titleENG center">Cooking</h4>
                 
            <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/1.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Spicy Crayfish</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/2.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Stewed Beef Brisket with Tomato</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/3.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Fried chicken chop</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/4.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Boiled Meatballs</span>
            </div>
            </div>
        </div>
        </div>     

              <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/5.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Spicy Shrimp Ball</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/6.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Mapo Tofu</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/7.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Korean army stew</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/8.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Boiled fish</span>
            </div>
            </div>
        </div>
        </div>     

              <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/9.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Candied sweet potatoes</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/10.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Sweet and sour pork ribs</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/11.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Braised pork trotters with soybeans</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/12.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Boiled Prawns</span>
            </div>
            </div>
        </div>
        </div>     

              <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/13.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Chicken stewed with mushrooms</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/14.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Toothpick Beef</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/15.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Red Bean Rice Cake</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/16.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Enoki mushroom with garlic</span>
            </div>
            </div>
        </div>
        </div>     

              <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/17.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Noodles with Soy Bean Paste</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/18.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Pineapple Sweet and Sour Pork</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/19.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Fried Pork in Scoop</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/20.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Coke Chicken Wings</span>
            </div>
            </div>
        </div>
        </div>     

              <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/21.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Fried tofu</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/22.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Chicken curry</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/23.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Spicy fried potato shreds</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/24.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Yuxiang shredded pork</span>
            </div>
            </div>
        </div>
        </div>     

              <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/25.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Kung Pao Diced Pork</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/26.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Spicy Fried Clams</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/27.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Shredded cabbage</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/28.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Shredded Pork with Vegetables</span>
            </div>
            </div>
        </div>
        </div>     

              <div className="row">
            <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/29.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Fried Larimichthys crocea</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/30.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Ribs soup</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/31.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Fried shrimp balls</span>
            </div>
            </div>
        </div>

        <div className="col s12 m6 l3">
            <div className="card">
            <div className="card-image">
                <img src="../img33/32.jpg" alt="" className="responsive-img materialboxed"/>
                <span className="card-title">Popcorn chicken</span>
            </div>
            </div>
        </div>
        </div>     

  
            </section>
            <footer className="page-footer black darken-3">
                <div className="container">
                <div className="row">

                    <div className="flex text-xs-center col l1 m2 s12">
                        {/* <img src="../img/me_small.png"></img> */}
                        <p className="center">Y.X.</p>
                    </div>

                    <div className="col l5 offset-l1 m5 s12 hide-on-small-only">
                    <h5>Who is Y.X.?</h5>
                    <p>Y.X. graduated from uOttawa in 2020. She studied computer science and mathematics. She enjoys programming, travelling & playing instruments.</p>
                    </div>
              

                    <div className="col l4 offset-l1 m5 s12">
                    {/* <h5 className="white-text">Connect</h5> */}
                    <div className="text-xs-center mx my social-icons">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/crys___7" className=" btn-floating btn-small transparent" >
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UClv5NmxBvWIjid8crocDvZQ" className=" btn-floating btn-small transparent">
                        <i className="fab fa-youtube"></i>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=100006981077508" className=" btn-floating btn-small transparent">
                        <i className="fab fa-facebook"></i>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/YueqingXie" className=" btn-floating btn-small transparent">
                        <i className="fab fa-twitter"></i>
                    </a>
                    </div>
                    </div>
                </div>
                </div>
                <div className="footer-copyright grey darken-4">
                <div className="container copyright center-align">Copyright &copy; 2020 Y.X.</div>
                </div>
            </footer>
  
            </section>
        )
    }
}





export default cai
