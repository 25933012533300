import React, { Component } from 'react';
import Footer from '../layout/Footer';



class travelNoteXianYunnanCN extends Component {

    render() {
     
        return ( 
            <section>
<header>


<nav className="nav-wrapper black">
<div className="container">
    <a href="/CN" className="brand-logo ">
        <span>Y.X.</span>
    </a>
    
    <a href="" className="sidenav-trigger" data-target="mobile-menu">
    <i className="material-icons">menu</i>
    </a>
    <ul className="right hide-on-med-and-down">
    <li><a href="/travelCN" >旅游</a></li>
    <li><a href="/musicCN">音乐</a></li>
    <li><a href="/calligraphyCN">书法</a></li>
    
    <li><a href="/catCN">🐈</a></li>

    <li><a href="https://www.instagram.com/crys___7" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-instagram"></i>
    </a></li>
    <li><a href="https://www.youtube.com/channel/UClv5NmxBvWIjid8crocDvZQ" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-youtube"></i>
    </a></li>
    <li><a href="https://www.facebook.com/profile.php?id=100006981077508" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-facebook"></i>
    </a></li>
    <li><a href="https://twitter.com/YueqingXie" className=" btn-floating btn-small transparent" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-twitter"></i>
    </a></li>
    <li><a href="/travelNoteXianYunnan" className="btn btn-floating transparent">ENG</a></li>
    
    </ul>
    
    <ul className="sidenav grey lighten-2" id="mobile-menu">
        <li><a href="/travelCN" className="sidenavtext">旅游</a></li>
        <li><a href="/musicCN" className="sidenavtext">音乐</a></li>
        <li><a href="/calligraphyCN" className="sidenavtext">书法</a></li>
        <li><a href="/catCN" className="sidenavtext">我的猫</a></li>
        <li><a href="/CN#aboutmenavCN" className="sidenavtext">关于我</a></li>
            <br/>
            <li><a href="/travelNoteXianYunnan" className="sidenavtext">ENG</a></li>

    </ul>
</div>
</nav>
</header>

          
            <div className="row hide-on-small-only" id="travelNoteXianYunnan">
                <div className="col s2 m2 l2">
                    <div>
                        {/* <nav className="nav">
                            <a href="#video4">title 4</a>
                        </nav> */}
                        
                        <ul className="nav">
                        <div className="bigTitle">西安&云南</div>
                            <li className="titleDate">
                                <a href="#title1">2018/06/04</a>
                            </li>
                            <li className="smallTitle">
                                <a>1.1/ 西安-<a href="#title1" className="verysmallTitle">回民街</a>/<a href="#title2" className="verysmallTitle">钟楼鼓楼</a></a>
                                
                            </li>

                            <li className="titleDate">
                                <a href="#title3">2018/06/05</a>
                            </li>
                            <li className="smallTitle">
                                <a>2.1/ 西安-<a href="#title3" className="verysmallTitle">兵马俑博物馆</a>/<a href="#title4" className="verysmallTitle">大雁塔音乐喷泉</a></a>
                                
                            </li>

                            <li className="titleDate">
                                <a href="#title5">2018/06/06</a>
                            </li>
                            <li className="smallTitle">
                                <a>3.1/ 香格里拉-<a href="#title5" className="verysmallTitle">松赞林寺</a>/<a href="#title6" className="verysmallTitle">拉姆央措湖</a></a>
                                
                            </li>

                            <li className="titleDate">
                                <a href="#title7">2018/06/07</a>
                            </li>
                            <li className="smallTitle">
                                <a>4.1/ 香格里拉-<a href="#title7" className="verysmallTitle">纳帕海</a>/<a href="#title8" className="verysmallTitle">独克宗古城</a></a>
                                
                            </li>

                            <li className="titleDate">
                                <a href="#title9">2018/06/08</a>
                            </li>
                            <li className="smallTitle">
                                <a>5.1/ 香格里拉-<a href="#title9" className="verysmallTitle">小中甸花海</a>/<a href="#title10" className="verysmallTitle">虎跳峡</a>/ <a href="#title11" className="verysmallTitle">丽江古城</a></a>
                                
                            </li>

                            <li className="titleDate">
                                <a href="#title12">2018/06/09</a>
                            </li>
                            <li className="smallTitle">
                                <a>6.1/ 丽江-<a href="#title12" className="verysmallTitle">泸沽湖</a>/<a href="#title13" className="verysmallTitle">篝火晚会</a></a>
                                
                            </li>

                            <li className="titleDate">
                                <a href="#title14">2018/06/10</a>
                            </li>
                            <li className="smallTitle">
                                <a>7.1/ 丽江-<a href="#title14" className="verysmallTitle">泸沽湖</a>/<a href="#title15" className="verysmallTitle">丽江千古情</a></a>
                                
                            </li>

                            <li className="titleDate">
                                <a href="#title16">2018/06/11</a>
                            </li>
                            <li className="smallTitle">
                                <a>8.1/ 大理-<a href="#title16" className="verysmallTitle">大理古城</a></a>
                    
                            </li>

                            <li className="titleDate">
                                <a href="#title17">2018/06/12</a>
                            </li>
                            <li className="smallTitle">
                                <a>9.1/ 大理-<a href="#title17" className="verysmallTitle">洱海</a>/<a href="#title18" className="verysmallTitle">蒋公祠</a>/<a href="#title19" className="verysmallTitle">玉洱园</a>/ <a href="#title20" className="verysmallTitle">大金塔</a></a>
                                
                            </li>

                            <li className="titleDate">
                                <a href="#title21">2018/06/13</a>
                            </li>
                            <li className="smallTitle">
                                <a>10.1/ 西双版纳-<a href="#title21" className="verysmallTitle">望天树</a>/<a href="#title22" className="verysmallTitle">总佛寺</a>/<a href="#title23" className="verysmallTitle">曼听公园</a></a>
                                
                            </li>

                            <li className="titleDate">
                                <a href="#title24">2018/06/14</a>
                            </li>
                            <li className="smallTitle">
                                <a>11.1/ 西双版纳-<a href="#title24" className="verysmallTitle">中国科学院热带植物园</a></a>
                                
                            </li>

                            <li className="titleDate">
                                <a href="#title25">2018/06/15</a>
                            </li>
                            <li className="smallTitle">
                                <a>12.1/ 昆明-<a href="#title25" className="verysmallTitle">石林</a>/<a href="#title26" className="verysmallTitle">九乡</a>/<a href="#title27" className="verysmallTitle">金马碧鸡坊</a></a>
                                
                            </li>

                            
                        </ul>
                    </div>
                </div>

                <div className="col s9 m9 l9 offset-s1 offset-m1 offset-l1 noteShanxiShandong">

                        <div className="row">
                            <div className="col s10 m10 l10 offset-s1 offset-m1 offset-l1 ">
                            <div className="row travelDetail">
                                <div className="time col s12 m4 l4">
                                    <i className=" material-icons left">access_time</i>
                                    <span>出发时间</span>
                                    <span> / </span>
                                    <span>2018-06-04</span>
                                </div>
                                <div className="day col s12 m4 l4">
                                    <i className=" material-icons left">wb_sunny</i>
                                    <span>出行天数</span>
                                    <span> / </span>
                                    <span>13天</span>
                                </div>
                                <div className="people col s12 m4 l4">
                                    <i className=" material-icons left">account_circle</i>
                                    <span>人物</span>
                                    <span> / </span>
                                    <span>和家人</span>
                                </div>
                            </div>
                            <div className="col s1 m1 l1"></div>
                            </div>
                        </div>

                    <div className="titles" id="title1">
                        <h4 className="tooltipped title" data-tooltip="Huimin Street">回民街</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/1.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Huimin+Street/@34.2598474,108.9428072,17z/data=!3m1!4b1!4m5!3m4!1s0x36637a613130367d:0xd8367ef361d2e616!8m2!3d34.259843!4d108.9449959">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">回民街</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal1">介绍</a>
                        <div id="modal1" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">回民街 - 介绍</h4>
                            <p>西安回民街是西安著名的美食文化街区，是西安小吃街区。回民街所在北院门，为清代官署区，因陕西巡抚衙门在鼓楼之北，故名北院。1990年代末，部分回民在此街租房经营餐饮，莲湖区遂改向餐饮街方向，北院门遂成为回民街。
                            <br/>
<br/>
西安回民街作为西安风情的代表之一，是回民街区多条街道的统称，由北广济街、北院门、西羊市、大皮院、化觉巷、洒金桥等数条街道组成，在钟鼓楼后。</p>

                            <p className="grey-text">Xi’an Muslim Street is a famous food and cultural street in Xi’an and a snack street in Xi’an. Huimin Street is located at the North Yard Gate, which was an official district in the Qing Dynasty. Because the Shaanxi governor’s Yamen was in the north of the Drum Tower, it was named North Yard. At the end of the 1990s, some Muslims rented houses and catering on this street, and the Lianhu District was changed to the restaurant street, and the North Yard Gate became a Muslim street.
                            <br/>
<br/>
Xi’an Huimin Street, as one of the representatives of Xi’an style, is the collective name of many streets in the Muslim neighborhood. It is composed of Beiguangji Street, Beiyuanmen, Xiyang City, Dapiyuan, Huajue Alley, and Sajin Bridge. Behind the Bell and Drum Tower.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%9B%9E%E6%B0%91%E8%A1%97/9853770?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2018/06/04</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/1.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>老白家肉夹馍</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/2.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>红柳烤肉</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/3.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>皮影戏：卖杂货</p>
                                </div>
                            </div>




                        </div>
                        
                    </div>

                    <div className="titles" id="title2">
                        <h4 className="tooltipped title" data-tooltip="Bell Tower & Drum Tower">钟楼&鼓楼</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/2.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Bell+Tower+of+Xi'an/@34.2594711,108.944831,17z/data=!3m1!4b1!4m5!3m4!1s0x36637a6108f00001:0x527adec5a3f239e1!8m2!3d34.2594667!4d108.9470197">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">钟楼</span>
                                </a>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Xi'an+Drum+Tower,+Zhong+Lou+Shang+Quan,+Lianhu+Qu,+Xian+Shi,+Shaanxi+Sheng,+China/@34.2602512,108.941256,17z/data=!3m1!4b1!4m5!3m4!1s0x36637a66dab175df:0x6e4a9ab21e5b3c48!8m2!3d34.2602468!4d108.9434447">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">鼓楼</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal2">介绍</a>
                        <div id="modal2" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">钟楼鼓楼 - 介绍</h4>
                            <p>西安钟鼓楼是西安钟楼和西安鼓楼的合称，位于陕西省省会西安市市中心，是西安的标志性建筑物，两座明代建筑遥相呼应，蔚为壮观。
                            <br/>
<br/>
西安钟楼始建于明洪武十七年（公元1384年），原建于今西大街北广济街东侧，明万历十年（公元1582年）移于现址。钟楼是一座重檐三滴水式四角攒尖顶的阁楼式建筑，面积1377.64平方米，建在用青砖、白灰砌成的方形基座上。基座下有高与宽均为6米的十字形券洞与东南西北四条大街相通。
<br/>
<br/>
西安鼓楼始建于明洪武十三年（公元1380年），比钟楼的建造时间稍早。鼓楼建于高大的长方形台基之上，台基下辟有高和宽均为6米的南北向券洞。</p>

                            <p className="grey-text">Xi’an Bell and Drum Tower is the collective name of Xi’an Bell Tower and Xi’an Drum Tower. It is located in the center of Xi’an, the capital of Shaanxi Province.
                            <br/>
<br/>
Xi’an Bell Tower was built in the 17th year of Hongwu in the Ming Dynasty (AD 1384). It was originally built on the east side of Guangji Street, North Guangji Street, present West Street. The bell tower is a loft-style building with double eaves, three drops of water and four-cornered spires, covering an area of 1377.64 square meters, built on a square base made of blue bricks and white ash. There is a cross-shaped voucher hole with a height and width of 6 meters under the base, which communicates with the four streets in the south, east, north and west.
<br/>
<br/>
Xi'an Drum Tower was built in the thirteenth year of Ming Hongwu (AD 1380), which was earlier than the construction of the Bell Tower. The Drum Tower is built on a tall rectangular platform, under which there is a north-south coupon hole with a height and width of 6 meters.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E8%A5%BF%E5%AE%89%E9%92%9F%E9%BC%93%E6%A5%BC/1850217?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2018/06/04</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/4.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>钟楼</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/5.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>鼓楼</p>
                                </div>
                            </div>

                            




                        </div>
                        
                    </div>

                    <div className="titles" id="title3">
                        <h4 className="tooltipped title" data-tooltip="Emperor Qinshihuang's Mausoleum Site Museum">兵马俑博物馆</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/3.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Emperor+Qinshihuang's+Mausoleum+Site+Museum/@34.3841197,109.2763031,17z/data=!3m1!4b1!4m5!3m4!1s0x3664a72d821403a3:0xc3e33e74e9e33fc7!8m2!3d34.3841153!4d109.2784918">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">兵马俑博物馆</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal3">介绍</a>
                        <div id="modal3" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">兵马俑博物馆 - 介绍</h4>
                            <p>秦始皇兵马俑博物馆位于陕西省西安市临潼区秦陵镇，成立于1975年11月，原为秦始皇兵马俑筹建处，于1979年10月1日正式开馆，建于临潼县东7.5公里的骊山北麓的秦始皇帝陵兵马俑坑遗址上，西距西安37.5公里；和丽山园合称秦始皇帝陵博物院。 截至2020年1月，秦始皇兵马俑博物馆已接待海内外观众达8000多万人次。秦兵马俑地下大军先后接待观众近5000万人次，其中共接待外国国家元首、政府首脑187批，副总统、副总理和议长506批、部长级客人1852批。 
<br/><br/>
截至2020年1月，已先后建成并开放了秦俑一、三、二号坑和文物陈列厅。目前秦俑博物馆面积已扩大到46.1公顷，拥有藏品5万余（套）件。 一号兵马俑坑内约埋藏陶俑、陶马6000件，同时还有大量的青铜兵器；二号兵马俑坑内埋藏陶俑、陶马1300余件，二号俑坑较一号俑坑的内容更丰富，兵种更齐全；三号俑坑的规模较小，坑内埋藏陶俑、陶马72件；陈列厅内有一、二号铜车马。</p>

                            <p className="grey-text">Emperor Qinshihuang's Mausoleum Site Museum is located in Qinling Town, Lintong District, Xi'an City, Shaanxi Province. On the site of the Terracotta Warriors and Horses Pit of Emperor Qin Shi Huang’s Mausoleum, 37.5 kilometers west of Xi’an; Helishan Garden is collectively called the Museum of Emperor Qin Shi Huang’s Mausoleum. As of January 2020, the Terracotta Warriors and Horses Museum of Qin Shihuang has received more than 80 million visitors from home and abroad. The underground army of Qin Terracotta Warriors and Horses has received nearly 50 million visitors, including 187 groups of foreign heads of state and government, 506 groups of vice presidents, vice premiers and speakers, and 1,852 groups of minister-level guests.
                            <br/><br/>
As of January 2020, the first, third and second pits of the Qin warriors and the exhibition hall of cultural relics have been completed and opened. At present, the area of ​​the Emperor Qinshihuang's Mausoleum Site Museum has expanded to 46.1 hectares, with more than 50,000 (sets) of collections. Terracotta Warriors and Horses Pit No. 1 contains about 6,000 pottery figurines and horses, as well as a large number of bronze weapons; Terracotta Warriors and Horses Pit No. 2 contains more than 1,300 pottery warriors and horses, and Pit No. 2 has more content than Pit No. 1. There are more types of troops; the No. 3 warrior pit is smaller in scale, 72 pottery warriors and horses are buried in the pit; there are 1 and 2 bronze carts and horses in the showroom.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E7%A7%A6%E5%A7%8B%E7%9A%87%E5%85%B5%E9%A9%AC%E4%BF%91%E5%8D%9A%E7%89%A9%E9%A6%86/1509704?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2018/06/05</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/6.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>手半握</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/7.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>三号</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/8.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>脚趾朝上挑：趾高气扬</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/9.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>手指比个一：一人之下</p>
                                </div>
                            </div>




                        </div>
                        
                    </div>

                    <div className="titles" id="title4">
                        <h4 className="tooltipped title" data-tooltip="Da-Yan Tower fountain">大雁塔音乐喷泉</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/4.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/%E5%A4%A7%E9%9B%81%E5%A1%94%E9%9F%B3%E4%B9%90%E5%96%B7%E6%B3%89%E5%B9%BF%E5%9C%BA/@34.2202029,108.9619324,17z/data=!4m8!1m2!2m1!1z6KW_5a6JIOWkp-mbgeWhlOmfs-S5kOWWt-aziQ!3m4!1s0x36637068cf98ec59:0x53105525177f63c2!8m2!3d34.222111!4d108.964183">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">大雁塔音乐喷泉</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal4">介绍</a>
                        <div id="modal4" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">大雁塔音乐喷泉 - 介绍</h4>
                            <p>西安大雁塔喷泉位于大雁塔北广场，东西宽218米，南北长346米。它是亚洲雕塑规模最大的广场，广场内有2个百米长的群雕，8组大型人物雕塑，40块地景浮雕。
                            <br/>
<br/>
它拥有全世界最豪华的绿化无接触式卫生间，保持最清洁、世界上坐凳最多、世界最长的光带、世界首家直引水、规模最大的音响组合等多项纪录。</p>

                            <p className="grey-text">The big wild goose pagoda fountain is located in the north square of the Big Wild Goose Pagoda. It is the largest sculpture square in Asia. There are two hundred-meter-long group sculptures, eight groups of large-scale figure sculptures, and 40 relief sculptures.
                            <br/>
<br/>
It has the world's most luxurious greening contactless toilet, maintaining many records such as the cleanest, the most stools in the world, the world's longest light belt, the world's first direct water diversion, and the largest sound combination.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%A4%A7%E9%9B%81%E5%A1%94%E9%9F%B3%E4%B9%90%E5%96%B7%E6%B3%89/560291?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2018/06/05</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/10.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>看的是晚上8:30的场次</p>
                                </div>
                            </div>
 




                        </div>
                        
                    </div>

                    <div className="titles" id="title5">
                        <h4 className="tooltipped title" data-tooltip="Songzanlin Temple">松赞林寺</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/5.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/%E5%99%B6%E4%B8%B9%E6%9D%BE%E8%B5%9E%E6%9E%97/@27.8169139,99.6879114,14z/data=!4m8!1m2!2m1!1z5p2-6LWe5p6X5a-6!3m4!1s0x3721ba61e1ea6799:0x496043453ca25487!8m2!3d27.816877!4d99.705421">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">松赞林寺</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal5">介绍</a>
                        <div id="modal5" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">松赞林寺 - 介绍</h4>
                            <p>噶丹·松赞林寺是云南省规模最大的藏传佛教寺院，也是康区有名的大寺院之一，还是川滇一带的黄教中心，在整个藏区都有着举足轻重的地位，被誉为“小布达拉宫”。该寺依山而建，外形犹如一座古堡，集藏族造型艺术之大成，又有“藏族艺术博物馆”之称。 
                            <br/>
<br/>
该寺又称归化寺，距中甸县城5公里，是一座古镇规模的古堡群建筑。于公元1679年（藏历第十一绕迥阴土羊年）兴建，公元1681年（阴铁鸡年）竣工。五世达赖喇嘛亲赐名“噶丹·松赞林”。</p>

                            <p className="grey-text">Kadan Songzanlin Temple is the largest Tibetan Buddhist monastery in Yunnan Province. It is also one of the most famous monasteries in the Kham area. It is also the center of the Yellow Sect in Sichuan and Yunnan. It has a pivotal position in the entire Tibetan area and is known as the "small Potala Palace". Built on the mountain, the temple looks like an ancient castle. It is a collection of Tibetan plastic arts and is also known as the "Tibetan Art Museum".
                            <br/>
<br/>
The temple, also known as Guihua Temple, is 5 kilometers away from Zhongdian County. It is an ancient town-scale castle complex. It was built in 1679 (the eleventh cycle of the Tibetan calendar, the year of the yin earth sheep), and was completed in 1681 (the year of the yin iron rooster). The Fifth Dalai Lama personally gave the name "Kadan Songtsanlin".</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%99%B6%E4%B8%B9%C2%B7%E6%9D%BE%E8%B5%9E%E6%9E%97%E5%AF%BA/10347256?fromtitle=%E6%9D%BE%E8%B5%9E%E6%9E%97%E5%AF%BA&fromid=828735&fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2018/06/06</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/11.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>西安飞往香格里拉</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/12.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>人数够了以后，会配个导游带领进入松赞林寺。</p>
                                </div>
                            </div>


                        </div>
                        
                    </div>

                    <div className="titles" id="title6">
                        <h4 className="tooltipped title" data-tooltip="Lamuyangcuo Lake">拉姆央措湖</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/6.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Lamuyangcuo+Lake/@27.8555797,99.70209,17z/data=!3m1!4b1!4m5!3m4!1s0x3721ba191bb4a1ad:0x7ed6b5923b74b6ae!8m2!3d27.8553125!4d99.7044581">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">拉姆央措湖</span>
                                </a>

                                
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal6">介绍</a>
                        <div id="modal6" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">拉姆央措湖 - 介绍</h4>
                            <p>拉姆央措湖面不大，但在藏区名声很大，景色秀美异常，湖中四季有各种水鸟生息，黑颈鹤、黄鸭等水鸟随处可见。站在扎仓大殿顶上观望拉姆央措湖，它是一个佛自然的投影。
                            <br/><br/>
天气晴朗时，水清如镜，湖山相映。时而天空会乌云密布，无风起浪，湖水会发出奇特的声响，还会出现各种奇妙景象。据说信徒们同时观看湖景，所见景象却各异，有的还能从湖底影像中看到自己的前世今生。
<br/><br/>
拉姆央措湖中的玛尼堆有它特殊的意义，藏语称之为“崩登”它与对面的山起着相辅相成的作用，对面山上的平台是专供寺院里的僧人圆寂后进行天葬的地方，在进行天葬时，僧人们便到此小岛上的玛尼堆边对死者进行诵经超渡仪式，因此一般情况下是不允许任何人上岛的。</p>

                            <p className="grey-text">Lamuyangcuo Lake is not big, but it is very famous in Tibetan areas, and the scenery is unusually beautiful. There are various water birds in the lake during the four seasons. Black-necked cranes and yellow ducks can be seen everywhere. Standing on the top of Dachang Hall and watching Ramyangcuo Lake, it is a natural projection of Buddha.
                            <br/><br/>
When the weather is clear, the water is as clear as a mirror, and the mountains and lakes mirror each other. Sometimes the sky will be covered with dark clouds, without wind and waves. The lake water will make peculiar sounds and various wonderful scenes will appear. It is said that believers watch the lake scene at the same time, but they see different scenes, and some can see their past and present lives in the images of the lake bottom.
<br/><br/>
The Mani pile in Lamuyangcuo Lake has its special meaning. It is called "Bengdeng" in Tibetan. It complements the opposite mountain. The platform on the opposite mountain is exclusively for monks in the monastery after their death. At the celestial burial place, during the celestial burial, the monks would go to the Mani pile on this small island to chant and transcend the deceased. Therefore, under normal circumstances, no one is allowed to go to the island.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%8B%89%E5%A7%86%E5%A4%AE%E6%8E%AA%E6%B9%96/17404117?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2018/06/06</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/13.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>就在松赞林寺的旁边</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/14.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p></p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/15.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>可以看到整个松赞林寺</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/16.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>晚上吃牦牛火锅。</p>
                                </div>
                            </div>



                        </div>
                        
                    </div>

                    <div className="titles" id="title7">
                        <h4 className="tooltipped title" data-tooltip="Napa Sea">纳帕海</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/7.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/%E7%BA%B3%E5%B8%95%E6%B5%B7/@27.8900353,99.6513433,17z/data=!4m8!1m2!2m1!1z57qz5biV5rW3!3m4!1s0x3721b9407dfc8ee9:0x1e27cbcb9ff0209c!8m2!3d27.889698!4d99.65366">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">纳帕海</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal7">介绍</a>
                        <div id="modal7" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">纳帕海 - 介绍</h4>
                            <p>纳帕海位于云南省迪庆藏族自治州香格里拉县，建立于1984年，总面积31.25平方公里，海拔3266米，湖泊积水面积660平方公里。 
<br/><br/>
属湿地生态类型保护区，主要保护对象为高原季节性湖泊、沼泽草甸，是黑颈鹤等候鸟越冬栖息地。这里是中甸县最大的草原，也是最富于高原特色的风景区之一。由于藏民的悉心爱护，每当秋季来临，许多飞禽便光顾这里，如黑颈鹤、黄鸭、斑头雁在草原上空高飞低旋，情景颇惹心喜爱。</p>

                            <p className="grey-text">Napa Sea is located in Shangri-La County, Diqing Tibetan Autonomous Prefecture, Yunnan Province. It was established in 1984 and has a total area of 31.25 square kilometers, an elevation of 3266 meters, and a lake area of 660 square kilometers.
                            <br/><br/>
It is a wetland ecological type reserve. The main protection objects are plateau seasonal lakes and swamp meadows. It is the habitat of black-necked cranes waiting for birds to overwinter. This is the largest grassland in Zhongdian County and one of the scenic spots with the most plateau characteristics. Thanks to the care of the Tibetans, when autumn comes, many birds visit here, such as black-necked cranes, yellow ducks, and bar-headed geese flying high and low over the grassland. The scene is quite lovely.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E7%BA%B3%E5%B8%95%E6%B5%B7%E8%87%AA%E7%84%B6%E4%BF%9D%E6%8A%A4%E5%8C%BA/1409256?fromtitle=%E7%BA%B3%E5%B8%95%E6%B5%B7&fromid=1138432&fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                        <div className="titleContent">
                            <h5>2018/06/07</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/17.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p></p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/18.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>纳帕海</p>
                                </div>
                            </div>



                        </div>
                  
                        
                    </div>

                    <div className="titles" id="title8">
                        <h4 className="tooltipped title" data-tooltip="Dukezong Ancient City">独克宗古城</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/8.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/%E9%A6%99%E6%A0%BC%E9%87%8C%E6%8B%89%E5%8E%BF%E7%8B%AC%E5%85%8B%E5%AE%97%E5%8F%A4%E5%9F%8E/@27.8123867,99.7025733,17z/data=!3m1!4b1!4m5!3m4!1s0x3721ba8bacac6789:0x517be1756e2cf1f7!8m2!3d27.812382!4d99.704762">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">独克宗古城</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal8">介绍</a>
                        <div id="modal8" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">独克宗古城 - 介绍</h4>
                            <p>独克宗也称为月光之城，位于独克宗古城北门，是独克宗花巷，由雪松文旅集团打造，整合藏区非遗体验、藏文化风情街、第5颗陨石系列酒店、《遇见·香格里拉》秀等，提供藏文化体验。 
                            <br/><br/>
这是座被藏人称作“独克宗”的古城，它是按照佛经中的香巴拉理想国建成的。古城依山势而建，路面起伏不平，那是一些岁月久远的旧石头就着自然地势铺成的，至今，石板路上还留着深深的马蹄印，那是当年的马帮给时间留下的信物了。</p>

                            <p className="grey-text">Dukezong, also known as the City of Moonlight, is located at the north gate of the ancient city of Dukezong. It is the Flower Alley of Dukezong. It was built by Cedar Cultural Tourism Group to integrate Tibetan intangible heritage experience, Tibetan cultural style street, and the fifth meteorite series hotel , "Meet Shangri-La" show, etc., to provide Tibetan cultural experience.
                            <br/><br/>
This is an ancient city called "Dukezong" by Tibetans. It was built in accordance with the Shambhala Utopia in Buddhist scriptures. The ancient city was built on the hillside, the road was undulating, it was some old stone that was paved with the natural topography. So far, there are still deep horseshoe prints on the stone roads, which are left by the horse caravan in those days. Token.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E7%8B%AC%E5%85%8B%E5%AE%97/95288?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2018/06/07</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/19.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>红军博物馆</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/20.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>龟山公园</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/21.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>大佛寺转经筒</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/22.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>晚上会有很多人在这个广场围一圈跳舞。</p>
                                </div>
                            </div>



                        </div>
                        
                    </div>

                    <div className="titles" id="title9">
                        <h4 className="tooltipped title" data-tooltip="Xiaozhongdian Flower Sea">小中甸花海</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/9.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Xiaozhongdianzhen,+Shangri-La+City,+Diqing+Tibetan+Autonomous+Prefecture,+Yunnan,+China/@27.5532353,99.8040642,15z/data=!3m1!4b1!4m8!1m2!2m1!1z5LqR5Y2X55yB6L-q5bqG6JeP5peP6Ieq5rK75bee6aaZ5qC86YeM5ouJ5Y6_5bu65aGY6ZWH5bCP5Lit55S45p2R!3m4!1s0x372100caac23f8ab:0x74b18b93ddd1969c!8m2!3d27.553217!4d99.812819">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">小中甸花海</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal9">介绍</a>
                        <div id="modal9" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">小中甸花海 - 介绍</h4>
                            <p>小中甸是丽江前往香格里拉必经的一个小镇，位于香格里拉县城南面，G214国道旁，地处高寒坝区，有着大片的茂盛草场。而每月5月下旬到6月中旬，这里则成了一片花海，多彩绚烂的杜鹃花、狼毒花、格桑花，还有各种不知名的小花小草，引得游人赞叹不已。同时，也是选择拍写真照婚纱照的绝好场地。而到了9、10月份的秋天，这里就成了狼毒花的天下，大朵大朵火红眼里的狼毒花，异常绚丽。</p>

                            <p className="grey-text">Xiaozhongdian is a small town that Lijiang must pass through to Shangri-La. It is located in the south of Shangri-La County, next to the G214 National Highway, in the high-cold dam area, with large lush pastures. And from late May to mid-June every month, here is a sea of flowers, colorful and gorgeous azaleas, wolfberry flowers, Gesang flowers, and various unknown flowers and grasses, attracting visitors' admiration. At the same time, it is also a great place to choose photo and wedding photos. And in the autumn of September and October, this place became the world of chamomile flowers. The big flowers of chamomile flowers in the red eyes were extremely gorgeous.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%B0%8F%E4%B8%AD%E7%94%B8%E8%8A%B1%E6%B5%B7/17404470?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        {/* <div className="titleContent">




                        </div> */}
                        
                    </div>

                    <div className="titles" id="title10">
                        <h4 className="tooltipped title" data-tooltip="Tiger Leaping Gorge">虎跳峡</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/10.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Tiger+Leaping+Gorge/@27.1893948,100.1116893,17z/data=!3m1!4b1!4m5!3m4!1s0x3720e825fc6ccfa9:0xb8609916f2ea8cca!8m2!3d27.18939!4d100.113878">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">虎跳峡</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal10">介绍</a>
                        <div id="modal10" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">虎跳峡 - 介绍</h4>
                            <p>虎跳峡是金沙江（长江）上的第一大峡谷，更是全球著名的大峡谷，虎跳峡位于香格里拉东南部，距香格里拉市区105公里，距长江第一湾35公里，距离丽江88公里，分为上虎跳、中虎跳、下虎跳3段，是香格里拉虎跳峡经营有限公司的主要运营旅游线路之一。峡内礁石林立，有险滩21处，高达10来米的跌坎7处，瀑布10条。长江江水在这里被玉龙、哈巴两大雪山所挟峙，海拔高差3900多米，峡谷之深，位居世界前列，最窄处，仅约30余米，相传猛虎下山，在江中的礁石上稍抬脚，便可腾空越过，故称虎跳峡。</p>
                            <p className="grey-text">Tiger Leaping Gorge is the largest canyon on the Jinsha River (Yangtze River) and a world-famous grand canyon. Tiger Leaping Gorge is located in the southeast of Shangri-La, 105 kilometers away from downtown Shangri-La, 35 kilometers away from the first bay of the Yangtze River, and 88 kilometers away from Lijiang. It is divided into three sections: Upper Tiger Jump, Middle Tiger Jump, and Lower Tiger Jump. It is one of the main tourist routes operated by Shangri-La Tiger Leaping Gorge Management Co., Ltd. There are many reefs in the gorge. There are 21 dangerous beaches, 7 ridges up to 10 meters high, and 10 waterfalls. The water of the Yangtze River is held here by the Yulong and Haba snow mountains. The altitude difference is more than 3900 meters. The depth of the canyon is among the highest in the world. The narrowest point is only about 30 meters. According to legend, the tiger descends on the reef in the river. With a little lift, you can fly over it, so it is called Tiger Leaping Gorge.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E8%99%8E%E8%B7%B3%E5%B3%A1%E6%99%AF%E5%8C%BA/6900936?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2018/06/08</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/23.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>包车从香格里拉到虎跳峡，¥400.</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/24.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p></p>
                                </div>
                            </div>



                        </div>
                        
                    </div>

                    <div className="titles" id="title11">
                        <h4 className="tooltipped title" data-tooltip="Old Town of Lijiang">丽江古城</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/11.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Old+Town+of+Lijiang/@26.8865053,100.0040798,10z/data=!4m8!1m2!2m1!1z5Li95rGf5Y-k5Z-O!3m4!1s0x3720b584064c0c01:0x2ca3f44972f75fb5!8m2!3d26.8718026!4d100.2358502">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">丽江古城</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal11">介绍</a>
                        <div id="modal11" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">丽江古城 - 介绍</h4>
                            <p>丽江古城位于云南省丽江市古城区，又名大研镇，坐落在丽江坝中部，始建于宋末元初（公元13世纪后期），地处云贵高原，面积为7.279平方公里。 
                            <br/>
<br/>
丽江古城内的街道依山傍水修建，以红色角砾岩铺就，有四方街、木府、五凤楼、黑龙潭、文昌宫、王丕震纪念馆、雪山书院、王家庄基督教堂、方国瑜故居、白马龙潭寺、顾彼得旧居、净莲寺、普贤寺、接风楼、十月文学馆、红军长征过丽江指挥部纪念馆、丽江古城历史文化展示馆、丽江古城徐霞客纪念馆等景点。丽江为第二批被批准的中国历史文化名城之一，是中国以整座古城申报世界文化遗产获得成功的两座古城之一。丽江古城体现了中国古代城市建设的成就，是中国民居中具有鲜明特色和风格的类型之一。</p>

                            <p className="grey-text">The Old Town of Lijiang is located in the ancient city of Lijiang City, Yunnan Province, also known as Dayan Town. It is located in the middle of Lijiang Dam. It was built in the late Song Dynasty and early Yuan Dynasty (late 13th century AD). It is located on the Yunnan-Guizhou Plateau and covers an area of 7.279 square kilometers.
                            <br/>
<br/>
The streets in the ancient city of Lijiang are built with mountains and rivers, paved with red breccia, including Sifang Street, Mufu, Wufeng Tower, Black Dragon Pool, Wenchang Palace, Wang Pizhen Memorial Hall, Xueshan Academy, Wangjiazhuang Christian Church, Fang Guoyu’s Former Residence, Baima Longtan Temple, Gu Peter’s Former Residence, Jinglian Temple, Puxian Temple, Jiefeng Building, October Literature Museum, Red Army Long March Lijiang Command Memorial Hall, Lijiang Ancient City History and Culture Exhibition Hall, Lijiang Ancient City Xu Xiake Memorial Hall and other attractions. Lijiang is one of the second batch of approved Chinese historical and cultural cities. It is one of the two ancient cities in China that has successfully declared the entire ancient city as a world cultural heritage. The ancient city of Lijiang embodies the achievements of ancient Chinese urban construction, and is one of the types with distinctive characteristics and styles of Chinese citizens.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%B8%BD%E6%B1%9F%E5%8F%A4%E5%9F%8E/304665?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2018/06/08</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/25.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>晚饭在古城边上的阿婆腊排骨吃的，味道还可以。</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/26.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>吃完饭来逛古城。</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/27.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p></p>
                                </div>
                            </div>



                        </div>
                        
                    </div>

                    <div className="titles" id="title12">
                        <h4 className="tooltipped title" data-tooltip="Lugu Lake">泸沽湖</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/12.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Lugu+Lake/@27.7029267,100.7561587,13z/data=!3m1!4b1!4m5!3m4!1s0x36df8536db0d65b7:0xfe307591753e18e8!8m2!3d27.7244646!4d100.7887231">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">泸沽湖</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal12">介绍</a>
                        <div id="modal12" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">泸沽湖 - 介绍</h4>
                            <p>泸沽湖，位于四川省盐源县与云南省宁蒗县交界处，为川滇共辖，湖东为盐源县泸沽湖镇（原左所区），湖西为宁蒗县永宁乡。湖泊略呈北西一东南走向，湖泊面积50.1平方公里，湖水库容量为22.52亿立方米。 
                            <br/><br/>
泸沽湖属高原断层溶蚀陷落湖泊，属长江上游干流金沙江支流雅砻江支流理塘河水系。是云南省海拔最高的湖泊，是中国第三大深水湖泊。泸沽湖周边主要居住着摩梭人、彝族和普米族，沿岸居住有蒙古族7种民族，约1.3万人。优美的自然环境和奇特的民族风情，使这里成为著名的旅游景区。</p>

                            <p className="grey-text">Lugu Lake is located at the junction of Yanyuan County, Sichuan Province and Ninglang County, Yunnan Province. It is under the jurisdiction of Sichuan and Yunnan. The east of the lake is Luguhu Town of Yanyuan County (formerly Zuosuo District), and the west of Lake is Yongning Township of Ninglang County. The lake is slightly northwest to southeast, with an area of 50.1 square kilometers and a reservoir capacity of 2.252 billion cubic meters.
                            <br/><br/>
Lugu Lake is a plateau fault-corroded and subsided lake, and belongs to the Litang River system, a tributary of the Jinsha River, a tributary of the Jinsha River in the upper reaches of the Yangtze River. It is the highest lake in Yunnan Province and the third largest deep-water lake in China. The surrounding areas of Lugu Lake are mainly inhabited by the Mosuo, Yi and Pumi peoples. There are 7 ethnic groups of Mongolians and about 13,000 people living along the coast. The beautiful natural environment and peculiar ethnic customs make it a famous tourist attraction.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%B3%B8%E6%B2%BD%E6%B9%96/396555?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2018/06/09</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/28.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>一早从丽江古城来到泸沽湖。</p>
                                    <p>来回路程遥远，所以第二天再回。</p>
                                    <p>客栈就在湖边，景色还可以。</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/29.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p></p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/30.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p></p>
                                </div>
                            </div>



                        </div>
                        
                    </div>

                    <div className="titles" id="title13">
                        <h4 className="tooltipped title" data-tooltip="Campfire">篝火晚会</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/13.png" alt="" className="responsive-img"></img>
                                {/* <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Huayan+Temple/@40.0905141,113.3125383,17z/data=!3m1!4b1!4m5!3m4!1s0x35e2d45f1ae0f0a1:0xf6817c1718ad8a84!8m2!3d40.09051!4d113.314727">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">篝火晚会</span>
                                </a> */}
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        {/* <a className="btn-flat modal-trigger right info " href="#modal13">介绍</a>
                        <div id="modal13" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">篝火晚会 - 介绍</h4>
                            <p></p>

                            <p className="grey-text"></p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%8D%8E%E4%B8%A5%E5%AF%BA/2285867?fr=aladdin">
                              
                                <span className="blue-text">[Source From...]</span>
                            </a>      
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div> */}

                        </div>
                            </div>
                        </div>

                  
                        {/* <div className="titleContent">




                        </div> */}
                        
                    </div>

                    <div className="titles" id="title14">
                        <h4 className="tooltipped title" data-tooltip="Lugu Lake">泸沽湖</h4>
                        
                        <div className="row">
                        <div className="col s12 m12 l5">
                                <img src="../img27/14.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Lugu+Lake/@27.7029267,100.7561587,13z/data=!3m1!4b1!4m5!3m4!1s0x36df8536db0d65b7:0xfe307591753e18e8!8m2!3d27.7244646!4d100.7887231">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">泸沽湖</span>
                                </a>
                            </div>

                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal14">介绍</a>
                        <div id="modal14" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">泸沽湖 - 介绍</h4>
                            <p>泸沽湖，位于四川省盐源县与云南省宁蒗县交界处，为川滇共辖，湖东为盐源县泸沽湖镇（原左所区），湖西为宁蒗县永宁乡。湖泊略呈北西一东南走向，湖泊面积50.1平方公里，湖水库容量为22.52亿立方米。 
                            <br/><br/>
泸沽湖属高原断层溶蚀陷落湖泊，属长江上游干流金沙江支流雅砻江支流理塘河水系。是云南省海拔最高的湖泊，是中国第三大深水湖泊。泸沽湖周边主要居住着摩梭人、彝族和普米族，沿岸居住有蒙古族7种民族，约1.3万人。优美的自然环境和奇特的民族风情，使这里成为著名的旅游景区。</p>

                            <p className="grey-text">Lugu Lake is located at the junction of Yanyuan County, Sichuan Province and Ninglang County, Yunnan Province. It is under the jurisdiction of Sichuan and Yunnan. The east of the lake is Luguhu Town of Yanyuan County (formerly Zuosuo District), and the west of Lake is Yongning Township of Ninglang County. The lake is slightly northwest to southeast, with an area of 50.1 square kilometers and a reservoir capacity of 2.252 billion cubic meters.
                            <br/><br/>
Lugu Lake is a plateau fault-corroded and subsided lake, and belongs to the Litang River system, a tributary of the Jinsha River, a tributary of the Jinsha River in the upper reaches of the Yangtze River. It is the highest lake in Yunnan Province and the third largest deep-water lake in China. The surrounding areas of Lugu Lake are mainly inhabited by the Mosuo, Yi and Pumi peoples. There are 7 ethnic groups of Mongolians and about 13,000 people living along the coast. The beautiful natural environment and peculiar ethnic customs make it a famous tourist attraction.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%B3%B8%E6%B2%BD%E6%B9%96/396555?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2018/06/10</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/31.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>5 2 1</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/32.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p></p>
                                </div>
                            </div>



                        </div>
                        
                    </div>

                    <div className="titles" id="title15">
                        <h4 className="tooltipped title" data-tooltip="Lijiang Eternal Love Tourist Area">丽江千古情</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/15.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/%E4%B8%BD%E6%B1%9F%E5%AE%8B%E5%9F%8E%E6%97%85%E6%B8%B8%E5%8C%BA/@26.8281325,100.2200684,17z/data=!3m1!4b1!4m5!3m4!1s0x3720cae717b59ed1:0xdb5c69f371b7769c!8m2!3d26.8281277!4d100.2222571">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">丽江千古情</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal15">介绍</a>
                        <div id="modal15" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">丽江千古情 - 介绍</h4>
                            <p>《丽江千古情》是丽江千古情景区的核心产品。丽江千古情景区由中国第一家演艺上市公司——宋城演艺倾力打造。景区距丽江古城6公里，毗邻文笔海自然景观，与玉龙雪山遥相呼应，地理环境优越，以丽江民族地域文化为主题，以大型歌舞《丽江千古情》为核心内容，展现了丽江千年文化和历史风情。景区主要包括茶马古街、那措海、雪山沙滩、民族村落、千古情广场、大型室内科技游乐综合体等多个主题区域。</p>

                            <p className="grey-text">"Lijiang Eternal Love" is the core product of Lijiang Eternal Scene Zone. The Lijiang Eternal Scenery Zone was created by Songcheng Performing Arts, China's first listed performing arts company. The scenic spot is 6 kilometers away from the ancient city of Lijiang, adjacent to the natural landscape of Wenbihai, and echoes the Jade Dragon Snow Mountain. The geographical environment is superior. With the theme of Lijiang ethnic and regional culture, the large-scale singing and dancing "Lijiang Eternal Love" is the core content, showing the thousand-year culture and historical customs of Lijiang . The scenic spot mainly includes multiple themed areas such as Tea Horse Street, Nacuo Sea, Snow Mountain Beach, Ethnic Village, Eternal Love Plaza, and a large indoor technology amusement complex.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%B8%BD%E6%B1%9F%E5%8D%83%E5%8F%A4%E6%83%85/13207973?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                        <div className="titleContent">
                            <h5>2018/06/10</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/33.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>《丽江千古情》</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/34.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>放灯</p>
                                </div>
                            </div>
                        


                        </div>
                        
                    </div>

                    <div className="titles" id="title16">
                        <h4 className="tooltipped title" data-tooltip="Dali Ancient City">大理古城</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/16.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/%E5%A4%A7%E7%90%86%E5%8F%A4%E5%9F%8E%E4%B8%9C%E9%97%A8/@25.6983914,100.1648829,17z/data=!4m13!1m7!3m6!1s0x3727bf4d2ce84a8d:0xf05686789efce876!2s42+Yita+Rd,+Dali+Shi,+Dali+Baizuzizhizhou,+Yunnan+Sheng,+China,+671003!3b1!8m2!3d25.699334!4d100.170419!3m4!1s0x3727bf4cfc798363:0x982269d4a6083e0a!8m2!3d25.7000612!4d100.1726197">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">大理古城</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal16">介绍</a>
                        <div id="modal16" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">大理古城 - 介绍</h4>
                            <p>大理古城位于云南省西部，又名叶榆城、紫城。古城其历史可追溯至唐天宝年间，南诏王阁逻凤筑的羊苴咩城，为其新都。古城始建于明洪武十五年（1382年），占地面积3平方公里。 
<br/><br/>
大理为1982年2月8日国务院公布的中国首批24个历史文化名城之一。
<br/><br/>
大理古城在唐、宋500多年的历史间是云南的政治、经济、文化的中心，在古城内分布了14处市级以上的重点文物保护单位，承载着大理历史文化、宗教文化、民族文化，是大理旅游核心区。</p>

                            <p className="grey-text">The ancient city of Dali is located in the west of Yunnan Province, also known as Yeyucheng and Zicheng. The history of the ancient city can be traced back to the Tianbao period of the Tang Dynasty. The city of Yangjumei built by the Luofeng of the Nanzhao King Pavilion is the new capital. The ancient city was built in the fifteenth year of Ming Hongwu (1382), covering an area of 3 square kilometers.
                            <br/><br/>
Dali is one of the first 24 historical and cultural cities in China announced by the State Council on February 8, 1982.
<br/><br/>
The ancient city of Dali was the political, economic, and cultural center of Yunnan during the 500-year history of the Tang and Song Dynasties. There are 14 key cultural relics protection units at the city level and above in the ancient city, which carry Dali’s historical culture, religious culture, and ethnic culture. It is the core tourist area of Dali.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%A4%A7%E7%90%86%E5%8F%A4%E5%9F%8E/1874828?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2018/06/11</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/35.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>上午从丽江坐火车到大理</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/36.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>晚上的大理古城</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/37.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>小型灯展</p>
                                </div>
                            </div>

                        
                        </div>
                        
                    </div>

                    <div className="titles" id="title17">
                        <h4 className="tooltipped title" data-tooltip="Erhai Lake">洱海</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/17.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Erhai+Lake/@25.7792419,100.0540452,11z/data=!3m1!4b1!4m5!3m4!1s0x3727bf6d59732bf3:0xb4288374da6970b5!8m2!3d25.8006596!4d100.1927389">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">洱海</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal17">介绍</a>
                        <div id="modal17" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">洱海 - 介绍</h4>
                            <p>洱海，古称昆明池、洱河、叶榆泽等。位于云南省大理白族自治州大理市。一般湖水面积约246平方千米（一说251平方公里），蓄水量约29.5亿立方米，呈狭长形，北起洱源县南端，南止大理市下关，南北长40公里，是仅次于滇池的云南第二大湖，中国淡水湖中居第7位。洱海形成于冰河时代末期，其成因主要是沉降侵蚀，属高原构造断陷湖泊，海拔1972米。
<br/><br/>
洱海，属澜沧江流域，系其支流漾濞江支流西洱河上源。湖水由西洱河流经大理市区下关，向西汇入漾濞江。
<br/><br/>
洱海具有供水、农灌、发电、调节气候、渔业、航运、旅游七大主要功能，洱海西面有点苍山横列如屏，东面有玉案山环绕衬托，空间环境优美。</p>

                            <p className="grey-text">Erhai Lake was called Kunming Pool, Erhe River and Yeyuze in ancient times. Located in Dali City, Dali Bai Autonomous Prefecture, Yunnan Province. The general lake area is about 246 square kilometers (251 square kilometers), with a water storage capacity of about 2.95 billion cubic meters. It is long and narrow. It starts from the southern tip of Eryuan County in the north and ends at Xiaguan, Dali City in the south. Yunnan's second largest lake after Dianchi Lake, ranks seventh among freshwater lakes in China. Erhai Lake was formed at the end of the Ice Age. Its main cause is subsidence and erosion. It belongs to a plateau tectonic faulted lake with an altitude of 1972 meters.
                            <br/><br/>
Erhai Lake belongs to the basin of the Lancang River and is the source of the Xi'er River, a tributary of the Yangbi River. The lake water flows from Xi'er River through Xiaguan in downtown Dali and flows westward into Yangbi River.
<br/><br/>
The Erhai Lake has seven main functions: water supply, agricultural irrigation, power generation, climate regulation, fishery, shipping, and tourism. On the west side of the Erhai Lake, there are Cangshan mountains lined up like a screen, and the east side is surrounded by Yuanshan Mountain.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%B4%B1%E6%B5%B7/19448368?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2018/06/12</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/38.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p></p>
                                </div>
                            </div>



                        </div>
                        
                    </div>

                    <div className="titles" id="title18">
                        <h4 className="tooltipped title" data-tooltip="Jiang Gong Ci">蒋公祠</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/18.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/%E8%92%8B%E5%85%AC%E7%A5%A0/@25.6958498,100.1585293,17z/data=!3m1!4b1!4m5!3m4!1s0x3727b8afbef357cb:0xe054c016921be9c6!8m2!3d25.695845!4d100.160718">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">蒋公祠</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal18">介绍</a>
                        <div id="modal18" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">蒋公祠 - 介绍</h4>
                            <p>蒋公祠位于大理古城玉洱路123号，是祭祀清代将领蒋宗汉的祠堂，是古城内保存最完好、最具白族建筑特色的清代祠堂建筑群，大理非物质文化遗产博物馆在“蒋公祠”基础上改扩建而成。</p>

                            <p className="grey-text">Jiang Gong Ci is located at No. 123 Yu'er Road in the ancient city of Dali. It is an ancestral hall dedicated to Jiang Zonghan, the general of the Qing Dynasty. The temple was rebuilt and expanded.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E8%92%8B%E5%85%AC%E7%A5%A0/5418422?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        {/* <div className="titleContent">




                        </div> */}
                        
                    </div>

                    <div className="titles" id="title19">
                        <h4 className="tooltipped title" data-tooltip="Yu'er Garden">玉洱园</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/19.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Yu'eryuan/@25.6965647,100.1589296,17z/data=!4m8!1m2!2m1!1z546J5rSx5Zut!3m4!1s0x3727b8afc81678ab:0xd67a4301dd2f9dc9!8m2!3d25.6960637!4d100.1613356">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">玉洱园</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal19">介绍</a>
                        <div id="modal19" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">玉洱园 - 介绍</h4>
                            <p>大理玉洱园集白族建筑的精华，外部为飞檐翘角的白族门楼和典雅精巧的三方照壁，以及檐裙彩画的围墙。园内以石块镶砌的多形状的白族花台为主体，栽种了大理的名花异卉，间有老人活动区、茶室及碑亭、石坊、水池点缀，形成富有大理古城传统花园的浓郁风趣。大理以大理石而闻名和以原始大石崇拜而盛行，因此园内修饰装点也以石为主，有大石庵、美人石、石牛、石马井等景点，园内有石砌短墙、石铺路面、石堆灯座等，整座花园追求回归大自然的风格，独具特点。园内设茶花、樱花、牡丹、杜鹃、梅花、月季、竹园等七处花木区，并请文人书法画家题字和作画，以诗、联、佳句装点。
                            <br/><br/>
为弘扬大理的文化传统美德，大理市于1995年3月建成恢复“志丹公园”，易名为“玉洱园”。花园落成开放之际，著名白族史学家马耀为玉洱园撰写了《玉洱园碑记》，记述建园始末。</p>

                            <p className="grey-text">Dali Yu'er Garden is the essence of Bai architecture. The exterior is the Bai gate tower with raised eaves, the elegant and exquisite tripartite wall, as well as the enclosing wall of eaves skirt color painting. The garden is dominated by the multi-shaped Bai flower stand built with stones, planted with Dali's famous flowers and flowers. There are activities areas for the elderly, tea rooms and stele pavilions, stone workshops, and ponds, forming a rich traditional garden in Dali. Funny. Dali is famous for marble and is popular for worshiping primitive large stones. Therefore, the decoration in the garden is also mainly stone. There are scenic spots such as Dashi Temple, Beauty Stone, Stone Niu, and Shimajing. There are short stone walls and stone shops in the park. The pavement, stone pile lamp holders, etc., the entire garden pursues the style of returning to nature, with unique characteristics. Seven flower and tree areas including camellia, cherry blossoms, peonies, rhododendrons, plum blossoms, roses, and bamboo gardens are set up in the garden. Literati calligraphers and painters are invited to write inscriptions and paintings, and decorate them with poems, couplets and beautiful lines.
                            <br/><br/>
In order to promote the traditional virtues of Dali, Dali City completed and restored the "Zhidan Park" in March 1995 and was renamed "Yu'er Park". When the garden was completed and opened, Ma Yao, a well-known Bai historian, wrote "The Stele of Yu'er Garden" for Yu'er Garden, describing the beginning and the end of the garden.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%A4%A7%E7%90%86%E7%8E%89%E6%B4%B1%E5%9B%AD/4752114?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2018/06/12</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/39.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p></p>
                                </div>
                            </div>



                        </div>
                        
                    </div>

                    <div className="titles" id="title20">
                        <h4 className="tooltipped title" data-tooltip="Dajin Pagoda">西双版纳大金塔</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/20.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Dajin+Pagoda/@22.0077062,100.8007761,15z/data=!4m8!1m2!2m1!1z6KW_5Y-M54mI57qzIOWkp-mHkeWhlA!3m4!1s0x312abb3ce33a24c5:0xdd4b242b325b3e2d!8m2!3d22.008008!4d100.819512">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">西双版纳大金塔</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        {/* <a className="btn-flat modal-trigger right info " href="#modal20">介绍</a>
                        <div id="modal20" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">大金塔 - 介绍</h4>
                            <p>潍坊风筝广场位于潍坊市中心，是一个集人防工事、商贸购物、休闲娱乐等多功能于一体，凸现“环保特色”、“人文特色”和“风筝文化特色”的市民活动广场。总占地面积约9公顷，其中一期上部广场面积约48000平方米，下部滨河景观区面积约9500平方米，计57500平方米。广场地上景观将建设吉祥物大道、中心广场、滨河景观区、露天剧场与码头，国贸阶梯广场、四平路出口广场、风筝博物馆入口广场、儿童游戏场，植物公园、树阵等十大景观。</p>

                            <p className="grey-text">Weifang Kite Plaza is located in the center of Weifang. It is a civic activity plaza that integrates civil air defense fortifications, commerce and shopping, leisure and entertainment, and highlights the "environmental protection", "humanistic characteristics" and "kite cultural characteristics". The total area is about 9 hectares, of which the upper square area of the first phase is about 48,000 square meters, and the lower riverside landscape area is about 9,500 square meters, totaling 57,500 square meters. The above-ground landscape of the square will be built with ten major landscapes including Mascot Avenue, Central Square, Riverside Scenic Area, Amphitheatre and Wharf, China World Trade Center Ladder Plaza, Siping Road Exit Plaza, Kite Museum Entrance Plaza, Children's Playground, Botanical Park, and Tree Array.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E9%A3%8E%E7%AD%9D%E5%B9%BF%E5%9C%BA/5783462?fr=aladdin">
          
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div> */}

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2018/06/12</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/40.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>下午从丽江飞往西双版纳，晚上吃的傣味烧烤，然后逛大金塔夜市。</p>
                                </div>
                            </div>



                        </div>
                        
                    </div>

                    <div className="titles" id="title21">
                        <h4 className="tooltipped title" data-tooltip="Wangtian Tree Scenic Area">望天树景区</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/21.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/%E8%A5%BF%E5%8F%8C%E7%89%88%E7%BA%B3%E7%83%AD%E5%B8%A6%E9%9B%A8%E6%9E%97%E5%9B%BD%E5%AE%B6%E5%85%AC%E5%9B%AD%E6%9C%9B%E5%A4%A9%E6%A0%91%E6%99%AF%E5%8C%BA/@21.6224888,101.5883714,13.22z/data=!4m8!1m2!2m1!1z5pyb5aSp5qCR5pmv5Yy6!3m4!1s0x0:0x889aa601456a930e!8m2!3d21.6242095!4d101.5886557">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">望天树景区</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal21">介绍</a>
                        <div id="modal21" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">望天树景区 - 介绍</h4>
                            <p>望天树景区坐落在云南省西双版纳自治州勐腊县城东北方补蚌国家自然保护区内，占地面积864.4公倾，距县城十四公里。
                            <br/>
                            <br/>
景区内建有旅馆、空中走廊（含树冠走廊及玻璃廊桥）、南腊河观光游船、探险游道、丛林穿越等，既可游玩，也可住宿。如果你到望天树景区游玩，一定要去“空中走廊”体验一下那种令人既惊又险的感觉。走廊长500米，是用粗大的铁索在高大的望天树之间连接建成，用钢绳悬吊，尼龙绳、网作护栏，铝合金梯子作踏板，每一段均与修在树干上的木质平台相连接。西双版纳热带雨林国家公园望天树景区位于中国云南省西双版纳州的勐腊县城东北面，距离县城14km，总占地面积864.4公顷，是西双版纳的重点旅游区，是昆（明）曼（谷）高速公路和泛亚铁路上出境的最后节点，距国家一类口岸磨憨60km，距景洪市134km，距离老挝南塔省城120km，距著名世界文化遗产古城琅勃拉邦370km。</p>

                            <p className="grey-text">Wangtian Tree Scenic Area is located in Bubang National Nature Reserve, northeast of Mengla County, Xishuangbanna Autonomous Prefecture, Yunnan Province. It covers an area of ​​864.4 hectares and is 14 kilometers away from the county.
                            <br/>
                            <br/>
There are hotels, sky corridors (including canopy corridors and glass covered bridges), sightseeing cruises on the Nanla River, adventure trails, jungle crossings, etc., which can be used for play and accommodation. If you visit Wangtianshu Scenic Area, you must go to the "air corridor" to experience the thrilling and dangerous feeling. The corridor is 500 meters long. It is constructed by connecting the tall trees with thick iron ropes. It is suspended by steel ropes, nylon ropes and nets are used as guardrails, and aluminum alloy ladders are used as treads. Each section is connected to the wood fixed on the trunk. The platforms are connected. Xishuangbanna Tropical Rainforest National Park Wangtianshu Scenic Area is located in the northeast of Mengla County, Xishuangbanna Prefecture, Yunnan Province, China. It is 14km away from the county seat and covers an area of ​​864.4 hectares. It is a key tourist area in Xishuangbanna and is the Kun Ming-Bangkok Expressway It is 60km away from Mohan, a national first-class port, 134km away from Jinghong City, 120km away from the provincial capital of Nanta, Laos, and 370km away from Luang Prabang, a famous ancient world cultural heritage city.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%9C%9B%E5%A4%A9%E6%A0%91%E6%99%AF%E5%8C%BA/10528150?fr=aladdin">
                                <span className="blue-text">[Source From...]</span>
                            </a>   
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2018/06/13</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/41.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>报的望天树一日游。</p>
                                    <p>空中走廊</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/42.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>望天树</p>
                                </div>
                            </div>


                        </div>
                        
                    </div>

                    <div className="titles" id="title22">
                        <h4 className="tooltipped title" data-tooltip="Sipsongpanna Zongfosi">总佛寺</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/22.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Sipsongpanna+Zongfosi/@21.9884699,100.8036594,17z/data=!3m1!4b1!4m5!3m4!1s0x312abb4e090cbdfb:0xe171eaecc6d76d87!8m2!3d21.9884649!4d100.8058481">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">总佛寺</span>
                                </a>

                                
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal22">介绍</a>
                        <div id="modal22" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">总佛寺 - 介绍</h4>
                            <p>据景洪市曼斗村寨史资料记载，一千多年前，西双版纳勐泐王宫曾建在现今允景洪街道办曼阁社区曼斗村附近，当时该区域是西双版纳的重要政治区，就佛寺而言，此区域内建有洼宰、洼掌佩、洼龙勐、洼庄德等佛寺。后因战乱，洼宰、洼掌佩、洼龙勐佛寺迁移，只有洼庄德佛寺长期坚守为景洪江北（澜沧江北岸）佛教信众服务，直至后来景洪江北各傣族村寨建盖佛寺，洼庄德佛寺才逐渐衰落。现遗址内还保留有洼庄德佛寺大殿柱子的石墩。洼庄德佛寺是西双版纳傣泐信众的重要佛寺之一，是广大佛教信众恭敬供养佛、法、僧三宝，同时为祈福所管辖范围的黎民百姓得到佛法的护佑，祈求世间安泰，降福于百姓而修建的佛寺。</p>

                            <p className="grey-text">According to historical records of Mandou Village in Jinghong City, more than a thousand years ago, the Mengle Palace in Xishuangbanna was built near Mandou Village, Mange Community, Yunjinghong Street Office. At that time, the area was an important political area in Xishuangbanna, and it was just a Buddhist temple. In other words, there are Buddhist temples such as Wazai, Wazhangpei, Walongmeng, and Wazhuangde built in this area. Later, due to the war, Wazai, Wazhangpei, and Walongmeng Buddhist temples were moved. Only Wazhuang Defo Temple maintained a long-term service for Buddhist believers in the north of the Jinghong River (the northern bank of the Lancang River). Later, various Dai villages in the north of Jinghong River built Buddhist temples and Wazhuang. Defo Temple only gradually declined. The stone piers of the main hall of Wazhuang Defo Temple are still preserved in the site. Wazhuang De Buddhist Temple is one of the important Buddhist temples for the Dai people in Xishuangbanna. It is a Buddhist worshiper who respectfully offers the three treasures of Buddha, Dharma and Sangha. At the same time, it prays for the blessings of the Li people under the jurisdiction of the Li people to receive the blessings of the Buddha, pray for peace in the world, and bless the people. And the built Buddhist temple.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%99%AF%E6%B4%AA%E6%80%BB%E4%BD%9B%E5%AF%BA/8710977?fromtitle=%E6%80%BB%E4%BD%9B%E5%AF%BA&fromid=10675203&fr=aladdin">
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        {/* <div className="titleContent">



                        </div> */}
                        
                    </div>

                    <div className="titles" id="title23">
                        <h4 className="tooltipped title" data-tooltip="Manting Park">曼听公园</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/23.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Manting+Park/@21.992875,100.8044603,17z/data=!3m1!4b1!4m5!3m4!1s0x312abb4eb13bb367:0x84ce6a5d88ff418d!8m2!3d21.99287!4d100.806649">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">曼听公园</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal23">介绍</a>
                        <div id="modal23" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">曼听公园 - 介绍</h4>
                            <p>曼听公园位于西双版纳傣族自治州首府景洪市东南方，距城区约2公里，处于澜沧江与流沙河汇合的三角地带，占地面积400余亩。有保存完好的500多株古铁刀木林及植被，园内有山丘和河道，又有民族特色浓郁的人文景观，是个天然的村寨式公园，以前是傣王御花园，已有1300多年的历史。景区集中体现了“傣王室文化、佛教文化、傣民俗文化”三大主题特色。曼听公园是西双版纳最古老的公园，傣族习惯把她叫做“春欢”，意为“灵魂之园”，过去是西双版纳傣王的御花园，传说傣王妃来游玩时，这里的美丽景色吸引了王妃的灵魂，因而得名。</p>

                            <p className="grey-text">Manting Park is located in the southeast of Jinghong City, the capital of Xishuangbanna Dai Autonomous Prefecture, about 2 kilometers away from the urban area. It is located in the triangle area where the Lancang River and Liusha River converge, covering an area of more than 400 acres. There are more than 500 well-preserved ancient iron sword trees and vegetation. There are hills and rivers in the park, and there are rich ethnic cultural landscapes. It is a natural village-style park. It used to be the Royal Garden of the Dai King, which has been used for more than 1,300 years. history. The scenic spot embodies the three themes of "Dai royal culture, Buddhist culture, and Dai folk culture". Manting Park is the oldest park in Xishuangbanna. The Dai people used to call her "Chunhuan", which means "soul garden". It used to be the royal garden of the Dai King in Xishuangbanna. Legend has it that when the Dai Princess came to play, the beautiful scenery here attracted The soul of the princess, hence the name.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%9B%BC%E5%90%AC%E5%85%AC%E5%9B%AD/6598751?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>

                        </div>

                        <div className="titleContent">
                            <h5>2018/06/13</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/43.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p></p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/44.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>傣族宫廷舞</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/45.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p></p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/46.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>篝火晚会</p>
                                </div>
                            </div>


                        </div>
                        
                    </div>

                    <div className="titles" id="title24">
                        <h4 className="tooltipped title" data-tooltip="XiShuangBanNa Tropical Botanical Garden">中国科学院热带植物园</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/24.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/%E4%B8%AD%E5%9B%BD%E7%A7%91%E5%AD%A6%E9%99%A2%E8%A5%BF%E5%8F%8C%E7%89%88%E7%BA%B3%E7%83%AD%E5%B8%A6%E6%A4%8D%E7%89%A9%E5%9B%AD/@21.91993,101.2757583,17z/data=!3m1!4b1!4m5!3m4!1s0x312adf94c22bfc9f:0x4059ffbc7c519403!8m2!3d21.919925!4d101.277947">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">中国科学院热带植物园</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal24">介绍</a>
                        <div id="modal24" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">中国科学院热带植物园 - 介绍</h4>
                            <p>中国科学院西双版纳热带植物园，国家AAAAA级旅游景区，位于中国云南省西双版纳傣族自治州勐腊县勐仑镇葫芦岛，中国面积最大、收集物种最丰富、植物专类园区最多的植物园，也是集科学研究、物种保存和科普教育为一体的综合性研究机构和风景名胜区。
                            <br/><br/>
中国科学院西双版纳热带植物园前身为1959年蔡希陶教授领导创建的“西双版纳热带植物园”，经历数次重组、改隶后，1996年9月经中央机构编制委员会办公室批准，定名为中国科学院西双版纳热带植物园，隶属于中国科学院。</p>

                            <p className="grey-text">Xishuangbanna Tropical Botanical Garden of Chinese Academy of Sciences, a national AAAAA-level tourist attraction, is located in Huludao, Menglun Town, Mengla County, Xishuangbanna Dai Autonomous Prefecture, Yunnan Province, China. It is the largest botanical garden in China with the largest collection of species and the most plant-specific parks. It is also a collection of scientific research and species A comprehensive research institution and scenic spot integrating preservation and popular science education.
                            <br/><br/>
The Xishuangbanna Tropical Botanical Garden of the Chinese Academy of Sciences was formerly known as the "Xishuangbanna Tropical Botanical Garden" founded by Professor Cai Xitao in 1959. After several reorganizations and changes, it was named the Xishuangbanna Tropical Botanical Garden of the Chinese Academy of Sciences in September 1996 with the approval of the Office of the Central Organization Committee. Chinese Academy of Sciences.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%B8%AD%E5%9B%BD%E7%A7%91%E5%AD%A6%E9%99%A2%E8%A5%BF%E5%8F%8C%E7%89%88%E7%BA%B3%E7%83%AD%E5%B8%A6%E6%A4%8D%E7%89%A9%E5%9B%AD/3030034?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2018/06/14</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/47.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>棕榈园</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/48.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>百花园</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/49.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p></p>
                                </div>
                            </div>





                        </div>
                        
                    </div>

                    <div className="titles" id="title25">
                        <h4 className="tooltipped title" data-tooltip="Stone Forest Scenic Area">石林</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/25.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Stone+Forest+Scenic+Area/@23.4247468,101.1099819,8z/data=!4m8!1m2!2m1!1z55-z5p6X!3m4!1s0x36d010f723e3eaeb:0xcfe66b71c7b15b0f!8m2!3d24.817106!4d103.329199">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">石林</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal25">介绍</a>
                        <div id="modal25" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">石林 - 介绍</h4>
                            <p>昆明市石林风景区，又称为云南石林，位于昆明石林彝族自治县境内，距离云南省会昆明78公里。范围达350平方公里。
<br/><br/>
昆明市石林风景区开发为游览区的主要是：石林风景区、黑松岩（乃古石林）风景区、飞龙瀑（大叠水）风景区、长湖风景区。
<br/><br/>
昆明市石林风景区已被联合国文教科评为“世界地质公园”，“世界自然遗产风光”。1982年，经国务院批准成为首批国家级重点风景名胜区之一，是国家5A级旅游景区、全国文明风景旅游区。</p>

                            <p className="grey-text">Kunming Stone Forest Scenic Area, also known as Yunnan Stone Forest, is located in Kunming Shilin Yi Autonomous County, 78 kilometers away from Kunming, the capital of Yunnan Province. The area is 350 square kilometers.
                            <br/><br/>
The Shilin Scenic Area in Kunming City is developed into a tourist area mainly: Shilin Scenic Area, Heisongyan (Naigu Stone Forest) Scenic Area, Feilong Waterfall (Dadieshui) Scenic Area, and Changhu Scenic Area.
<br/><br/>
Kunming's Stone Forest Scenic Area has been appraised as "World Geological Park" and "World Natural Heritage Scenery" by the United Nations Educational and Cultural Sciences. In 1982, it was approved by the State Council to become one of the first batch of national key scenic spots. It is a national 5A-level tourist attraction and a national civilized scenic tourist area.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%98%86%E6%98%8E%E5%B8%82%E7%9F%B3%E6%9E%97%E9%A3%8E%E6%99%AF%E5%8C%BA/15516834?fromtitle=%E7%9F%B3%E6%9E%97&fromid=9709681&fr=aladdin">
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2018/06/15</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/50.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>报的石林九乡纯玩一日游。</p>
                                    <p>石林是地上的喀斯特地貌，九乡是地下的喀斯特地貌。</p>
                                    <p>断臂的鹰，不适合合影。</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/51.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>千钧一发</p>
                                    <p>上面的石头被地震震断，挂在上面。</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/52.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>登顶看全貌</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/53.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>它的名字叫阿诗玛</p>
                                    <p>因其形肖美丽传说中的阿诗玛，身着撒尼族的装束、肩背背篓，仰首望天，似在呼唤，又似期待，给传说中的阿诗玛，更增添了想像和期待。</p>
                                </div>
                            </div>



                        </div>
                        
                    </div>

                    <div className="titles" id="title26">
                        <h4 className="tooltipped title" data-tooltip="Yiliang Jiuxiang Scenic Area">九乡</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/26.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Yiliang+Jiuxiang+Scenic+Area/@25.0683728,103.3817063,17z/data=!3m1!4b1!4m5!3m4!1s0x36daa83dba305b3d:0x47099884c8c670db!8m2!3d25.068368!4d103.383895">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">九乡</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal26">介绍</a>
                        <div id="modal26" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">九乡 - 介绍</h4>
                            <p>宜良九乡风景区地处山区、气候温凉，区内峰峦连绵，山峰谷底相对高差200米左右，地表海拔在1750~1900米之间，地势起伏不大。显示出溶洞分布区内地势上升，河流下切浸蚀的青幼年期河流地貌景观。
                            <br/><br/>
九乡境内森林茂密，覆盖率达62.3%，珍贵动、植物资源丰富。张口洞古人类居住遗址，代表了我国南方一种独特的旧石器文化，被称为“九乡一绝”。</p>

                            <p className="grey-text">Yiliang Jiuxiang Scenic Area is located in a mountainous area with a cool climate. There are continuous peaks and mountains in the area. The relative elevation difference of the peaks and valleys is about 200 meters. The surface elevation is between 1750 and 1900 meters. It shows that the terrain in the karst cave distribution area rises and the river cuts and erodes the young river landscape.
                            <br/><br/>
Jiuxiang has dense forests with a coverage rate of 62.3% and rich precious animal and plant resources. The ancient human settlement site of Zhangkoudong represents a unique paleolithic culture in southern my country, and is called "Nine Villages and One Unique".</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%AE%9C%E8%89%AF%E4%B9%9D%E4%B9%A1%E9%A3%8E%E6%99%AF%E5%8C%BA/10058607?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        <div className="titleContent">
                            <h5>2018/06/15</h5>
                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/54.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p></p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/55.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>雌雄瀑布</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/56.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>“鳄鱼”石板</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12 m12 l5">
                                    <img src="../img28/57.png" alt="" className="responsive-img"></img>
                                </div>
                                <div className="col s12 m12 l6 offset-l1">
                                    <p>风吹石弯</p>
                                </div>
                            </div>


                        </div>
                        
                    </div>

                    <div className="titles" id="title27">
                        <h4 className="tooltipped title" data-tooltip="Jinma Biji Fang">金马碧鸡坊</h4>
                        
                        <div className="row">
                            <div className="col s12 m12 l5">
                                <img src="../img27/27.png" alt="" className="responsive-img"></img>
                                <br></br>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/%E9%87%91%E9%A9%AC%E7%A2%A7%E9%B8%A1%E5%9D%8A/@25.0331418,102.7077973,17z/data=!3m1!4b1!4m5!3m4!1s0x36d083e3063dd033:0x3b8fef87924e4b2e!8m2!3d25.033137!4d102.709986">
                                    <i className="tiny material-icons">location_on</i>
                                    <span className="titleMap">金马碧鸡坊</span>
                                </a>
                            </div>
                            <div className="col s12 m12 l6 offset-l1">

                            <div className="details">


                        <a className="btn-flat modal-trigger right info " href="#modal27">介绍</a>
                        <div id="modal27" className="modal modal-fixed-footer">
                            <div className="modal-content">
                            <h4 className="title">金马碧鸡坊 - 介绍</h4>
                            <p>金马碧鸡坊位于昆明市中心三市街与金碧路汇处，高12米，宽18米，雕梁画栋精美绝伦，东坊临金马山而名为金马坊，西坊靠碧鸡山而名为碧鸡坊，是昆明的象征。金马碧鸡坊始建于明朝宣德年间，至今已有近四百年的历史。
<br/><br/>
当太阳将落，余辉从西边照射碧鸡坊，它的倒影投到东面街上；同时，月亮则刚从东方升起，银色的光芒照射金马坊，将它的倒影投到西边街面上；两个牌坊的影子，渐移渐近，最后互相交接。这就是60年才会出现一次的"金碧交辉"的奇观。相传，清道光年间，这个奇观曾经出现过一次。由于地球、月亮、太阳运转的角度关系，这样的景，要 60年才能出现一次。"金马碧鸡坊"的设计体现了古代云南人对数学、天文学和建筑学方面的造诣。原“金马碧鸡坊”于10年动乱中被拆毁，现有的"金马碧鸡坊"是1998年在原址按原风格重建的。</p>

                            <p className="grey-text">Jinma Biji Fang is located at the intersection of Sanshi Street and Jinbi Road in the center of Kunming. It is 12 meters high and 18 meters wide. The carved beams and paintings are exquisitely carved. The east side is adjacent to Jinma Mountain and is called Jinmafang, and the west side is adjacent to Jieji Mountain. The chicken workshop is a symbol of Kunming. The Golden Horse and Jade Rooster was built in the Xuande period of the Ming Dynasty and has a history of nearly 400 years.
                            <br/><br/>
When the sun is about to set, the afterglow shines on Biji Square from the west, and its reflection is cast on the east street; at the same time, the moon has just risen from the east, and the silver light shines on the Golden Horse Square, casting its reflection on the west street. ; The shadows of the two archways gradually moved closer to each other. This is the spectacle of "gold and green" that will only appear once in 60 years. According to legend, this spectacle once appeared once during the Daoguang Period of the Qing Dynasty. Due to the angle of rotation of the earth, moon, and sun, such a scene can only appear once in 60 years. The design of "Golden Horse and Jade Rooster" reflects the ancient Yunnan people's accomplishments in mathematics, astronomy and architecture. The original "Jinma Biji Fang" was demolished during the 10-year turmoil. The existing "Jinma Biji Fang" was rebuilt in 1998 on the original site in the original style.</p>
                            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E9%87%91%E9%A9%AC%E7%A2%A7%E9%B8%A1%E5%9D%8A/902721?fr=aladdin">
                                {/* <i className="material-icons left">details</i>   */}
                                <span className="blue-text">[Source From...]</span>
                            </a>    
                            </div>
                            <div className="modal-footer">
                            <a href="#!" className="modal-close btn-flat">Close</a>
                            </div>
                        </div>

                        </div>
                            </div>
                        </div>

                  
                        {/* <div className="titleContent">




                        </div> */}
                        
                    </div>


                    <h4 className="title"> </h4>

                    {/* <div className="Comment">
                    
                        <div className="row">   
                        <div className="col s1 m1 l1">
                            <a className="btn btn-floating black lighten-1 ">YX</a>
                        </div>
                        <div className="col s11 m11 l11 commentContent">
                            <form action="">
                                <div className="input-field">
                                    <textarea id="message" className="materialize-textarea"></textarea>
                                    <label htmlFor="message">发表评论</label>
                                </div>
                            </form>
                        </div>
                        </div>

                      

                    </div> */}
                    


                </div>
            </div>
            
            <div className="row hide-on-med-and-up" id="travelNoteXianYunnan">

<div className="col s12 m12 l12 noteXianYunnan">


<a href="" className="sidenav-trigger" data-target="mobile-menu999">
        <i className="material-icons smallnotenav" >menu</i>
    </a>
    <ul className="sidenav grey lighten-2 sidenav999" id="mobile-menu999">
    <div className="bigTitle">西安&云南</div>
            <li className="titleDate">
                <a href="#title28">2018/06/04</a>
            </li>
            <li className="smallTitle">
                <a>西安-<a href="#title28" className="verysmallTitle">回民街</a>/<a href="#title29" className="verysmallTitle">钟楼鼓楼</a></a>
                
            </li>

            <li className="titleDate">
                <a href="#title30">2018/06/05</a>
            </li>
            <li className="smallTitle">
                <a>西安-<a href="#title30" className="verysmallTitle">兵马俑博物馆</a>/<a href="#title31" className="verysmallTitle">音乐喷泉</a></a>
                
            </li>

            <li className="titleDate">
                <a href="#title32">2018/06/06</a>
            </li>
            <li className="smallTitle">
                <a>香格里拉-<a href="#title32" className="verysmallTitle">松赞林寺</a>/<a href="#title33" className="verysmallTitle">拉姆央措湖</a></a>
                
            </li>

            <li className="titleDate">
                <a href="#title34">2018/06/07</a>
            </li>
            <li className="smallTitle">
                <a>香格里拉-<a href="#title34" className="verysmallTitle">纳帕海</a>/<a href="#title35" className="verysmallTitle">独克宗古城</a></a>
                
            </li>

            <li className="titleDate">
                <a href="#title36">2018/06/08</a>
            </li>
            <li className="smallTitle">
                <a>香格里拉-<a href="#title36" className="verysmallTitle">小中甸</a>/<a href="#title37" className="verysmallTitle">虎跳峡</a>/ <a href="#title38" className="verysmallTitle">丽江古城</a></a>
                
            </li>

            <li className="titleDate">
                <a href="#title39">2018/06/09</a>
            </li>
            <li className="smallTitle">
                <a>丽江-<a href="#title39" className="verysmallTitle">泸沽湖</a>/<a href="#title40" className="verysmallTitle">篝火晚会</a></a>
                
            </li>

            <li className="titleDate">
                <a href="#title41">2018/06/10</a>
            </li>
            <li className="smallTitle">
                <a>丽江-<a href="#title41" className="verysmallTitle">泸沽湖</a>/<a href="#title42" className="verysmallTitle">丽江千古情</a></a>
                
            </li>

            <li className="titleDate">
                <a href="#title43">2018/06/11</a>
            </li>
            <li className="smallTitle">
                <a>大理-<a href="#title43" className="verysmallTitle">大理古城</a></a>
    
            </li>

            <li className="titleDate">
                <a href="#title44">2018/06/12</a>
            </li>
            <li className="smallTitle">
                <a>大理-<a href="#title44" className="verysmallTitle">洱海</a>/<a href="#title45" className="verysmallTitle">蒋公祠</a>/<a href="#title46" className="verysmallTitle">玉洱园</a>/ <a href="#title47" className="verysmallTitle">大金塔</a></a>
                
            </li>

            <li className="titleDate">
                <a href="#title48">2018/06/13</a>
            </li>
            <li className="smallTitle">
                <a>西双版纳-<a href="#title48" className="verysmallTitle">望天树</a>/<a href="#title49" className="verysmallTitle">总佛寺</a>/<a href="#title50" className="verysmallTitle">曼听公园</a></a>
                
            </li>

            <li className="titleDate">
                <a href="#title51">2018/06/14</a>
            </li>
            <li className="smallTitle">
                <a>西双版纳-<a href="#title51" className="verysmallTitle">中国科学院热带植物园</a></a>
                
            </li>

            <li className="titleDate">
                <a href="#title52">2018/06/15</a>
            </li>
            <li className="smallTitle">
                <a>昆明-<a href="#title52" className="verysmallTitle">石林</a>/<a href="#title53" className="verysmallTitle">九乡</a>/<a href="#title54" className="verysmallTitle">金马碧鸡坊</a></a>
                
            </li>

        
    </ul>


        <div className="row">
            <div className="col s10 m10 l10 offset-s1 offset-m1 offset-l1 ">
            <div className="row travelDetail">
                <div className="time col s12 m4 l4">
                    <i className=" material-icons left">access_time</i>
                    <span>出发时间</span>
                    <span> / </span>
                    <span>2018-06-04</span>
                </div>
                <div className="day col s12 m4 l4">
                    <i className=" material-icons left">wb_sunny</i>
                    <span>出行天数</span>
                    <span> / </span>
                    <span>13天</span>
                </div>
                <div className="people col s12 m4 l4">
                    <i className=" material-icons left">account_circle</i>
                    <span>人物</span>
                    <span> / </span>
                    <span>和家人</span>
                </div>
            </div>
            <div className="col s1 m1 l1"></div>
            </div>
        </div>

    <div className="titles" id="title28">
        <h4 className="tooltipped title" data-tooltip="Huimin Street">回民街</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/1.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Huimin+Street/@34.2598474,108.9428072,17z/data=!3m1!4b1!4m5!3m4!1s0x36637a613130367d:0xd8367ef361d2e616!8m2!3d34.259843!4d108.9449959">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">回民街</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal9991">介绍</a>
        <div id="modal9991" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">回民街 - 介绍</h4>
            <p>西安回民街是西安著名的美食文化街区，是西安小吃街区。回民街所在北院门，为清代官署区，因陕西巡抚衙门在鼓楼之北，故名北院。1990年代末，部分回民在此街租房经营餐饮，莲湖区遂改向餐饮街方向，北院门遂成为回民街。
            <br/>
<br/>
西安回民街作为西安风情的代表之一，是回民街区多条街道的统称，由北广济街、北院门、西羊市、大皮院、化觉巷、洒金桥等数条街道组成，在钟鼓楼后。</p>

            <p className="grey-text">Xi’an Muslim Street is a famous food and cultural street in Xi’an and a snack street in Xi’an. Huimin Street is located at the North Yard Gate, which was an official district in the Qing Dynasty. Because the Shaanxi governor’s Yamen was in the north of the Drum Tower, it was named North Yard. At the end of the 1990s, some Muslims rented houses and catering on this street, and the Lianhu District was changed to the restaurant street, and the North Yard Gate became a Muslim street.
            <br/>
<br/>
Xi’an Huimin Street, as one of the representatives of Xi’an style, is the collective name of many streets in the Muslim neighborhood. It is composed of Beiguangji Street, Beiyuanmen, Xiyang City, Dapiyuan, Huajue Alley, and Sajin Bridge. Behind the Bell and Drum Tower.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%9B%9E%E6%B0%91%E8%A1%97/9853770?fr=aladdin">
                {/* <i className="material-icons left">details</i>   */}
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

  
        <div className="titleContent">
            <h5>2018/06/04</h5>
            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/1.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>老白家肉夹馍</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/2.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>红柳烤肉</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/3.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>皮影戏：卖杂货</p>
                </div>
            </div>




        </div>
        
    </div>

    <div className="titles" id="title29">
        <h4 className="tooltipped title" data-tooltip="Bell Tower & Drum Tower">钟楼&鼓楼</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/2.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Bell+Tower+of+Xi'an/@34.2594711,108.944831,17z/data=!3m1!4b1!4m5!3m4!1s0x36637a6108f00001:0x527adec5a3f239e1!8m2!3d34.2594667!4d108.9470197">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">钟楼</span>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Xi'an+Drum+Tower,+Zhong+Lou+Shang+Quan,+Lianhu+Qu,+Xian+Shi,+Shaanxi+Sheng,+China/@34.2602512,108.941256,17z/data=!3m1!4b1!4m5!3m4!1s0x36637a66dab175df:0x6e4a9ab21e5b3c48!8m2!3d34.2602468!4d108.9434447">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">鼓楼</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal9992">介绍</a>
        <div id="modal9992" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">钟楼鼓楼 - 介绍</h4>
            <p>西安钟鼓楼是西安钟楼和西安鼓楼的合称，位于陕西省省会西安市市中心，是西安的标志性建筑物，两座明代建筑遥相呼应，蔚为壮观。
            <br/>
<br/>
西安钟楼始建于明洪武十七年（公元1384年），原建于今西大街北广济街东侧，明万历十年（公元1582年）移于现址。钟楼是一座重檐三滴水式四角攒尖顶的阁楼式建筑，面积1377.64平方米，建在用青砖、白灰砌成的方形基座上。基座下有高与宽均为6米的十字形券洞与东南西北四条大街相通。
<br/>
<br/>
西安鼓楼始建于明洪武十三年（公元1380年），比钟楼的建造时间稍早。鼓楼建于高大的长方形台基之上，台基下辟有高和宽均为6米的南北向券洞。</p>

            <p className="grey-text">Xi’an Bell and Drum Tower is the collective name of Xi’an Bell Tower and Xi’an Drum Tower. It is located in the center of Xi’an, the capital of Shaanxi Province.
            <br/>
<br/>
Xi’an Bell Tower was built in the 17th year of Hongwu in the Ming Dynasty (AD 1384). It was originally built on the east side of Guangji Street, North Guangji Street, present West Street. The bell tower is a loft-style building with double eaves, three drops of water and four-cornered spires, covering an area of 1377.64 square meters, built on a square base made of blue bricks and white ash. There is a cross-shaped voucher hole with a height and width of 6 meters under the base, which communicates with the four streets in the south, east, north and west.
<br/>
<br/>
Xi'an Drum Tower was built in the thirteenth year of Ming Hongwu (AD 1380), which was earlier than the construction of the Bell Tower. The Drum Tower is built on a tall rectangular platform, under which there is a north-south coupon hole with a height and width of 6 meters.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E8%A5%BF%E5%AE%89%E9%92%9F%E9%BC%93%E6%A5%BC/1850217?fr=aladdin">
                {/* <i className="material-icons left">details</i>   */}
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

  
        <div className="titleContent">
            <h5>2018/06/04</h5>
            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/4.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>钟楼</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/5.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>鼓楼</p>
                </div>
            </div>

            




        </div>
        
    </div>

    <div className="titles" id="title30">
        <h4 className="tooltipped title" data-tooltip="Emperor Qinshihuang's Mausoleum Site Museum">兵马俑博物馆</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/3.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Emperor+Qinshihuang's+Mausoleum+Site+Museum/@34.3841197,109.2763031,17z/data=!3m1!4b1!4m5!3m4!1s0x3664a72d821403a3:0xc3e33e74e9e33fc7!8m2!3d34.3841153!4d109.2784918">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">兵马俑博物馆</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal9993">介绍</a>
        <div id="modal9993" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">兵马俑博物馆 - 介绍</h4>
            <p>秦始皇兵马俑博物馆位于陕西省西安市临潼区秦陵镇，成立于1975年11月，原为秦始皇兵马俑筹建处，于1979年10月1日正式开馆，建于临潼县东7.5公里的骊山北麓的秦始皇帝陵兵马俑坑遗址上，西距西安37.5公里；和丽山园合称秦始皇帝陵博物院。 截至2020年1月，秦始皇兵马俑博物馆已接待海内外观众达8000多万人次。秦兵马俑地下大军先后接待观众近5000万人次，其中共接待外国国家元首、政府首脑187批，副总统、副总理和议长506批、部长级客人1852批。 
<br/><br/>
截至2020年1月，已先后建成并开放了秦俑一、三、二号坑和文物陈列厅。目前秦俑博物馆面积已扩大到46.1公顷，拥有藏品5万余（套）件。 一号兵马俑坑内约埋藏陶俑、陶马6000件，同时还有大量的青铜兵器；二号兵马俑坑内埋藏陶俑、陶马1300余件，二号俑坑较一号俑坑的内容更丰富，兵种更齐全；三号俑坑的规模较小，坑内埋藏陶俑、陶马72件；陈列厅内有一、二号铜车马。</p>

            <p className="grey-text">Emperor Qinshihuang's Mausoleum Site Museum is located in Qinling Town, Lintong District, Xi'an City, Shaanxi Province. On the site of the Terracotta Warriors and Horses Pit of Emperor Qin Shi Huang’s Mausoleum, 37.5 kilometers west of Xi’an; Helishan Garden is collectively called the Museum of Emperor Qin Shi Huang’s Mausoleum. As of January 2020, the Terracotta Warriors and Horses Museum of Qin Shihuang has received more than 80 million visitors from home and abroad. The underground army of Qin Terracotta Warriors and Horses has received nearly 50 million visitors, including 187 groups of foreign heads of state and government, 506 groups of vice presidents, vice premiers and speakers, and 1,852 groups of minister-level guests.
            <br/><br/>
As of January 2020, the first, third and second pits of the Qin warriors and the exhibition hall of cultural relics have been completed and opened. At present, the area of ​​the Emperor Qinshihuang's Mausoleum Site Museum has expanded to 46.1 hectares, with more than 50,000 (sets) of collections. Terracotta Warriors and Horses Pit No. 1 contains about 6,000 pottery figurines and horses, as well as a large number of bronze weapons; Terracotta Warriors and Horses Pit No. 2 contains more than 1,300 pottery warriors and horses, and Pit No. 2 has more content than Pit No. 1. There are more types of troops; the No. 3 warrior pit is smaller in scale, 72 pottery warriors and horses are buried in the pit; there are 1 and 2 bronze carts and horses in the showroom.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E7%A7%A6%E5%A7%8B%E7%9A%87%E5%85%B5%E9%A9%AC%E4%BF%91%E5%8D%9A%E7%89%A9%E9%A6%86/1509704?fr=aladdin">
                {/* <i className="material-icons left">details</i>   */}
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

  
        <div className="titleContent">
            <h5>2018/06/05</h5>
            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/6.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>手半握</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/7.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>三号</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/8.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>脚趾朝上挑：趾高气扬</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/9.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>手指比个一：一人之下</p>
                </div>
            </div>




        </div>
        
    </div>

    <div className="titles" id="titl31">
        <h4 className="tooltipped title" data-tooltip="Da-Yan Tower fountain">大雁塔音乐喷泉</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/4.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/%E5%A4%A7%E9%9B%81%E5%A1%94%E9%9F%B3%E4%B9%90%E5%96%B7%E6%B3%89%E5%B9%BF%E5%9C%BA/@34.2202029,108.9619324,17z/data=!4m8!1m2!2m1!1z6KW_5a6JIOWkp-mbgeWhlOmfs-S5kOWWt-aziQ!3m4!1s0x36637068cf98ec59:0x53105525177f63c2!8m2!3d34.222111!4d108.964183">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">大雁塔音乐喷泉</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal9994">介绍</a>
        <div id="modal9994" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">大雁塔音乐喷泉 - 介绍</h4>
            <p>西安大雁塔喷泉位于大雁塔北广场，东西宽218米，南北长346米。它是亚洲雕塑规模最大的广场，广场内有2个百米长的群雕，8组大型人物雕塑，40块地景浮雕。
            <br/>
<br/>
它拥有全世界最豪华的绿化无接触式卫生间，保持最清洁、世界上坐凳最多、世界最长的光带、世界首家直引水、规模最大的音响组合等多项纪录。</p>

            <p className="grey-text">The big wild goose pagoda fountain is located in the north square of the Big Wild Goose Pagoda. It is the largest sculpture square in Asia. There are two hundred-meter-long group sculptures, eight groups of large-scale figure sculptures, and 40 relief sculptures.
            <br/>
<br/>
It has the world's most luxurious greening contactless toilet, maintaining many records such as the cleanest, the most stools in the world, the world's longest light belt, the world's first direct water diversion, and the largest sound combination.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%A4%A7%E9%9B%81%E5%A1%94%E9%9F%B3%E4%B9%90%E5%96%B7%E6%B3%89/560291?fr=aladdin">
                {/* <i className="material-icons left">details</i>   */}
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

  
        <div className="titleContent">
            <h5>2018/06/05</h5>
            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/10.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>看的是晚上8:30的场次</p>
                </div>
            </div>





        </div>
        
    </div>

    <div className="titles" id="title32">
        <h4 className="tooltipped title" data-tooltip="Songzanlin Temple">松赞林寺</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/5.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/%E5%99%B6%E4%B8%B9%E6%9D%BE%E8%B5%9E%E6%9E%97/@27.8169139,99.6879114,14z/data=!4m8!1m2!2m1!1z5p2-6LWe5p6X5a-6!3m4!1s0x3721ba61e1ea6799:0x496043453ca25487!8m2!3d27.816877!4d99.705421">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">松赞林寺</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal9995">介绍</a>
        <div id="modal9995" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">松赞林寺 - 介绍</h4>
            <p>噶丹·松赞林寺是云南省规模最大的藏传佛教寺院，也是康区有名的大寺院之一，还是川滇一带的黄教中心，在整个藏区都有着举足轻重的地位，被誉为“小布达拉宫”。该寺依山而建，外形犹如一座古堡，集藏族造型艺术之大成，又有“藏族艺术博物馆”之称。 
            <br/>
<br/>
该寺又称归化寺，距中甸县城5公里，是一座古镇规模的古堡群建筑。于公元1679年（藏历第十一绕迥阴土羊年）兴建，公元1681年（阴铁鸡年）竣工。五世达赖喇嘛亲赐名“噶丹·松赞林”。</p>

            <p className="grey-text">Kadan Songzanlin Temple is the largest Tibetan Buddhist monastery in Yunnan Province. It is also one of the most famous monasteries in the Kham area. It is also the center of the Yellow Sect in Sichuan and Yunnan. It has a pivotal position in the entire Tibetan area and is known as the "small Potala Palace". Built on the mountain, the temple looks like an ancient castle. It is a collection of Tibetan plastic arts and is also known as the "Tibetan Art Museum".
            <br/>
<br/>
The temple, also known as Guihua Temple, is 5 kilometers away from Zhongdian County. It is an ancient town-scale castle complex. It was built in 1679 (the eleventh cycle of the Tibetan calendar, the year of the yin earth sheep), and was completed in 1681 (the year of the yin iron rooster). The Fifth Dalai Lama personally gave the name "Kadan Songtsanlin".</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%99%B6%E4%B8%B9%C2%B7%E6%9D%BE%E8%B5%9E%E6%9E%97%E5%AF%BA/10347256?fromtitle=%E6%9D%BE%E8%B5%9E%E6%9E%97%E5%AF%BA&fromid=828735&fr=aladdin">
                {/* <i className="material-icons left">details</i>   */}
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

  
        <div className="titleContent">
            <h5>2018/06/06</h5>
            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/11.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>西安飞往香格里拉</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/12.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>人数够了以后，会配个导游带领进入松赞林寺。</p>
                </div>
            </div>


        </div>
        
    </div>

    <div className="titles" id="title33">
        <h4 className="tooltipped title" data-tooltip="Lamuyangcuo Lake">拉姆央措湖</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/6.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Lamuyangcuo+Lake/@27.8555797,99.70209,17z/data=!3m1!4b1!4m5!3m4!1s0x3721ba191bb4a1ad:0x7ed6b5923b74b6ae!8m2!3d27.8553125!4d99.7044581">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">拉姆央措湖</span>
                </a>

                
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal9996">介绍</a>
        <div id="modal9996" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">拉姆央措湖 - 介绍</h4>
            <p>拉姆央措湖面不大，但在藏区名声很大，景色秀美异常，湖中四季有各种水鸟生息，黑颈鹤、黄鸭等水鸟随处可见。站在扎仓大殿顶上观望拉姆央措湖，它是一个佛自然的投影。
            <br/><br/>
天气晴朗时，水清如镜，湖山相映。时而天空会乌云密布，无风起浪，湖水会发出奇特的声响，还会出现各种奇妙景象。据说信徒们同时观看湖景，所见景象却各异，有的还能从湖底影像中看到自己的前世今生。
<br/><br/>
拉姆央措湖中的玛尼堆有它特殊的意义，藏语称之为“崩登”它与对面的山起着相辅相成的作用，对面山上的平台是专供寺院里的僧人圆寂后进行天葬的地方，在进行天葬时，僧人们便到此小岛上的玛尼堆边对死者进行诵经超渡仪式，因此一般情况下是不允许任何人上岛的。</p>

            <p className="grey-text">Lamuyangcuo Lake is not big, but it is very famous in Tibetan areas, and the scenery is unusually beautiful. There are various water birds in the lake during the four seasons. Black-necked cranes and yellow ducks can be seen everywhere. Standing on the top of Dachang Hall and watching Ramyangcuo Lake, it is a natural projection of Buddha.
            <br/><br/>
When the weather is clear, the water is as clear as a mirror, and the mountains and lakes mirror each other. Sometimes the sky will be covered with dark clouds, without wind and waves. The lake water will make peculiar sounds and various wonderful scenes will appear. It is said that believers watch the lake scene at the same time, but they see different scenes, and some can see their past and present lives in the images of the lake bottom.
<br/><br/>
The Mani pile in Lamuyangcuo Lake has its special meaning. It is called "Bengdeng" in Tibetan. It complements the opposite mountain. The platform on the opposite mountain is exclusively for monks in the monastery after their death. At the celestial burial place, during the celestial burial, the monks would go to the Mani pile on this small island to chant and transcend the deceased. Therefore, under normal circumstances, no one is allowed to go to the island.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%8B%89%E5%A7%86%E5%A4%AE%E6%8E%AA%E6%B9%96/17404117?fr=aladdin">
                {/* <i className="material-icons left">details</i>   */}
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

  
        <div className="titleContent">
            <h5>2018/06/06</h5>
            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/13.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>就在松赞林寺的旁边</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/14.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p></p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/15.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>可以看到整个松赞林寺</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/16.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>晚上吃牦牛火锅。</p>
                </div>
            </div>



        </div>
        
    </div>

    <div className="titles" id="title34">
        <h4 className="tooltipped title" data-tooltip="Napa Sea">纳帕海</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/7.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/%E7%BA%B3%E5%B8%95%E6%B5%B7/@27.8900353,99.6513433,17z/data=!4m8!1m2!2m1!1z57qz5biV5rW3!3m4!1s0x3721b9407dfc8ee9:0x1e27cbcb9ff0209c!8m2!3d27.889698!4d99.65366">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">纳帕海</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal9997">介绍</a>
        <div id="modal9997" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">纳帕海 - 介绍</h4>
            <p>纳帕海位于云南省迪庆藏族自治州香格里拉县，建立于1984年，总面积31.25平方公里，海拔3266米，湖泊积水面积660平方公里。 
<br/><br/>
属湿地生态类型保护区，主要保护对象为高原季节性湖泊、沼泽草甸，是黑颈鹤等候鸟越冬栖息地。这里是中甸县最大的草原，也是最富于高原特色的风景区之一。由于藏民的悉心爱护，每当秋季来临，许多飞禽便光顾这里，如黑颈鹤、黄鸭、斑头雁在草原上空高飞低旋，情景颇惹心喜爱。</p>

            <p className="grey-text">Napa Sea is located in Shangri-La County, Diqing Tibetan Autonomous Prefecture, Yunnan Province. It was established in 1984 and has a total area of 31.25 square kilometers, an elevation of 3266 meters, and a lake area of 660 square kilometers.
            <br/><br/>
It is a wetland ecological type reserve. The main protection objects are plateau seasonal lakes and swamp meadows. It is the habitat of black-necked cranes waiting for birds to overwinter. This is the largest grassland in Zhongdian County and one of the scenic spots with the most plateau characteristics. Thanks to the care of the Tibetans, when autumn comes, many birds visit here, such as black-necked cranes, yellow ducks, and bar-headed geese flying high and low over the grassland. The scene is quite lovely.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E7%BA%B3%E5%B8%95%E6%B5%B7%E8%87%AA%E7%84%B6%E4%BF%9D%E6%8A%A4%E5%8C%BA/1409256?fromtitle=%E7%BA%B3%E5%B8%95%E6%B5%B7&fromid=1138432&fr=aladdin">
                {/* <i className="material-icons left">details</i>   */}
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

        <div className="titleContent">
            <h5>2018/06/07</h5>
            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/17.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p></p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/18.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>纳帕海</p>
                </div>
            </div>



        </div>
  
        
    </div>

    <div className="titles" id="title35">
        <h4 className="tooltipped title" data-tooltip="Dukezong Ancient City">独克宗古城</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/8.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/%E9%A6%99%E6%A0%BC%E9%87%8C%E6%8B%89%E5%8E%BF%E7%8B%AC%E5%85%8B%E5%AE%97%E5%8F%A4%E5%9F%8E/@27.8123867,99.7025733,17z/data=!3m1!4b1!4m5!3m4!1s0x3721ba8bacac6789:0x517be1756e2cf1f7!8m2!3d27.812382!4d99.704762">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">独克宗古城</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal9998">介绍</a>
        <div id="modal9998" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">独克宗古城 - 介绍</h4>
            <p>独克宗也称为月光之城，位于独克宗古城北门，是独克宗花巷，由雪松文旅集团打造，整合藏区非遗体验、藏文化风情街、第5颗陨石系列酒店、《遇见·香格里拉》秀等，提供藏文化体验。 
            <br/><br/>
这是座被藏人称作“独克宗”的古城，它是按照佛经中的香巴拉理想国建成的。古城依山势而建，路面起伏不平，那是一些岁月久远的旧石头就着自然地势铺成的，至今，石板路上还留着深深的马蹄印，那是当年的马帮给时间留下的信物了。</p>

            <p className="grey-text">Dukezong, also known as the City of Moonlight, is located at the north gate of the ancient city of Dukezong. It is the Flower Alley of Dukezong. It was built by Cedar Cultural Tourism Group to integrate Tibetan intangible heritage experience, Tibetan cultural style street, and the fifth meteorite series hotel , "Meet Shangri-La" show, etc., to provide Tibetan cultural experience.
            <br/><br/>
This is an ancient city called "Dukezong" by Tibetans. It was built in accordance with the Shambhala Utopia in Buddhist scriptures. The ancient city was built on the hillside, the road was undulating, it was some old stone that was paved with the natural topography. So far, there are still deep horseshoe prints on the stone roads, which are left by the horse caravan in those days. Token.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E7%8B%AC%E5%85%8B%E5%AE%97/95288?fr=aladdin">
                {/* <i className="material-icons left">details</i>   */}
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

  
        <div className="titleContent">
            <h5>2018/06/07</h5>
            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/19.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>红军博物馆</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/20.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>龟山公园</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/21.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>大佛寺转经筒</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/22.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>晚上会有很多人在这个广场围一圈跳舞。</p>
                </div>
            </div>



        </div>
        
    </div>

    <div className="titles" id="title36">
        <h4 className="tooltipped title" data-tooltip="Xiaozhongdian Flower Sea">小中甸花海</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/9.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Xiaozhongdianzhen,+Shangri-La+City,+Diqing+Tibetan+Autonomous+Prefecture,+Yunnan,+China/@27.5532353,99.8040642,15z/data=!3m1!4b1!4m8!1m2!2m1!1z5LqR5Y2X55yB6L-q5bqG6JeP5peP6Ieq5rK75bee6aaZ5qC86YeM5ouJ5Y6_5bu65aGY6ZWH5bCP5Lit55S45p2R!3m4!1s0x372100caac23f8ab:0x74b18b93ddd1969c!8m2!3d27.553217!4d99.812819">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">小中甸花海</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal9999">介绍</a>
        <div id="modal9999" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">小中甸花海 - 介绍</h4>
            <p>小中甸是丽江前往香格里拉必经的一个小镇，位于香格里拉县城南面，G214国道旁，地处高寒坝区，有着大片的茂盛草场。而每月5月下旬到6月中旬，这里则成了一片花海，多彩绚烂的杜鹃花、狼毒花、格桑花，还有各种不知名的小花小草，引得游人赞叹不已。同时，也是选择拍写真照婚纱照的绝好场地。而到了9、10月份的秋天，这里就成了狼毒花的天下，大朵大朵火红眼里的狼毒花，异常绚丽。</p>

            <p className="grey-text">Xiaozhongdian is a small town that Lijiang must pass through to Shangri-La. It is located in the south of Shangri-La County, next to the G214 National Highway, in the high-cold dam area, with large lush pastures. And from late May to mid-June every month, here is a sea of flowers, colorful and gorgeous azaleas, wolfberry flowers, Gesang flowers, and various unknown flowers and grasses, attracting visitors' admiration. At the same time, it is also a great place to choose photo and wedding photos. And in the autumn of September and October, this place became the world of chamomile flowers. The big flowers of chamomile flowers in the red eyes were extremely gorgeous.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%B0%8F%E4%B8%AD%E7%94%B8%E8%8A%B1%E6%B5%B7/17404470?fr=aladdin">
                {/* <i className="material-icons left">details</i>   */}
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

  
        {/* <div className="titleContent">




        </div> */}
        
    </div>

    <div className="titles" id="title37">
        <h4 className="tooltipped title" data-tooltip="Tiger Leaping Gorge">虎跳峡</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/10.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Tiger+Leaping+Gorge/@27.1893948,100.1116893,17z/data=!3m1!4b1!4m5!3m4!1s0x3720e825fc6ccfa9:0xb8609916f2ea8cca!8m2!3d27.18939!4d100.113878">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">虎跳峡</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal99910">介绍</a>
        <div id="modal99910" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">虎跳峡 - 介绍</h4>
            <p>虎跳峡是金沙江（长江）上的第一大峡谷，更是全球著名的大峡谷，虎跳峡位于香格里拉东南部，距香格里拉市区105公里，距长江第一湾35公里，距离丽江88公里，分为上虎跳、中虎跳、下虎跳3段，是香格里拉虎跳峡经营有限公司的主要运营旅游线路之一。峡内礁石林立，有险滩21处，高达10来米的跌坎7处，瀑布10条。长江江水在这里被玉龙、哈巴两大雪山所挟峙，海拔高差3900多米，峡谷之深，位居世界前列，最窄处，仅约30余米，相传猛虎下山，在江中的礁石上稍抬脚，便可腾空越过，故称虎跳峡。</p>
            <p className="grey-text">Tiger Leaping Gorge is the largest canyon on the Jinsha River (Yangtze River) and a world-famous grand canyon. Tiger Leaping Gorge is located in the southeast of Shangri-La, 105 kilometers away from downtown Shangri-La, 35 kilometers away from the first bay of the Yangtze River, and 88 kilometers away from Lijiang. It is divided into three sections: Upper Tiger Jump, Middle Tiger Jump, and Lower Tiger Jump. It is one of the main tourist routes operated by Shangri-La Tiger Leaping Gorge Management Co., Ltd. There are many reefs in the gorge. There are 21 dangerous beaches, 7 ridges up to 10 meters high, and 10 waterfalls. The water of the Yangtze River is held here by the Yulong and Haba snow mountains. The altitude difference is more than 3900 meters. The depth of the canyon is among the highest in the world. The narrowest point is only about 30 meters. According to legend, the tiger descends on the reef in the river. With a little lift, you can fly over it, so it is called Tiger Leaping Gorge.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E8%99%8E%E8%B7%B3%E5%B3%A1%E6%99%AF%E5%8C%BA/6900936?fr=aladdin">
                {/* <i className="material-icons left">details</i>   */}
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

  
        <div className="titleContent">
            <h5>2018/06/08</h5>
            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/23.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>包车从香格里拉到虎跳峡，¥400.</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/24.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p></p>
                </div>
            </div>



        </div>
        
    </div>

    <div className="titles" id="title38">
        <h4 className="tooltipped title" data-tooltip="Old Town of Lijiang">丽江古城</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/11.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Old+Town+of+Lijiang/@26.8865053,100.0040798,10z/data=!4m8!1m2!2m1!1z5Li95rGf5Y-k5Z-O!3m4!1s0x3720b584064c0c01:0x2ca3f44972f75fb5!8m2!3d26.8718026!4d100.2358502">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">丽江古城</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal99911">介绍</a>
        <div id="modal99911" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">丽江古城 - 介绍</h4>
            <p>丽江古城位于云南省丽江市古城区，又名大研镇，坐落在丽江坝中部，始建于宋末元初（公元13世纪后期），地处云贵高原，面积为7.279平方公里。 
            <br/>
<br/>
丽江古城内的街道依山傍水修建，以红色角砾岩铺就，有四方街、木府、五凤楼、黑龙潭、文昌宫、王丕震纪念馆、雪山书院、王家庄基督教堂、方国瑜故居、白马龙潭寺、顾彼得旧居、净莲寺、普贤寺、接风楼、十月文学馆、红军长征过丽江指挥部纪念馆、丽江古城历史文化展示馆、丽江古城徐霞客纪念馆等景点。丽江为第二批被批准的中国历史文化名城之一，是中国以整座古城申报世界文化遗产获得成功的两座古城之一。丽江古城体现了中国古代城市建设的成就，是中国民居中具有鲜明特色和风格的类型之一。</p>

            <p className="grey-text">The Old Town of Lijiang is located in the ancient city of Lijiang City, Yunnan Province, also known as Dayan Town. It is located in the middle of Lijiang Dam. It was built in the late Song Dynasty and early Yuan Dynasty (late 13th century AD). It is located on the Yunnan-Guizhou Plateau and covers an area of 7.279 square kilometers.
            <br/>
<br/>
The streets in the ancient city of Lijiang are built with mountains and rivers, paved with red breccia, including Sifang Street, Mufu, Wufeng Tower, Black Dragon Pool, Wenchang Palace, Wang Pizhen Memorial Hall, Xueshan Academy, Wangjiazhuang Christian Church, Fang Guoyu’s Former Residence, Baima Longtan Temple, Gu Peter’s Former Residence, Jinglian Temple, Puxian Temple, Jiefeng Building, October Literature Museum, Red Army Long March Lijiang Command Memorial Hall, Lijiang Ancient City History and Culture Exhibition Hall, Lijiang Ancient City Xu Xiake Memorial Hall and other attractions. Lijiang is one of the second batch of approved Chinese historical and cultural cities. It is one of the two ancient cities in China that has successfully declared the entire ancient city as a world cultural heritage. The ancient city of Lijiang embodies the achievements of ancient Chinese urban construction, and is one of the types with distinctive characteristics and styles of Chinese citizens.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%B8%BD%E6%B1%9F%E5%8F%A4%E5%9F%8E/304665?fr=aladdin">
                {/* <i className="material-icons left">details</i>   */}
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

  
        <div className="titleContent">
            <h5>2018/06/08</h5>
            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/25.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>晚饭在古城边上的阿婆腊排骨吃的，味道还可以。</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/26.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>吃完饭来逛古城。</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/27.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p></p>
                </div>
            </div>



        </div>
        
    </div>

    <div className="titles" id="title39">
        <h4 className="tooltipped title" data-tooltip="Lugu Lake">泸沽湖</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/12.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Lugu+Lake/@27.7029267,100.7561587,13z/data=!3m1!4b1!4m5!3m4!1s0x36df8536db0d65b7:0xfe307591753e18e8!8m2!3d27.7244646!4d100.7887231">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">泸沽湖</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal99912">介绍</a>
        <div id="modal99912" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">泸沽湖 - 介绍</h4>
            <p>泸沽湖，位于四川省盐源县与云南省宁蒗县交界处，为川滇共辖，湖东为盐源县泸沽湖镇（原左所区），湖西为宁蒗县永宁乡。湖泊略呈北西一东南走向，湖泊面积50.1平方公里，湖水库容量为22.52亿立方米。 
            <br/><br/>
泸沽湖属高原断层溶蚀陷落湖泊，属长江上游干流金沙江支流雅砻江支流理塘河水系。是云南省海拔最高的湖泊，是中国第三大深水湖泊。泸沽湖周边主要居住着摩梭人、彝族和普米族，沿岸居住有蒙古族7种民族，约1.3万人。优美的自然环境和奇特的民族风情，使这里成为著名的旅游景区。</p>

            <p className="grey-text">Lugu Lake is located at the junction of Yanyuan County, Sichuan Province and Ninglang County, Yunnan Province. It is under the jurisdiction of Sichuan and Yunnan. The east of the lake is Luguhu Town of Yanyuan County (formerly Zuosuo District), and the west of Lake is Yongning Township of Ninglang County. The lake is slightly northwest to southeast, with an area of 50.1 square kilometers and a reservoir capacity of 2.252 billion cubic meters.
            <br/><br/>
Lugu Lake is a plateau fault-corroded and subsided lake, and belongs to the Litang River system, a tributary of the Jinsha River, a tributary of the Jinsha River in the upper reaches of the Yangtze River. It is the highest lake in Yunnan Province and the third largest deep-water lake in China. The surrounding areas of Lugu Lake are mainly inhabited by the Mosuo, Yi and Pumi peoples. There are 7 ethnic groups of Mongolians and about 13,000 people living along the coast. The beautiful natural environment and peculiar ethnic customs make it a famous tourist attraction.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%B3%B8%E6%B2%BD%E6%B9%96/396555?fr=aladdin">
                {/* <i className="material-icons left">details</i>   */}
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

  
        <div className="titleContent">
            <h5>2018/06/09</h5>
            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/28.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>一早从丽江古城来到泸沽湖。</p>
                    <p>来回路程遥远，所以第二天再回。</p>
                    <p>客栈就在湖边，景色还可以。</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/29.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p></p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/30.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p></p>
                </div>
            </div>



        </div>
        
    </div>

    <div className="titles" id="title40">
        <h4 className="tooltipped title" data-tooltip="Campfire">篝火晚会</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/13.png" alt="" className="responsive-img"></img>
                {/* <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Huayan+Temple/@40.0905141,113.3125383,17z/data=!3m1!4b1!4m5!3m4!1s0x35e2d45f1ae0f0a1:0xf6817c1718ad8a84!8m2!3d40.09051!4d113.314727">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">篝火晚会</span>
                </a> */}
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        {/* <a className="btn-flat modal-trigger right info " href="#modal99913">介绍</a>
        <div id="modal99913" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">篝火晚会 - 介绍</h4>
            <p></p>

            <p className="grey-text"></p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%8D%8E%E4%B8%A5%E5%AF%BA/2285867?fr=aladdin">
              
                <span className="blue-text">[Source From...]</span>
            </a>      
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div> */}

        </div>
            </div>
        </div>

  
        {/* <div className="titleContent">




        </div> */}
        
    </div>

    <div className="titles" id="title41">
        <h4 className="tooltipped title" data-tooltip="Lugu Lake">泸沽湖</h4>
        
        <div className="row">
        <div className="col s12 m12 l5">
                <img src="../img27/14.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Lugu+Lake/@27.7029267,100.7561587,13z/data=!3m1!4b1!4m5!3m4!1s0x36df8536db0d65b7:0xfe307591753e18e8!8m2!3d27.7244646!4d100.7887231">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">泸沽湖</span>
                </a>
            </div>

            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal99914">介绍</a>
        <div id="modal99914" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">泸沽湖 - 介绍</h4>
            <p>泸沽湖，位于四川省盐源县与云南省宁蒗县交界处，为川滇共辖，湖东为盐源县泸沽湖镇（原左所区），湖西为宁蒗县永宁乡。湖泊略呈北西一东南走向，湖泊面积50.1平方公里，湖水库容量为22.52亿立方米。 
            <br/><br/>
泸沽湖属高原断层溶蚀陷落湖泊，属长江上游干流金沙江支流雅砻江支流理塘河水系。是云南省海拔最高的湖泊，是中国第三大深水湖泊。泸沽湖周边主要居住着摩梭人、彝族和普米族，沿岸居住有蒙古族7种民族，约1.3万人。优美的自然环境和奇特的民族风情，使这里成为著名的旅游景区。</p>

            <p className="grey-text">Lugu Lake is located at the junction of Yanyuan County, Sichuan Province and Ninglang County, Yunnan Province. It is under the jurisdiction of Sichuan and Yunnan. The east of the lake is Luguhu Town of Yanyuan County (formerly Zuosuo District), and the west of Lake is Yongning Township of Ninglang County. The lake is slightly northwest to southeast, with an area of 50.1 square kilometers and a reservoir capacity of 2.252 billion cubic meters.
            <br/><br/>
Lugu Lake is a plateau fault-corroded and subsided lake, and belongs to the Litang River system, a tributary of the Jinsha River, a tributary of the Jinsha River in the upper reaches of the Yangtze River. It is the highest lake in Yunnan Province and the third largest deep-water lake in China. The surrounding areas of Lugu Lake are mainly inhabited by the Mosuo, Yi and Pumi peoples. There are 7 ethnic groups of Mongolians and about 13,000 people living along the coast. The beautiful natural environment and peculiar ethnic customs make it a famous tourist attraction.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%B3%B8%E6%B2%BD%E6%B9%96/396555?fr=aladdin">
                {/* <i className="material-icons left">details</i>   */}
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

  
        <div className="titleContent">
            <h5>2018/06/10</h5>
            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/31.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>5 2 1</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/32.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p></p>
                </div>
            </div>



        </div>
        
    </div>

    <div className="titles" id="title42">
        <h4 className="tooltipped title" data-tooltip="Lijiang Eternal Love Tourist Area">丽江千古情</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/15.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/%E4%B8%BD%E6%B1%9F%E5%AE%8B%E5%9F%8E%E6%97%85%E6%B8%B8%E5%8C%BA/@26.8281325,100.2200684,17z/data=!3m1!4b1!4m5!3m4!1s0x3720cae717b59ed1:0xdb5c69f371b7769c!8m2!3d26.8281277!4d100.2222571">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">丽江千古情</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal99915">介绍</a>
        <div id="modal99915" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">丽江千古情 - 介绍</h4>
            <p>《丽江千古情》是丽江千古情景区的核心产品。丽江千古情景区由中国第一家演艺上市公司——宋城演艺倾力打造。景区距丽江古城6公里，毗邻文笔海自然景观，与玉龙雪山遥相呼应，地理环境优越，以丽江民族地域文化为主题，以大型歌舞《丽江千古情》为核心内容，展现了丽江千年文化和历史风情。景区主要包括茶马古街、那措海、雪山沙滩、民族村落、千古情广场、大型室内科技游乐综合体等多个主题区域。</p>

            <p className="grey-text">"Lijiang Eternal Love" is the core product of Lijiang Eternal Scene Zone. The Lijiang Eternal Scenery Zone was created by Songcheng Performing Arts, China's first listed performing arts company. The scenic spot is 6 kilometers away from the ancient city of Lijiang, adjacent to the natural landscape of Wenbihai, and echoes the Jade Dragon Snow Mountain. The geographical environment is superior. With the theme of Lijiang ethnic and regional culture, the large-scale singing and dancing "Lijiang Eternal Love" is the core content, showing the thousand-year culture and historical customs of Lijiang . The scenic spot mainly includes multiple themed areas such as Tea Horse Street, Nacuo Sea, Snow Mountain Beach, Ethnic Village, Eternal Love Plaza, and a large indoor technology amusement complex.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%B8%BD%E6%B1%9F%E5%8D%83%E5%8F%A4%E6%83%85/13207973?fr=aladdin">
                {/* <i className="material-icons left">details</i>   */}
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

        <div className="titleContent">
            <h5>2018/06/10</h5>
            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/33.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>《丽江千古情》</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/34.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>放灯</p>
                </div>
            </div>
        


        </div>
        
    </div>

    <div className="titles" id="title43">
        <h4 className="tooltipped title" data-tooltip="Dali Ancient City">大理古城</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/16.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/%E5%A4%A7%E7%90%86%E5%8F%A4%E5%9F%8E%E4%B8%9C%E9%97%A8/@25.6983914,100.1648829,17z/data=!4m13!1m7!3m6!1s0x3727bf4d2ce84a8d:0xf05686789efce876!2s42+Yita+Rd,+Dali+Shi,+Dali+Baizuzizhizhou,+Yunnan+Sheng,+China,+671003!3b1!8m2!3d25.699334!4d100.170419!3m4!1s0x3727bf4cfc798363:0x982269d4a6083e0a!8m2!3d25.7000612!4d100.1726197">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">大理古城</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal99916">介绍</a>
        <div id="modal99916" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">大理古城 - 介绍</h4>
            <p>大理古城位于云南省西部，又名叶榆城、紫城。古城其历史可追溯至唐天宝年间，南诏王阁逻凤筑的羊苴咩城，为其新都。古城始建于明洪武十五年（1382年），占地面积3平方公里。 
<br/><br/>
大理为1982年2月8日国务院公布的中国首批24个历史文化名城之一。
<br/><br/>
大理古城在唐、宋500多年的历史间是云南的政治、经济、文化的中心，在古城内分布了14处市级以上的重点文物保护单位，承载着大理历史文化、宗教文化、民族文化，是大理旅游核心区。</p>

            <p className="grey-text">The ancient city of Dali is located in the west of Yunnan Province, also known as Yeyucheng and Zicheng. The history of the ancient city can be traced back to the Tianbao period of the Tang Dynasty. The city of Yangjumei built by the Luofeng of the Nanzhao King Pavilion is the new capital. The ancient city was built in the fifteenth year of Ming Hongwu (1382), covering an area of 3 square kilometers.
            <br/><br/>
Dali is one of the first 24 historical and cultural cities in China announced by the State Council on February 8, 1982.
<br/><br/>
The ancient city of Dali was the political, economic, and cultural center of Yunnan during the 500-year history of the Tang and Song Dynasties. There are 14 key cultural relics protection units at the city level and above in the ancient city, which carry Dali’s historical culture, religious culture, and ethnic culture. It is the core tourist area of Dali.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%A4%A7%E7%90%86%E5%8F%A4%E5%9F%8E/1874828?fr=aladdin">
                {/* <i className="material-icons left">details</i>   */}
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

  
        <div className="titleContent">
            <h5>2018/06/11</h5>
            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/35.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>上午从丽江坐火车到大理</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/36.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>晚上的大理古城</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/37.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>小型灯展</p>
                </div>
            </div>

        
        </div>
        
    </div>

    <div className="titles" id="title44">
        <h4 className="tooltipped title" data-tooltip="Erhai Lake">洱海</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/17.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Erhai+Lake/@25.7792419,100.0540452,11z/data=!3m1!4b1!4m5!3m4!1s0x3727bf6d59732bf3:0xb4288374da6970b5!8m2!3d25.8006596!4d100.1927389">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">洱海</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal99917">介绍</a>
        <div id="modal99917" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">洱海 - 介绍</h4>
            <p>洱海，古称昆明池、洱河、叶榆泽等。位于云南省大理白族自治州大理市。一般湖水面积约246平方千米（一说251平方公里），蓄水量约29.5亿立方米，呈狭长形，北起洱源县南端，南止大理市下关，南北长40公里，是仅次于滇池的云南第二大湖，中国淡水湖中居第7位。洱海形成于冰河时代末期，其成因主要是沉降侵蚀，属高原构造断陷湖泊，海拔1972米。
<br/><br/>
洱海，属澜沧江流域，系其支流漾濞江支流西洱河上源。湖水由西洱河流经大理市区下关，向西汇入漾濞江。
<br/><br/>
洱海具有供水、农灌、发电、调节气候、渔业、航运、旅游七大主要功能，洱海西面有点苍山横列如屏，东面有玉案山环绕衬托，空间环境优美。</p>

            <p className="grey-text">Erhai Lake was called Kunming Pool, Erhe River and Yeyuze in ancient times. Located in Dali City, Dali Bai Autonomous Prefecture, Yunnan Province. The general lake area is about 246 square kilometers (251 square kilometers), with a water storage capacity of about 2.95 billion cubic meters. It is long and narrow. It starts from the southern tip of Eryuan County in the north and ends at Xiaguan, Dali City in the south. Yunnan's second largest lake after Dianchi Lake, ranks seventh among freshwater lakes in China. Erhai Lake was formed at the end of the Ice Age. Its main cause is subsidence and erosion. It belongs to a plateau tectonic faulted lake with an altitude of 1972 meters.
            <br/><br/>
Erhai Lake belongs to the basin of the Lancang River and is the source of the Xi'er River, a tributary of the Yangbi River. The lake water flows from Xi'er River through Xiaguan in downtown Dali and flows westward into Yangbi River.
<br/><br/>
The Erhai Lake has seven main functions: water supply, agricultural irrigation, power generation, climate regulation, fishery, shipping, and tourism. On the west side of the Erhai Lake, there are Cangshan mountains lined up like a screen, and the east side is surrounded by Yuanshan Mountain.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%B4%B1%E6%B5%B7/19448368?fr=aladdin">
                {/* <i className="material-icons left">details</i>   */}
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

  
        <div className="titleContent">
            <h5>2018/06/12</h5>
            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/38.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p></p>
                </div>
            </div>



        </div>
        
    </div>

    <div className="titles" id="title45">
        <h4 className="tooltipped title" data-tooltip="Jiang Gong Ci">蒋公祠</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/18.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/%E8%92%8B%E5%85%AC%E7%A5%A0/@25.6958498,100.1585293,17z/data=!3m1!4b1!4m5!3m4!1s0x3727b8afbef357cb:0xe054c016921be9c6!8m2!3d25.695845!4d100.160718">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">蒋公祠</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal99918">介绍</a>
        <div id="modal99918" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">蒋公祠 - 介绍</h4>
            <p>蒋公祠位于大理古城玉洱路123号，是祭祀清代将领蒋宗汉的祠堂，是古城内保存最完好、最具白族建筑特色的清代祠堂建筑群，大理非物质文化遗产博物馆在“蒋公祠”基础上改扩建而成。</p>

            <p className="grey-text">Jiang Gong Ci is located at No. 123 Yu'er Road in the ancient city of Dali. It is an ancestral hall dedicated to Jiang Zonghan, the general of the Qing Dynasty. The temple was rebuilt and expanded.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E8%92%8B%E5%85%AC%E7%A5%A0/5418422?fr=aladdin">
                {/* <i className="material-icons left">details</i>   */}
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

  
        {/* <div className="titleContent">




        </div> */}
        
    </div>

    <div className="titles" id="title46">
        <h4 className="tooltipped title" data-tooltip="Yu'er Garden">玉洱园</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/19.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Yu'eryuan/@25.6965647,100.1589296,17z/data=!4m8!1m2!2m1!1z546J5rSx5Zut!3m4!1s0x3727b8afc81678ab:0xd67a4301dd2f9dc9!8m2!3d25.6960637!4d100.1613356">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">玉洱园</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal99919">介绍</a>
        <div id="modal99919" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">玉洱园 - 介绍</h4>
            <p>大理玉洱园集白族建筑的精华，外部为飞檐翘角的白族门楼和典雅精巧的三方照壁，以及檐裙彩画的围墙。园内以石块镶砌的多形状的白族花台为主体，栽种了大理的名花异卉，间有老人活动区、茶室及碑亭、石坊、水池点缀，形成富有大理古城传统花园的浓郁风趣。大理以大理石而闻名和以原始大石崇拜而盛行，因此园内修饰装点也以石为主，有大石庵、美人石、石牛、石马井等景点，园内有石砌短墙、石铺路面、石堆灯座等，整座花园追求回归大自然的风格，独具特点。园内设茶花、樱花、牡丹、杜鹃、梅花、月季、竹园等七处花木区，并请文人书法画家题字和作画，以诗、联、佳句装点。
            <br/><br/>
为弘扬大理的文化传统美德，大理市于1995年3月建成恢复“志丹公园”，易名为“玉洱园”。花园落成开放之际，著名白族史学家马耀为玉洱园撰写了《玉洱园碑记》，记述建园始末。</p>

            <p className="grey-text">Dali Yu'er Garden is the essence of Bai architecture. The exterior is the Bai gate tower with raised eaves, the elegant and exquisite tripartite wall, as well as the enclosing wall of eaves skirt color painting. The garden is dominated by the multi-shaped Bai flower stand built with stones, planted with Dali's famous flowers and flowers. There are activities areas for the elderly, tea rooms and stele pavilions, stone workshops, and ponds, forming a rich traditional garden in Dali. Funny. Dali is famous for marble and is popular for worshiping primitive large stones. Therefore, the decoration in the garden is also mainly stone. There are scenic spots such as Dashi Temple, Beauty Stone, Stone Niu, and Shimajing. There are short stone walls and stone shops in the park. The pavement, stone pile lamp holders, etc., the entire garden pursues the style of returning to nature, with unique characteristics. Seven flower and tree areas including camellia, cherry blossoms, peonies, rhododendrons, plum blossoms, roses, and bamboo gardens are set up in the garden. Literati calligraphers and painters are invited to write inscriptions and paintings, and decorate them with poems, couplets and beautiful lines.
            <br/><br/>
In order to promote the traditional virtues of Dali, Dali City completed and restored the "Zhidan Park" in March 1995 and was renamed "Yu'er Park". When the garden was completed and opened, Ma Yao, a well-known Bai historian, wrote "The Stele of Yu'er Garden" for Yu'er Garden, describing the beginning and the end of the garden.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%A4%A7%E7%90%86%E7%8E%89%E6%B4%B1%E5%9B%AD/4752114?fr=aladdin">
                {/* <i className="material-icons left">details</i>   */}
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

  
        <div className="titleContent">
            <h5>2018/06/12</h5>
            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/39.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p></p>
                </div>
            </div>



        </div>
        
    </div>

    <div className="titles" id="title47">
        <h4 className="tooltipped title" data-tooltip="Dajin Pagoda">西双版纳大金塔</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/20.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Dajin+Pagoda/@22.0077062,100.8007761,15z/data=!4m8!1m2!2m1!1z6KW_5Y-M54mI57qzIOWkp-mHkeWhlA!3m4!1s0x312abb3ce33a24c5:0xdd4b242b325b3e2d!8m2!3d22.008008!4d100.819512">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">西双版纳大金塔</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        {/* <a className="btn-flat modal-trigger right info " href="#modal99920">介绍</a>
        <div id="modal99920" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">大金塔 - 介绍</h4>
            <p>潍坊风筝广场位于潍坊市中心，是一个集人防工事、商贸购物、休闲娱乐等多功能于一体，凸现“环保特色”、“人文特色”和“风筝文化特色”的市民活动广场。总占地面积约9公顷，其中一期上部广场面积约48000平方米，下部滨河景观区面积约9500平方米，计57500平方米。广场地上景观将建设吉祥物大道、中心广场、滨河景观区、露天剧场与码头，国贸阶梯广场、四平路出口广场、风筝博物馆入口广场、儿童游戏场，植物公园、树阵等十大景观。</p>

            <p className="grey-text">Weifang Kite Plaza is located in the center of Weifang. It is a civic activity plaza that integrates civil air defense fortifications, commerce and shopping, leisure and entertainment, and highlights the "environmental protection", "humanistic characteristics" and "kite cultural characteristics". The total area is about 9 hectares, of which the upper square area of the first phase is about 48,000 square meters, and the lower riverside landscape area is about 9,500 square meters, totaling 57,500 square meters. The above-ground landscape of the square will be built with ten major landscapes including Mascot Avenue, Central Square, Riverside Scenic Area, Amphitheatre and Wharf, China World Trade Center Ladder Plaza, Siping Road Exit Plaza, Kite Museum Entrance Plaza, Children's Playground, Botanical Park, and Tree Array.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E9%A3%8E%E7%AD%9D%E5%B9%BF%E5%9C%BA/5783462?fr=aladdin">

                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div> */}

        </div>
            </div>
        </div>

  
        <div className="titleContent">
            <h5>2018/06/12</h5>
            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/40.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>下午从丽江飞往西双版纳，晚上吃的傣味烧烤，然后逛大金塔夜市。</p>
                </div>
            </div>



        </div>
        
    </div>

    <div className="titles" id="title48">
        <h4 className="tooltipped title" data-tooltip="Wangtian Tree Scenic Area">望天树景区</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/21.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/%E8%A5%BF%E5%8F%8C%E7%89%88%E7%BA%B3%E7%83%AD%E5%B8%A6%E9%9B%A8%E6%9E%97%E5%9B%BD%E5%AE%B6%E5%85%AC%E5%9B%AD%E6%9C%9B%E5%A4%A9%E6%A0%91%E6%99%AF%E5%8C%BA/@21.6224888,101.5883714,13.22z/data=!4m8!1m2!2m1!1z5pyb5aSp5qCR5pmv5Yy6!3m4!1s0x0:0x889aa601456a930e!8m2!3d21.6242095!4d101.5886557">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">望天树景区</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal99921">介绍</a>
        <div id="modal99921" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">望天树景区 - 介绍</h4>
            <p>望天树景区坐落在云南省西双版纳自治州勐腊县城东北方补蚌国家自然保护区内，占地面积864.4公倾，距县城十四公里。
            <br/>
            <br/>
景区内建有旅馆、空中走廊（含树冠走廊及玻璃廊桥）、南腊河观光游船、探险游道、丛林穿越等，既可游玩，也可住宿。如果你到望天树景区游玩，一定要去“空中走廊”体验一下那种令人既惊又险的感觉。走廊长500米，是用粗大的铁索在高大的望天树之间连接建成，用钢绳悬吊，尼龙绳、网作护栏，铝合金梯子作踏板，每一段均与修在树干上的木质平台相连接。西双版纳热带雨林国家公园望天树景区位于中国云南省西双版纳州的勐腊县城东北面，距离县城14km，总占地面积864.4公顷，是西双版纳的重点旅游区，是昆（明）曼（谷）高速公路和泛亚铁路上出境的最后节点，距国家一类口岸磨憨60km，距景洪市134km，距离老挝南塔省城120km，距著名世界文化遗产古城琅勃拉邦370km。</p>

            <p className="grey-text">Wangtian Tree Scenic Area is located in Bubang National Nature Reserve, northeast of Mengla County, Xishuangbanna Autonomous Prefecture, Yunnan Province. It covers an area of ​​864.4 hectares and is 14 kilometers away from the county.
            <br/>
            <br/>
There are hotels, sky corridors (including canopy corridors and glass covered bridges), sightseeing cruises on the Nanla River, adventure trails, jungle crossings, etc., which can be used for play and accommodation. If you visit Wangtianshu Scenic Area, you must go to the "air corridor" to experience the thrilling and dangerous feeling. The corridor is 500 meters long. It is constructed by connecting the tall trees with thick iron ropes. It is suspended by steel ropes, nylon ropes and nets are used as guardrails, and aluminum alloy ladders are used as treads. Each section is connected to the wood fixed on the trunk. The platforms are connected. Xishuangbanna Tropical Rainforest National Park Wangtianshu Scenic Area is located in the northeast of Mengla County, Xishuangbanna Prefecture, Yunnan Province, China. It is 14km away from the county seat and covers an area of ​​864.4 hectares. It is a key tourist area in Xishuangbanna and is the Kun Ming-Bangkok Expressway It is 60km away from Mohan, a national first-class port, 134km away from Jinghong City, 120km away from the provincial capital of Nanta, Laos, and 370km away from Luang Prabang, a famous ancient world cultural heritage city.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%9C%9B%E5%A4%A9%E6%A0%91%E6%99%AF%E5%8C%BA/10528150?fr=aladdin">
                <span className="blue-text">[Source From...]</span>
            </a>   
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

  
        <div className="titleContent">
            <h5>2018/06/13</h5>
            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/41.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>报的望天树一日游。</p>
                    <p>空中走廊</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/42.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>望天树</p>
                </div>
            </div>


        </div>
        
    </div>

    <div className="titles" id="title49">
        <h4 className="tooltipped title" data-tooltip="Sipsongpanna Zongfosi">总佛寺</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/22.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Sipsongpanna+Zongfosi/@21.9884699,100.8036594,17z/data=!3m1!4b1!4m5!3m4!1s0x312abb4e090cbdfb:0xe171eaecc6d76d87!8m2!3d21.9884649!4d100.8058481">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">总佛寺</span>
                </a>

                
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal99922">介绍</a>
        <div id="modal99922" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">总佛寺 - 介绍</h4>
            <p>据景洪市曼斗村寨史资料记载，一千多年前，西双版纳勐泐王宫曾建在现今允景洪街道办曼阁社区曼斗村附近，当时该区域是西双版纳的重要政治区，就佛寺而言，此区域内建有洼宰、洼掌佩、洼龙勐、洼庄德等佛寺。后因战乱，洼宰、洼掌佩、洼龙勐佛寺迁移，只有洼庄德佛寺长期坚守为景洪江北（澜沧江北岸）佛教信众服务，直至后来景洪江北各傣族村寨建盖佛寺，洼庄德佛寺才逐渐衰落。现遗址内还保留有洼庄德佛寺大殿柱子的石墩。洼庄德佛寺是西双版纳傣泐信众的重要佛寺之一，是广大佛教信众恭敬供养佛、法、僧三宝，同时为祈福所管辖范围的黎民百姓得到佛法的护佑，祈求世间安泰，降福于百姓而修建的佛寺。</p>

            <p className="grey-text">According to historical records of Mandou Village in Jinghong City, more than a thousand years ago, the Mengle Palace in Xishuangbanna was built near Mandou Village, Mange Community, Yunjinghong Street Office. At that time, the area was an important political area in Xishuangbanna, and it was just a Buddhist temple. In other words, there are Buddhist temples such as Wazai, Wazhangpei, Walongmeng, and Wazhuangde built in this area. Later, due to the war, Wazai, Wazhangpei, and Walongmeng Buddhist temples were moved. Only Wazhuang Defo Temple maintained a long-term service for Buddhist believers in the north of the Jinghong River (the northern bank of the Lancang River). Later, various Dai villages in the north of Jinghong River built Buddhist temples and Wazhuang. Defo Temple only gradually declined. The stone piers of the main hall of Wazhuang Defo Temple are still preserved in the site. Wazhuang De Buddhist Temple is one of the important Buddhist temples for the Dai people in Xishuangbanna. It is a Buddhist worshiper who respectfully offers the three treasures of Buddha, Dharma and Sangha. At the same time, it prays for the blessings of the Li people under the jurisdiction of the Li people to receive the blessings of the Buddha, pray for peace in the world, and bless the people. And the built Buddhist temple.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%99%AF%E6%B4%AA%E6%80%BB%E4%BD%9B%E5%AF%BA/8710977?fromtitle=%E6%80%BB%E4%BD%9B%E5%AF%BA&fromid=10675203&fr=aladdin">
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

  
        {/* <div className="titleContent">



        </div> */}
        
    </div>

    <div className="titles" id="title50">
        <h4 className="tooltipped title" data-tooltip="Manting Park">曼听公园</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/23.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Manting+Park/@21.992875,100.8044603,17z/data=!3m1!4b1!4m5!3m4!1s0x312abb4eb13bb367:0x84ce6a5d88ff418d!8m2!3d21.99287!4d100.806649">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">曼听公园</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal99923">介绍</a>
        <div id="modal99923" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">曼听公园 - 介绍</h4>
            <p>曼听公园位于西双版纳傣族自治州首府景洪市东南方，距城区约2公里，处于澜沧江与流沙河汇合的三角地带，占地面积400余亩。有保存完好的500多株古铁刀木林及植被，园内有山丘和河道，又有民族特色浓郁的人文景观，是个天然的村寨式公园，以前是傣王御花园，已有1300多年的历史。景区集中体现了“傣王室文化、佛教文化、傣民俗文化”三大主题特色。曼听公园是西双版纳最古老的公园，傣族习惯把她叫做“春欢”，意为“灵魂之园”，过去是西双版纳傣王的御花园，传说傣王妃来游玩时，这里的美丽景色吸引了王妃的灵魂，因而得名。</p>

            <p className="grey-text">Manting Park is located in the southeast of Jinghong City, the capital of Xishuangbanna Dai Autonomous Prefecture, about 2 kilometers away from the urban area. It is located in the triangle area where the Lancang River and Liusha River converge, covering an area of more than 400 acres. There are more than 500 well-preserved ancient iron sword trees and vegetation. There are hills and rivers in the park, and there are rich ethnic cultural landscapes. It is a natural village-style park. It used to be the Royal Garden of the Dai King, which has been used for more than 1,300 years. history. The scenic spot embodies the three themes of "Dai royal culture, Buddhist culture, and Dai folk culture". Manting Park is the oldest park in Xishuangbanna. The Dai people used to call her "Chunhuan", which means "soul garden". It used to be the royal garden of the Dai King in Xishuangbanna. Legend has it that when the Dai Princess came to play, the beautiful scenery here attracted The soul of the princess, hence the name.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%9B%BC%E5%90%AC%E5%85%AC%E5%9B%AD/6598751?fr=aladdin">
                {/* <i className="material-icons left">details</i>   */}
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>

        </div>

        <div className="titleContent">
            <h5>2018/06/13</h5>
            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/43.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p></p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/44.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>傣族宫廷舞</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/45.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p></p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/46.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>篝火晚会</p>
                </div>
            </div>


        </div>
        
    </div>

    <div className="titles" id="title51">
        <h4 className="tooltipped title" data-tooltip="XiShuangBanNa Tropical Botanical Garden">中国科学院热带植物园</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/24.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/%E4%B8%AD%E5%9B%BD%E7%A7%91%E5%AD%A6%E9%99%A2%E8%A5%BF%E5%8F%8C%E7%89%88%E7%BA%B3%E7%83%AD%E5%B8%A6%E6%A4%8D%E7%89%A9%E5%9B%AD/@21.91993,101.2757583,17z/data=!3m1!4b1!4m5!3m4!1s0x312adf94c22bfc9f:0x4059ffbc7c519403!8m2!3d21.919925!4d101.277947">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">中国科学院热带植物园</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal99924">介绍</a>
        <div id="modal99924" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">中国科学院热带植物园 - 介绍</h4>
            <p>中国科学院西双版纳热带植物园，国家AAAAA级旅游景区，位于中国云南省西双版纳傣族自治州勐腊县勐仑镇葫芦岛，中国面积最大、收集物种最丰富、植物专类园区最多的植物园，也是集科学研究、物种保存和科普教育为一体的综合性研究机构和风景名胜区。
            <br/><br/>
中国科学院西双版纳热带植物园前身为1959年蔡希陶教授领导创建的“西双版纳热带植物园”，经历数次重组、改隶后，1996年9月经中央机构编制委员会办公室批准，定名为中国科学院西双版纳热带植物园，隶属于中国科学院。</p>

            <p className="grey-text">Xishuangbanna Tropical Botanical Garden of Chinese Academy of Sciences, a national AAAAA-level tourist attraction, is located in Huludao, Menglun Town, Mengla County, Xishuangbanna Dai Autonomous Prefecture, Yunnan Province, China. It is the largest botanical garden in China with the largest collection of species and the most plant-specific parks. It is also a collection of scientific research and species A comprehensive research institution and scenic spot integrating preservation and popular science education.
            <br/><br/>
The Xishuangbanna Tropical Botanical Garden of the Chinese Academy of Sciences was formerly known as the "Xishuangbanna Tropical Botanical Garden" founded by Professor Cai Xitao in 1959. After several reorganizations and changes, it was named the Xishuangbanna Tropical Botanical Garden of the Chinese Academy of Sciences in September 1996 with the approval of the Office of the Central Organization Committee. Chinese Academy of Sciences.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E4%B8%AD%E5%9B%BD%E7%A7%91%E5%AD%A6%E9%99%A2%E8%A5%BF%E5%8F%8C%E7%89%88%E7%BA%B3%E7%83%AD%E5%B8%A6%E6%A4%8D%E7%89%A9%E5%9B%AD/3030034?fr=aladdin">
                {/* <i className="material-icons left">details</i>   */}
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

  
        <div className="titleContent">
            <h5>2018/06/14</h5>
            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/47.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>棕榈园</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/48.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>百花园</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/49.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p></p>
                </div>
            </div>





        </div>
        
    </div>

    <div className="titles" id="title52">
        <h4 className="tooltipped title" data-tooltip="Stone Forest Scenic Area">石林</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/25.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Stone+Forest+Scenic+Area/@23.4247468,101.1099819,8z/data=!4m8!1m2!2m1!1z55-z5p6X!3m4!1s0x36d010f723e3eaeb:0xcfe66b71c7b15b0f!8m2!3d24.817106!4d103.329199">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">石林</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal99925">介绍</a>
        <div id="modal99925" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">石林 - 介绍</h4>
            <p>昆明市石林风景区，又称为云南石林，位于昆明石林彝族自治县境内，距离云南省会昆明78公里。范围达350平方公里。
<br/><br/>
昆明市石林风景区开发为游览区的主要是：石林风景区、黑松岩（乃古石林）风景区、飞龙瀑（大叠水）风景区、长湖风景区。
<br/><br/>
昆明市石林风景区已被联合国文教科评为“世界地质公园”，“世界自然遗产风光”。1982年，经国务院批准成为首批国家级重点风景名胜区之一，是国家5A级旅游景区、全国文明风景旅游区。</p>

            <p className="grey-text">Kunming Stone Forest Scenic Area, also known as Yunnan Stone Forest, is located in Kunming Shilin Yi Autonomous County, 78 kilometers away from Kunming, the capital of Yunnan Province. The area is 350 square kilometers.
            <br/><br/>
The Shilin Scenic Area in Kunming City is developed into a tourist area mainly: Shilin Scenic Area, Heisongyan (Naigu Stone Forest) Scenic Area, Feilong Waterfall (Dadieshui) Scenic Area, and Changhu Scenic Area.
<br/><br/>
Kunming's Stone Forest Scenic Area has been appraised as "World Geological Park" and "World Natural Heritage Scenery" by the United Nations Educational and Cultural Sciences. In 1982, it was approved by the State Council to become one of the first batch of national key scenic spots. It is a national 5A-level tourist attraction and a national civilized scenic tourist area.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E6%98%86%E6%98%8E%E5%B8%82%E7%9F%B3%E6%9E%97%E9%A3%8E%E6%99%AF%E5%8C%BA/15516834?fromtitle=%E7%9F%B3%E6%9E%97&fromid=9709681&fr=aladdin">
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

  
        <div className="titleContent">
            <h5>2018/06/15</h5>
            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/50.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>报的石林九乡纯玩一日游。</p>
                    <p>石林是地上的喀斯特地貌，九乡是地下的喀斯特地貌。</p>
                    <p>断臂的鹰，不适合合影。</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/51.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>千钧一发</p>
                    <p>上面的石头被地震震断，挂在上面。</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/52.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>登顶看全貌</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/53.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>它的名字叫阿诗玛</p>
                    <p>因其形肖美丽传说中的阿诗玛，身着撒尼族的装束、肩背背篓，仰首望天，似在呼唤，又似期待，给传说中的阿诗玛，更增添了想像和期待。</p>
                </div>
            </div>



        </div>
        
    </div>

    <div className="titles" id="title53">
        <h4 className="tooltipped title" data-tooltip="Yiliang Jiuxiang Scenic Area">九乡</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/26.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/Yiliang+Jiuxiang+Scenic+Area/@25.0683728,103.3817063,17z/data=!3m1!4b1!4m5!3m4!1s0x36daa83dba305b3d:0x47099884c8c670db!8m2!3d25.068368!4d103.383895">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">九乡</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal99926">介绍</a>
        <div id="modal99926" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">九乡 - 介绍</h4>
            <p>宜良九乡风景区地处山区、气候温凉，区内峰峦连绵，山峰谷底相对高差200米左右，地表海拔在1750~1900米之间，地势起伏不大。显示出溶洞分布区内地势上升，河流下切浸蚀的青幼年期河流地貌景观。
            <br/><br/>
九乡境内森林茂密，覆盖率达62.3%，珍贵动、植物资源丰富。张口洞古人类居住遗址，代表了我国南方一种独特的旧石器文化，被称为“九乡一绝”。</p>

            <p className="grey-text">Yiliang Jiuxiang Scenic Area is located in a mountainous area with a cool climate. There are continuous peaks and mountains in the area. The relative elevation difference of the peaks and valleys is about 200 meters. The surface elevation is between 1750 and 1900 meters. It shows that the terrain in the karst cave distribution area rises and the river cuts and erodes the young river landscape.
            <br/><br/>
Jiuxiang has dense forests with a coverage rate of 62.3% and rich precious animal and plant resources. The ancient human settlement site of Zhangkoudong represents a unique paleolithic culture in southern my country, and is called "Nine Villages and One Unique".</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E5%AE%9C%E8%89%AF%E4%B9%9D%E4%B9%A1%E9%A3%8E%E6%99%AF%E5%8C%BA/10058607?fr=aladdin">
                {/* <i className="material-icons left">details</i>   */}
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

  
        <div className="titleContent">
            <h5>2018/06/15</h5>
            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/54.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p></p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/55.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>雌雄瀑布</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/56.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>“鳄鱼”石板</p>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12 l5">
                    <img src="../img28/57.png" alt="" className="responsive-img"></img>
                </div>
                <div className="col s12 m12 l6 offset-l1">
                    <p>风吹石弯</p>
                </div>
            </div>


        </div>
        
    </div>

    <div className="titles" id="title54">
        <h4 className="tooltipped title" data-tooltip="Jinma Biji Fang">金马碧鸡坊</h4>
        
        <div className="row">
            <div className="col s12 m12 l5">
                <img src="../img27/27.png" alt="" className="responsive-img"></img>
                <br></br>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.ca/maps/place/%E9%87%91%E9%A9%AC%E7%A2%A7%E9%B8%A1%E5%9D%8A/@25.0331418,102.7077973,17z/data=!3m1!4b1!4m5!3m4!1s0x36d083e3063dd033:0x3b8fef87924e4b2e!8m2!3d25.033137!4d102.709986">
                    <i className="tiny material-icons">location_on</i>
                    <span className="titleMap">金马碧鸡坊</span>
                </a>
            </div>
            <div className="col s12 m12 l6 offset-l1">

            <div className="details">


        <a className="btn-flat modal-trigger right info " href="#modal99927">介绍</a>
        <div id="modal99927" className="modal modal-fixed-footer">
            <div className="modal-content">
            <h4 className="title">金马碧鸡坊 - 介绍</h4>
            <p>金马碧鸡坊位于昆明市中心三市街与金碧路汇处，高12米，宽18米，雕梁画栋精美绝伦，东坊临金马山而名为金马坊，西坊靠碧鸡山而名为碧鸡坊，是昆明的象征。金马碧鸡坊始建于明朝宣德年间，至今已有近四百年的历史。
<br/><br/>
当太阳将落，余辉从西边照射碧鸡坊，它的倒影投到东面街上；同时，月亮则刚从东方升起，银色的光芒照射金马坊，将它的倒影投到西边街面上；两个牌坊的影子，渐移渐近，最后互相交接。这就是60年才会出现一次的"金碧交辉"的奇观。相传，清道光年间，这个奇观曾经出现过一次。由于地球、月亮、太阳运转的角度关系，这样的景，要 60年才能出现一次。"金马碧鸡坊"的设计体现了古代云南人对数学、天文学和建筑学方面的造诣。原“金马碧鸡坊”于10年动乱中被拆毁，现有的"金马碧鸡坊"是1998年在原址按原风格重建的。</p>

            <p className="grey-text">Jinma Biji Fang is located at the intersection of Sanshi Street and Jinbi Road in the center of Kunming. It is 12 meters high and 18 meters wide. The carved beams and paintings are exquisitely carved. The east side is adjacent to Jinma Mountain and is called Jinmafang, and the west side is adjacent to Jieji Mountain. The chicken workshop is a symbol of Kunming. The Golden Horse and Jade Rooster was built in the Xuande period of the Ming Dynasty and has a history of nearly 400 years.
            <br/><br/>
When the sun is about to set, the afterglow shines on Biji Square from the west, and its reflection is cast on the east street; at the same time, the moon has just risen from the east, and the silver light shines on the Golden Horse Square, casting its reflection on the west street. ; The shadows of the two archways gradually moved closer to each other. This is the spectacle of "gold and green" that will only appear once in 60 years. According to legend, this spectacle once appeared once during the Daoguang Period of the Qing Dynasty. Due to the angle of rotation of the earth, moon, and sun, such a scene can only appear once in 60 years. The design of "Golden Horse and Jade Rooster" reflects the ancient Yunnan people's accomplishments in mathematics, astronomy and architecture. The original "Jinma Biji Fang" was demolished during the 10-year turmoil. The existing "Jinma Biji Fang" was rebuilt in 1998 on the original site in the original style.</p>
            <a className="right" target="_blank" rel="noopener noreferrer" href="https://baike.baidu.com/item/%E9%87%91%E9%A9%AC%E7%A2%A7%E9%B8%A1%E5%9D%8A/902721?fr=aladdin">
                {/* <i className="material-icons left">details</i>   */}
                <span className="blue-text">[Source From...]</span>
            </a>    
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close btn-flat">Close</a>
            </div>
        </div>

        </div>
            </div>
        </div>

  
        {/* <div className="titleContent">




        </div> */}
        
    </div>


    <h4 className="title"> </h4>

    {/* <div className="Comment">
    
        <div className="row">   
        <div className="col s1 m1 l1">
            <a className="btn btn-floating black lighten-1 ">YX</a>
        </div>
        <div className="col s11 m11 l11 commentContent">
            <form action="">
                <div className="input-field">
                    <textarea id="message" className="materialize-textarea"></textarea>
                    <label htmlFor="message">发表评论</label>
                </div>
            </form>
        </div>
        </div>

      

    </div> */}
    


</div>
</div>

         
<Footer/>
              </section>
        )
    }
}





export default travelNoteXianYunnanCN;